import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'; 
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { Input , InputLabel} from '@material-ui/core'; 
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './columnist-jss'; 
import ls from 'local-storage';

class AddColumnistForm extends React.Component {
    
  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

 
  state ={
    columnist_name:'',
    synthesis:0,
    openNotification:false,
    typeNotification:"info",
    messageNotification:""
  }

  resetValues = (band) =>{
    if(band){
      this.setState({
        columnist_name:'',
        synthesis:0
      })
    }else{
      this.setState({
        columnist_name : this.props.dataInit ? this.props.dataInit.columnist_name :'',
        synthesis : this.props.dataInit ? this.props.dataInit.menu_id : 0
      })
    }
  }

  addDefaultSrc(ev){
    ev.target.src = "";
  }
  
  handleChange = event => {
    //if(!Alphanumeric(event.target.value) && (event.target.value.toString().trim('').length < 101)){
      this.setState({ [event.target.name]: event.target.value });
    //}
  };

 
  componentWillReceiveProps(newProps){
    
    if(!newProps.isOpen){
      this.resetValues(true);
    }
    if(newProps.dataInit != null){
      this.setState({
        columnist_name : newProps.dataInit ? newProps.dataInit.columnist_name :'',
        synthesis : newProps.dataInit ? parseInt(newProps.dataInit.menu_id) : 0
    });
    }
    // else{
    //   this.resetValues();
    // }
    
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      onDrop,
      imgAvatar,
      imgError
    } = this.props;
    let dropzoneRef;

    const acceptedFiles = ['image/jpeg', 'image/png', 'image/bmp'];
    const fileSizeLimit = 939999999; 
 
      return(
        <div>
         
        <form onSubmit={(event) => handleSubmit(event, this.state)}>
          <section className={classes.bodyForm}>
            <div>
              <Typography variant="button" className={classes.textCenter}>SUBIR IMAGEN PRINCIPAL</Typography>
              
              <Dropzone
                className={classes.hiddenDropzone}
                accept={acceptedFiles.join(',')}
                acceptClassName="stripes"
                onDrop={onDrop}
                maxSize={fileSizeLimit}
                ref={(node) => { dropzoneRef = node; }}
              />
              <div className={classes.avatarWrap}>
                <Avatar
                  className={classes.uploadAvatar}
                  src={imgAvatar}
                  onError={(event) => this.addDefaultSrc(event)}
                />
                <Tooltip id="tooltip-upload" title="Subir foto">
                  <IconButton
                    className={classes.buttonUpload}
                    component="button"
                    onClick={() => {
                      dropzoneRef.open();
                    }}
                  >
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </div> 
            </div>
            <Typography variant="button" className={classes.textCenter}>{imgError}</Typography>     
            <div>
              <InputLabel htmlFor="columnist_name">Nombre</InputLabel>
              <Input 
                id="columnist_name"
                name="columnist_name"
                value={this.state.columnist_name}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            <div>
              <InputLabel htmlFor="synthesis">Síntesis</InputLabel>
              <Select
                value={this.state.synthesis}
                onChange={this.handleChange}
                className={classes.field}
                name="synthesis"
              >
                {
                  ls.get("session_menu").map((item, index) =>{
                    return(
                      <MenuItem key={index.toString()} value={item.menu_id}>{item.menu_name}</MenuItem>
                    );
                  })                                
                }
              </Select>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button  variant="raised" color="primary" type="submit" disabled={imgAvatar && this.state.synthesis!==0?false:true}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() =>{this.resetValues(false)}}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
      )
     
  }
}

AddColumnistForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit:PropTypes.object,
  isOpen:PropTypes.bool
};
 

export default withStyles(styles)(AddColumnistForm);
