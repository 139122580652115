import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Loader from '../../../../components/Loader/Loader';
import { Button } from '@material-ui/core';

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    maxHeight: 500,
    backgroundColor: 'white',
    textAlign: 'right',
    position: 'absolute',
    width: 700,
    outline: 'none',
    borderRadius: 5,
  };
}

class PreviewSynthesis extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      synthesis_section: [],
      sections: null,
      contentSection: [],
      previewHTML: "",
      isLoading: true
    }
  }


  _content = [];

  componentWillReceiveProps(newProps) {
    if (newProps.preview !== 0 && newProps.openModalPermission) {
      this.setState({
        previewHTML: newProps.preview,
        isLoading: false
      });


    }
  }

  render() {
    const file = new Blob([`${this.state.previewHTML}`], { type: 'text/html' });
    const fileURL = URL.createObjectURL(file);
    const {
      handleCloseModal,
      openModalPermission,
      classes
    } = this.props;

    return (
      <div>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openModalPermission}
          onClose={handleCloseModal}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Button onClick={() => handleCloseModal()} color="primary">Cerrar</Button>
            {
              this.state.isLoading ? <Loader text="Generando vista previa..." /> : <object title="preview" data={fileURL} type="text/html" width="100%" height="465"></object>
              // this.state.isLoading ? <Loader text="Generando vista previa..." /> : <div style={iframeStyle()}><iframe srcDoc={`${this.state.previewHTML}`} width="100%" height="465" /></div>
            }
          </div>
        </Modal>
      </div>
    );
  }
}

PreviewSynthesis.propTypes = {
  classes: PropTypes.object,
  openModalPermission: PropTypes.bool,
  profileSelected: PropTypes.object,
  handleCloseModal: PropTypes.func,
  optionsMenu: PropTypes.array,
  onlyView: PropTypes.bool
};

export default (PreviewSynthesis);
