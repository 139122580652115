import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input , InputLabel} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import styles from './section-jss';
import { ValidationContent } from '../../../validator/Regex';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { charTitleLength } from '../../../utils/validation';

class AddContentSectionForm extends React.Component {
  
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };
  
  state = {
    title:'',
    contId:0,
    isUpdating:false
  };

  handleChange = event => {
    if(!ValidationContent(event.target.value) && event.target.value.toString().trim('').length <= charTitleLength ){
      this.setState({ [event.target.name]: `${event.target.value}` });
    }
  };

  componentWillReceiveProps(newProps){

    if(newProps.dataInit && newProps.dataInit.cont_name){
      this.setState({
        title: this.state.title !== '' ? this.state.title : newProps.dataInit.cont_name,
        contId: this.state.contId !== 0 ? this.state.contId : newProps.dataInit.cont_id,
        isUpdating:true
      });
    } 

    if(!newProps.isOpen){
      this._clearData(true);
      this.setState({
        isUpdating:false,
        contId :0
      });
    }
  }
  
  _clearData = (band) =>{
    if(band){
      this.setState({
        title:''
      });
    }else{
      this.setState({
        title:this.props.dataInit ? this.props.dataInit.cont_name : ''
      });
    }
  }
 
  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      addAttachment,
      attachment
    } = this.props;
    

    let dropzone;
    const acceptedAttachment = [
      'application/pdf'
    ];

    const fileSizeLimit = 93000000;
     
    return (
      <div>
        <form onSubmit={(event)=>{
            handleSubmit(event, this.state);
            this.props.onClean(true);
            this._clearData(true)
        }}>
          <section className={classes.bodyForm}>
            <div>            
              <div >
              <div className={classes.flexCenter}>
                <div>
                  <Dropzone
                    className={classes.hiddenDropzone}
                    accept={acceptedAttachment.join(',')}
                    acceptClassName="stripes"
                    onDrop={addAttachment}
                    maxSize={fileSizeLimit}
                    ref={(node) => { dropzone = node; }}
                  />
                </div>
              </div>
              <div className={classes.wrapCenter}>
                  <Button variant="contained" color="primary" component="span" className={classes.button} onClick={() => {
                      dropzone.open();
                    }}>
                    Subir Archivos
                  </Button>
                  {attachment || (this.props.dataInit && this.props.dataInit.cont_attachment!=0&&this.props.dataInit.cont_attachment) ? <CheckCircle/> : null}
                  {attachment ? <small title={attachment.name}>{attachment.name}</small> : this.props.dataInit && this.props.dataInit.cont_attachment!=0&&<small title={this.props.dataInit.cont_attachment}>{this.props.dataInit.cont_attachment}</small>}
              </div>
            <div>
            <InputLabel htmlFor="title">Título *</InputLabel>
            <Input 
                id="title"
                name="title"
                value={this.state.title}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            {this.state.title.length === charTitleLength && (<div>Has llegado al límite de caracteres ({charTitleLength})</div>)}
          </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button  variant="raised" color="primary" type="submit" disabled={ (this.state.isUpdating && this.state.title!=="") || (this.state.title!=="") ? false : true }>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() =>{ 
                this.props.onClean(!this.state.isUpdating);
                setTimeout(() => {
                  this._clearData(false);                   
                }, 500);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddContentSectionForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dataInit:PropTypes.object,
  contentType:PropTypes.string,
  preview: PropTypes.string,
  onClean:PropTypes.func,
  isOpen:PropTypes.bool
};

export default withStyles(styles)(AddContentSectionForm);
