import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../../utils/brand';
import { ThemeContext } from '../../../../utils/ThemeContext';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../components/Tables/tableParts/tableStyle-jss';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import { fetchCall } from '../../../../service/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { Snackbar, Toolbar, Hidden, Button, Tooltip, Paper, Table, TableBody, TableHead, TableRow, Switch, IconButton, Icon, Typography } from '@material-ui/core';
import CustomNotification from '../../../../components/Notification/CustomNotification';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import SearchIcon from '@material-ui/icons/Search';
import AddEditorial from './AddEditorial';
import TableCell from '@material-ui/core/TableCell';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import { saveImage } from '../../../../service/s3Amplify';


const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#9F9F9F',
        padding: '4px 24px 4px 24px',
    },
    body: {
        padding: '4px 24px 4px 24px',
        fontSize: 14,
    },
}))(TableCell);

class Editorials extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        anchorEl: null,
        dataColumnist: [],
        openAddColumnist: false,
        columnistSelected: null,
        editColumnist: false,
        openNotification: false,
        typeNotification: "info",
        messageNotification: "",
        canEdit: true,
        canShow: false,
        canPublish: true,
        canDelete: true,
        searchData: [],
        openConfirmDialog: false,
        idDelete: 0,
        isLoading: true,
        currentUser: ls.get("current_user")['user_id']
    }

    handleCloseNotification = () => {
        this.setState({ openNotification: false, messageNotification: '' });
    }

    componentDidMount() {
        this.context.toggleTheme(undefined);
        this._getUserPermissions();
    }

    _getUserPermissions = () => {
        let currentUser = ls.get("current_user");
        let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
        if (permissionsCurrentUser[0] != null) {
            let add = permissionsCurrentUser.find(x => x.name === "Configuración/Catálogos/Editoriales");
            if (add != undefined || add != null) {
                let currentPermissions = ls.get("permissions");
                if (currentPermissions != undefined || currentPermissions != null) {
                    let data = currentPermissions.find(x => x.menu_id == add.id);
                    if (data != undefined) {
                        let config = JSON.parse(data.grant_configuration);
                        this.setState({
                            canShow: config.chkViewEditorial,
                            canEdit: config.chkEditEditorial,
                        })
                        this._getUsersByList()
                    }

                }
            }
        }
    }

    _getUsersByList = () => {
        fetchCall("GET", "/columnist/get").then(response => {
            const editorials = response.data.filter(x => x.isEditorial === 1)
            console.log(editorials)
            this.setState({
                dataColumnist: editorials,
                searchData: editorials,
                isLoading: false
            });
        }).catch(err => console.log(err));
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    _handleOpenAddColumnist = () => {
        this.setState({
            openAddColumnist: true,
            columnistSelected: null,
            editColumnist: false
        })
    }

    closeNewColumnist = () => {
        this.setState({
            columnistSelected: null,
            openAddColumnist: false
        })
    }

    _handleEditColumnist = (columnist) => {
        this.setState({
            columnistSelected: columnist,
            openAddColumnist: true,
            editColumnist: true
        })
    }

    _handleDeleteColumnist = () => {
        this.setState({ isLoading: true });
        this.state.columnistSelected.active = 2;
        console.log(this.state.columnistSelected)
        fetchCall("POST", "/columnist/update", this.state.columnistSelected).then(response => {
            console.log(response)
            if (response.status == 200) {
                this.setState({
                    openNotification: true,
                    messageNotification: "Se eliminó " + this.state.columnistSelected.columnist_name,
                    typeNotification: 'success',
                    openConfirmDialog: false,
                    idDelete: 0,
                    isLoading: false
                })
                this._getUsersByList();
            }
        }).catch(err => console.log(err))
    }

    _search = (event) => {
        let searchWord = event.target.value.toString().toLowerCase();
        let result = this.state.dataColumnist.slice(0);
        let arr = result.filter(x => {
            if (`${x.columnist_name}`.toLowerCase().includes(searchWord)) {
                return x;
            }
            // if(x.nombre.toString().toLowerCase().includes(searchWord) ||
            //    x.apellido.toString().toLowerCase().includes(searchWord) || 
            //    x.correo.toString().toLowerCase().includes(searchWord)){
            //   arr.push(x); 
            // }

        });
        this.setState({ searchData: arr });
    }

    handleStatus = (item) => {
        console.log(item)
        this.setState({ isLoading: true });
        item.active = item.columnist_status == 1 ? 0 : 1
        item.menu_id = 0
        fetchCall("POST", "/columnist/update", item).then(response => {
            console.log(response)
            if (response.status == 200) {
                this.setState({
                    openNotification: true,
                    messageNotification: "Se cambió el estatus a " + item.columnist_name,
                    typeNotification: 'success',
                    openConfirmDialog: false,
                    idDelete: 0,
                    isLoading: false
                })
                this._getUsersByList();
            } else {
                this.setState({
                    openNotification: true,
                    messageNotification: "Ocurrió un error al cambiar el estatus",
                    typeNotification: "error",
                    isLoading: false
                });
            }
        }).catch(err => console.log(err));
    }

    submitColumnist = (event, data, file) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        data.active = 1;
        data.isEditorial = 1;
        if (data.columnist_name.toString().trim() === '') {
            this.setState({
                openNotification: true,
                messageNotification: 'Debes ingresar un nombre',
                typeNotification: 'warning',
                isLoading: false
            });
            return false;
        }
        let exists = this.state.dataColumnist.find(x => x.columnist_name === data.columnist_name.toString().trim().toLowerCase());
        if (exists != undefined) {
            this.setState({
                openNotification: true,
                messageNotification: 'Ya existe un editorial con este nombre',
                typeNotification: 'warning',
                isLoading: false
            });
            return false;
        }

        let keyS3 = `Catalogs/Editorial/${file ? `${new Date().getTime()+"_" + file.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}`;
        if (this.state.editColumnist) {
            data.columnist_id = this.state.columnistSelected.columnist_id;
            Promise.all([(file ? saveImage(keyS3, file, file.type) : null)])
                .then(response => {
                    data.columnist_photo = response[0] !== null ? response[0].key : this.state.columnistSelected.columnist_photo;
                    fetchCall("POST", "/columnist/update", data).then(response => {
                        if (response.status == 200) {
                            this.setState({
                                openAddColumnist: false,
                                editColumnist: false,
                                openNotification: true,
                                messageNotification: "Se modificó el editorial",
                                typeNotification: 'success',
                                isLoading: false,
                                columnistSelected: null
                            });
                            this._getUsersByList();
                        }
                    }).catch(err => console.log(err));
                })
        } else {
            Promise.all([saveImage(keyS3, file, file.type)])
                .then(response => {
                    data.columnist_photo = response[0].key;
                    fetchCall("POST", "/columnist/add", data).then(response => {
                        if (response.status == 200) {
                            this.setState({
                                openAddColumnist: false,
                                editColumnist: false,
                                openNotification: true,
                                messageNotification: "Se agregó el editorial",
                                typeNotification: 'success',
                                isLoading: false
                            });
                            this._getUsersByList();
                        }
                    }).catch(err => console.log(err));
                })
        }
    }

    _openConfirmDialog = (columnist) => {
        this.setState({
            openConfirmDialog: true,
            columnistSelected: columnist
        });
    }

    _closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
            columnistSelected: null
        });
    }

    render() {
        const {
            classes,
        } = this.props;
        const title = brand.name + '- Contáctos';
        const description = brand.desc;
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="twitter:title" content={title} />
                    <meta property="twitter:description" content={description} />
                </Helmet>

                {this.state.isLoading ? <LoaderFull /> : ''}

                <AddEditorial
                    openForm={this.state.openAddColumnist}
                    closeForm={this.closeNewColumnist}
                    classes={classes}
                    submit={this.submitColumnist}
                    dataInit={this.state.columnistSelected}
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openNotification}
                    autoHideDuration={6000}
                    onClose={this.handleCloseNotification}>
                    <CustomNotification
                        onClose={this.handleCloseNotification}
                        variant={this.state.typeNotification}
                        message={this.state.messageNotification} />
                </Snackbar>

                <ConfirmDialog
                    title={"¿Está seguro que desea eliminar el editorial?"}
                    message={"El editorial al ser eliminado no se podrá recuperar"}
                    textAccept={"Aceptar"}
                    textCancel={"Cancelar"}
                    funcAccept={this._handleDeleteColumnist}
                    funcCancel={this._closeConfirmDialog}
                    open={this.state.openConfirmDialog}
                />

                <PapperBlock title="Editoriales" fullTitle>
                    <>
                        <Toolbar className={classes.toolbar}>
                            <div className={classes.name}>
                                {/* TODO: AGREGAR INPUT DE BUSQUEDA */}

                                <div className={classes.flex}>
                                    <div className={classes.searchWrapper}>
                                        <div className={classes.search}>
                                            <SearchIcon />
                                        </div>
                                        <input className={classes.input} onChange={(event) => this._search(event)} placeholder="Buscar editorial" />
                                    </div>
                                </div>
                            </div>

                            {/* <div className={classes.spacer} /> */}
                            <div className={classes.actions}>
                                <Hidden xsDown>
                                    <Button variant="raised" onClick={this._handleOpenAddColumnist} color="secondary" className={classes.button}>
                                        <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                        Nuevo
                                    </Button>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title="Nuevo" placement="top">
                                        <Button variant="fab" onClick={this._handleOpenAddColumnist} mini color="secondary" aria-label="add">
                                            <AddIcon className={classNames(classes.iconSmall)} />
                                        </Button>
                                    </Tooltip>
                                </Hidden>

                            </div>
                        </Toolbar>
                        <Paper className={classes.rootTable}>
                            <div className={classes.maxHeightTable}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell align="right">Nombre</CustomTableCell>
                                            <CustomTableCell align="right">Tipo de síntesis</CustomTableCell>
                                            <CustomTableCell align="right">Estatus</CustomTableCell>
                                            <CustomTableCell align="right">Acciones</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.searchData.map((row, i) => {
                                            return (
                                                <TableRow className={classes.row} key={row.columnist_id}>
                                                    <CustomTableCell component="th" scope="row">
                                                        {row.columnist_name}
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">{row.menu_name}</CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        <Switch
                                                            disabled={!this.state.canEdit}
                                                            checked={row.columnist_status === 1 ? true : false}
                                                            onChange={() => { this.handleStatus(row) }}
                                                            value="checkedB"
                                                            color="primary"
                                                        />
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        <div key={i} className={classes.contentIcons}>
                                                            <Tooltip title="Editar">
                                                                <IconButton disabled={!this.state.canEdit} onClick={() => {
                                                                    this._handleEditColumnist(row)
                                                                }} color="secondary" className={classes.button}>
                                                                    <Icon>edit</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Eliminar">
                                                                <IconButton disabled={!this.state.canEdit} onClick={() => {
                                                                    this._openConfirmDialog(row)
                                                                }} color="secondary" className={classes.button}>
                                                                    <Icon>delete</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                            {
                                this.state.searchData.length === 0 ?
                                    <Typography className={classes.noMatch}>No se encontraron editorials con ese criterio de búsqueda</Typography> : ''
                            }
                        </Paper>
                    </>
                </PapperBlock>
            </>
        );
    }
}

Editorials.propTypes = {
    classes: PropTypes.object.isRequired
};

Editorials.contextType = ThemeContext;

export default withStyles(styles)(Editorials);