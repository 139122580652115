import Amplify ,{ Storage } from 'aws-amplify';
import awsmobile from '../../aws-exports';

Amplify.configure(awsmobile);

const PATH_PHOTO_SYNTHESIS ='Synthesis/Icons/';
const PATH_ICON_SECTION_SYNTHESIS = 'Synthesis/';

export const getPhotoCurrentUser = (username) => {
    return new Promise(
        function(resolve, reject){
            Storage.get(username)
            .then(result => resolve(result))
            .catch(err => reject(err));
        }
    );
}

export const getKeys = (path) => {
    return new Promise(
        function(resolve, reject){
            Storage.list(path).then(response => resolve(response)).catch(err =>reject(err));
        }
    );
}

export const getKeysProtected = (path) => {
    return new Promise(
        function(resolve, reject){
            Storage.list(path).then(response => resolve(response)).catch(err =>reject(err));
        }
    );
}

export const getUrlImage = (userId) =>{
    return new Promise(
        function(resolve, reject){
            Storage.get(userId)
            .then(result => resolve(result))
            .catch(err => reject(err));
        }
    );
}

export const saveUserPhoto = (name, file, content) =>{
    return new Promise(
        function(resolve, reject){
            Storage.put("ProfilePictures/"+name, file, {
                contentType: content
            })
            .then (result => {
              resolve(result);
            }).catch(err => reject(err));
        }
    )
}

export const saveIconSynthesis = (name, file, content) =>{
    return new Promise(
        function(resolve, reject){
            Storage.put(PATH_PHOTO_SYNTHESIS + name, file, {
                contentType: content
            })
            .then (result => {
              resolve(result);
            }).catch(err => reject(err));
        }
    )
}

export const getUrlIconSynthesis = (userId) =>{
    return new Promise(
        function(resolve, reject){
            Storage.get(userId)
            .then(result => resolve(result))
            .catch(err => reject(err));
        }
    );
}

export const saveIconSectionSynthesis = (name, file, content) =>{
    return new Promise(
        function(resolve, reject){
            Storage.put(PATH_ICON_SECTION_SYNTHESIS + name, file, {
                contentType: content
            })
            .then (result => {
              resolve(result);
            }).catch(err => reject(err));
        }
    )
}

export const savePortrait = (name, file, content) =>{
    return new Promise(
        function(resolve, reject){
            Storage.put(PATH_ICON_SECTION_SYNTHESIS + name, file, {
                contentType: content
            })
            .then (result => {
              resolve(result);
            }).catch(err => reject(err));
        }
    )
}

export const saveImage = (name, file, content) =>{
    return new Promise(
        function(resolve, reject){
            Storage.put(name, file, {
                contentType: content
            })
            .then (result => {
              resolve(result);
            }).catch(err => reject(err));
        }
    )
}

export const deleteS3Object = (key) =>{
    return new Promise(
        function(resolve, reject){
            Storage.remove(key)
            .then (result => {
              resolve(result);
            }).catch(err => reject(err));
        }
    )
}