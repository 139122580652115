import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import classNames from 'classnames';
import styles from './cardStyle-jss';
import {S3Image} from 'aws-amplify-react';
class CartoonCard extends React.Component {
  render() {
    const {
      classes,
      children,
      title,
      image,
      attachment
    } = this.props;
    return (
      <Card className={classNames(classes.cardMedia)}>
        {/* <CardMedia
          className={classes.mediaCartoon}
          image={image}
          title={title}
        /> */}
        <div className={classes.mediaCartoon}>
        <S3Image imgKey={image} />
        </div>
        <CardContent className={classNames(classes.contentBetween)}>
          <Typography className={classes.title}>
            {title}
          </Typography>
          <Button variant="raised" color="primary" size="small" className={classNames(classes.rightAction, classes.button)} onClick={() => {
              window.open(`/multimedia/${encodeURIComponent(attachment)}`)
            }} >
                Ver más
          </Button>
        </CardContent>
        {/* <CardActions className={classes.actions} disableActionSpacing>
            <Button variant="outlined" color="secondary" size="small" className={classNames(classes.rightAction, classes.button)} onClick={() => {
              window.open(`/multimedia/${encodeURIComponent(attachment)}`)
            }} >
                Cartones
            </Button>
        </CardActions> */}
      </Card>
    );
  }
}

CartoonCard.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default withStyles(styles)(CartoonCard);
