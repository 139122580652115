import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input , InputLabel} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';

import styles from './profile-jss';
import {Alphanumeric} from '../../../../validator/Regex';
// validation functions
// const required = value => (value == null ? 'Required' : undefined);
// const email = value => (
//   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
//     ? 'Invalid email'
//     : undefined
// );

class AddProfileForm extends React.Component {
  
  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state ={
    proName:'',
    proDescripcion:'',
    checkedB:false
  }

  resetValues = () =>{
    this.setState({
      proName:'',
      proDescripcion:'',
      checkedB:false
    })
  }

  handleChange = (event, c) => {
    if(!Alphanumeric(event.target.value) && event.target.value.toString().split('').length <= c){
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  componentWillReceiveProps(newProps){
    
    if(newProps.dataInit != null){
      
      this.setState({
        proName: this.state.proName === '' ? newProps.dataInit.name : this.state.proName,
        proDescripcion: this.state.proDescripcion === '' ?  newProps.dataInit.description : this.state.proDescripcion
      });

    }

    if(!newProps.isOpen){
      this._clearData(true);
    }
    // else{
    //   this._clearData();
    // }
  }

  _clearData = (band) =>{
    if(band){
      this.setState({
        proName: '',
        proDescripcion: ''
      });
    }else{
        this.setState({
          proName: this.props.dataInit ? this.props.dataInit.name :'',
          proDescripcion: this.props.dataInit ? this.props.dataInit.description :''
        });
    }
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit
    } = this.props;
    return (
      <div>
        <form onSubmit={(event) => handleSubmit(event, this.state)}>
          <section className={classes.bodyForm}>
           
            <div>
              <InputLabel htmlFor="proName">Nombre</InputLabel>
              <Input 
                id="proName"
                name="proName"
                value={this.state.proName}
                // value={this.state.proName ? this.state.proName : dataInit.name}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={(event) => this.handleChange(event, 100)}
              />
            </div>
            <div>
            <InputLabel htmlFor="proDescripcion">Descripción</InputLabel>
              <TextField
                id="proDescripcion"
                name="proDescripcion"
                multiline
                rows="3"
                
                className={classes.field}
                onChange={(event) => this.handleChange(event, 200)}
                margin="normal"
                // value={this.state.proDescripcion ? this.state.proDescripcion : dataInit.description}
                value={this.state.proDescripcion}
              />
            </div>
            
          </section>
          <div className={classes.buttonArea}>
            <Button onClick={() => {
              // setTimeout(() => {
              //   this.resetValues();
              // }, 2000);
            }} variant="raised" color="primary" type="submit" disabled={submitting}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={()=>{this._clearData(false)}}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddProfileForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit:PropTypes.object
};
 

export default withStyles(styles)(AddProfileForm);
