import { API } from 'aws-amplify';

const service = process.env.REACT_APP_local==1?process.env.REACT_APP_aws_apigateway_endpoint:
window.location.origin + process.env.REACT_APP_aws_apigateway_behavior;

export const fetchCall = (method,api, data) => {
    return new Promise(
        function (resolve, reject) {
            var myHeaders = new Headers();

            var myInit = {
                method: method,
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            };
            var myRequest = new Request(service + api, myInit);
            localStorage.setItem("last_activity", new Date().getTime());
            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    reject(error);
                });
        }
    );
}

export const apiElasticSearch = (path, body) => {
    let myInit = {
        body: body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    return new Promise(
        function(resolve, reject){
            API.post(process.env.REACT_APP_elastic_name, path, myInit).then(response => {
                resolve(response); 
            }).catch(error => {
                reject(error); 
            });
        }
    )
}