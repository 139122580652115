import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// Reducer Branch
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import styles from '../../components/Tables/tableParts/tableStyle-jss';
import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Message from '@material-ui/icons/Message';
import ContactMail from '@material-ui/icons/ContactMail';
import ClearAll from '@material-ui/icons/ClearAll';
import Description from '@material-ui/icons/Description';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#9F9F9F',
    padding: '4px 24px 4px 24px',
  },
  body: {
    padding: '4px 24px 4px 24px',
    fontSize: 14,
  },
}))(TableCell);

const ITEM_HEIGHT = 48;

class ProfileTable extends Component {

  state = {
    anchorEl: null
  }

  handleClick = (event, profileId) => {
    this.setState({ [`${profileId}`]: true, anchorEl: event.currentTarget });
  };

  handleClose = (profileId) => {
    this.setState({ [`${profileId}`]: null, anchorEl: null });
  };

  render() {

    const {
      classes,
      dataProfile,
      openNewProfile,
      handleStatus,
      handleDefaultProfile,
      handleEditProfile,
      handleViewPermissions,
      handleEditPermissions,
      handleDeletePermissions,
      canPublish,
      canEdit,
      canDelete,
      canShowPermissions,
      canEditPermissions
    } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
          <Toolbar className={classes.toolbar} style={{justifyContent: "flex-end"}}>
              <Button variant="raised" disabled={canPublish} onClick={openNewProfile} color="secondary" className={classes.button}>
                <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                Nuevo
              </Button>
          </Toolbar>
        <Paper className={classes.rootTable}>
          <div className={classes.maxHeightTable}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Clave</CustomTableCell>
                <CustomTableCell align="right">Nombre</CustomTableCell>
                <CustomTableCell align="right">Descripción</CustomTableCell>
                <CustomTableCell align="right">Opciones del menú</CustomTableCell>
                <CustomTableCell align="right">Usuarios</CustomTableCell>
                <CustomTableCell align="right">Estatus</CustomTableCell>
                <CustomTableCell align="right">Default Rol</CustomTableCell>
                <CustomTableCell align="right">Opciones</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataProfile.map(row => {
                return (
                  <TableRow className={classes.row} key={row.profileId}>
                    <CustomTableCell component="th" scope="row">
                      {row.key}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {row.name}
                    </CustomTableCell>
                    <CustomTableCell align="right">{row.description}</CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classNames(classes.contentIcons, classes.alignCellIcon)}>
                        {
                          JSON.parse(row.menuSynthesis !== null ? "[" + row.menuSynthesis + "]" : '[]').map((item, index) => {
                            const getIcon = (item) => {

                              switch (parseInt(item.id)) {
                                case 1:
                                  return <AccountCircle className={classes.icon} color="primary" />
                                  break;
                                case 2:
                                  return <AssignmentInd className={classes.icon} color="primary" />
                                  break;
                                case 4:
                                  return <SupervisorAccount className={classes.icon} color="primary" />
                                  break;
                                case 5:
                                  return <ContactMail className={classes.icon} color="primary" />
                                  break;
                                case 6:
                                  return <ClearAll className={classes.icon} color="primary" />
                                  break;
                                case 7:
                                  return <Description className={classes.icon} color="primary" />
                                  break;
                                default:
                                  return <ChromeReaderMode className={classNames(classes.icon,
                                    item.description === 'a' ? classes.colorUno :
                                      item.description === 'b' ? classes.colorDos :
                                        item.description === 'c' ? classes.colorTres :
                                          item.description === 'd' ? classes.colorCuatro : '')} color="primary" />
                              }
                            }
                            return (
                              <Tooltip title={item.name}>
                                <div key={index.toString()} className={classes.icon}>
                                  {getIcon(item)}
                                </div>
                              </Tooltip>
                            );
                          })
                        }

                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">{row.users}</CustomTableCell>
                    <CustomTableCell align="right">

                      <Switch
                        checked={row.status === "ON" ? true : false}
                        onChange={() => { handleStatus(row) }}
                        value="checkedB"
                        color="primary"
                        disabled={canEdit}
                      />

                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <Switch
                        checked={row.pro_default === "ON" ? true : false}
                        onChange={() => { handleDefaultProfile(row) }}
                        value="checkedB"
                        color="primary"
                        disabled={canEdit}
                      />
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      <div key={row.profileId} className={classes.contentIcons}>
                        <Tooltip title="Opciones">
                          <IconButton
                            aria-label="More"
                            aria-owns={open ? 'long-menu' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => this.handleClick(event, row.profileId)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          open={Boolean(this.state[`${row.profileId}`])}
                          onClose={() => this.handleClose(`${row.profileId}`)}
                          key={row.profileId}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: 200,
                            },
                          }}
                        >
                          <MenuItem disabled={canEdit} onClick={() => {
                            handleEditProfile(row);
                            this.handleClose(`${row.profileId}`);
                          }}>Editar perfil</MenuItem>

                          <MenuItem disabled={canShowPermissions} onClick={() => {
                            handleViewPermissions(row);
                            this.handleClose(`${row.profileId}`);
                          }}>Ver privilegios</MenuItem>

                          <MenuItem disabled={canEditPermissions} onClick={() => {
                            handleEditPermissions(row);
                            this.handleClose(`${row.profileId}`);
                          }}>Editar privilegios</MenuItem>

                          <MenuItem disabled={canDelete} onClick={() => {
                            handleDeletePermissions(row);
                            this.handleClose(`${row.profileId}`);
                          }}>Eliminar perfil</MenuItem>

                        </Menu>

                      </div>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </div>
        </Paper>
      </div>
    );
  }
}

ProfileTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataProfile: PropTypes.array,
  openNewProfile: PropTypes.func,
  handleStatus: PropTypes.func,
  handleDefaultProfile: PropTypes.func,
  handleEditProfile: PropTypes.func,
  handleViewPermissions: PropTypes.func,
  handleEditPermissions: PropTypes.func,
  handleDeletePermissions: PropTypes.func,
  canPublish: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canShowPermissions: PropTypes.bool,
  canEditPermissions: PropTypes.bool
};

export default withStyles(styles)(ProfileTable);