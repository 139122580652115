import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './notas-jss';

function getActions(color, classes, title, description, notes, attachment) {
  return (
    <Card className={classes.cardRelevante} >
      <div className={classes.details} style={{backgroundColor:color}}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} style={{color:color&&color.includes("#")&&color.toLowerCase()!="#ffffff"?"white":""}}>{title}</Typography>
          <Typography color="textSecondary" className={classes.description}>
            <div style={{fontFamily:"sans-serif",color: color&&color.includes("#")&&color.toLowerCase()!="#ffffff"?"white":""}} 
            dangerouslySetInnerHTML={{ __html: `${description}` }} />
          </Typography>

          <Button variant="raised" color="primary" size="small" className={classNames(classes.mb, classes.mt, classes.button)} onClick={() => window.open(`/multimedia/${encodeURIComponent(attachment)}`)}>
            Leer {notes} {notes>1?'notas':'nota'}
            </Button>
        </CardContent>
      </div>
    </Card>
  );
}

class Notas extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      classes,
      title,
      description,
      color,
      notes,
      attachment
    } = this.props;

    return (
      <div>
        {getActions(color, classes, title, description, notes, attachment)}
      </div>
    );
  }
}

Notas.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object,
  title: PropTypes.string,
  artist: PropTypes.string,
  cover: PropTypes.string,
  notes: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Notas);
