import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input, InputLabel } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import SelectorIcon from '../SelectorIcon'
import styles from './menu-jss';
import ColorPicker from '../../atoms/ColorPicker';
import { primary, secondary } from '../../utils/colors';

class AddMenuForm extends React.Component {

  state = {
    menu_name: '',
    menu_primaryColor: '',
    menu_secondaryColor: '',
    menuId: 0,
    menu_public: 0,
    menu_visible: 1,
    menu_icono: ''
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeIcon = (value) => {
    this.setState({ menu_icono: value })
  }

  handleChangeComplete = (color, input) => {
    if (color) {
      this.setState(
        {
          [input]: color.hex
        }
      )
    }
  };

  onChange = (color) => {
    if (color) {
      this.setState(
        {
          menu_description: color.hex
        }
      )
    }
  }

  getcolors = (data) => {
    let primary = "", secondary = "";
    if (data&&data.menu_description && data.menu_description != '') {
      const colors = data.menu_description.split(",");
      primary = colors[0];
      secondary = colors[1] || colors[0];
    }
    return { primary, secondary }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.dataInit != null) {
      const colors = this.getcolors(newProps.dataInit)
      this.setState({
        menu_name: this.state.menu_name !== '' ? this.state.menu_name : newProps.dataInit.menu_name,
        menu_primaryColor: colors.primary,
        menu_secondaryColor: colors.secondary,
        menuId: this.state.menuId !== 0 ? this.state.menuId : newProps.dataInit.menu_id,
        menu_visible: newProps.dataInit.menu_visible,
        menu_icono: this.state.menu_icono !== '' ? this.state.menu_icono : newProps.dataInit.menu_icono,
      });
    }
    if (!newProps.isOpen) {
      this._clearData();
    }
  }

  _clearData = () => {
    const colors = this.getcolors(this.props.dataInit)
    this.setState({
      menu_name: this.props.dataInit ? this.props.dataInit.menu_name : '',
      menu_primaryColor: colors.primary,
      menu_secondaryColor: colors.secondary,
      menuId: this.props.dataInit ? this.props.dataInit.menu_id : 0,
      menu_visible: this.props.dataInit ? this.props.dataInit.menu_visible : 1,
      menu_icono: this.props.dataInit ? this.props.dataInit.menu_icono : '',
    });
  }

  handleCheck = (ev) => {
    this.setState(
      {
        menu_public: ev.target.checked ? 1 : 0
      }
    )
  }

  render() {
    const {
      classes,
      handleSubmit
    } = this.props;

    const {
      menu_name, menu_icono, menu_primaryColor, menu_secondaryColor
    } = this.state;

    return (
      <div>
        <form onSubmit={(event) => handleSubmit(event, this.state)}>
          <section className={classes.bodyForm}>
            <div>
              <InputLabel htmlFor="menu_name">Nueva síntesis*</InputLabel>
              <Input
                id="menu_name"
                name="menu_name"
                value={menu_name}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            <InputLabel htmlFor="menu_primaryColor" className={classes.dblock}>Selecciona un ícono*</InputLabel>
            <SelectorIcon onChange={this.handleChangeIcon} value={menu_icono} />
            <div>
              <InputLabel htmlFor="menu_primaryColor" className={classes.dblock}>Selecciona un color principal*</InputLabel>
              <div className={classes.colorsPicker}>
                <ColorPicker
                  color={menu_primaryColor}
                  colors={primary}
                  triangle={`top`}
                  width={`100%`}
                  onChangeComplete={(e) => this.handleChangeComplete(e, "menu_primaryColor")}
                />
              </div>
            </div>
            <div>
              <InputLabel htmlFor="menu_secondaryColor" className={classes.dblock}>Selecciona un color secundario</InputLabel>
              <div className={classes.colorsPicker}>
                <ColorPicker
                  color={menu_secondaryColor}
                  colors={secondary}
                  triangle={`top`}
                  width={`100%`}
                  onChangeComplete={(e) => this.handleChangeComplete(e, "menu_secondaryColor")}
                />
              </div>
            </div>

          </section>
          <div className={classes.buttonArea}>
            <Button variant="raised" color="primary" type="submit" disabled={(menu_name.trim() === "" || menu_icono === "" || menu_primaryColor==="") ? true : false}>
              Guardar
            </Button>
            <Button
              type="button"
              onClick={() => {
                this._clearData();
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddMenuForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dataInit: PropTypes.object,
  isOpen: PropTypes.bool,
  isUpdating: PropTypes.bool
};



export default withStyles(styles)(AddMenuForm);
