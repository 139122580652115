import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../new-jss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddCard from '../../New/Card/AddCard';
import { fetchCall } from '../../../service/service_base';
import { savePortrait } from '../../../service/s3Amplify';
import CartoonCard from './CartoonCard';
import Grid from '@material-ui/core/Grid/Grid';
import Edit from '@material-ui/icons/Edit';
import ls from 'local-storage';
import Delete from '@material-ui/icons/Delete';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Loader from '../../Loader/Loader';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';

class CoverNotasNew extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      openAddCard : false,
      card : [],
      cardSelected:null,
      isLoading:true,
      openConfirmDialog:false,
      openNotification:false,
      typeNotification:"info",
      messageNotification:"",
      idDeleted:0,
      action : "Nueva"
    }
  }

  componentDidMount(){ 
    if(this.props.content.length>0){ 
      this.setState({card:this.props.content,
        cardSelected:null,
        openAddCard:false,
        isLoading: false
      });
    }else{
    this.loadCard();
    }
  }

  handleCloseNotification = () =>{
    this.setState({
      openNotification: false
    })
  }

  loadCard = () =>{
    fetchCall("POST", "/synthesis/getcontent",{
      sectionId: this.props.section.sec_id,
      isPublish: this.props.isPublish,
      idPublish: this.props.idPublish
    }).then(response =>{
      this.setState({card:response.data,
      cardSelected:null,
      isLoading: false
  });      
    }).catch(err => console.log(err))
  }

  _addNewCard = () => { 
    this.setState(
      {
        openAddCard : false
      }, () => {
        setTimeout(() => {
          this.setState({
            openAddCard : true,
            action : 'Nueva',
            cardSelected : null
          })
        }, 250);
      }
    )
  }

  _handleCloseAddNewCard = () => {
    this.setState({
      openAddCard : false,
      cardSelected:null
    })
  }

  _handleEditCard = (item) =>{
    this.setState({
      openAddCard: true,
      cardSelected: item,
      action : "Editar"
    })
  }
  
  _handleAddNewCard = (event, data, file, attachment) =>{
    event.preventDefault();
    this.setState({ isLoading: true });
    if (data.title.toString().trim() === '') {
      this.setState({
        openNotification: true,
        messageNotification: 'Debes agregar un título',
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    } 
    data.sectionId = this.props.section.sec_id;
    data.userId = ls.get("current_user").user_Id;
    data.columnist = data.newspaper;

    let keyS3 = `${this.props.synthesis}/${this.props.section.sec_id}/${file ? `${new Date().getTime()+"_" + file.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}`;
    let keyS3Attachment = attachment != null ? `${this.props.synthesis}/${this.props.section.sec_id}/attachment/${attachment ? `${new Date().getTime()+"_" + attachment.name}`.replace(/[^0-9A-Z._]+/gi,"-") : ''}` : '';

    if (file != null) {
      if (data.isUpdating) {
        data.order=this.state.cardSelected.cont_order
        if (this.state.card.filter(
          x => x.cont_name === data.title.toString().trim() && parseInt(x.cont_id) !== this.state.cardSelected.cont_id)[0]
        ) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe un contenido con el mismo título',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }
        if (attachment === null) {
          data.attachment = this.state.cardSelected.cont_attachment;
          savePortrait(keyS3, file, file.type).then(response => {
            data.imagePath = response.key;
          }).then(() => {
            fetchCall("POST", "/synthesis/updatecontent", data).then(response => {
              if (response.status == 200) {
                this.setState({
                  openAddCard:false,
                  cardSelected: null,
                  openNotification: true,
                  messageNotification: 'Contenido actualizado',
                  typeNotification: "success"
                });
                this.loadCard();
              }
            }).catch(err => err);
          }).catch(err => console.log("error save portrait", err));
        } else {
          savePortrait(keyS3, file, file.type).then(response => {
            data.imagePath = response.key;
          }).then(() => {

            savePortrait(keyS3Attachment, attachment, attachment.type).then(response => {
              data.attachment = response.key;
            }).then(() => {
              fetchCall("POST", "/synthesis/updatecontent", data).then(response => {
                if (response.status == 200) {
                  this.setState({
                    openAddCard:false,
                    cardSelected: null,
                    openNotification: true,
                    messageNotification: 'Contenido actualizado',
                    typeNotification: "success"
                  });
                  this.loadCard();
                }

              }).catch(err => err);
            }).catch(err => console.log("error save portrait", err));

          }).catch(err => console.log("error save portrait", err));
        }
      } else {
        data.order = this.state.card.length+1
        if (this.state.card.filter(
          x => x.cont_name === data.title.toString().trim())[0]
        ) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe un contenido con el mismo título',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }

        if (attachment === null) {
          savePortrait(keyS3, file, file.type).then(response => {
            data.imagePath = response.key;
          }).then(() => {
            data.idPublish=this.props.idPublish
            fetchCall("POST", "/synthesis/addcontent", data).then(response => {
              this.setState({
                cardSelected: null
              });
              if (response.status == 200) {
                this.setState({
                  openNotification: true,
                  messageNotification: 'Contenido guardado',
                  typeNotification: "success"
                });
                this.loadCard();
              }
            }).catch(err => err);
          }).catch(err => console.log("error save portrait", err));
        } else {
          savePortrait(keyS3, file, file.type).then(response => {
            data.imagePath = response.key;
          }).then(() => {
            savePortrait(keyS3Attachment, attachment, attachment.type).then(response => {
              data.attachment = response.key;
            }).then(() => {
              data.idPublish=this.props.idPublish
              fetchCall("POST", "/synthesis/addcontent", data).then(response => {
                if (response.status == 200) {
                  this.setState({
                    cardSelected: null,
                    openNotification: true,
                    messageNotification: 'Contenido guardado',
                    typeNotification: "success"
                  });
                  this.loadCard();
                }
              }).catch(err => err);
            }).catch(err => console.log("error save portrait", err));

          }).catch(err => console.log("error save portrait", err));
        }
      }
    } else {
      if (data.isUpdating) {
        data.order=this.state.cardSelected.cont_order
        if (this.state.card.filter(
          x => x.cont_name === data.title.toString().trim() && parseInt(x.cont_id) !== this.state.cardSelected.cont_id)[0]
        ) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe un contenido con el mismo título',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }

        if (attachment === null) {
          data.imagePath = this.state.cardSelected.cont_imagePath;
          data.attachment = this.state.cardSelected.cont_attachment;
          fetchCall("POST", "/synthesis/updatecontent", data).then(response => {
            this.setState({
              openAddCard:false,
              cardSelected: null,
              openNotification: true,
              messageNotification: 'Contenido actualizado',
              typeNotification: "success"
            });
            this.loadCard();
          }).catch(err => err);
        } else {
          data.imagePath = this.state.cardSelected.cont_imagePath;
          savePortrait(keyS3Attachment, attachment, attachment.type).then(response => {
            data.attachment = response.key;
          }).then(() => {
            fetchCall("POST", "/synthesis/updatecontent", data).then(response => {
              this.setState({
                openAddCard:false,
                cardSelected: null,
                openNotification: true,
                messageNotification: 'Contenido actualizado',
                typeNotification: "success"
              });
              this.loadCard();
            }).catch(err => err);
          }).catch(err => console.log("error save portrait", err));
        }
      } else {
        data.order = this.state.card.length+1
        if (this.state.card.filter(
          x => x.cont_name === data.title.toString().trim())[0]
        ) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe un contenido con el mismo título',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }

        data.imagePath = '';
        if (attachment === null) {
          data.idPublish=this.props.idPublish
          fetchCall("POST", "/synthesis/addcontent", data).then(response => {

            this.setState({
              cardSelected: null,
              openNotification: true,
              messageNotification: 'Contenido guardado',
              typeNotification: "success"
            });
            this.loadCard();
          }).catch(err => err);
        } else {
          savePortrait(keyS3Attachment, attachment, attachment.type).then(response => {
            data.attachment = response.key;
          }).then(() => {
            data.idPublish=this.props.idPublish
            fetchCall("POST", "/synthesis/addcontent", data).then(response => {
              this.setState({
                cardSelected: null,
                openNotification: true,
                messageNotification: 'Contenido actualizado',
                typeNotification: "success"
              });
              this.loadCard();
            }).catch(err => err);
          }).catch(err => console.log("error save portrait", err));
        }
      }
    }
  }

  _closeConfirmDialog = () =>{
    this.setState({
      openConfirmDialog:false,
      idDeleted:0
    })
  }
  
  _removeContent = () =>{
    fetchCall("POST", "/synthesis/removecontentsection",{
      contId:this.state.idDeleted,
      userId : ls.get("current_user").user_id
    }).then(response =>{  
      if(response.status == 200){
         
        this.setState({
          openConfirmDialog:false,
          idDeleted:0,
          openNotification:true,
          messageNotification:'Contenido eliminado',
          typeNotification:"success",
          isLoading:false
        });
        this.loadCard(); 
      }
    }).catch(err => console.log(err));
  }

  _confirmRemoveContent = (item) =>{
    this.setState({
      openConfirmDialog:true,
      idDeleted:item.cont_id
    })
  }


  moverDerecha=(order)=>{
    console.log("der",order,this.state.card)
    let sections = new Array();
    sections = this.state.card;
    let findElement = sections.findIndex(x => x.cont_order === order);
    var aux = sections[findElement];
    if(sections[findElement+1] !== undefined){
      sections[findElement] = sections[findElement+1];
      sections[findElement].cont_orden = sections[findElement+1].cont_orden;
      sections[findElement+1] = aux;
      sections[findElement+1].cont_orden = aux.cont_orden;

      this.setState({
        card:sections
      }, () =>{
        fetchCall("POST", "/synthesis/updateorder", {
          order: sections.map((v,k)=>({id:v.cont_id,position:(k + 1)})),
          userId: ls.get("current_user").user_id,
          content: 1
        }).catch(err => console.log(err))

        this.setState({
          isLoading: false
        });
      });
    }
  }

  moverIzquierda=(order)=>{
    console.log("izq",order,this.state.card)
    let sections = new Array();
    sections = this.state.card;
    let findElement = sections.findIndex(x => x.cont_order === order);
    var aux = sections[findElement];
    if(sections[findElement-1] !== undefined){
      sections[findElement] = sections[findElement-1];
      sections[findElement].cont_orden = sections[findElement-1].cont_orden;
      sections[findElement-1] = aux;
      sections[findElement-1].cont_orden = aux.cont_orden;

      this.setState({
        card:sections
      }, () =>{
        fetchCall("POST", "/synthesis/updateorder", {
          order: sections.map((v,k)=>({id:v.cont_id,position:(k + 1)})),
          userId: ls.get("current_user").user_id,
          content: 1
        }).catch(err => console.log(err))

        this.setState({
          isLoading: false
        });
      });
    }
  }

  render() {
    const {
      classes,
      canEdit,
      canDelete, dataNewspaper
    } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>
        {
          this.state.isLoading ? <Loader text={"Cargando..."} /> :
          <Grid container spacing={24} >
           {
             this.state.card.map((item, index)=>{  
              item.cont_imagePath = item.cont_key
              item.cont_attachment = item.cont_attachmentKey
               
               return(
                <Grid item key={index.toString()} xs={12} md={4}>
                
                <CartoonCard
                  image={item.cont_imagePath}
                  attachment={item.cont_attachmentKey}
                  title={item.cont_name}
                >
                
                </CartoonCard>
                
                <div className={classes.adminActions}>
                  <div>
                      <Tooltip title="Mover Izquierda">
                        <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>{this.moverIzquierda(item.cont_order)}}>
                          <ChevronLeft />
                        </Button>   
                      </Tooltip>
                      <Tooltip title="Mover Derecha">
                        <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>{this.moverDerecha(item.cont_order)}}>
                          <ChevronRight />
                        </Button>   
                      </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Editar">
                      <Button variant="fab" mini disabled={!canEdit}  aria-label="add" className={classes.button} onClick={()=>{this._handleEditCard(item)}}>
                        <Edit />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Eliminar">
                      <Button variant="fab" mini disabled={!canDelete}  aria-label="add" className={classes.button} onClick={()=>{this._confirmRemoveContent(item)}}>
                        <Delete />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
               )
             })
           
        }
        <Grid item xs={12} md={4}>
          <div className={classes.coverNew}>
            <div className={classes.contentBtnNew}>
            <Tooltip title="Nuevo Contenido">
              <Button variant="fab" mini aria-label="add" onClick={()=>this._addNewCard()}  className={classes.btnNew} disabled={!canEdit}>
                  <AddIcon />
              </Button>
              </Tooltip>
            </div>
          </div>
        </Grid>
      </Grid>

        }
      <AddCard
        classes={classes}
        submit={this._handleAddNewCard}
        openForm={this.state.openAddCard}
        closeForm={this._handleCloseAddNewCard}
        dataInit={this.state.cardSelected}
        isLoading={this.state.isLoading}
        action={this.state.action}
        secName={this.props.section.sec_name}
        dataNewspaper={dataNewspaper}
      />

      <ConfirmDialog 
        title={"¿Desea eliminar el contenido?"}
        message={"El contenido al ser eliminado no podrá recuperarse"}
        textAccept={"Aceptar"}
        textCancel={"Cancelar"}
        funcAccept={this._removeContent}
        funcCancel={this._closeConfirmDialog}
        open={this.state.openConfirmDialog}
      />


      </div>
    );
  }
}

CoverNotasNew.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  synthesis: PropTypes.string,
  canEdit:PropTypes.bool,
  canDelete:PropTypes.bool
};

export default withStyles(styles)(CoverNotasNew);
