import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button'
import Edit from '@material-ui/icons/Edit'
import Done from '@material-ui/icons/Done'
import { S3Image } from 'aws-amplify-react';

import MainMenu from './MainMenu';
import styles from './sidebar-jss';
import { logOut } from '../../service/cognitoService';
import app from '../../../../package.json'

function updatemenu() {
  window.location.href = `/`;
}
const MenuContent = props => {

  const {
    classes,
    //  turnDarker,
    drawerPaper,
    toggleDrawerOpen,
    loadTransition,
    name,
    profile,
    urlPhoto,
    addMenu,
    funcAddMenu,
    addNewSynthesis,
    handleClick,
    logOut,
    synthesis,
    openNewMenu
  } = props;
  return (
    <div className={classNames(classes.drawerInner, !drawerPaper ? classes.drawerPaperClose : '')}>
      <div className={classes.drawerHeader}>
        <div className={classNames(classes.profile, classes.user)}>
          <Avatar className={classNames(classes.avatar, classes.bigAvatar)} >
            <S3Image className={classes.avatar} imgKey={urlPhoto} />
          </Avatar>
        </div>
        <div className={classes.usuario}>
          <h4>{name}</h4>
          <span>{profile}</span>
        </div>
        <div className={classes.adminActions}>
          <Tooltip title="Editar Menú">
            <Button variant="fab" mini color="secondary" aria-label="add" disabled={!addNewSynthesis} className={classes.colorEdit} onClick={funcAddMenu} >
              {addMenu ? <Done onClick={() => updatemenu()} /> : <Edit />}
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className={classes.menuContainer} >
        <MainMenu
          loadTransition={loadTransition}
          toggleDrawerOpen={toggleDrawerOpen}
          addMenu={addMenu}
          addNewSynthesis={addNewSynthesis}
          synthesis={synthesis}
          openNewMenu={openNewMenu}
        />
        <ListItem button className={classNames(classes.nested)} onClick={logOut} onTouchEnd={logOut} style={{ marginTop: '15px' }}>
          <ListItemIcon>
            <Icon className={[classes.icon, classes.colorDos]}>lock</Icon>
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary="Cerrar Sesión" />
        </ListItem>
        <center className={classes.version}>v{app.version}</center>
      </div>
    </div>
  );
};

MenuContent.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerPaper: PropTypes.bool.isRequired,
  turnDarker: PropTypes.bool,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  name: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  urlPhoto: PropTypes.string.isRequired,
  addMenu: PropTypes.bool.isRequired,
  funcAddMenu: PropTypes.func.isRequired,
  addNewSynthesis: PropTypes.bool.isRequired,
  logOut: PropTypes.func,
  synthesis: PropTypes.array,
  openNewMenu: PropTypes.func
};

MenuContent.defaultProps = {
  turnDarker: false,
  handleClick: () => { logOut(); }
};

MenuContent.defaultProps = {
  toggleDrawerOpen: () => { },
  loadTransition: () => { },
};

const MenuContentStyle = withStyles(styles)(MenuContent);


class Sidebar extends React.Component {
  state = {
    anchor: 'left'
  };

  render() {
    const { anchor } = this.state;
    const {
      classes,
      open,
      toggleDrawerOpen,
      loadTransition,
      turnDarker,
      name,
      profile,
      urlPhoto,
      addMenu,
      funcAddMenu,
      addNewSynthesis,
      logOut,
      synthesis,
      openNewMenu
    } = this.props;
    return (
      <Fragment>
        <Hidden lgUp>
          <SwipeableDrawer
            onClose={toggleDrawerOpen}
            onOpen={toggleDrawerOpen}
            open={!open}
            anchor={anchor}
          >
            <MenuContentStyle
              drawerPaper
              toggleDrawerOpen={toggleDrawerOpen}
              loadTransition={loadTransition}
              name={name}
              profile={profile}
              urlPhoto={urlPhoto}
              addMenu={addMenu}
              funcAddMenu={funcAddMenu}
              addNewSynthesis={addNewSynthesis}
              logOut={logOut}
              synthesis={synthesis}
              openNewMenu={openNewMenu}
            />
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            variant="permanent"
            onClose={toggleDrawerOpen}
            classes={{
              paper: classNames(classes.drawer, classes.drawerPaper, !open ? classes.drawerPaperClose : ''),
            }}
            open={open}
            anchor={anchor}
          >
            <MenuContentStyle
              drawerPaper={open}
              turnDarker={turnDarker}
              loadTransition={loadTransition}
              name={name}
              profile={profile}
              urlPhoto={urlPhoto}
              addMenu={addMenu}
              funcAddMenu={funcAddMenu}
              addNewSynthesis={addNewSynthesis}
              logOut={logOut}
              synthesis={synthesis}
              openNewMenu={openNewMenu}
            />
          </Drawer>
        </Hidden>
      </Fragment>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  turnDarker: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  profile: PropTypes.string,
  urlPhoto: PropTypes.string,
  addMenu: PropTypes.bool.isRequired,
  funcAddMenu: PropTypes.func.isRequired,
  addNewSynthesis: PropTypes.bool.isRequired,
  logOut: PropTypes.func,
  synthesis: PropTypes.array,
  openNewMenu: PropTypes.func
};

export default withStyles(styles)(Sidebar);
