import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Manager, Target, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import styles from './header-jss';
import ls from 'local-storage';
import { cognitoLogOut } from '../../service/cognitoService';
import { S3Image } from 'aws-amplify-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SessionDialog = props => {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="alert-dialog-title">{"Tu sesión ha expirado"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Debes volver a ingresar tus credenciales para seguir en el portal
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const SessionDialogWrapped = withStyles(styles)(SessionDialog);

class UserMenu extends React.Component {

  state = {
    openMenu: null,
    openSession: false
  };

  componentDidMount() {
    this.interval = setInterval(this.checkLastActivity, 10000);//10seg
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  checkLastActivity = () => {
    let currentTime = new Date().getTime();
    const lastTime = localStorage.getItem("last_activity");

    if (lastTime) {
      let diff = (currentTime - lastTime) / 1000;
      diff = diff / 60;
      if (diff >= 30) {
        this.setState({ openSession: true })
        return true;
      }
    }
  }

  handleMenu = menu => {
    this.setState({
      openMenu: this.state.openMenu === menu ? null : menu,
    });
  };

  handleLogOut = () => {
    cognitoLogOut().then(response => { console.log(response) }).catch(err => { console.log(err) });
    ls.clear();
    window.location.href = "/";
  }

  handleCloseToggle = () => {
    this.setState({ openMenu: null });
  };

  render() {
    const { classes, urlPhoto } = this.props;
    const { openMenu, openSession } = this.state;
    return (
      <div className={classes.userMenu}>
        <SessionDialogWrapped onClose={this.handleLogOut} open={openSession} />
        <Manager>
          <Target>
            <div
              ref={node => {
                this.target1 = node;
              }}
            >
              <Button onClick={() => this.handleMenu('user-setting')}>
                <Avatar className={classNames(classes.avatarMin, classes.avatar)}>
                  <S3Image className={classes.avatar} imgKey={urlPhoto} />
                </Avatar>
              </Button>
            </div>
          </Target>
          <Popper
            placement="bottom-start"
            eventsEnabled={openMenu === 'user-setting'}
            className={classNames({ [classes.popperClose]: openMenu !== 'user-setting' })}
          >
            <ClickAwayListener onClickAway={this.handleCloseToggle}>
              <Grow in={openMenu === 'user-setting'} id="user_settint" style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.paper}>
                  <MenuList role="menu">
                    <MenuItem onClick={() => { this.handleLogOut(); }} onTouchEnd={() => { this.handleLogOut(); }}>
                      <ListItemIcon>
                        <ExitToApp />
                      </ListItemIcon>
                      Cerrar sesión
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>
        </Manager>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  urlPhoto: PropTypes.string.isRequired
};

export default withStyles(styles)(UserMenu);
