import React from 'react';
import { Helmet } from 'react-helmet';

import Button from '@material-ui/core/Button/Button';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Snackbar from '@material-ui/core/Snackbar';
import Publish from '@material-ui/icons/Publish';
import Modal from '@material-ui/core/Modal';
import ls from 'local-storage';

import AddContent from '../../../components/Content/AddContent';
import brand from '../../../utils/brand';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import { fetchCall, apiElasticSearch, } from '../../../service/service_base';
import { saveIconSectionSynthesis } from '../../../service/s3Amplify';
import Notas from '../../../components/Sections/Notas';
import AddMenu from '../../../components/Menu/AddMenu';
import Banner from '../../../components/Sections/Banner';
import Header from '../../../components/Sections/Header';
import Portadas from '../../../components/Sections/Portadas'
import CardButton from '../../../components/Sections/CardButton'
import Relevante from '../../../components/Sections/Relevante';
import Card from '../../../components/Sections/Card';
import Radio from '../../../components/Sections/Radio';
import Evento from '../../../components/Sections/Evento';
import Footer from '../../../components/Sections/Footer';

import CustomNotification from '../../../components/Notification/CustomNotification';
import LoaderFull from '../../../components/Loader/LoaderFull';
import { saveIconSynthesis } from '../../../service/s3Amplify';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import SelectDialog from '../../../components/Dialog/ConfirmDialog.1';
import { ThemeContext } from '../../../utils/ThemeContext';
import PreviewSynthesis from '../Administration/SendEmail/PreviewSynthesis';

const styles = theme => ({
  diaSemana: {
    textAlign: 'center',
    width: 130,
  },
  contentDia: {
    //width: 130,
    height: 130,
    borderRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontSize: 28,
      color: '#cacaca',
      fontWeight: 600
    }
  },
  activo: {
    backgroundColor: '#f16c4f',
    '& p': {
      color: '#ffffff'
    }
  },
  contentPendientes: {
    padding: 5
  },
  item: {
    textAlign: 'center',
    '& img': {
      margin: '10px auto'
    }
  },
  title: {
    fontSize: 18,
    height: 24,
  },
  adminActions: {
    position: 'absolute',
    top: 10,
    right: 10,
    [theme.breakpoints.down('xs')]: {
      top: 0,
    },
  },
  divider1: {
    position: 'relative',
    margin: '40px 0',
    borderBottom: '1px solid rgba(0,0,0,0.12)'
  },
  accionDivider1: {
    color: '#cacaca',
    textAlign: 'center',
    backgroundColor: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px 25px',
    position: 'absolute',
    textDecoration: 'none'
  },
  accionDivider2: {
    color: '#cacaca',
    backgroundColor: '#fff',
    top: -20,
    right: 0,
    cursor: 'pointer',
    padding: '10px 25px',
    position: 'absolute',
    textDecoration: 'none'
  },
  descriptionSobresaliente: {
    height: 60,
    display: 'block',
    // display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    //whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  sectionButton: {
    position: 'relative'
  },
  button: {
    margin: '0 3px',
  },
  contentPublish: {
    display: 'flex',
    gap: '5px',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '& button': {
      minWidth: 40
    }
  }
});

class Synthesis extends React.Component {

  _isMount = true;


  constructor(props) {
    super(props);
    this.state = {
      param: '',
      openNewContent: false,
      isLoading: true,
      currentSynthesis: null,
      section: [],
      selectedSynthesis: null,
      openAddContentSection: false,
      contentType: '',
      idSectionSelected: 0,
      canShow: false,
      canEdit: false,
      canPublish: false,
      canDelete: false,
      sectionSelected: null,
      openNotification: false,
      typeNotification: "info",
      messageNotification: "",
      openAddMenu: false,
      bodyElastic: {},
      openConfirmDialog: false,
      openPublishSynthesis: false,
      openDeleteSection: false,
      idDeletedSection: 0,
      actionContent: '',
      currentUser: ls.get("current_user")['user_id'],
      //preview modal
      fechaInicio: null,
      openModal: false,
      preview: false,
      previewHTML: "",
      isEditing: 0,
      menuPublish: [],
      openSelectDialog: false,
      ps_id: 0,
      contentSection: [],
      datePublish: '',
      dataColumnist: [],
      dataRadiostation: [],
      dataNewspaper: [],
      dataKicker: [],
      openModalWeb: false
    }
  }

  _getUserPermissions = (currentPage) => {
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
    if (permissionsCurrentUser[0] != null) {
      let add = permissionsCurrentUser.filter(x => x.name === currentPage)[0];
      if (add != undefined || add != null) {
        let currentPermissions = ls.get("permissions");
        if (currentPermissions != undefined || currentPermissions != null) {
          let data = currentPermissions.filter(x => parseInt(x.menu_id) == parseInt(add.id))[0];
          if (data != undefined) {
            let config = JSON.parse(data.grant_configuration);
            this.setState({
              canShow: config.chkViewSynthesis,
              canEdit: config.chkEditSynthesis,
              canPublish: config.chkPublishSynthesis,
              canDelete: config.chkDeleteSynthesis,
              currentSynthesis: currentPage
            }, () => {
              if (config.chkViewSynthesis) {
                this._loadData();
              } else {
                this.setState({
                  isLoading: false
                });
              }
            });
          }
        }
      } else {
        /*
          Cuando no se detecta la síntesis de politica y negocios
        */
        this.setState({
          isLoading: false
        });
      }
    }
  }

  _loadData = () => {
    this._getRadiostationsByList()
    this._getColumnistsByList()
    this._getNewspapersByList()
    this._getKickersByList()
    this.setState(
      {
        param: this.props.match.params.param
      }, () => {
        ls.get("session_menu").map((item) => {
          if (item.menu_name == this.props.match.params.param) {

            this.setState({
              selectedSynthesis: item,
            }, () => {
              this.context.toggleTheme(item);
              this._loadSection(item.menu_id);
            })

          } else {
            this.setState({
              isLoading: false
            });
          }
        });
      }
    );

  }

  handleCloseNotification = () => {
    this.setState({
      openNotification: false
    })
  }

  _loadSection = (item) => {
    this.setState(
      {
        section: []
      }, () => {
        fetchCall("POST", "/synthesis/getsection", {
          menuId: item
        }).then(response => {
          if (response) {
            this.setState(
              {
                section: response.data,
                isLoading: false
              }
            );
          }
        }).catch(err => console.log(err));
      }
    );
  }


  componentWillReceiveProps(newProp) {
    if (this.state.currentSynthesis !== newProp.match.params.param) {
      this._getUserPermissions(newProp.match.params.param);
    } else {
      this.setState({
        isLoading: false,
        fechaInicio: null,
        openModal: false,
        preview: false,
        previewHTML: "",
        isEditing: 0,
        menuPublish: [],
        openSelectDialog: false,
        ps_id: 0,
        contentSection: []
      })
    }
  }

  _handleOpenNewContent = (event) => {
    event.preventDefault();
    if (this.state.canEdit) {
      this.setState({ openNewContent: true, actionContent: 'Nueva', sectionSelected: null });

    }
  }

  _handleCloseNewContent = () => {
    this.setState({ openNewContent: false, sectionSelected: null });
  }

  getContent = (classes, section) => {
    switch (section.sec_tipo) {
      case "Header":
        return <Header synthesis={this.state.selectedSynthesis.menu_id} section={section} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} />;
      case "Banner":
        return <Banner synthesis={this.state.selectedSynthesis.menu_id} section={section} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} />;
      case "Relevante":
        return (
          <React.Fragment>
            <Relevante synthesis={this.state.selectedSynthesis.menu_id} section={section} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} />
          </React.Fragment>
        )
      case "Portada":
        return (
          <React.Fragment>
            <Portadas type="Portada" synthesis={this.state.selectedSynthesis.menu_id} section={section} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} />
          </React.Fragment>
        )
      case "Notas":
        return (
          <React.Fragment>
            <Notas type="Kickers" section={section} synthesis={this.state.selectedSynthesis.menu_id} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} dataSelect={this.state.dataKicker} />
          </React.Fragment>
        )
      case "NotasColumnas":
        return (
          <React.Fragment>
            <Notas type="Columnas" section={section} synthesis={this.state.selectedSynthesis.menu_id} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} dataSelect={this.state.dataColumnist.filter(x => x.isEditorial !== 1)} />
          </React.Fragment>
        )
      case "NotasEditoriales":
        return (
          <React.Fragment>
            <Portadas type="Editoriales" synthesis={this.state.selectedSynthesis.menu_id} section={section} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} />
          </React.Fragment>
        )
      case "Card":
        return (
          <React.Fragment>
            <Portadas type="Cartones" synthesis={this.state.selectedSynthesis.menu_id} section={section} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} />
          </React.Fragment>
        )
      case "Radio":
        return (
          <React.Fragment>
            <Radio section={section} synthesis={this.state.selectedSynthesis.menu_id} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} dataRadiostation={this.state.dataRadiostation} />
          </React.Fragment>
        )
      case "Evento":
        return (
          <React.Fragment>
            <Evento section={section} synthesis={this.state.param} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} />
          </React.Fragment>
        )
      case "Footer":
        return (
          <React.Fragment>
            <Footer section={section} synthesis={this.state.selectedSynthesis.menu_id} canEdit={this.state.canEdit} canDelete={this.state.canDelete} content={this.state.contentSection.filter(x => x.sec_id === section.sec_id)} isPublish={this.state.isEditing} idPublish={this.state.ps_id} dataNewspaper={this.state.dataNewspaper} />
          </React.Fragment>
        )
    }
  }

  addNewSection = (event, data, file) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true
      }
    )

    if (data.titulo.toString().trim() === '') {
      this.setState({
        openNotification: true,
        messageNotification: 'Debes agregar un título',
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    }

    if (data.idTipoSeccion.toString().trim() === '') {
      this.setState({
        openNotification: true,
        messageNotification: 'Debes agregar un tipo de sección',
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    }

    data.idMenu = this.state.selectedSynthesis.menu_id;
    data.userId = ls.get("current_user").user_id;
    data.icono = "";
    //data.enableSocialMedia = data.enableSocialMedia ? 1 : 0;
    //data.columnist = data.columnist ? 1 : 0;
    if (file) {
      if (data.isUpdating) {
        let alreadyExist = this.state.section.filter(x => x.sec_name === data.titulo.toString().trim() && x.sec_id !== this.state.sectionSelected.sec_id).length;
        if (alreadyExist > 0) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe una sección con ese nombre',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }
        saveIconSectionSynthesis(this.state.selectedSynthesis.menu_id + "/" + `${new Date().getTime() + "_" + file.name}`.replace(/[^0-9A-Z._]+/gi, "-"), file, file.type).then(response => {
          data.icono = response.key;
          fetchCall("POST", "/synthesis/updatesection", data).then(response => {
            if (response.status == 200) {
              this.setState({
                openNewContent: false,
                openNotification: true,
                messageNotification: "Se actualizó la sección",
                typeNotification: "success",
                sectionSelected: null
              })
              this._loadSection(this.state.selectedSynthesis.menu_id);
            }
          }).catch(err => console.log(err));
        }).catch(err => console.log(err));
      } else {

        let alreadyExist = this.state.section.filter(x => x.sec_name === data.titulo.toString().trim()).length;
        if (alreadyExist > 0) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe una sección con ese nombre',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }

        saveIconSectionSynthesis(this.state.selectedSynthesis.menu_id + "/" + `${new Date().getTime() + "_" + file.name}`.replace(/[^0-9A-Z._]+/gi, "-"), file, file.type).then(response => {
          data.icono = response.key;
          fetchCall("POST", "/synthesis/addsection", data).then(response => {
            if (response.status == 200) {
              this.setState({
                openNewContent: false,
                openNotification: true,
                messageNotification: "Se guardó la sección",
                typeNotification: "success",
                sectionSelected: null
              })
              this._loadSection(this.state.selectedSynthesis.menu_id);
            }
          }).catch(err => console.log(err));
        }).catch(err => console.log(err));
      }
    } else {
      if (data.isUpdating) {
        let alreadyExist = this.state.section.filter(x => x.sec_name === data.titulo.toString().trim() && x.sec_id !== this.state.sectionSelected.sec_id).length;
        if (alreadyExist > 0) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe una sección con ese nombre',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }
        data.icono = this.state.sectionSelected.sec_icono;
        fetchCall("POST", "/synthesis/updatesection", data).then(response => {
          if (response.status == 200) {
            this.setState({
              openNewContent: false,
              openNotification: true,
              messageNotification: "Se actualizó la sección",
              typeNotification: "success",
              sectionSelected: null
            })
            this._loadSection(this.state.selectedSynthesis.menu_id);
          }
        }).catch(err => console.log(err));
      } else {

        let alreadyExist = this.state.section.filter(x => x.sec_name === data.titulo.toString().trim()).length;
        if (alreadyExist > 0) {
          this.setState({
            openNotification: true,
            messageNotification: 'Ya existe una sección con ese nombre',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }
        if (!file && (!["Header", "Banner", "Relevante", "Portada", "Footer"].includes(data.idTipoSeccion))) {
          this.setState({
            openNotification: true,
            messageNotification: 'Debes agregar un ícono',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }

        fetchCall("POST", "/synthesis/addsection", data).then(response => {
          if (response.status == 200) {
            this.setState({
              openNewContent: false,
              openNotification: true,
              messageNotification: "Se guardó la sección",
              typeNotification: "success",
              sectionSelected: null
            });
            this._loadSection(this.state.selectedSynthesis.menu_id);
          }
        }).catch(err => console.log(err));
      }
    }
  }

  _removeSection = () => {
    fetchCall("POST", "/synthesis/removesection", {
      secId: this.state.idDeletedSection,
      userId: ls.get("current_user").user_id
    }).then(response => {
      if (response.status == 200) {
        this.setState({
          openNotification: true,
          messageNotification: "Se eliminó la sección",
          typeNotification: "success",
          openDeleteSection: false
        })
        this._loadSection(this.state.selectedSynthesis.menu_id);
      }
    }).catch(err => console.log(err));
  }

  _editSection = (item) => {
    this.setState({
      sectionSelected: item,
      openNewContent: true,
      actionContent: "Editar"
    });
  }

  _upSection = (sec) => {
    if (this.state.canEdit) {
      this.setState({
        isLoading: true
      });
      let sections = this.state.section.slice(0);
      let findElement = sections.findIndex(x => x.sec_orden === sec);

      var aux = sections[findElement];
      if (sections[findElement - 1] !== undefined && sections[findElement - 1].sec_tipo != "Header") {
        sections[findElement] = sections[findElement - 1];
        sections[findElement].sec_orden = sections[findElement - 1].sec_orden;
        sections[findElement - 1] = aux;
        sections[findElement - 1].sec_orden = aux.sec_orden;

        this.setState({
          section: sections
        }, () => {
          fetchCall("POST", "/synthesis/updateorder", {
            order: sections.map((v, k) => { if (!["Header", "Footer"].includes(v.sec_tipo)) return { id: v.sec_id, position: (k + 1) } }),
            userId: ls.get("current_user").user_id
          }).catch(err => console.log(err))
          this.setState({
            isLoading: false
          });
        });

      } else {
        this.setState({
          isLoading: false
        });
      }
    }
  }

  _downSection = (sec) => {
    if (this.state.canEdit) {
      this.setState({
        isLoading: true
      });
      let sections = this.state.section.slice(0);
      let findElement = sections.findIndex(x => x.sec_orden === sec);

      var aux = sections[findElement];
      if (sections[findElement + 1] !== undefined && sections[findElement + 1].sec_tipo != "Footer") {
        sections[findElement] = sections[findElement + 1];
        sections[findElement].sec_orden = sections[findElement + 1].sec_orden;
        sections[findElement + 1] = aux;
        sections[findElement + 1].sec_orden = aux.sec_orden;

        this.setState({
          section: sections
        }, () => {
          fetchCall("POST", "/synthesis/updateorder", {
            order: sections.map((v, k) => { if (!["Header", "Footer"].includes(v.sec_tipo)) return { id: v.sec_id, position: (k + 1) } }),
            userId: ls.get("current_user").user_id
          }).catch(err => console.log(err))
          this.setState({
            isLoading: false
          });

          this.setState({
            isLoading: false
          });

        });

      } else {
        this.setState({
          isLoading: false
        });
      }
    }
  }

  validaAttachment = (pos) => {
    let pasa = true
    if (pos === this.state.section.length) {
      fetchCall("POST", "/synthesis/publish", {
        menuId: this.state.selectedSynthesis.menu_id
      }).then(response => {
        if (response.status == 200) {
          if (response.data[0].error == "Ya existen síntesis publicadas") {
            this.setState({
              openNotification: true,
              messageNotification: `Ya existe una síntesis publicada hoy`,
              typeNotification: 'warning',
              isLoading: false
            })
          } else {
            let data = new Array();
            var date = new Date(`${response.data[0].date}`);
            // date.setDate(date.getDate() - 1);
            this._saveContentElasticSearch(0, data, response.data[0].ps_lastPublish, date.getTime(), response.data[0].ps_id);

            this.setState({
              openNotification: true,
              messageNotification: `Se publicó la síntesis: ${response.data[0].date}`,
              typeNotification: 'success',
              openPublishSynthesis: false,
              // isLoading : false
            }, () => {
              let tokens = JSON.parse(`[${response.data[0].tokens}]`);
              if (tokens[0] != null) {
                fetchCall("POST", "/notification/sendnotification", {
                  pushTokens: tokens,
                  title: response.data[0].menu_name,
                  data: {
                    menuid: response.data[0].menu_id,
                    title: response.data[0].menu_name,
                    color: response.data[0].menu_description
                  }
                })
              }

              fetchCall("POST", "/notification/addnotification", {
                title: response.data[0].menu_name,
                menuid: response.data[0].menu_id
              }).then(
                response => {
                  fetchCall("POST", "/report/addlogmovements", {
                    username: ls.get("username"),
                    action: "Publicación de síntesis",
                    description: `El usuario ${`${ls.get("username")}`.split("@")[0]} publicó la síntesis ${this.state.selectedSynthesis.menu_name}`,
                    userId: this.state.currentUser
                  }).then(() => {
                    this.setState(
                      {
                        isLoading: false
                      }
                    )
                  }
                  ).catch(
                    err => {
                      this.setState(
                        {
                          isLoading: false
                        }
                      )
                    }
                  )
                }
              )
            });
          }
        }
      }).catch(err => console.log(err));
    } else {
      let item = this.state.section[pos];

      fetchCall("POST", "/synthesis/getcontent", {
        sectionId: item.sec_id,
        isPublish: 0,
        idPublish: 0
      }).then(
        response => {
          if (response.data) {
            response.data.map(
              i => {
                if (!["Header", "Banner", "Evento", "Footer"].includes(item.sec_tipo))
                  if (i.cont_attachmentKey == "" || i.cont_attachmentKey == "0") {
                    pasa = false;
                    this.setState({
                      openNotification: true,
                      messageNotification: `Hay notas sin archivos adjuntos en la sección: ` + item.sec_name,
                      typeNotification: 'warning',
                      isLoading: false
                    })
                  }
              }
            )
          }
          if (pasa)
            this.validaAttachment(pos + 1)
        }
      )
    }
  }

  _publishSynthesis = () => {

    this.setState(
      {
        isLoading: true,
        openPublishSynthesis: false
      })
    this.validaAttachment(0)
  }

  _saveContentElasticSearch = (pos, arrayElastic, publishId, datePublish, ps_id) => {

    if (pos === this.state.section.length) {
      this._saveDataToElastic(arrayElastic)
    } else {
      let item = this.state.section[pos];
      fetchCall("POST", "/synthesis/getcontentpublish", {
        sectionId: item.sec_id,
        menuId: this.state.selectedSynthesis.menu_id
      }).then(
        response => {
          if (response.data) {
            response.data.map(
              i => {
                if ((["Header", "Banner", "Portada", "Footer"].includes(item.sec_tipo)) && ps_id) {
                  fetchCall("POST", "/synthesis/addcontent",
                    { idPublish: ps_id, order: item.sec_orden, sectionId: item.sec_id, imagePath: i.cont_key, title: i.cont_name, description: i.cont_description, order: i.cont_order }).then(response => {

                    }).catch(err => err);
                }
                if (!["Header", "Footer"].includes(item.sec_tipo)) {
                  const elasticBody = Object.assign({}, this.state.bodyElastic);
                  elasticBody.menu_id = this.state.selectedSynthesis.menu_id;
                  elasticBody.menu_name = this.state.selectedSynthesis.menu_name;
                  elasticBody.sec_id = item.sec_id;
                  elasticBody.sec_name = item.sec_name;
                  elasticBody.sec_tipo = item.sec_tipo;
                  elasticBody.sec_order = item.sec_orden;
                  elasticBody.sec_color = item.sec_color;
                  elasticBody.sec_icono = item.sec_icono;
                  elasticBody.publish_date = `${datePublish}`;
                  elasticBody.cont_id = i.cont_id;
                  elasticBody.cont_name = i.cont_name;
                  elasticBody.cont_description = i.cont_description;
                  elasticBody.cont_imagePath = i.cont_key;
                  elasticBody.cont_attachment = i.cont_attachmentKey;
                  elasticBody.cont_order = i.cont_order;
                  elasticBody.cont_notas = i.cont_notas;
                  elasticBody.cont_program = i.cont_program;
                  elasticBody.publish_id = publishId;
                  arrayElastic.push(elasticBody);
                }
              }
            )
          }
          this._saveContentElasticSearch(pos + 1, arrayElastic, publishId, datePublish, ps_id);
        }
      ).catch(
        err => {

        }
      )
    }
  }

  _saveDataToElastic = (data) => {
    apiElasticSearch("/doc/upsert-doc", { dataElastic: data }).then(
      response => {
        this.setState(
          {
            // isLoading : false,
            section: []
          }, () => {
            this._getUserPermissions(this.state.currentSynthesis);
          }
        )
      }
    ).catch(
      error => {
        console.log("error", error);
      }
    )
  }

  _editSynthesis = () => {
    this.setState({ openAddMenu: true });
  }

  _deleteSynthesis = () => {

    fetchCall("POST", "/synthesis/remove", {
      menuId: this.state.selectedSynthesis.menu_id,
      userId: ls.get("current_user").user_id
    }).then(response => {
      if (response.status == 200) {
        this.setState({
          messageNotification: "Se eliminó la síntesis correctamente",
          typeNotification: "success",
          openNotification: true,
          section: [],
          openConfirmDialog: false
        }, () => {
          ls.remove("sesion_menu");
          window.location.href = `/`;
        });
      }
    }).catch(err => console.log(err));
  }

  editMenu = (event, data) => {
    event.preventDefault();
    data.createdBy = this.state.currentUser;
    data.userId = this.state.currentUser;
    data.menu_description = `${data.menu_primaryColor},${data.menu_secondaryColor}`
    this.setState({ isLoading: true })
    if (data.menu_name.toString().trim() === "") {
      this.setState({
        messageNotification: "Debes ingresar un nombre para la síntesis",
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
      return true;
    }

    if (ls.get("session_menu").filter(item => item.menu_name === data.menu_name.toString().trim() && item.menu_id !== parseInt(data.menuId))[0]) {
      this.setState({
        messageNotification: "Ya existe una síntesis con ese nombre",
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
      return true;
    }
    this._handleSaveSynthesis(data);
  }

  closeNewMenu = () => {
    this.setState({ openAddMenu: false });
  }

  _handleSaveSynthesis = (data) => {
    fetchCall("POST", "/synthesis/update", data).then(response => {
      if (response.status == 200) {
        this.setState({
          messageNotification: "Se modificó la síntesis correctamente",
          typeNotification: "success",
          openNotification: true,
          openAddMenu: false,
          isLoading: false
        }, () => {
          ls.remove("session_menu");
          window.location.href = `/`;
        });
      }
    }).catch(err => console.log(err));
  }

  openConfirmDelete = () => {
    this.setState({
      openConfirmDialog: true
    });
  }

  _closeConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      openPublishSynthesis: false,
      openDeleteSection: false
    });
  }

  openPublishSynthesisConfirm = () => {
    this.setState({
      openPublishSynthesis: true
    });
  }

  confirmDeleteSection = (item) => {
    this.setState({
      openDeleteSection: true,
      idDeletedSection: item.sec_id
    })
  }

  _getColumnistsByList = () => {
    fetchCall("GET", "/columnist/get").then(response => {
      this.setState({
        dataColumnist: response.data.filter(x => (x.menu_id === this.state.selectedSynthesis.menu_id && x.columnist_status == 1))
      });
    }).catch(err => console.log(err));
  }
  _getRadiostationsByList = () => {
    fetchCall("GET", "/radiostation/get").then(response => {
      this.setState({
        dataRadiostation: response.data.filter(x => (x.menu_id === this.state.selectedSynthesis.menu_id && x.radiostation_status == 1))
      });
    }).catch(err => console.log(err));
  }
  _getNewspapersByList = () => {
    fetchCall("GET", "/newspaper/get").then(response => {
      this.setState({
        dataNewspaper: response.data.filter(x => (x.menu_id === this.state.selectedSynthesis.menu_id && x.newspaper_status == 1))
      });
    }).catch(err => console.log(err));
  }
  _getKickersByList = () => {
    fetchCall("GET", "/kicker/get").then(response => {
      this.setState({
        dataKicker: response.data.filter(x => (x.menu_id === this.state.selectedSynthesis.menu_id && x.kicker_status == 1))
      });
    }).catch(err => console.log(err));
  }
  render() {

    const { match, classes } = this.props;
    const title = brand.name + ' - ' + match.params.param;
    const url = this.state.selectedSynthesis && `${process.env.REACT_APP_url_web_public}/previewWeb/${this.state.selectedSynthesis.menu_id}/${this.state.fechaInicio == null ? '0' : this.state.fechaInicio.getFullYear() + '-' + (this.state.fechaInicio.getMonth() < 10 ? '0' : '') + (this.state.fechaInicio.getMonth() + 1) + '-' + (this.state.fechaInicio.getDate() < 10 ? '0' : '') + this.state.fechaInicio.getDate()}/${encodeURIComponent(this.state.selectedSynthesis.menu_name)}/${encodeURIComponent(this.state.selectedSynthesis.menu_description)}/${this.state.ps_id}`;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {this.state.isLoading ? <LoaderFull /> : ''}
        <PreviewSynthesis
          classes={classes}
          openModalPermission={this.state.openModal}
          preview={this.state.previewHTML}
          handleCloseModal={this.handleCloseModal}
        //date={this.state.fechaInicio}
        />
        {this.state.openModalWeb ? <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openModalWeb}
          onClose={() => this.setState({ openModalWeb: false })}
        >
          <div style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            maxHeight: 500,
            backgroundColor: 'white',
            textAlign: 'right',
            position: 'absolute',
            width: '90%',

            outline: 'none',
            borderRadius: 5,
          }} className={classes.paper}>
            <Button onClick={() => window.open(url, '_blank')} color="primary">Abrir en pestaña</Button>
            <Button onClick={() => this.setState({ openModalWeb: false })} color="primary">Cerrar</Button>
            <iframe src={url} width="100%" height="465"></iframe>
          </div>
        </Modal> : null}
        {/* Form Add New section */}
        <AddContent
          openForm={this.state.openNewContent}
          closeForm={this._handleCloseNewContent}
          submit={this.addNewSection}
          dataInit={this.state.sectionSelected}
          action={this.state.actionContent}
        />

        <AddMenu
          openForm={this.state.openAddMenu}
          closeForm={this.closeNewMenu}
          classes={classes}
          submit={this.editMenu}
          dataInit={this.state.selectedSynthesis}
          action={"Editar"}
          isLoading={true}
          isUpdating={true}
        />

        <ConfirmDialog
          title={"¿Está seguro que desea eliminar la síntesis?"}
          message={"La síntesis será eliminada y no se podrá recuperar"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._deleteSynthesis}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />

        <ConfirmDialog
          title={"¿Desea publicar la síntesis?"}
          message={`La fecha de publicación de la síntesis será hoy ${this._getCurrentTime(new Date())}`}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._publishSynthesis}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openPublishSynthesis}
        />

        <ConfirmDialog
          title={"¿Desea eliminar la sección?"}
          message={`La sección será eliminada y no se podrá recuperar`}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._removeSection}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openDeleteSection}
        />
        <SelectDialog
          title="Se encontraron varias síntesis publicadas en esta fecha, favor de seleccionar una:"
          funcAccept={this.verSynthesisPublicada}
          funcCancel={() => this.setState({ openSelectDialog: false })}
          open={this.state.openSelectDialog}
          data={this.state.menuPublish}
        />
        <PapperBlock
          title={this.props.match.params.param}
          colorMode
          optionsSinthesis
          canEditSynthesis={this.state.canEdit}
          canDeleteSynthesis={this.state.canDelete}
          editSynthesis={this._editSynthesis}
          deleteSynthesis={this.openConfirmDelete}
          previewSynthesis={this.verSynthesis}
          handleDateChange={this.handleDateChange}
          date={this.state.fechaInicio}
          isEditing={this.state.isEditing}
          previewWeb={() => this.setState({ openModalWeb: true })}
        >
          {/* <div className="fecha">5 de Diciembre 2018</div> */}
          <div className={classes.adminActions}>
            {this.state.isEditing != 1 ?
              <Tooltip title="Publicar">
                <Button variant="fab" mini color="primary" onClick={() => {
                  this.openPublishSynthesisConfirm();
                }} disabled={!this.state.canPublish} aria-label="add" className={classes.button}>
                  <Publish />
                </Button>
              </Tooltip> :
              <div className={classes.contentPublish}>
                <div style={{ color: 'whitesmoke', display: 'inline', fontStyle: 'bold' }}>Editando publicación del {this.state.datePublish} </div>
                <Tooltip title="Re-publicar">
                  <Button variant="fab" onClick={() => this.restoreSynthesis()} mini aria-label="add" className={classes.button}>
                    <Publish />
                  </Button>
                </Tooltip>
              </div>
            }
          </div>
        </PapperBlock>
        {
          this.state.isLoading ? <LoaderFull /> :

            this.state.section.map((item, index) => {
              return (
                <div key={index.toString()}>
                  <div>
                    <Grid container spacing={24}>
                      <Grid item xs={12} >
                        <div className={classes.divider1}>
                          <a disabled={!this.state.canPublish} className={classes.accionDivider1}>
                            {item.sec_name}
                          </a>
                          {
                            ["Header", "Footer"].includes(item.sec_tipo) ? '' :
                              <div className={classes.accionDivider2}>
                                <Tooltip title="Bajar">
                                  <ArrowDownward onClick={() => this._downSection(item.sec_orden)} />
                                </Tooltip>
                                <Tooltip title="Subir">
                                  <ArrowUpward onClick={() => this._upSection(item.sec_orden)} />
                                </Tooltip>
                              </div>
                          }
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.sectionButton}>
                    <PapperBlock key={index.toString()} title={item.sec_name} img={item.sec_icono} colorMode desc="" noMargin color={!["Header", "Banner", "Portada", "Footer"].includes(item.sec_tipo) ? item.sec_color : null}>
                      <div className={classes.adminActions}>
                        <Tooltip title="Editar">
                          <Button variant="fab" disabled={!this.state.canEdit} mini color="primary" aria-label="add" onClick={() => this._editSection(item)} className={classes.button}>
                            <Edit />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <Button variant="fab" disabled={!this.state.canDelete} mini color="primary" aria-label="add" onClick={() => this.confirmDeleteSection(item)} className={classes.button}>
                            <Delete />
                          </Button>
                        </Tooltip>
                      </div>
                      {
                        this.getContent(classes, item)
                      }
                    </PapperBlock>
                  </div>
                </div>
              )
            })
        }
        <div>
          <Grid container spacing={24} >
            <Grid item xs={12} >
              <div className={classes.divider1}>
                <a href="#" disabled={!this.state.canPublish} onClick={(event) => this._handleOpenNewContent(event)} className={classes.accionDivider1}>NUEVA SECCIÓN</a>
              </div>
            </Grid>
          </Grid>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>
      </div>
    );

  }

  verSynthesisPublicada = e => {
    this.setState({ ps_id: e, openSelectDialog: false, isLoading: true, isEditing: true, datePublish: this._getCurrentTime(this.state.fechaInicio) })
    this.verSynthesis()
  };

  handleDateChange = date => {
    if (this.state.fechaInicio == new Date(date)) {
      this.setState({ isLoading: false })
      if (this.state.menuPublish.length > 1)
        this.setState({ openSelectDialog: true })

    } else {
      this.setState({ fechaInicio: new Date(date), isLoading: true, menuPublish: [] },
        () => {
          fetchCall("POST", "/synthesis/getpublishmenu", {
            datePublish: this.state.fechaInicio == null ? '' : this.state.fechaInicio.getFullYear() + '-' + (this.state.fechaInicio.getMonth() + 1) + '-' + this.state.fechaInicio.getDate(),
            idMenu: this.state.selectedSynthesis.menu_id
          }).then(response => {
            if (response) {
              if (response.status === 200) {
                if (response.data.length > 0) {
                  if (response.data.length > 1) {
                    this.setState({ menuPublish: response.data, openSelectDialog: true, isLoading: false })
                  } else {
                    this.setState({ ps_id: response.data[0].ps_id, isLoading: false, isEditing: true, datePublish: this._getCurrentTime(this.state.fechaInicio) }, () => {

                    })
                    this.verSynthesis()
                  }
                } else {
                  this.setState({
                    openNotification: true,
                    messageNotification: "No existen publicaciones en esta síntesis correspondientes a la fecha",
                    typeNotification: 'warning',
                    isLoading: false,
                  });
                }
              }
            }
          }).catch(err => {
            console.log("error contenido", err)
          });
        });
    }


  };

  restoreSynthesis = () => {
    fetchCall("POST", "/synthesis/publish", {
      menuId: this.state.selectedSynthesis.menu_id,
      publishId: this.state.ps_id
    }).then(response => {
      if (response.status == 200) {
        fetchCall("POST", "/report/addlogmovements", {
          username: ls.get("username"),
          action: "Publicación de síntesis",
          description: `El usuario ${`${ls.get("username")}`.split("@")[0]} publicó la síntesis ${this.state.selectedSynthesis.menu_name}`,
          userId: this.state.currentUser
        })
        let data = new Array();
        var date = new Date(`${response.data[0].date}`);
        // date.setDate(date.getDate() - 1);
        this._saveContentElasticSearch(0, data, response.data[0].ps_lastPublish, date.getTime());
        this.setState({
          openNotification: true,
          messageNotification: `Se re-publicó la síntesis`,
          typeNotification: 'success',
          // section:[],
          openPublishSynthesis: false,
          isLoading: false,
          fechaInicio: null,
          openModal: false,
          preview: false,
          previewHTML: "",
          isEditing: 0,
          menuPublish: [],
          openSelectDialog: false,
          ps_id: 0,
          contentSection: []
        }, () => {

        });
      }
    }).catch(err => console.log(err));
  }
  handleCloseModal = () => {
    this.setState({
      openModal: false
    });
  }

  verSynthesis = (preview) => {
    this._content = [];
    if (this.state.section.length > 0) {
      this.setState({ previewHTML: '', synthesiscom: [], contentSection: [], sections: this.state.section, isLoading: true }, () => {
        this._getPublishContent(this.state.sections, this.state.selectedSynthesis.menu_id, this.state.section.length, 0, preview);
      })
    } else {
      this.setState({
        openNotification: true,
        messageNotification: "No se encontró una síntesis",
        typeNotification: 'warning',
        isLoading: false
      });
    }
  }

  _getPublishContent = (section, menu, tam, index, preview) => {
    if (section.length === index) {
      if (this.state.contentSection.length > 0) {

        let section = new Array();
        this.state.sections.map(item => {
          let o = {
            sec_icono: item.sec_icono,
            sec_iconoUrl: item.sec_iconoUrl,
            sec_id: item.sec_id,
            sec_name: item.sec_name,
            sec_orden: item.sec_orden,
            sec_color: item.sec_color,
            sec_tipo: item.sec_tipo,
            content: [...new Set(this.state.contentSection.filter(x => x.sec_id === item.sec_id))]
          }
          section.push(o);
        });
        const d = this.state.fechaInicio ? this.state.fechaInicio : new Date();
        d.setHours(9, 0, 0);
        let complete = {
          menu_id: this.state.selectedSynthesis.menu_id,
          menu_name: this.state.selectedSynthesis.menu_name,
          menu_description: this.state.selectedSynthesis.menu_description,
          lastPublish: d,
          section: [...new Set(section)]
        }

        this.setState(
          {
            synthesiscom: complete,
            isLoading: false
          }, () => {

            fetchCall("POST", "/distUserList/email", {
              emails: null,
              synthesis: this.state.synthesiscom,
              preview: true,
              availableSynthesis: JSON.stringify(ls.get("session_menu").filter(x => x.lastPublish !== null)),
              date: this._getCurrentTime(d)
            }).then(
              response => {
                this.setState({
                  previewHTML: response,
                  openModal: this.state.isEditing == 1 ? (preview ? true : false) : true,
                  isLoading: false,
                  synthesiscom: null,
                  section: complete.section
                });
                ls.set("synthesis_structure", this.state.synthesiscom);
                ls.set("preview_html", response);
              }
            );
          });
      } else {
        this.setState({
          openNotification: true,
          messageNotification: "No se encontró una síntesis",
          typeNotification: 'warning',
          isLoading: false
        });
      }
    } else {
      fetchCall("POST", "/synthesis/getcontent", {
        sectionId: section[index].sec_id,
        isPublish: this.state.isEditing,
        idPublish: this.state.ps_id
      }).then(response => {
        if (response) {
          if (response.status === 200) {
            if (response.data != null) {
              response.data.map(item => {
                this._content.push(item);
              });
              this.setState({
                contentSection: this._content
              }, () => {
                this._getPublishContent(section, menu, tam, index + 1, preview)
              });
            }
          }
        } else {
          this._getPublishContent(section, menu, tam, index + 1)
        }
      }).catch(err => {
        console.log("error contenido", err)
      });
    }
  }

  _translateDay = (str) => {
    switch (str) {
      case "Mon": return "Lunes"; break;
      case "Tue": return "Martes"; break;
      case "Wed": return "Miércoles"; break;
      case "Thu": return "Jueves"; break;
      case "Fri": return "Viernes"; break;
      case "Sat": return "Sábado"; break;
      case "Sun": return "Domingo"; break;
    }
  }

  _translateYear = (str) => {
    switch (str) {
      case "Jan": return "enero"; break;
      case "Feb": return "febrero"; break;
      case "Mar": return "marzo"; break;
      case "Apr": return "abril"; break;
      case "May": return "mayo"; break;
      case "Jun": return "junio"; break;
      case "Jul": return "julio"; break;
      case "Aug": return "agosto"; break;
      case "Sep": return "septiembre"; break;
      case "Oct": return "octubre"; break;
      case "Nov": return "noviembre"; break;
      case "Dec": return "diciembre"; break;
    }
  }

  _getCurrentTime = (f) => {

    let date = new Date(f);
    let currentDay = this._translateDay(date.toString().split(" ")[0]);
    let currentDate = this._translateYear(date.toString().split(" ")[1]);
    return `${currentDay}, ${date.toString().split(" ")[2]} ${currentDate} ${date.toString().split(" ")[3]}`;
  }

}

Synthesis.contextType = ThemeContext;

export default withStyles(styles)(Synthesis);
