import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../../utils/brand';
import  PapperBlock  from '../../../../components/PapperBlock/PapperBlock';  
import { Button, MenuItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import {Select} from '@material-ui/core/';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ContactIcon from '@material-ui/icons/PermContactCalendar'
import SendIcon from '@material-ui/icons/Send';
import ls from 'local-storage';
import { fetchCall } from '../../../../service/service_base';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import styles from '../../../../components/Tables/tableParts/tableStyle-jss';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';
import Checkbox from '@material-ui/core/Checkbox';
import AddList from './AddList';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from   '@date-io/moment';
import esLocale from 'moment/locale/es';
import PreviewSynthesis from './PreviewSynthesis';
import Visibility from '@material-ui/icons/Visibility';
import { Validate } from '../../../../validator/Regex';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeContext } from '../../../../utils/ThemeContext';

MomentUtils.prototype.startOfMonth=MomentUtils.prototype.getStartOfMonth;
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#9F9F9F',
    padding: '4px 24px 4px 24px',
  },
  body: {
    padding: '4px 24px 4px 24px',
    fontSize: 14,
  },
}))(TableCell);

let menuSelected="";
class SendEmail extends React.Component {

  constructor(props) {
    super(props);   
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    synthesisSelected : 0,
    showPreview:0,
    data :[],
    openNotification:false,
    typeNotification:"info",
    messageNotification:"",
    listSelected:null,
    editList:false,
    searchData:[],
    canEdit:true,
    canShow:false,
    canPublish:true,
    canDelete : true,
    candSend:false,
    totalContacts:0,
    checkedB:false,
    checkedA:false,
    openAddList:false,
    isLoading:true,
    openConfirmDialog:false,
    idDelete:0,
    today :new Date(),
    synthesis_section:[],
    openModal:false,
    fechaInicio:new Date(),
    sendEmails:[],
    previewHTML : "",
    synthesis: null,
    sections:null,
    contentSection:[],
    synthesis_section: [],
    destinatario: '',
    onlyOne: false,
    listDistribution : []
  }

  _content = []; 
  componentDidMount(){
    this.context.toggleTheme(undefined);
    this._getUserPermissions();
  }

  _getUserPermissions = () =>{
     
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("["+currentUser.menuSynthesis+"]");    
    if(permissionsCurrentUser[0] != null){
      let add = permissionsCurrentUser.filter(x => x.name ==="Configuración/Lista de distribución")[0];
    
    if(add != undefined || add != null){
      let currentPermissions = ls.get("permissions");
      if(currentPermissions != undefined || currentPermissions != null){
        
        let data = currentPermissions.filter(x => x.menu_id == add.id)[0];
        
       if(data != undefined){
        let config = JSON.parse(data.grant_configuration);
        
        this.setState({
          canShow : config.chkViewSendEmail,
          canEdit : !config.chkEditSendMail,
          canPublish: !config.chkPublishSendMail,
          canDelete : !config.chkDeleteSendEmail,
          canSend: !config.chkSendEmail
        });

       if(config.chkViewSendEmail){
         this._loadContactList();
       }else{
         this.setState({
           openNotification:true,
           messageNotification:"No tienes permiso para visualizar información",
           typeNotification:'info',
           isLoading:false
         });
       }
       }

      }
    }
    } 
  }
 

  handleCloseNotification = () =>{
    this.setState({openNotification:false,messageNotification:''});
  }

  _loadContactList = () => {
    fetchCall("GET", "/distUserList/get").then(response =>{
      this.setState({data:response.data, searchData:response.data,isLoading:false});
    }).catch(err => console.log(err))
  }

  handleChangeSelect = (event) =>{
    this.setState({[event.target.name]: event.target.value});
  }
  
  handleChangeEmail = (event) =>  {
    this.setState({
      [`${event.target.name}`] : event.target.value,
      onlyOne: event.target.value.toString().trim() !== "" ? true :  false
    });
  }

  handleChange = (event, data) =>{ 
    let total = 0;    
     
    if(data == "checkedA"){ 
      this.state.searchData.map(item =>{
        this.setState({
          [`${item.clistId}`] : event.target.checked
        });
       if(event.target.checked)total += item.contactos;
      });
      
      this.setState({[event.target.name]: event.target.checked, totalContacts:total});
    } else{
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      if(value){
        total = Number.parseInt(this.state.totalContacts) + Number.parseInt(data.contactos); 
      }else{
        total = Number.parseInt(this.state.totalContacts) - Number.parseInt(data.contactos); 
      } 
      this.setState({
        [`${data.clistId}`] : value ,
        totalContacts:total
      });
    } 
      
  }

  closeNewList = () => {
    this.setState({
      listSelected:null,
      openAddList:false,
      editList:false
    })
  }

  _handleOpenAddList = () => {
    this.setState({
      listSelected:null,
      openAddList:true,
      editList:false
    })
  }

  _handleEditList = (list) =>{
    this.setState({
      openAddList:false,
      listSelected: null,
      editList:false
    }, () => {
      this.setState(
        {
          openAddList:true,
          listSelected: list,
          editList:true
        }
      )
    });
  }

  _handleRemoveList = () =>{
    this.setState({isLoading:true, openConfirmDialog : false}, () => {

      let list = this.state.data.filter(x => x.clistId === this.state.idDelete)[0];
      
        if(list.contactos>0){
          this.setState({
            openNotification : true,
            messageNotification : 'Esta lista tiene contactos registrados',
            typeNotification:'error',
            isLoading:false,
            openConfirmDialog:false
          });
          return true;
        }
  
      fetchCall("POST","/distUserList/remove", {
        clistId:this.state.idDelete
      }).then(response =>{
        if(response.status == 200){
          this.setState({
            openAddList:false,
            openNotification:true,
            messageNotification:"Lista eliminada",
            typeNotification:"success",
            isLoading: false,
            openConfirmDialog:false
          })
          this._loadContactList();
        }
      }).catch(err => console.log(err));
    });

  }

  submitList = (event, data) => {
    event.preventDefault();
    this.setState({isLoading:true});

    if(data.nombre.toString().trim() === ''){
      this.setState({
        openNotification:true,
        messageNotification:"Debes ingresar un nombre para la lista",
        typeNotification:'error',
        isLoading:false,
        openConfirmDialog:false
      });
      return false;
    }
 
    let alreadyExists = false;
    this.state.data.map((item =>{
      if(data.nombre.toString().trim() == item.nombre){
        alreadyExists = true;
        return false;
      }
    }))
    
    if(alreadyExists){
      this.setState({
        openNotification:true,
        messageNotification:"Ya existe una lista con ese nombre",
        typeNotification:'info',
        isLoading:false,
        openConfirmDialog:false
      });
      return true;
    }
    
    if(!this.state.editList){
      fetchCall("POST","/distUserList/add", {
        clistName:data.nombre
      }).then(response =>{
        if(response.status == 200){
          this.setState({
            openAddList:false,
            openNotification:true,
            messageNotification:"Lista guardada",
            typeNotification:"success",
            isLoading:false,
            openConfirmDialog:false
          })
          this._loadContactList();
        }
      }).catch(err => console.log(err));
    }else{
      fetchCall("POST","/distUserList/update", {
        clistName:data.nombre,
        clistId:this.state.listSelected.clistId
      }).then(response =>{
        if(response.status == 200){
          this.setState({
            openAddList:false,
            openNotification:true,
            messageNotification:"Lista editada",
            typeNotification:"success",
            isLoading:false,
            openConfirmDialog:false
          });
          this._loadContactList();
        }
      }).catch(err => console.log(err));
    }
  }

  _search = (event)=>{
    let searchWord = event.target.value.toString().toLowerCase();
    
    let result = this.state.data.slice(0);
     
    var arr = new Array();
    result = result.filter(x => {
    
      if(x.nombre.toString().toLowerCase().includes(searchWord) ||
         x.contactos.toString().toLowerCase().includes(searchWord)){
        arr.push(x); 
      }

    });
    this.setState({searchData:arr});
  }

  _openConfirmDialog = (list) => {
    this.setState({
      openConfirmDialog:true,
      idDelete : list.clistId
    });
  }

  _closeConfirmDialog = () =>{
    this.setState({
      openConfirmDialog:false,
      idDelete : 0
    });
  }

  handleDateChange = date => {
    if(date)
      this.setState({ fechaInicio: new Date(date) });
    else
      this.setState({ fechaInicio: new Date() });
  };
   
  handleCloseModal = () =>{
    this.setState({
      openModal:false
    });
  }

  handleOpenModal = (preview, emails, listDist) =>{
    this._content = [];
    this.setState({isLoading:true, previewHTML : '', synthesis : [], sections: [], contentSection:[]}, () => {
      if(this.state.synthesisSelected !== 0){
      
        fetchCall("POST","/synthesis/getsection",{
          menuId: this.state.synthesisSelected
        }).then(response =>{
            this.setState(
              {
                sections: response.data
              }, () => {
                this._getPublishContent(this.state.sections, this.state.synthesisSelected, this.state.fechaInicio, response.data.length, 0, preview, emails, listDist);
              }
            );  
        }).catch(err => {
          console.log(err);
        });
      }else{
        this.setState({
          messageNotification:"Selecciona una síntesis",
          openNotification:true,
          typeNotification:'warning',
          isLoading:false
        });
      }
    })
  }

  _getEmails = (emails,pos, listDist) => {
    if(pos === this.state.searchData.length){
      this.email(this.state.sendEmails, listDist);
    }else{
      let item = this.state.searchData[pos];
      let checked = this.state[`${item.clistId}`];      
      if(checked){
        if(item.contactos > 0){
          let contactos=[];
          fetchCall("POST","/contact/getList", {
            clistId : item.clistId
          }).then(response =>{      
            if(response.status === 200){  
              response.data.map(item => {
                  contactos.push(item.correo)
                  emails.push(item.correo);
              }); 
              listDist.push({name:item.nombre,emails:contactos}); 
              this.setState({
                sendEmails: emails
              }, () => {
                this._getEmails(emails, pos+1, listDist);
              })
            }
          }).catch(err => console.log(err));
        }
      }else{
        this._getEmails(emails,pos+1, listDist)
      }
    }
  }

  _handleSendEmail = () => {
      if(this.state.synthesisSelected !== 0){
        let emails = new Array();
        let auxListDistribution = new Array();
        if(this.verifyEmails()){ 
          this.setState({isLoading:true})
          this._getEmails(emails,0, auxListDistribution);
        }
      }else{
        this.setState({
          messageNotification:"Selecciona una síntesis",
          openNotification:true,
          typeNotification:'warning'
        });
      }
  }

  email = (emails, listDist) => {  
      if(emails.length > 0){
        this.handleOpenModal(false, emails, listDist);
      }else{

        if(this.state.onlyOne){
          this.handleOpenModal(false, [], listDist);
        }else{
          this.setState({
            openNotification: true,
            messageNotification : "Debes seleccionar al menos un correo",
            typeNotification : 'warning'
          });
        }
      }
  }

  verifyEmails = ()=>{
    if(this.state.destinatario!== ""){
      const temp = this.state.destinatario.split(";") 
      let pass = true;
      temp.some(element => {
        if(element!=="" && !Validate(element.trim())){
          this.setState({
            openNotification: true,
            messageNotification: element + ' Formato de correo inválido',
            typeNotification:'error',
            isLoading:false
          });
          pass = false;
          return false;
        }
      });
      return pass
    }else{
      return true
    }
  }

  _getPublishContent = (section,menu, date, tam, index, preview, emails, listDist) =>{
    if(section.length === index){
      if(this.state.contentSection.length > 0){
        let synthesis = new Array();
        menuSelected = ls.get("session_menu").filter(x => x.menu_id === this.state.synthesisSelected)[0];
        let section = new Array();
        this.state.sections.map(item =>{
          let o = {
            sec_icono: item.sec_icono,
            sec_iconoUrl: `${item.sec_iconoUrl}`.split('?')[0],
            sec_id: item.sec_id,
            sec_name: item.sec_name,
            sec_orden:item.sec_orden,
            sec_tipo: item.sec_tipo,
            sec_color: item.sec_color,
            content : [...new Set(this.state.contentSection.filter(x => x.sec_id === item.sec_id))]
          }
          section.push(o);
        });
        // var d= new Date(date);
        date.setHours(9,0,0)
        let complete = {
          menu_id : menuSelected.menu_id,
          menu_name : menuSelected.menu_name,
          menu_description: menuSelected.menu_description,
          lastPublish: date,
          section : [...new Set(section)]
        }

        this.setState(
        {
          synthesis : complete,
          isLoading : false
        } , () => {

            let auxEmail = emails;

            if(this.state.onlyOne){  
              if(this.state.destinatario.toString().trim() != ""){
                const temp = this.state.destinatario.split(";").filter((e)=>e.trim()!=="")
                auxEmail = [...emails,...temp]
                listDist.push({name:"Destinatario único",emails:temp});
              }
            }
            
            fetchCall("POST","/distUserList/email", {
              emails : auxEmail,
              synthesis : this.state.synthesis,
              preview : preview,
              availableSynthesis : JSON.stringify(ls.get("session_menu").filter(x => x.lastPublish !== null&&x.menu_visible===1&&x.menu_public==0)),
              date : this._getCurrentTime(date),
              listDist :listDist
            }).then(
              response => {
                if(preview){

                  this.setState({
                    previewHTML : response,
                    openModal : preview,
                    isLoading: false,
                    synthesis: null
                  });

                  ls.set("synthesis_structure", this.state.synthesis);
                  ls.set("preview_html", response);

                }else{
                  let dest = this.state.destinatario;
                  this.setState({
                    openNotification : true,
                    messageNotification : `Se ha enviado la síntesis a ${auxEmail.length} correo${auxEmail.length>0?"s":""}`,
                    typeNotification : 'success',
                    isLoading :false,
                    synthesis : null,
                    destinatario : '',
                    sendEmails : []
                  },()=>{
                    fetchCall("POST","/report/addlogmovements", {
                      username:ls.get("username"),
                      action:"Envío de síntesis por correo",
                      description: `El usuario ${`${ls.get("username")}`.split("@")[0]} envió por correo la síntesis ${menuSelected.menu_name} ${listDist.length > 0 ? `a ${listDist.length==1&&dest!=""?"":"la lista de distribución"} ${listDist.map(e=>e.name)}` : '' }  ${dest!=""?"al correo "+dest:""}`,
                      userId:ls.get("current_user")['user_id']
                    });
                  });
                }
              }
            );
        });
      }else{
        this.setState({
          openNotification: true,
          messageNotification : "No se encontró una síntesis",
          typeNotification : 'warning',
          isLoading:false
        });
      }
    }else{
      fetchCall("POST", "/synthesis/getcontentpublish",{
        sectionId: section[index].sec_id,
        menuId: menu,
        publishDate : date==null?'':date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
      }).then(response =>{
        if(response){
          if(response.status === 200){
            if(response.data != null){
               response.data.map(item => {
                 item.cont_attachment=`${item.cont_attachment}`.split('?')[0]
                 item.cont_imagePath=`${item.cont_imagePath}`.split('?')[0]
                 this._content.push(item);
               });
               this.setState({
                 contentSection : this._content
               },() => {
                 this._getPublishContent(section, menu, date, tam, index +1, preview, emails, listDist)
               });
            }
           }
        }else{
          this._getPublishContent(section, menu, date, tam, index +1, preview, emails, listDist)
        }
      }).catch(err =>{
        console.log("error contenido", err)
      });
    }
  }

  _translateDay = (str) => {
    switch (str) {
      case "Mon": return "Lunes"; break;
      case "Tue": return "Martes"; break;
      case "Wed": return "Miércoles"; break;
      case "Thu": return "Jueves"; break;
      case "Fri": return "Viernes"; break;
      case "Sat": return "Sábado"; break;
      case "Sun": return "Domingo"; break;
    }
  }

  _translateYear = (str) => {
    switch (str) {
      case "Jan": return "enero"; break;
      case "Feb": return "febrero"; break;
      case "Mar": return "marzo"; break;
      case "Apr": return "abril"; break;
      case "May": return "mayo"; break;
      case "Jun": return "junio"; break;
      case "Jul": return "julio"; break;
      case "Aug": return "agosto"; break;
      case "Sep": return "septiembre"; break;
      case "Oct": return "octubre"; break;
      case "Nov": return "noviembre"; break;
      case "Dec": return "diciembre"; break;
    }
  }

  _getCurrentTime = (f) => {
    
    let date = new Date(f);
    let currentDay = this._translateDay(date.toString().split(" ")[0]);
    let currentDate = this._translateYear(date.toString().split(" ")[1]);
    return `${currentDay}, ${date.toString().split(" ")[2]} ${currentDate} ${date.toString().split(" ")[3]}`;
  }

  render() {
    const {
      classes
    } = this.props;
    const title = brand.name + ' - Lista de distribución';
    const description = brand.desc;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>

        <PreviewSynthesis
          classes={classes}
          openModalPermission={this.state.openModal}
          preview={this.state.previewHTML}
          handleCloseModal={this.handleCloseModal}
          date={this.state.fechaInicio}
        />

        {this.state.isLoading ? <LoaderFull /> : ''}

        <AddList 
          openForm={this.state.openAddList}
          closeForm={this.closeNewList} 
          classes={classes}
          submit={(event, data)=> this.submitList(event, data)}
          dataInit={this.state.listSelected}
        />

         <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>

        <ConfirmDialog 
          title={"¿Está seguro que desea eliminar la lista?"}
          message={"La lista al ser eliminada no se podrá recuperar"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._handleRemoveList}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />

        <PapperBlock title="Correos" fullTitle>
          <div>
            <form className={classes.container}>
              <Grid container spacing={24} direction="row" justify="flex-start" alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel >Selecciona una síntesis</InputLabel>
                    <Select
                      value={this.state.synthesisSelected}
                      id="synthesisSelected"
                      name="synthesisSelected"
                      onChange={this.handleChangeSelect}
                      className={classes.f1}
                      >
                    {
                      ls.get("session_menu").map((item, index) =>{
                          return(
                            <MenuItem key={index.toString()} value={item.menu_id}>{item.menu_name}</MenuItem>
                          );
                      })                    
                    }
                    </Select>
                    <div className={classes.margin}>
                      <Tooltip title="Previsualizar" placement="top">
                        <Button variant="fab" onClick={()=>this.handleOpenModal(true)} mini color="secondary" aria-label="add">
                          <Visibility />
                        </Button>
                      </Tooltip>
                    </div>
                  </FormControl>

                  {/* <FormControl>
                    <div className={classes.margin}>
                      <Tooltip title="Previsualizar" placement="top">
                        <Button variant="fab" onClick={()=>this.handleOpenModal(true)} mini color="secondary" aria-label="add">
                          <Visibility />
                        </Button>
                      </Tooltip>
                    </div>
                  </FormControl> */}
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl className={classes.formControl}>
                      <MuiPickersUtilsProvider utils={MomentUtils} locale={esLocale}>
                        <DatePicker
                          //keyboard={true}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaInicio}
                          onChange={this.handleDateChange}
                          disableOpenOnEnter
                          label="Fecha de publicación"
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          cancelLabel={"Cancelar"}
                          clearLabel={"Limpiar"}
                          className={classes.f1}
                        />                    
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl className={classes.formControl}>
                    <Tooltip id="tooltip-help" title="Lista de correos separados por punto y coma ';'">
                      <TextField id="destinatario" name="destinatario"  className={classes.f1} value={this.state.destinatario} onChange={(event) => this.handleChangeEmail(event)} label="Destinatarios" fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.gray}>
                            <ContactIcon />
                          </InputAdornment>
                        ),
                      }}
                      />
                    </Tooltip>
                    <div className={classes.margin}>
                      <Tooltip title="Enviar" placement="top">
                        <Button variant="fab" onClick={()=> {
                            this._handleSendEmail();
                        }} disabled={this.state.canSend} mini color="secondary" aria-label="add">
                          <SendIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
            <Paper>

              <Toolbar className={classes.toolbar}>
                <div className={classes.title}>
                    <div className={classes.flex}>
                      <div className={classes.searchWrapper}>
                        <div className={classes.search}>
                          <SearchIcon />
                        </div>
                        <input className={classes.input} onChange={(event) => this._search(event)} placeholder="Buscar lista" />
                      </div>
                    </div>
                </div>
                {/* <div className={classes.spacer} /> */}
                <div className={classes.actions}>
                    <Hidden xsDown>
                      <Button variant="raised" disabled={this.state.canPublish} onClick={this._handleOpenAddList} color="secondary" className={classes.button}>
                        <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                        Nuevo
                      </Button>
                    </Hidden>
                    <Hidden smUp>
                      <Tooltip title="Nuevo" placement="top">
                        <Button variant="fab" disabled={this.state.canPublish} onClick={this._handleOpenAddList} mini color="secondary" aria-label="add">
                          <AddIcon className={classNames(classes.iconSmall)} />
                        </Button>
                      </Tooltip>
                    </Hidden>
                </div>
              </Toolbar>
              <div className={classes.rootTable}>
                <div className={classes.maxHeightTable}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell align="right">
                        <Checkbox
                            checked={this.state.checkedA}
                            onChange={(event)=>this.handleChange(event,'checkedA')}
                            value={`${this.state.checkedA}`}
                            id="checkedA"
                            name="checkedA"
                          />
                        </CustomTableCell>             
                        <CustomTableCell align="right">Id.</CustomTableCell>
                        <CustomTableCell align="right">Nombre de la lista</CustomTableCell>
                        <CustomTableCell align="right">N° de contactos</CustomTableCell>
                        <CustomTableCell align="right">Acciones</CustomTableCell> 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.searchData.map(row => {
                        return (
                          <TableRow className={classes.row} key={row.clistId}>    
                            <CustomTableCell align="right">
                              <Checkbox
                                checked={this.state[`${row.clistId}`] ? true :false}
                                onChange={(event)=>this.handleChange(event,row)}
                                value={this.state[`${row.clistId}`]}
                                id={`${row.clistId}`}
                                name={`${row.clistId}`}
                              />
                            </CustomTableCell>
                            <CustomTableCell component="th" scope="row">
                              {row.clistId}
                            </CustomTableCell>
                            <CustomTableCell component="th" scope="row">
                              {row.nombre}
                            </CustomTableCell>
                            <CustomTableCell align="right">{row.contactos}</CustomTableCell>
                            <CustomTableCell align="right">
                              <div key={row.clistId} className={classes.contentIcons}>
                                <Tooltip title="Editar">
                                  <IconButton disabled={this.state.canEdit} onClick={()=>{
                                    this._handleEditList(row)
                                  }} color="secondary" className={classes.button}>
                                    <Icon>edit</Icon>
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Eliminar">
                                  <IconButton disabled={this.state.canDelete} onClick={()=>{
                                    this._openConfirmDialog(row)
                                  }} color="secondary" className={classes.button}>
                                    <Icon>delete</Icon>
                                  </IconButton>
                                </Tooltip>

                              </div>
                            </CustomTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>

              {
                this.state.searchData.length > 0 ?
                <Typography component="p" className={classes.selectContact}>
                  {this.state.totalContacts}  Contactos seleccionados
                </Typography> :
                <Typography component="p" className={classes.noMatch}>
                  No se encontraron listas de distribución con ese nombre
                </Typography>
              }
          </Paper>
         </div>
        </PapperBlock>
      </div>
    );
  }
}

SendEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

SendEmail.contextType = ThemeContext;

export default withStyles(styles)(SendEmail);
