import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './header-jss';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete'
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AddHeader from '../../New/Header/AddHeader';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';
import { fetchCall } from '../../../service/service_base';
import { savePortrait } from '../../../service/s3Amplify';
import ls from 'local-storage';
import { S3Image } from 'aws-amplify-react';
class Header extends React.Component {
  state = {
    openAddHeader: false,
    action: "Nuevo",
    header: []
  }

  componentDidMount() {
    this._loadNotes();
  }

  _loadNotes = () => {
    fetchCall("POST", "/synthesis/getcontent", {
      sectionId: this.props.section.sec_id,
      isPublish: this.props.isPublish,
      idPublish: this.props.idPublish
    }).then(response => {
      this.setState({ header: response.data, isLoading: false, });

    }).catch(err => console.log(err));
  }
  handleCloseNotification = () => {
    this.setState({
      openNotification: false
    })
  }
  openAddHeader = () => {
    this.setState({
      openAddHeader: true,
      action: "Nuevo",
      noteSelected: null
    })
  }

  _handleCloseAddHeader = () => {
    this.setState({
      openAddHeader: false,
      action: "Nuevo",
      noteSelected: null
    })
  }

  _handleAddHeader = (event, data, file, filemail) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    data.imagePath = '';
    data.sectionId = this.props.section.sec_id;
    data.userId = ls.get("current_user").user_id;
    let keyS3 = `${this.props.synthesis}/${this.props.section.sec_id}/${file ? `${new Date().getTime()+"_" + file.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}`;
    let keyS3mail = `${this.props.synthesis}/${this.props.section.sec_id}/${filemail ? `${new Date().getTime()+"_" + filemail.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}`;

    if (data.isUpdating) {
      Promise.all([(file ? savePortrait(keyS3, file, file.type) : null),(filemail? savePortrait(keyS3mail, filemail, filemail.type):null)])
        .then(response => {
          data.imagePath = response[0] !== null ? response[0].key : this.state.noteSelected.cont_imagePath;
          data.description = response[1]!==null? response[1].key:this.state.noteSelected.cont_description;
          fetchCall("POST", "/synthesis/updatecontent", data).then(response => {
            if (response.status == 200) {
              this.setState({
                openNotification: true,
                messageNotification: 'Contenido actualizado',
                typeNotification: "success",
                isLoading: false,
                noteSelected: null,
                openAddHeader: false,
              });
              this._handleCloseAddHeader()
            setTimeout(() => { this._loadNotes(); }, 1500);
            }
          }).catch(err => err);
        })
    } else {
      data.idPublish = this.props.idPublish
      data.order = this.state.header.length + 1
      Promise.all([savePortrait(keyS3, file, file.type),savePortrait(keyS3mail, filemail, filemail.type)])
        .then(response => {
          data.imagePath = response[0].key;
          data.description = response[1].key;
          fetchCall("POST", "/synthesis/addcontent", data).then(() => {
            this.setState({
              openNotification: true,
              messageNotification: 'Contenido guardado',
              typeNotification: "success",
              noteSelected: null
            });
            this._handleCloseAddHeader()
            setTimeout(() => { this._loadNotes(); }, 1500);
          }).catch(err => err);
        })
    }
  }

  openConfirmDelete = (item) => {
    this.setState({
      openConfirmDialog: true,
      idDeleted: item
    })
  }
  edit = (item) => {
    this.setState({
      openAddHeader: false,
      noteSelected: null
    }, () => {
      this.setState(
        {
          openAddHeader: true,
          noteSelected: item,
          action: "Editar"
        }
      )
    })
  }

  remove = () => {
    this.setState({ isLoading: true });
    fetchCall("POST", "/synthesis/removecontentsection", {
      contId: this.state.idDeleted.cont_id,
      userId: ls.get("current_user").user_id
    }).then(response => {
      if (response.status == 200) {
        // apiElasticSearch("/doc/upsert-doc", { dataElastic: [{cont_id:this.state.idDeleted.cont_id}],deleteDoc:true})
        // deleteS3Object(this.state.idDeleted.cont_key)
        this.setState({
          openConfirmDialog: false,
          isLoading: false,
          openNotification: true,
          messageNotification: 'Contenido eliminado',
          typeNotification: "success"
        });
         this._loadNotes(); 
      }
    }).catch(err => console.log(err));
  }

  _closeConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      idDeleted: null
    })
  }
  
  render() {
    const {
      classes,
      canDelete,
      canEdit,
      section
    } = this.props;
    return (
      <div className="container">
        <AddHeader
          classes={classes}
          submit={this._handleAddHeader}
          openForm={this.state.openAddHeader}
          closeForm={this._handleCloseAddHeader}
          dataInit={this.state.openAddHeader ? this.state.noteSelected : null}
          action={this.state.action}
        />

        <ConfirmDialog
          title={"¿Desea eliminar el contenido?"}
          message={"El contenido al ser eliminado no podrá recuperarse"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this.remove}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>
        <Grid container spacing={8}>
          <Grid item xs={12} style={{backgroundColor: section.sec_color}} className={classes.bgDefaultSection}>
            <Grid container spacing={16} justify="center" >
              {
                this.state.header.length>0 ?
                  this.state.header.map((item, key) => {
                    item.cont_imagePath = item.cont_key
                    return <Grid item key={key}>
                        <div className={classes.btn}>
                          <S3Image imgKey={item.cont_imagePath} />
                        </div>
                        <div className={classes.adminActions}>
                          <Tooltip title="Editar">
                            <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={() => this.edit(item)}>
                              <Edit />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Eliminar">
                            <Button variant="fab" mini disabled={!canDelete} aria-label="add" className={classes.button} onClick={() => this.openConfirmDelete(item)}>
                              <Delete />
                            </Button>
                          </Tooltip>
                        </div>
                      
                    </Grid>
                  })
                  :
                  <Grid item xs={12} md={6} lg={4}>
                    <div className={classes.headerNew}>
                      <div className={classes.contentBtnNew}>
                        <Tooltip title="Nuevo header">
                          <Button variant="fab" onClick={this.openAddHeader} mini aria-label="add" className={classes.btnNew} disabled={!canEdit}>
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}


export default withStyles(styles)(Header);
