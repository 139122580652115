import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Typography from "@material-ui/core/Typography";
import { Input, InputLabel } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import styles from "./card-jss";
import { ValidationContent } from "../../../validator/Regex";
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Editor } from "@bit/primefaces.primereact.editor";
import { charDescriptionLength } from "../../../utils/validation";

class AddCardForm extends React.Component {
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state = {
    description: "",
    contId: 0,
    isUpdating: false,
    textLimited:0
  };

  handleChange = (event, min) => {
    if (!ValidationContent(event.target.value) &&event.target.value.toString().trim("").length <= min) {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  componentWillReceiveProps(newProps) {
    if (newProps.dataInit != null) {
      this.setState({
        description:
          this.state.description === ""
            ? newProps.dataInit.cont_description
            : this.state.description,
        contId: newProps.dataInit.cont_id,
        isUpdating: true
      });
    }

    if (!newProps.isOpen) {
      this._cleanData(true);
      this.setState({
        isUpdating: false,
        contId: 0
      });
    }
  }

  _cleanData = band => {
    if (band) {
      this.setState({
        description: "",
        textLimited:0
      });
    } else {
      this.setState({
        description: this.props.dataInit
          ? this.props.dataInit.cont_description
          : "",
      });
    }
  };
  handleTextEditor = event => {
    if (event.textValue.toString().trim("").length <= charDescriptionLength) {
      this.setState({ description: event.htmlValue && event.htmlValue.replace("background", ""), textLimited: false });
    } else {
      this.setState({
        textLimited: event.textValue.toString().trim("").length
      });
    }
  };
  renderHeader() {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold" />
        <button className="ql-italic" aria-label="Italic" />
        <button className="ql-underline" aria-label="Underline" />
        <select className="ql-color"></select>
      </span>
    );
  }
  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      onDrop,
      preview,
      errorMessage,
      isLoading
    } = this.props;
    let dropzoneRef;
    const acceptedFiles = ["image/jpeg", "image/png", "image/bmp"];
    const fileSizeLimit = 93000000;
    const renderHeader = this.renderHeader();
    return (
      <div>
        <form
          onSubmit={event => {
            handleSubmit(event, this.state);
            this.props.onClean(true);
            this._cleanData(true);
          }}
        >
          <section className={classes.bodyForm}>
            <div>
              <div>
                <Typography variant="button" className={classes.textCenter}>
                  SUBIR LOGO
                </Typography>
                <Dropzone
                  className={classes.hiddenDropzone}
                  accept={acceptedFiles.join(",")}
                  acceptClassName="stripes"
                  onDrop={onDrop}
                  maxSize={fileSizeLimit}
                  ref={node => {
                    dropzoneRef = node;
                  }}
                />
                <div className={classes.avatarWrap}>
                  <Avatar
                    alt={preview}
                    className={classes.uploadAvatar}
                    src={preview}
                  />
                  <Tooltip id="tooltip-upload" title="Subir foto">
                    <IconButton
                      className={classes.buttonUpload}
                      component="button"
                      onClick={() => {
                        dropzoneRef.open();
                      }}
                    >
                      <PhotoCamera />
                    </IconButton>
                  </Tooltip>
                </div>
                <Typography variant="button" className={classes.textCenter}>
                  {errorMessage}
                </Typography>
              </div>
              <div>
                <InputLabel htmlFor="notas">Descripción *</InputLabel>
                <Editor
                  headerTemplate={renderHeader}
                  style={{ height: "100px", marginBottom: "15px" }}
                  value={this.state.description}
                  onTextChange={event => this.handleTextEditor(event)}
                />
                {this.state.textLimited > charDescriptionLength && (
                  <div>
                    Has llegado al límite de caracteres ({this.state.textLimited}
                    )
                  </div>
                )}
              </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button
              variant="raised"
              color="primary"
              type="submit"
              disabled={
                (preview && this.state.textLimited <= charDescriptionLength)
                  ? false
                  : true
              }
            >
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this.props.onClean(false);
                setTimeout(() => {
                  this._cleanData(false);
                }, 500);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddCardForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  preview: PropTypes.string,
  dataInit: PropTypes.object,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(AddCardForm);
