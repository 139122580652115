import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input , InputLabel} from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import {Alphanumeric} from '../../../../validator/Regex'; 
import styles from './list-jss';


class AddListForm extends React.Component {
  

  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state ={
    nombre:''
  }

  resetValues = () =>{
    this.setState({
        nombre:''
    })
  }

  handleChange = event => {
    if(!Alphanumeric(event.target.value) && event.target.value.toString().split('').length < 51 ){
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  componentWillReceiveProps(newProps){
    if(newProps.dataInit != null){
      this.setState({
        nombre: this.state.nombre !== '' ? this.state.nombre : newProps.dataInit.nombre
      });
    }

    if(!newProps.isOpen){
      this._clearData(false);
    }
  }

  _clearData = (band) =>{
    if(band){
      this.setState({
        nombre: this.props.dataInit ? this.props.dataInit.nombre : ''
      })
    }else{
      this.setState({
        nombre: ''
      })
    }
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit
    } = this.props;     
  
    return (
      <div>
        <form onSubmit={(event) => handleSubmit(event, this.state)}>
          <section className={classes.bodyForm}>           
          <div>
              <InputLabel htmlFor="nombre">Nombre</InputLabel>
              <Input 
                id="nombre"
                name="nombre"
                value={this.state.nombre}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            
          </section>
          <div className={classes.buttonArea}>
            <Button onClick={() => {
              setTimeout(() => {
                this.resetValues();
              }, 2000);
            }} variant="raised" color="primary" type="submit" disabled={submitting}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => this._clearData(true)}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddListForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit:PropTypes.object,
  isOpen:PropTypes.bool
};
 

export default withStyles(styles)(AddListForm);
