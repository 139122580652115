import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './cardButton-jss';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
class CardButton extends React.Component {
  render() {
    const {
      classes,
      edit,
      canEdit, 
      attachment,
      text,
      icon
    } = this.props;
    return (
            <div className={classes.CardButton}>
              <a target="_blank" href={`/multimedia/${encodeURIComponent(attachment)}`} className={classes.btnCard}>
                {icon}{text}
              </a>
              <div className={classes.adminActions}>
                <Tooltip title="Editar">
                  <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>edit()}>
                    <Edit />
                  </Button>   
                </Tooltip>
                </div>
            </div>
    );
  }
}

CardButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.node,
  edit: PropTypes.func,
  remove: PropTypes.func,
  canEdit:PropTypes.bool,
  canDelete:PropTypes.bool
};

export default withStyles(styles)(CardButton);
