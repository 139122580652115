import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Hub } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import ls from 'local-storage';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import { apiPOST, cognitoSignIn } from '../../service/cognitoService';
import CustomNotification from '../Notification/CustomNotification';
import OAuthButton from './OAuthButton';
import { cognitoCurrentUser, cognitoInfoCurrentUser } from '../../service/cognitoService';
import { fetchCall } from '../../service/service_base';
import { Alphanumeric } from '../../validator/Regex';
import brand from '../../utils/brand';
import styles from './user-jss';
import logo_televisa from '../../../static/images/logo-televisa.svg';
import app from '../../../../package.json'

class LoginForm extends React.Component {


  _isMounted = false;

  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    Hub.listen('auth', this);
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      openNotification: false,
      requireChangePassword: false,
      messageNotification: '',
      typeNotification: 'default',
      isLoading: false,
      userData: []
    }
  }

  componentDidMount() {
    cognitoCurrentUser().then(user => {
      cognitoInfoCurrentUser().then(response => {

        this._syncUserWithDB(user, response.attributes);
      }).catch(err => console.log("current info", err));
    }).catch(e => {
      console.log(e);
    });
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          cognitoCurrentUser().then(user => {
            if (!this._isMounted) {

              cognitoInfoCurrentUser().then(response => {

                this._syncUserWithDB(user, response.attributes);
              }).catch(err => console.log("current info", err));
              this._isMounted = true;
            }
          }).catch(e => {
            console.log(e);
          });
          break;
        case 'signIn_failure':
          console.log('not signed in');
          break;
        default:
          break;
      }
    }
  }



  _syncUserWithDB = (user, attributtes) => {
    let decode = user.signInUserSession.idToken.payload;
    apiPOST("/login", {
      "username": `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""),
      "password": `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""),
    }).then(response => {
      if (response.status === 200) {
        if (response.data[0].status_handler === 0) {
          /*User have permissions to access to synthesis but does not exists in BD */
          let userdata = {};
          if (decode["identities"] === undefined) {
            // userdata ={//cognito
            //   username: user.username,
            //   cred_username: user.username,
            //   cred_password: user.username,
            //   email: attributtes.email,
            //   profileActive : attributtes ? attributtes.profile : '',
            //   name : attributtes.given_name ? attributtes.given_name : `${attributtes.email}`.split("@")[0],
            //   lastname : attributtes.family_name ? attributtes.family_name : '',
            //   id_empleado: attributtes["custom:employeeid"] ? attributtes["custom:employeeid"]: ''
            // }
          } else {
            userdata = {//directorio activo
              username: `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""),
              cred_username: `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""),
              cred_password: `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""),
              email: attributtes.email,
              profileActive: attributtes ? attributtes.profile : '',
              name: attributtes.given_name ? attributtes.given_name : `${attributtes.email}`.split("@")[0],
              lastname: attributtes.family_name ? attributtes.family_name : '',
              id_empleado: attributtes["custom:employeeid"] ? attributtes["custom:employeeid"] : ''
            }
          }
          fetchCall("POST", "/user/add", userdata).then(response => {

            if (response.status === 200) {
              //success
              if (response.data[0].status_handler === 1) {
                fetchCall("POST", "/report/addlogmovements", {
                  username: user.username.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""),
                  action: "Nuevo Usuario",
                  description: `Se dio de alta el usuario ${user.username.replace(process.env.REACT_APP_cognito_identity_provider + "_", "")}`,
                  userId: response.data[0].user_id
                })
                this._initUser(response.data[0], `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""));
              }
            }
          }).catch(err => console.log("ADD USER ERROR", err));

        } else if (response.data[0].status_handler == 1) {
          this._initUser(response.data[0], `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", ""));
        }
      }
    }).catch(err => console.log(err));
  }

  _initUser = (user, userLogin) => {
    if (user.user_status == 1) {
      fetchCall("POST", "/report/addlogmovements", {
        username: userLogin,
        action: "Inicio de sesión",
        description: `El usuario ${`${userLogin}`.split("@")[0]} inició sesión en el portal`,
        userId: user.user_id
      })
      ls.set("current_user", user);
      ls.set("username", userLogin);
      window.location.href = "/app";

    } else {
      this.setState({
        messageNotification: "Tu usuario está bloqueado",
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
    }
  }

  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleChange = (event, c) => {
    if (c > 0) {
      if (!Alphanumeric(event.target.value) && event.target.value.toString().split('').length <= c)
        this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    cognitoSignIn(this.state.email, this.state.password).then(response => {
      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setState({ userData: response, requireChangePassword: true, isLoading: false });
      } else {
        //ls.set("currentUser", response);
        this.setState({ userData: response });

        //console.log("Form")
        //this._syncUserWithDB(response);
      }
    }).catch(err => {
      this.setState({
        messageNotification: err.message === "User does not exist." ? "El usuario no existe" : err.message === `Incorrect username or password.` ? 'Usuario y/o contraseña incorrecta' : 'Ocurrió un error, intente más tarde',
        typeNotification: 'error',
        openNotification: false,
        isLoading: false
      });
    })
  }

  render() {
    const {
      classes,
      submitting,
      pristine
    } = this.props;
    return (
      <div className={classes.formWrap}>

        {
          this.state.isLoading ?

            <div className={classes.centerProgress}>
              <div className={classes.centerProgress}>
                <CircularProgress style={{ color: 'white' }}
                  size={50}
                />
              </div>
            </div>

            :
            <div >
              <form onSubmit={this.handleSubmit}>
                <div className={classes.contentLogo}>
                  <img src={logo_televisa} alt={brand.name} />
                </div>
                <OAuthButton />
                <Typography className={classes.help}>
                  Si tienes problemas para ingresar comunícate al <strong>CAT</strong> extensión <strong>12345</strong>
                </Typography>
                {/* <div className={classes.footer}>
              ¿No puedes ingresar? <a href="mailto:sintesis@televisa.com"><Button size="small" color="secondary" className={classes.lowercase}>sintesis@televisa.com</Button></a>
            </div> */}
              </form>
              <center><Typography className={classes.version}>v{app.version}</Typography></center>
            </div>
        }

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>

      </div>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginForm);
