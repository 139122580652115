const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    '&$noMargin': {
      margin: 0
    },
  }),
  title: {
    marginBottom: 10,
    paddingBottom: 13,
    display: 'inline-block',
    position: 'relative',
    textTransform: 'none !important',
    fontSize: 20,
    lineHeight: '20px',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 60,
      borderBottom: `4px solid ${theme.palette.primary.main}`
    },
    '@media (max-width: 420px)': {
      maxWidth: 230,
    },
  },
  fullTitle:{
    maxWidth: '100% !important',
  },
  description: {
    maxWidth: 960,
    fontSize: 16,
  },
  content: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
    backgroundColor: theme.palette.background.paper,
  },
  whiteBg: {
    backgroundColor: 'transparent',
    margin: 0,
  },
  noMargin: {
    marginTop: 0
  },
  colorMode: {
    //backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'white',
    margin: 0,
    paddingLeft: "1rem !important",
    paddingRight: "1rem !important",
    margin: 'auto',
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: '0 !important',
    '& $title': {
      marginBottom: 10,
      paddingBottom: 13,
      position: 'relative',
      textTransform: 'capitalize',
      fontSize: 20,
      color:theme.palette.primary.main,
      lineHeight: '20px',
      '@media (max-width: 320px)': {
        fontSize: '18px !important',
        lineHeight: '20px',
      },
      width: '100%',
      '@media (max-width: 430px)': {
        paddingBottom: 25,
      },
      // '@media (max-width: 375px)': {
      //   width: '100%',
      // },
      '@media (max-width: 320px)': {
        fontSize: 18,
        fontWeight: 600
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 200,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        display: 'none'
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        borderBottom: `1px solid #d7d7d5`
      },
    },
    '& $description': {
      color: theme.palette.grey[100],
    },
    '& $content': {
      backgroundColor: 'transparent !important'
    }
  },
  overflowX: {
    width: '100%',
    overflowX: 'auto',
  },
  iconTitle: {
    display: 'inline-Block',
    marginRight: 10,
    width: 24,
    height: 24,
    float: 'left',
    '& img':{
      aspectRatio: '1/1',
      objectFit: 'cover',
    }
  },
  iconTitleMenu: {
    display: 'inline-Block',
    marginRight: 10,
    width: 24,
    height: 24,
    float: 'left',
    '& img':{
      aspectRatio: '1/1',
      objectFit: 'cover',
    }
  },
  button: {
    margin: '0 3px',
  },
  contentActions: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  btnsMobile:{
    width: '100%',
    display: 'block',
  },
  btns:{
    position: 'absolute',
    width: 195,
    top: -12,
    display: 'inline-block',
  },
  picker : {
    '@media (max-width: 540px)': {
      width: '100%'
    }
  }
});

export default styles;
