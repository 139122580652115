import React from 'react';
import { object, PropTypes } from 'prop-types';
import classNames from 'classnames';
import { renderRoutes } from 'react-router-config';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import styles from './appStyles-jss';
import brand from '../../utils/brand';
import logo from '../../../static/images/logo-televisa-direccion.svg';//'ba-images/logo-televisa-direccion.svg';
import './estilos.css'
import { cognitoCurrentUser, cognitoLogOut } from '../../service/cognitoService';
import ls from 'local-storage';
import { fetchCall } from '../../service/service_base';
import AddMenu from '../Menu/AddMenu';
import { saveIconSynthesis } from '../../service/s3Amplify';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../components/Notification/CustomNotification';
import avatar from '../../../static/images/avatars/pp_boy4.svg';

class Dashboard extends React.Component {
  state = {
    transform: 0,
    sidebarOpen: true,
    pageLoaded: false,
    currentName: 'initial',
    currentProfile: 'initial',
    urlPhoto: '',
    addMenu: false,
    permissionAddMenu: true,
    synthesis: [],
    openNewMenu: false,
    openNotification: false,
    isLoading: false
  };
  MenuContent = [];
  componentDidMount = () => {

    cognitoCurrentUser().then(response => {
    }).catch(err => {
      if (err === "not authenticated") {
        ls.clear();
        window.location.href = "/";
      }
    });
    // Scroll content to top
    const mainContent = document.getElementById('mainContent');
    this.loadTransition(true);

    // Execute all arguments when page changes
    this.unlisten = this.props.history.listen(() => {
      mainContent.scrollTo(0, 0);
      setTimeout(() => {
        this.loadTransition(true);
      }, 500);
    });

    let currentUser = ls.get("current_user");
    if (ls.length === 0) {
      ls.clear();
      window.location.href = "/";
    }

    let addNewSynthesis = false;
    let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
    if (permissionsCurrentUser[0] != null) {
      let add = permissionsCurrentUser.find(x => x.name === "Nuevo tipo de síntesis");


      if (add != undefined) {
        let currentPermissions = ls.get("permissions");
        if (currentPermissions != undefined) {
          let data = currentPermissions.find(x => x.menu_id == add.id);
          if (data != undefined) {
            let configuration = JSON.parse(data.grant_configuration);

            if (configuration.chkPublishAdminCont) {
              addNewSynthesis = true;
            }
          }
        }
      }
    }

    this.setState({
      currentName: `${currentUser.user_name} ${currentUser.user_lastname}`,
      currentProfile: currentUser.pro_name,
      urlPhoto: currentUser.user_photo !== ' ' ? currentUser.user_photo : avatar,
      permissionAddMenu: addNewSynthesis
    });

    this._loadMenu();
  }

  loadTransition = () => {
    this.setState({ pageLoaded: true });
  }

  componentWillUnmount() {
    //const mainContent = document.getElementById('mainContent');
    //mainContent.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    const scoll = event.target.scrollTop;
    this.setState({
      transform: scoll
    });
  }

  toggleDrawer = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  _handleNewMenu = () => {
    if (this.state.addMenu) {
      this.setState({ addMenu: false });
    } else {
      this.setState({ addMenu: true });
    }
  }

  handleLogOut = () => {
    cognitoLogOut().then(response => { console.log(response) }).catch(err => { console.log(err) });
    ls.clear();
    window.location.href = "/";
  }

  _loadMenu = (addMenu) => {
    let userMenu = ls.get("current_user");
    fetchCall("POST", "/synthesis/menuaction", {
      profileId: userMenu.pro_id
    }).then(response => {
      ls.set("permissions", response.data);
    }).catch(err => {
      console.log(err);
    });

    let configScreen = {
      id: 0,
      key: 'configuración',
      name: 'Configuración',
      icon: 'settings',
      link: '/admin',
      child: []
    };

    let configScreenCatalog = {
      id: 0,
      key: 'catalogs',
      name: "Catalogos",
      link: '/admin',
      child: []
    }

    this.MenuContent = [];

    JSON.parse(userMenu.menuSynthesis !== null ? "[" + userMenu.menuSynthesis + "]" : '[]').map((item) => {
      if ("Configuración/Columnistas" === item.name ||
        "Configuración/Catálogos/Editoriales" === item.name ||
        "Configuración/Catálogos/Kickers" === item.name ||
        "Configuración/Catálogos/Periódicos" === item.name ||
        "Configuración/Catálogos/Radiodifusoras" === item.name ||
        "Bitácora de movimientos" === item.name ||
        "Histórico de síntesis" === item.name ||
        "Configuración/Administración de usuarios" === item.name ||
        "Configuración/Administración de perfiles" === item.name ||
        "Configuración/Contactos" === item.name ||
        "Configuración/Lista de distribución" === item.name ||
        "Nuevo tipo de síntesis" === item.name ||
        "Configuración/Catalogos" === item.name) {

        if ("Configuración/Administración de usuarios" === item.name) {
          if (ls.get("permissions") != null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(1))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);

              if (permissions.chkViewAdminUser) {
                configScreen.child.push({
                  id: 1,
                  key: 'adminUsuarios',
                  name: "Admin de Usuarios",
                  link: '/admin/administracion-de-usuarios'
                });
              }
            }
          }
        }

        if ("Configuración/Administración de perfiles" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(2))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);
              if (permissions.chkViewAdminProfile) {
                configScreen.child.push({
                  id: 2,
                  key: 'adminPerfiles',
                  name: "Admin de Perfiles",
                  link: '/admin/administracion-de-perfiles'
                });
              }
            }
          }
        }

        if ("Configuración/Contactos" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(4))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);

              if (permissions.chkViewContact) {
                configScreen.child.push({
                  id: 4,
                  key: 'listaContactos',
                  name: "Contactos",
                  link: '/admin/lista-contactos'
                });
              }
            }
          }
        }
        if ("Configuración/Lista de distribución" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(5))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);
              if (permissions.chkViewSendEmail) {
                configScreen.child.push({
                  id: 5,
                  key: 'envioCorreos',
                  name: "Envío de Correos",
                  link: '/admin/envio-de-correos'
                });
              }
            }
          }
        }

        if ("Bitácora de movimientos" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(7))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);
              if (permissions.chkViewReport) {
                configScreen.child.push({
                  id: 7,
                  key: 'bitacoramovimientos',
                  name: "Bitácora de Movimientos",
                  link: '/report/bitacora-movimientos'
                });
              }
            }
          }
        }

        if ("Histórico de síntesis" === item.name) {
          if (ls.get("permissions") != null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(6))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);
              if (permissions.chkViewHistory) {
                configScreen.child.push({
                  id: 6,
                  key: 'sintesispublicadas',
                  name: "Histórico de síntesis",
                  link: '/report/sintesis-publicadas'
                });
              }
            }
          }
        }

        if ("Configuración/Catalogos" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(17))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);
              if (permissions.chkViewCatalog) {               
                configScreen.child.push({
                  ...configScreenCatalog,
                });
              }
            }
          }
        }
        if ("Configuración/Columnistas" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(12))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);

              if (permissions.chkViewColumnist) {
                configScreenCatalog.child.push({
                  id: 12,
                  key: 'columnistas',
                  name: "Columnistas",
                  link: '/admin/columnas'
                });
              }
            }
          }
        }
        if ("Configuración/Catálogos/Editoriales" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(16))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);
              if (permissions.chkViewEditorial) {
                configScreenCatalog.child.push({
                  id: 12,
                  key: 'editoriales',
                  name: "Editoriales",
                  link: '/admin/editoriales'
                });
              }
            }
          }
        }
        if ("Configuración/Catálogos/Kickers" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(13))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);

              if (permissions.chkViewKicker) {
                configScreenCatalog.child.push({
                  id: 12,
                  key: 'kickers',
                  name: "Kickers",
                  link: '/admin/kickers'
                });
              }
            }
          }
        }
        if ("Configuración/Catálogos/Periódicos" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(14))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);

              if (permissions.chkViewNewspaper) {
                configScreenCatalog.child.push({
                  id: 12,
                  key: 'newspapers',
                  name: "Periódicos",
                  link: '/admin/newspapers'
                });
              }
            }
          }
        }
        if ("Configuración/Catálogos/Radiodifusoras" === item.name) {
          if (ls.get("permissions") !== null) {
            let config = ls.get("permissions").filter(x => x.menu_id === Number.parseInt(15))[0];
            if (config !== undefined) {
              let permissions = JSON.parse(config.grant_configuration);

              if (permissions.chkViewRadiostation) {
                configScreenCatalog.child.push({
                  id: 12,
                  key: 'radiostations',
                  name: "Radiodifusoras",
                  link: '/admin/radiostations'
                });
              }
            }
          }
        }

      } else {

        let obj = {
          id: parseInt(item.id),
          icon: item.icon || "chrome_reader_mode",
          key: item.name,
          link: "/synthesis/" + item.name,
          name: item.name,
          menu_description: item.description,
          visible: parseInt(item.visible),
          menu_orden: parseInt(item.orden)
        }

        this.MenuContent.push(obj);
      }
    });
    configScreenCatalog.child.sort(function (a, b){
      if(a.name > b.name ){
        return 1 
      }
      if(a.name < b.name){
        return -1 
      }
      return 0
    })
    
    this.MenuContent.push(configScreen);
    let report = this.MenuContent.findIndex(x => x.name === "Bitácora de movimientos");
    if (report !== -1) {
      this.MenuContent.splice(report, 1);
    }
    let historyNews = this.MenuContent.findIndex(x => x.name === "Histórico de síntesis");
    if (historyNews !== -1) {
      this.MenuContent.splice(historyNews, 1);
    }

    fetchCall("GET", "/synthesis/get").then(response => {
      ls.set("session_menu", response.data);
    }).catch(err => console.log("Menu", err));
    this.refreshData();
  }

  refreshData = () => {
    let newMenu = [];
    if (ls.get("permissions") !== null) {
      this.MenuContent.map(item => {
        let config = ls.get("permissions").filter(x => parseInt(x.menu_id) === parseInt(item.id))[0];
        if (config !== undefined) {
          let permissions = JSON.parse(config.grant_configuration);
          if (permissions.chkViewSynthesis) {
            newMenu.push(item);
          }
        } else {
          newMenu.push(item);
        }
      });
    }
    this.setState({ synthesis: newMenu.sort((a, b) => {
      return a.menu_orden - b.menu_orden;
    }) });
  }

  _openNewMenu = () => {
    this.setState(
      {
        openNewMenu: true
      }
    )
  }

  _closeNewMenu = () => {
    this.setState(
      {
        openNewMenu: false
      }
    )
  }

  saveNewMenu = (event, data) => {
    event.preventDefault();
    data.createdBy = ls.get("current_user").user_id;
    this.setState(
      {
        isLoading: true
      }
    )

    if (data.menu_name.toString().trim() === "") {
      this.setState({
        messageNotification: "Debes ingresar un nombre para la síntesis",
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
      return true;
    }


    if (ls.get("session_menu").filter(item => item.menu_name === data.menu_name.toString().trim())[0]) {
      this.setState({
        messageNotification: "Ya existe una síntesis con ese nombre",
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
      return true;
    }

    this._handleSaveSynthesis(data);

  }

  _handleSaveSynthesis = (data) => {
    fetchCall("POST", "/synthesis/addmenu", data).then(response => {
      if (response.status === 200) {
        this.setState({
          messageNotification: "Se guardó la síntesis",
          typeNotification: 'success',
          openNotification: true,
          isLoading: false
        });

        fetchCall("GET", "/synthesis/get").then(response => {
          ls.set("session_menu", response.data);
          this._closeNewMenu();
        }).catch(err => {
          this.setState({
            messageNotification: "Ocurrió un error al guardar la síntesis",
            typeNotification: 'error',
            openNotification: true,
            isLoading: false
          });
        });
      }
    }).catch(err => {
      this.setState({
        messageNotification: "Ocurrió un error al guardar la síntesis",
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
    });
  }

  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  }

  render() {
    const {
      classes,
      route,
    } = this.props;
    const darker = true;
    const { sidebarOpen, pageLoaded } = this.state;
    return (
      <div className={classes.appFrameInner}>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>

        <AddMenu
          openForm={this.state.openNewMenu}
          closeForm={this._closeNewMenu}
          classes={classes}
          submit={this.saveNewMenu}
          action={"Nueva"}
          isLoading={this.state.isLoading}
          isUpdating={false}
        />

        <Header toggleDrawerOpen={this.toggleDrawer} turnDarker={this.state.transform > 30 && darker} margin={sidebarOpen} photo={this.state.urlPhoto} />
        <Sidebar
          open={sidebarOpen}
          toggleDrawerOpen={this.toggleDrawer}
          loadTransition={this.loadTransition}
          turnDarker={this.state.transform > 30 && darker}
          name={this.state.currentName}
          profile={this.state.currentProfile}
          urlPhoto={this.state.urlPhoto}
          addMenu={this.state.addMenu}
          funcAddMenu={this._handleNewMenu}
          addNewSynthesis={this.state.permissionAddMenu}
          logOut={this.handleLogOut}
          synthesis={this.state.synthesis}
          openNewMenu={this._openNewMenu}
        />
        <main className={classNames(classes.content, !sidebarOpen && classes.contentPadding)} id="mainContent">
          <div className={classes.bgbar}>
            <img src={logo} alt={brand.name} />
          </div>
          <section className={classes.mainWrap}>
            <div>

            </div>
            {/* <BreadCrumb separator=" › " theme="light" location={this.props.history.location} /> */}
            <Fade
              in={pageLoaded}
              mountOnEnter
              unmountOnExit
              {...(pageLoaded ? { timeout: 700 } : {})}
            >
              <div className={!pageLoaded ? classes.hideApp : ''}>
                {renderRoutes(route.routes)}
              </div>
            </Fade>
          </section>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  route: object
};

Dashboard.defaultProps = {
  route: object
};

export default (withStyles(styles)(Dashboard));
