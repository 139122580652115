import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';
import { Input, InputLabel, Checkbox } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import styles from './section-jss';
import { ValidationContent } from '../../../validator/Regex';

class AddBannerForm extends React.Component {

  state = {
    title: '',
    description:"",
    contId: 0,
    isUpdating: false
  };

  handleChange = event => {
    if (!ValidationContent(event.target.value) && event.target.value.toString().trim('').length < 151) {
      this.setState({ [event.target.name]: `${event.target.value}` });
    }
  };
  handleCheck=event=>{
    let checks = this.state.description.split(",")
    if(checks.includes(event.target.value)){
      checks.splice(checks.indexOf(event.target.value),1)
    }else{
      checks.push(event.target.value);
    }
    this.setState({description:checks.toString()})
  }

  componentWillReceiveProps(newProps) {
    if (newProps.dataInit !== null) {
      this.setState({
        title: this.state.title !== '' ? this.state.title : newProps.dataInit.cont_name,
        description: this.state.description !== '' ? this.state.description : newProps.dataInit.cont_description,
        contId: this.state.contId !== 0 ? this.state.contId : newProps.dataInit.cont_id,
        isUpdating: true
      });
    }

    if (!newProps.isOpen) {
      this._clearData(true);
      this.setState({
        isUpdating: false,
        contId: 0
      });
    }
  }

  _clearData = (band) => {
    if (band) {
      this.setState({
        title: '',
        description:''
      });
    } else {
      this.setState({
        title: this.props.dataInit ? this.props.dataInit.cont_name : '',
        description: this.props.dataInit ? this.props.dataInit.cont_description : ''
      });
    }
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      onDrop,
      preview,
      errorMessage,
    } = this.props;

    let dropzoneRef;
    const acceptedFiles = ['image/jpeg', 'image/png'];
    const fileSizeLimit = 93000000;

    return (
      <div>
        <form onSubmit={(event) => {
          handleSubmit(event, this.state);
          //this.props.onClean(true);
          this._clearData(true)
        }}>
          <section className={classes.bodyForm}>
            <div>
              <div>
                <div>
                  <Typography variant="button" className={classes.textCenter}>SUBIR IMAGEN PRINCIPAL</Typography>
                  <Dropzone
                    className={classes.hiddenDropzone}
                    accept={acceptedFiles.join(',')}
                    acceptClassName="stripes"
                    onDrop={onDrop}
                    maxSize={fileSizeLimit}
                    ref={(node) => { dropzoneRef = node; }}
                  />
                  <div className={classes.avatarWrap}>
                    <Avatar
                      alt=""
                      className={classes.uploadAvatar}
                      src={preview}
                    />
                    <Tooltip id="tooltip-upload" title="Subir foto">
                      <IconButton
                        className={classes.buttonUpload}
                        component="button"
                        onClick={() => {
                          dropzoneRef.open();
                        }}
                      >
                        <PhotoCamera />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography variant="button" className={classes.textCenter}>{errorMessage}</Typography>
                </div>
                <div>
                  <InputLabel htmlFor="Link">Link (incluye el protocolo https:// ó http:// del link)</InputLabel>
                  <Input
                    name="title"
                    value={this.state.title}
                    type="text"
                    autoComplete="off"
                    required
                    className={classes.field}
                    startAdornment={
                      <InputAdornment position="start">
                        <PermContactCalendar />
                      </InputAdornment>
                    }
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <Checkbox
                    checked={this.state.description.includes("Web")}
                    onChange={this.handleCheck}
                    value="Web"
                    name="web"
                    aria-label="A"
                    classes={{
                      root: classes.colorGray,
                      checked: classes.checked,
                    }}
                  />Web
                  <Checkbox
                    checked={this.state.description.includes("Correo")}
                    onChange={this.handleCheck}
                    value="Correo"
                    name="correo"
                    aria-label="A"
                    classes={{
                      root: classes.colorGray,
                      checked: classes.checked,
                    }}
                  />Correo
              </div>
              </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="raised" color="primary" type="submit" disabled={this.state.isUpdating || preview!==''? false : true}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this.props.onClean(!this.state.isUpdating);
                setTimeout(() => {
                  this._clearData(false);
                }, 500);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddBannerForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dataInit: PropTypes.object,
  contentType: PropTypes.string,
  preview: PropTypes.string,
  onClean: PropTypes.func,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(AddBannerForm);
