module.exports = [
  {
    key: 'configuración',
    name: 'Configuración',
    icon: 'settings',
    link: '/admin',
    child:[
      {
        id: 1,
        key:'adminUsuarios',
        name:"Admin de Usuarios",
        link: '/admin/administracion-de-usuarios'
      },
      {
        id : 2,
        key:'adminPerfiles',
        name:"Admin de Perfiles",
        link: '/admin/administracion-de-perfiles'
      },
      {
        id : 5,
        key:'envioCorreos',
        name:"Envío de Correos",
        link: '/admin/envio-de-correos'
      },
      {
        id: 4,
        key:'listaContactos',
        name:"Contactos",
        link: '/admin/lista-contactos'
      }
    ]
  },
  {
    key: 'reports',
    name: 'Reportes',
    icon: 'settings',
    link: '/report',
    child:[
      {
        key:'bitacoraMovimientos',
        name:"Bitacora de Movimientos",
        link: '/report/bitacora-movimientos'
      },
      {
        key:'sintesisPublicadas',
        name:"Sintesis Publicadas",
        link: '/report/sintesis-publicadas'
      },
    ]
  }
];
