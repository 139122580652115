import React from 'react';
import PropTypes from 'prop-types';
import FloatingPanel from '../../../components/Panel/FloatingPanel';
import AddCardForm from './AddCardForm';
import { getPhotoCurrentUser } from '../../../service/s3Amplify';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';

class AddCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: [],
      selectedPicture: null,
      urlPreview:'',
      errorMessage:'',
      attachment:null,
      openNotification : false,
      messageNotification : 'Selecciona un archivo válido',
      typeNotification : "warning"
    }; 
    this.onDrop = this.onDrop.bind(this);
    this.onClean = this.onClean.bind(this);
  }

  onDrop(files) {
    if(files.length===0){
      this.setState({errorMessage:"archivo inválido"});
    }else{
      let oldFiles = this.state.files;
      const filesLimit = 2;
      oldFiles = oldFiles.concat(files);
      if (oldFiles.length > filesLimit) {
        this.setState({
          errorMessage : 'No puedes subir más de un archivo'
        });
      } else {
        this.setState({ img: files[0].preview || '/pic' + files[0].path, selectedPicture: files[0] ,errorMessage:''});        
      }
    }    
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification : false
      }
    )
  }

  componentWillReceiveProps(newProps){
    
    if(newProps.dataInit !== null){
      getPhotoCurrentUser(newProps.dataInit.cont_imagePath).then(response =>{
        this.setState({
          urlPreview:response
        });
      }).catch(err =>{
        console.log("error url", err);
      });
    }else{
      this.setState(
        {
          urlPreview : ''
        }
      )
    }

    if(this.props.openForm){
      this.onClean(true);
    }

  }

  onClean(band){
    if(band){
      this.setState({
        selectedPicture:null,
        urlPreview:'',
        errorMessage:'',
        attachment:null
      });
    }else{
      
      
      if(this.props.dataInit && this.props.dataInit.cont_imagePath !== ""){
        getPhotoCurrentUser(this.props.dataInit.cont_imagePath).then(response =>{
          this.setState({
            selectedPicture : null,
            urlPreview:response
          });
        });
      }else{
        this.setState({
          selectedPicture : null,
          urlPreview:""
        });
      }
    }
  }

  render() {
    const {
      openForm,
      closeForm,
      dataInit,
      submit,
      isLoading,
      action,
      secName,
      dataNewspaper
    } = this.props;
    
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action}`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddCardForm  
            handleSubmit={(event, data)=>submit(event, data, this.state.selectedPicture)}
            onDrop={this.onDrop}
            preview={this.state.selectedPicture ? this.state.selectedPicture.preview : this.state.urlPreview}
            isOpen={openForm}
            dataInit={dataInit}
            onClean={this.onClean}
            errorMessage={this.state.errorMessage}
            isLoading={isLoading}
            secName={secName}
            dataNewspaper={dataNewspaper}
          />
        </FloatingPanel>
        <Snackbar
         anchorOrigin={{
             vertical: 'top',
             horizontal: 'right',
         }}
         open={this.state.openNotification}
         autoHideDuration={6000}
         onClose={this.handleCloseNotification}>
       <CustomNotification
         onClose={this.handleCloseNotification}
         variant={this.state.typeNotification}
         message={this.state.messageNotification}/>
       </Snackbar>
      </div>
    );
  }
}

AddCard.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func, 
  openForm: PropTypes.bool, 
  closeForm: PropTypes.func,
  dataInit: PropTypes.object
};

export default (AddCard);
