import React from 'react';
import PropTypes from 'prop-types';
import AddEditorialForm from './AddEditorialForm';
import FloatingPanel from '../../../../components/Panel/FloatingPanel';
import { getPhotoCurrentUser } from '../../../../service/s3Amplify';

class AddEditorial extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: [],
      urlPreview:'',
      imgError:''
    };
    this.onDrop = this.onDrop.bind(this);
  }

  componentWillReceiveProps(newProps){
    if(newProps.dataInit !== null){
      getPhotoCurrentUser(newProps.dataInit.columnist_photo).then(response => {
        this.setState({
          urlPreview:response
        });
      }).catch(err =>{
        console.log("error url", err);
      });
    }else{
      this.setState({
        img:''
      });
    }
  }

  onDrop(files) {
    console.log(files)
    if (files.length>0) {
      this.setState({ img: files[0] || '/pic' + files[0].path,imgError:'' });
    } else {      
      this.setState({imgError:'Archivo Inválido'})
    }
  }

  cleanValues = () => { 
    setTimeout(() => {
      this.props.closeForm();
      this.setState({ img: '',urlPreview:'',files: [] });
    }, 500);
  }

  render() {
    const { 
      openForm,
      dataInit,
      submit
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title="Editorial" openForm={openForm} branch={branch} closeForm={this.cleanValues}>
          <AddEditorialForm
            onSubmit={()=>{}}
            onDrop={this.onDrop}
            imgAvatar={this.state.img ? this.state.img.preview : this.state.urlPreview}
            handleSubmit={(event, data)=>(submit(event, data, this.state.img),this.cleanValues())}
            dataInit={dataInit}
            isOpen={openForm}
            imgError={this.state.imgError}
          />
        </FloatingPanel>
      </div>
    );
  }
}

AddEditorial.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  AddEditorial: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object
};
export default AddEditorial;
