import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddContentForm from './AddContentForm';
import FloatingPanel from './../Panel/FloatingPanel';
import styles from './content-jss';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../Notification/CustomNotification';
class AddContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: [],
      selectedPicture: null,
      openNotification : false,
      messageNotification : 'Selecciona un archivo válido',
      typeNotification : "warning"
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    let oldFiles = this.state.files;
    const filesLimit = 2;
    oldFiles = oldFiles.concat(files);
    if (oldFiles.length > filesLimit) {
      console.log('Cannot upload more than ' + filesLimit + ' items.');
    } else {
      if(files[0]){ 
        this.setState({ img: files[0].preview || '/pic' + files[0].path , selectedPicture : files[0]});
      }else{
        this.setState(
          {
            selectedPicture : undefined,
            openNotification : true
          }
        )
      }
    }
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification : false
      }
    )
  }

  onClear = () => {
    this.setState(
      {
        selectedPicture : null
      }
    )
  }

  sendValues = (values) => {
    setTimeout(() => {
      this.props.submit(values, this.state.img);
      this.setState({ img: '' });
    }, 500);
  }

  render() {
    const {
      openForm,
      closeForm,
      avatarInit,
      dataInit,
      submit,
      action,
    } = this.props;
    const { img } = this.state;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action} Sección`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddContentForm
            onSubmit={this.sendValues}
            onDrop={this.onDrop}
            imgAvatar={img === '' ? avatarInit : img}
            handleSubmit={(event, data)=>submit(event, data, this.state.selectedPicture)}
            dataInit={dataInit}
            isOpen={openForm}
            icon={this.state.selectedPicture}
            onClear={this.onClear}
          />
        </FloatingPanel>
        <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>
      </div>
    );
  }
}

AddContent.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  addContact: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object,
  action: PropTypes.string
};

export default withStyles(styles)(AddContent);
