import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import brand from '../../../../utils/brand';
import  PapperBlock  from '../../../../components/PapperBlock/PapperBlock'; 
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';  
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import styles from '../../../../components/Tables/tableParts/tableStyle-jss'; 
import ls from 'local-storage';
import {fetchCall} from '../../../../service/service_base'; 
import AddRadiostation from './AddRadiostation';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification'; 
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import { ThemeContext } from '../../../../utils/ThemeContext';
import Switch from '@material-ui/core/Switch';
import { saveImage } from '../../../../service/s3Amplify'; 
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#9F9F9F',
    padding: '4px 24px 4px 24px',
  },
  body: {
    padding: '4px 24px 4px 24px',
    fontSize: 14,
  },
}))(TableCell);


class Radiostations extends React.Component {

  constructor(props) {
    super(props);   
    this.state ={
      anchorEl: null,
      dataRadiostation: [],
      openAddRadiostation: false,
      radiostationSelected:null,
      editRadiostation : false,
      openNotification:false,
      typeNotification:"info",
      messageNotification:"",
      canEdit:true,
      canShow:false,
      canPublish:true,
      canDelete : true,
      searchData:[],
      openConfirmDialog:false,
      idDelete:0,
      isLoading:true,
      currentUser:ls.get("current_user")['user_id']
    }
  }

  

  handleCloseNotification = () =>{
    this.setState({openNotification:false,messageNotification:''});
  }

  componentDidMount(){  
    this.context.toggleTheme(undefined);  
    this._getUserPermissions();
  }

  _getUserPermissions = () =>{    
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("["+currentUser.menuSynthesis+"]");    
    if(permissionsCurrentUser[0] != null){
      let add = permissionsCurrentUser.find(x => x.name ==="Configuración/Catálogos/Radiodifusoras");
    
    if(add !== undefined){
      let currentPermissions = ls.get("permissions");
      if(currentPermissions != undefined || currentPermissions !== null){
        
        let data = currentPermissions.find(x => x.menu_id == add.id);        
        if(data !== undefined){
          let config = JSON.parse(data.grant_configuration);
            this.setState({
              canShow : config.chkViewRadiostation,
              canEdit : config.chkEditRadiostation,
            })
          this._getUsersByList()
        }

      }
    }
    }

  }

  _getUsersByList = () => {
    fetchCall("GET","/radiostation/get").then(response =>{   
       this.setState({
         dataRadiostation: response.data,
         searchData:response.data,
         isLoading:false
       });      
    }).catch(err => console.log(err));
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  _handleOpenAddRadiostation = () =>{
    this.setState({
      openAddRadiostation : true,
      radiostationSelected : null,
      editRadiostation:false,
    })
  }

  closeNewRadiostation = () =>{
    this.setState({
      radiostationSelected : null,
      openAddRadiostation : false
    })
  }

  submitRadiostation = (event, data, file) => {
    event.preventDefault();
    this.setState({isLoading:true});
    data.active=1;
    if(data.radiostation_name.toString().trim() ===''){
      this.setState({
        openNotification: true,
        messageNotification: 'Debes ingresar un nombre',
        typeNotification:'warning',
        isLoading:false
      });      
      return false;
    }
    if(!this.state.editRadiostation){
      let exists = this.state.dataRadiostation.find(x => x.radiostation_name === data.radiostation_name.toString().trim().toLowerCase() );
      if(exists !== undefined){
        this.setState({
          openNotification: true,
          messageNotification: 'Ya existe un radiodifusora con este nombre',
          typeNotification:'warning',
          isLoading:false
        });      
        return false;
      }
    }
    let keyS3 = `Catalogs/Radiostation/${file ? `${new Date().getTime()+"_" + file.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}`;
    if (this.state.editRadiostation) {
      data.radiostation_id = this.state.radiostationSelected.radiostation_id;
      Promise.all([(file ? saveImage(keyS3, file, file.type) : null)])
        .then(response => {
          data.radiostation_photo = response[0] !== null ? response[0].key :this.state.radiostationSelected.radiostation_photo;
          fetchCall("POST", "/radiostation/update", data).then(response =>{
            if(response.status === 200){
              this.setState({
                openAddRadiostation : false,
                editRadiostation:false,
                openNotification:true,
                messageNotification:"Se modificó la radiodifusora",
                typeNotification:'success',
                isLoading:false,
                radiostationSelected:null
              });
              this.forceUpdate()
              this._getUsersByList();
            }
          }).catch(err => console.log(err));
        })
    } else {
      Promise.all([saveImage(keyS3, file, file.type)])
        .then(response => {
          data.radiostation_photo = response[0].key;
          fetchCall("POST", "/radiostation/add", data).then(response =>{
            if(response.status === 200){
              this.setState({
                openAddRadiostation : false,
                editRadiostation: false,
                openNotification:true,
                messageNotification:"Se agregó la radiodifusora",
                typeNotification:'success',
                isLoading:false,
                radiostationSelected:null
              });
              this.forceUpdate()
              this._getUsersByList();
            }
          }).catch(err => console.log(err));
        })
    }
  }

  _handleEditRadiostation = (radiostation)=>{
    this.setState({
      radiostationSelected : radiostation,
      openAddRadiostation : true,
      editRadiostation: true
    })
  }

  _handleDeleteRadiostation= ()=>{ 
    this.setState({isLoading:true});
    this.state.radiostationSelected.active=2;
      fetchCall("POST","/radiostation/update",this.state.radiostationSelected).then(response =>{
          if(response.status === 200){ 
            this.setState({
              openNotification:true,
              messageNotification:"Se eliminó "+this.state.radiostationSelected.radiostation_name,
              typeNotification:'success',
              openConfirmDialog:false,
              idDelete:0,
              isLoading:false,
              radiostationSelected:null
            })
            this._getUsersByList();
          }
      }).catch(err => console.log(err))
  }

  handleStatus = (item) =>{
    this.setState({isLoading:true});
    item.active= item.radiostation_status===1?0:1
    item.menu_id= 0
    fetchCall("POST","/radiostation/update",item).then(response =>{ 
      if(response.status === 200){
        this.setState({
          openNotification:true,
          messageNotification:"Se cambió el estatus a " + item.radiostation_name,
          typeNotification:'success',
          openConfirmDialog:false,
          idDelete:0,
          isLoading:false
        })
        this._getUsersByList();        
      }else{
        this.setState({
          openNotification:true,
          messageNotification:"Ocurrió un error al cambiar el estatus",
          typeNotification:"error",
          isLoading:false
        });
      }
    }).catch(err => console.log(err));
  }

  _search = (event)=>{
    let searchWord = event.target.value.toString().toLowerCase();    
    let result = this.state.dataRadiostation.slice(0);     
    var arr = [];
    arr = result.filter(x => { 
      if(`${x.radiostation_name}`.toLowerCase().includes(searchWord)){
        return x;
      }
    });
    this.setState({searchData:arr});
  }

  _openConfirmDialog = (radiostation) =>{
    this.setState({
      openConfirmDialog : true,
      radiostationSelected : radiostation
    });
  }

  _closeConfirmDialog = () =>{
    this.setState({
      openConfirmDialog : false,
      radiostationSelected : null
    });
  }
   
  render() {
    const {
      classes
    } = this.props;
    const title = brand.name + ' - Contáctos';
    const description = brand.desc;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        
        {this.state.isLoading ? <LoaderFull /> : ''}
        
         <AddRadiostation 
          openForm={this.state.openAddRadiostation}
          closeForm={this.closeNewRadiostation} 
          classes={classes}
          submit={this.submitRadiostation}
          dataInit={this.state.radiostationSelected}
        />

         <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>

        <ConfirmDialog 
          title={"¿Está seguro que desea eliminar la radiodifusora?"}
          message={"Al ser eliminado no se podrá recuperar"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._handleDeleteRadiostation}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />
        
        <PapperBlock title="Radiodifusora" fullTitle>
          <div>
            <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            {/* TODO: AGREGAR INPUT DE BUSQUEDA */}
             
              <div className={classes.flex}>
                <div className={classes.searchWrapper}>
                  <div className={classes.search}>
                    <SearchIcon />
                  </div>
                  <input className={classes.input} onChange={(event) => this._search(event)} placeholder="Buscar" />
                </div>
              </div>
            </div>
          
          {/* <div className={classes.spacer} /> */}
          <div className={classes.actions}>
            <Hidden xsDown>
              <Button variant="raised"  onClick={this._handleOpenAddRadiostation} color="secondary" className={classes.button}>
                <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                Nuevo
              </Button>
            </Hidden>
            <Hidden smUp>
              <Tooltip title="Nuevo" placement="top">
                <Button variant="fab"  onClick={this._handleOpenAddRadiostation} mini color="secondary" aria-label="add">
                  <AddIcon className={classNames(classes.iconSmall)} />
                </Button>
              </Tooltip>
            </Hidden>
              
          </div>
        </Toolbar>
        <Paper className={classes.rootTable}>
          <div className={classes.maxHeightTable}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {/* <CustomTableCell align="right">
                  <Checkbox
                      checked={this.state.checkedA}
                      onChange={this.handleChange('checkedA')}
                      value="checkedA"
                    />
                  </CustomTableCell> */}
                  <CustomTableCell align="right">Nombre</CustomTableCell>
                  <CustomTableCell align="right">Tipo de síntesis</CustomTableCell>
                  <CustomTableCell align="right">Estatus</CustomTableCell>
                  <CustomTableCell align="right">Acciones</CustomTableCell> 
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.searchData.map((row,i) => {
                  return (
                    <TableRow className={classes.row} key={row.radiostation_id}>   
                      <CustomTableCell component="th" scope="row">
                        {row.radiostation_name}
                      </CustomTableCell>
                      <CustomTableCell align="right">{row.menu_name}</CustomTableCell>
                      <CustomTableCell align="right">
                      <Switch
                              disabled={!this.state.canEdit}
                              checked={row.radiostation_status === 1 ? true : false}
                              onChange={() => { this.handleStatus(row) }}
                              value="checkedB"
                              color="primary"
                            />
                      </CustomTableCell>
                      <CustomTableCell align="right">
                        <div key={i} className={classes.contentIcons}>
                        <Tooltip title="Editar">
                          <IconButton disabled={!this.state.canEdit} onClick={()=>{
                            this._handleEditRadiostation(row)
                          }} color="secondary" className={classes.button}>
                            <Icon>edit</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <IconButton disabled={!this.state.canEdit} onClick={()=>{
                            this._openConfirmDialog(row)
                          }} color="secondary" className={classes.button}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                        </div>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
      {
        this.state.searchData.length === 0 ? 
        <Typography className={classes.noMatch}>No se encontraron radiodifusoras con ese criterio de búsqueda</Typography>:''
      }
        </Paper>
         </div>
        </PapperBlock>
      </div>
    );
  }
}

Radiostations.propTypes = {
  classes: PropTypes.object.isRequired
};

Radiostations.contextType = ThemeContext;

export default withStyles(styles)(Radiostations);
