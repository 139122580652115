const service = process.env.REACT_APP_local==1?process.env.REACT_APP_aws_apigateway_endpoint:
window.location.origin + process.env.REACT_APP_aws_apigateway_behavior;

const awsmobile =  {
    "aws_project_region": process.env.REACT_APP_aws_project_region,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_aws_cognito_identity_pool_id,
    "aws_cognito_region": process.env.REACT_APP_aws_project_region,
    "aws_user_pools_id": process.env.REACT_APP_aws_user_pools_id,
    "aws_user_pools_web_client_id":process.env.REACT_APP_aws_user_pools_web_client_id,
    "aws_user_files_s3_bucket": process.env.REACT_APP_aws_user_files_s3_bucket,
    "aws_user_files_s3_bucket_region": process.env.REACT_APP_aws_user_files_s3_bucket_region,
    "aws_cloud_logic_custom": [
        {
            "name": process.env.REACT_APP_aws_apigateway_name,
            "endpoint": service,
            "region": process.env.REACT_APP_aws_apigateway_region
        },
        {
            "name": process.env.REACT_APP_elastic_name,
            "endpoint": window.location.origin + process.env.REACT_APP_elastic_behavior,
            "region": process.env.REACT_APP_elastic_region
        }
    ],
    "aws_content_delivery_bucket": process.env.REACT_APP_aws_content_delivery_bucket,
    "aws_content_delivery_bucket_region": process.env.REACT_APP_aws_content_delivery_bucket_region,
    "aws_content_delivery_url": process.env.REACT_APP_aws_content_delivery_url ,
};

export default awsmobile;