import { lighten } from '@material-ui/core/styles/colorManipulator';
import { fade } from '@material-ui/core/styles/colorManipulator';
const styles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  rootTable: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  maxHeightTable: {
    position: 'relative',
    maxHeight: 786,
    height: '100%',
    overflow: 'auto',
    '& thead tr th':{
      zIndex: 100,
      position: 'sticky',
      background: 'white',
      top: 0,
      left: 0,
    },
    '@media (max-width: 640px)': {
      maxHeight: 499,
    },
  },
  flexEnd: {
    justifyContent: "flex-end",
    padding: 0,
  },
  highlight:
    theme.palette.type === 'light' ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    } : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  f1: {
    flex: 1,
  },
  actionsToolbar: {
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
  },
  titleToolbar: {
    flex: '0 0 auto',
  },
  filterBtn: {
    top: -5,
  },
  textField: {
    flexBasis: 200,
    width: 300
  },
  table: {
    minWidth: 900,
    margin: '0 !important'
  },
  actions: {
    color: theme.palette.text.secondary,
    margin: 10
  },
  toolbar: {
    backgroundColor: "#174a7c",
    marginTop: theme.spacing.unit * 3,
    justifyContent: 'space-between'
  },
  title: {
    flex: '0 1 auto',
    color: '#FFFFFF',
    '& h2': {
      color: theme.palette.common.white
    }
  },
  button: {
    margin: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  stripped: {
    '& tbody tr:nth-child(even)': {
      background: '#d9d9d9',
    }
  },

  search: {
    width: 50,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    color: "#ffffff"
  },
  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    borderRadius: 2,
    background: fade(theme.palette.common.white, 0.15),
    display: 'inline-block',
    '&:hover': {
      background: fade(theme.palette.common.white, 0.25),
    },
    '& $input': {
      transition: theme.transitions.create('width'),
      width: 180,
      '&:focus': {
        width: 350,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
  },
  flex: {
    flex: 1,
    textAlign: 'right'
  },
  input: {
    font: '#ffffff',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px 50px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: '#80a6c0',
    margin: 0, // Reset for Safari
    color: '#ffffff',
    width: '100%',
    WebkitAppearance: 'none',
    borderRadius: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing.unit
    },
  }
  ,
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  // ESTILOS TABLA MATERIAL
  rootTable: {
    width: '100%',
    //marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    borderRadius: 0,
  },
  table: {
    minWidth: 700,
    marginTop: 0
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  // ESTILOS ICONOS ENCIMADOS
  contentIcons: {
    position: 'relative',
    width: 145,
    '& div': {
      display: 'inline-block',
      textAlign: 'center',
      width: 27,
      height: 27,
      backgroundColor: 'white',
      color: theme.palette.secondary.main,
      boxShadow: theme.shadows[2],
      borderRadius: '50%',
      '&:hover':{
        transform: 'scale(1.3)',
        transition: 'all 0.3s ease',
      },
      '& svg': {
        marginBottom: -8,
        fontSize: 18,
      }
    },
    '& div:not(:first-child)': {
      marginLeft: -15
    }
  },
  paper: {
    position: 'absolute',
    width: '90%',
    overflow: 'hidden',
    maxWidth: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing.unit * 4,
    outline: 'none',
    borderRadius: 5,
  },
  paperHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: theme.spacing.unit * 2,
    fontSize: '1.2rem'
  },
  paperBody: {
    padding: theme.spacing.unit * 2,
    maxHeight: 450,
    overflow: 'auto',
  },
  paperFooter: {
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%',
    textAlign: 'right',
    padding: '8px 24px',
    '& button': {
      marginRight: 5,
    }
  },
  scrollTab: {
    maxHeight: 400,
    overflow: 'auto',
  },
  colorUno: {
    color: '#174A7C',
    '&$checked': {
      color: '#174A7C',
    },
  },
  colorDos: {
    color: '#008085',
    '&$checked': {
      color: '#008085',
    },
  },
  colorTres: {
    color: '#66CDCD',
    '&$checked': {
      color: '#66CDCD',
    },
  },
  colorCuatro: {
    color: '#FF5E3E',
    '&$checked': {
      color: '#FF5E3E',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100% !important',
  }, searchWrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    borderRadius: 2,
    display: 'block',
    background: theme.palette.grey[100],
    '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
      color: 'rgba(255,255,255,1) !important'
    },
    '& ::-moz-placeholder': { /* Firefox 19+ */
      color: 'rgba(255,255,255,1) !important'
    },
    '& :-ms-input-placeholder': { /* IE 10+ */
      color: 'rgba(255,255,255,1) !important'
    },
    '& :-moz-placeholder': { /* Firefox 18- */
      color: 'rgba(255,255,255,1) !important'
    },
  },
  menuOptions: {
    rigth: '250px !important',
    [theme.breakpoints.down('sm')]: {
      left: '50% !important',
    },
    [theme.breakpoints.up('md')]: {
      left: '70% !important',
    },
    [theme.breakpoints.up('lg')]: {
      left: '85% !important',
    },
  },
  alignCellIcon: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'start',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  gray: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  selectContact: {
    color: 'rgba(0, 0, 0, 0.54)',
    paddingLeft: 40,
    paddingBottom: 24
  },
  overflowVisible: {
    overflow: 'visible'
  },
  noMatch :{
    // color: 'rgba(130, 110, 120, 0.54)',
     paddingBottom: '24px !important',
     marginLeft : '35% !important'
  }
});

export default styles;
