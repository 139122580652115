import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import Bookmark from '@material-ui/icons/Bookmark'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Email from '@material-ui/icons/Email';
import Divider from '@material-ui/core/Divider';
import styles from './contact-jss';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Block from '@material-ui/icons/Block';
import avatar from '../../../static/images/avatars/pp_boy4.svg';

import { S3Image } from 'aws-amplify-react';

const ITEM_HEIGHT = 48;

class ContactDetail extends React.Component {
  state = {
    anchorElOpt: null,
  };

  handleClickOpt = event => {
    this.setState({ anchorElOpt: event.currentTarget });
  };

  handleCloseOpt = () => {
    this.setState({ anchorElOpt: null });
  };

  render() {
    const {
      classes,
      itemSelected,
      edit,
      block,
      darBaja,
      showMobileDetail,
      permissionsEdit,
      permissionBlock,
      permissionUnblock
    } = this.props;
    const { anchorElOpt } = this.state;
    if (!itemSelected) {
      return (
        <main className={classNames(classes.content, showMobileDetail ? classes.detailPopup : '')}>
          <section className={classes.cover}>

          </section>
        </main>
      );
    }
    let accessTo = new Array();
    if (itemSelected.availableSynthesis) {
      let parse = JSON.parse(`[${itemSelected.availableSynthesis}]`);
      parse.map(item => {
        let selected = this.props.availableSynthesis.filter(x => x.menu_id === parseInt(item.id))[0];
        if (selected) {
          accessTo.push(selected.menu_name)
        }
      });
    }

    return (
      <main className={classNames(classes.content, showMobileDetail ? classes.detailPopup : '')}>
        <section className={classes.cover}>
          <div className={classes.opt}>
            <Tooltip title="Editar">
              <IconButton disabled={permissionsEdit} aria-label="Edit" onClick={() => edit()}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Bloquear/Activar Usuario">
              <IconButton
                aria-label="More"
                aria-owns={anchorElOpt ? 'long-menu' : null}
                aria-haspopup="true"
                className={classes.button}
                onClick={this.handleClickOpt}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              anchorEl={anchorElOpt}
              open={Boolean(anchorElOpt)}
              onClose={this.handleCloseOpt}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200,
                },
              }}
            >
              {
                itemSelected.status == '1' ?
                  <MenuItem key={2} disabled={permissionBlock} onClick={() => {
                    block(2);
                    this.handleCloseOpt();
                  }}>
                    Bloquear usuario
                </MenuItem>
                  :
                  <MenuItem key={1} disabled={permissionUnblock} onClick={() => {
                    block(1);
                    this.handleCloseOpt();
                  }}>
                    Activar usuario
                    </MenuItem>
              }
              {itemSelected.status != '0'?<MenuItem key="3" onClick={() => {
                darBaja();
                this.handleCloseOpt();
              }} style={{ color: "#d60000" }}>
                Dar de baja
              </MenuItem>:null}
            </Menu>
          </div>

          <Avatar alt="Foto" className={classes.avatar} >
            {itemSelected.photo.trim().length > 0 ? <S3Image className={classes.avatar} imgKey={itemSelected.photo} /> : <img src={avatar} />}
          </Avatar>

          <Typography className={classes.userName} variant="display1">
            {itemSelected.username} {itemSelected.lastname}
            <Typography variant="caption">
              {itemSelected.employeeid=="Externo"?"Externo":itemSelected.profileAD}
            </Typography>
          </Typography>
        </section>
        <div>
          <List>
            <ListItem>
              <Avatar className={classes.themeSecondaryIcon}>
                <AssignmentInd />
              </Avatar>
              <ListItemText primary={itemSelected.email.split("@")[0]} secondary="Usuario" />
            </ListItem>

            <Divider inset />
            <ListItem>
              <Avatar className={classes.themeSecondaryIcon}>
                <Bookmark />
              </Avatar>
              <ListItemText primary={itemSelected.employeeid} secondary="Número de empleado" />
            </ListItem>
            <Divider inset />
            <ListItem>
              <Avatar className={classes.themeSecondaryIcon}>
                <Email />
              </Avatar>
              <ListItemText className={classes.wordBreak} primary={itemSelected.email} secondary="Email" />
            </ListItem>
            <Divider inset />
            <ListItem>
              <Avatar className={classes.themeSecondaryIcon}>
                <AccountCircle />
              </Avatar>
              <ListItemText primary={itemSelected.profile} secondary="Perfil" />
            </ListItem>

            <Divider inset />
            <ListItem>
              <Avatar className={classes.themeSecondaryIcon}>
                <AccountCircle />
              </Avatar>
              <ListItemText primary={accessTo.join(', ')} secondary="Síntesis" />
            </ListItem>

            <Divider inset />
            <ListItem>
              <Avatar className={classes.themeSecondaryIcon}>
                <Block />
              </Avatar>
              <ListItemText primary={itemSelected.status === 1 ? 'Activo' : itemSelected.status === 0 ? 'Inactivo' : 'Bloqueado'} secondary="Estatus" />
            </ListItem>

          </List>
        </div>
      </main>
    );
  }
}

ContactDetail.propTypes = {
  classes: PropTypes.object,
  showMobileDetail: PropTypes.bool,
  itemSelected: PropTypes.object,
  edit: PropTypes.func,
  favorite: PropTypes.func,
  permissionsEdit: PropTypes.bool.isRequired,
  permissionsDelete: PropTypes.bool.isRequired
};

export default withStyles(styles)(ContactDetail);
