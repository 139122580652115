import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input , InputLabel} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './content-jss';
import {Alphanumeric} from '../../validator/Regex';
import ColorPicker from '../../atoms/ColorPicker';
import { primary } from '../../utils/colors';

class AddContentForm extends React.Component {

  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state = {
    titulo:'',
    idTipoSeccion:'',
    idMenu:'',
    isUpdating:false,
    secId:0,
    columnist:false,
    color: "",
    hasicon: ""
  };

  handleChangeOther = event => {
    this.setState({ color: event.target.value });
  };

  handleChange = event => {
    if(!Alphanumeric(event.target.value)){
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChangeC = event => {
      this.setState({ [event.target.name]: event.target.value, columnist:false});
  };

  handleChangeChecked = name => event => {
    this.setState({[name]: event.target.checked})
  }

  handleChangeComplete = (color) => {
    if(color){
      this.setState(
        {
          color : color.hex
        }
      )
    }
  };

  onChange = (color, event) => {
    if(color){
      this.setState(
        {
          color : color.hex
        }
      )
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.dataInit != null){
      this.setState({
        titulo: this.state.titulo === '' ? newProps.dataInit.sec_name : this.state.titulo,
        idTipoSeccion: this.state.idTipoSeccion ==='' ? newProps.dataInit.sec_tipo : this.state.idTipoSeccion,
        color :this.state.color ===""? newProps.dataInit.sec_color : this.state.color,
        hasicon: this.state.hasicon==="" ? newProps.dataInit.sec_icono : this.state.hasicon,
        secId : newProps.dataInit.sec_id,
        isUpdating:true
      })
    }

    if(!newProps.isOpen){
      this._clearData(false);
      this.setState(
        {
          isUpdating : false,
          
        }
      )
    }
  }

  _clearData = (band) =>{
    if(band){
      this.setState({
        titulo: this.props.dataInit ? this.props.dataInit.sec_name : '',
        idTipoSeccion: this.props.dataInit ? this.props.dataInit.sec_tipo : 0,
        idMenu:'',
        color : this.props.dataInit? this.props.dataInit.sec_color:"#FFFFFF", 
        hasicon: this.props.dataInit ? this.props.dataInit.sec_icono : ''
      })
    }else{
      this.setState({
        titulo:'',
        idTipoSeccion:'',
        idMenu:'',
        color : "",
        hasicon: ""
      })
    }
  }
  
  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      onDrop,
      onClear,
      icon
    } = this.props;

    const {
      color
    } = this.state;

    let dropzoneRef;
    const acceptedFiles = ['image/jpeg', 'image/png', 'image/bmp'];

    return (
      <div>
        <form onSubmit={(event)=>{
          handleSubmit(event, this.state);
          onClear();
        }}>
          <section className={classes.bodyForm}>
           
            <div>
            <InputLabel htmlFor="titulo">Título</InputLabel>
            <Input 
                id="titulo"
                name="titulo"
                value={this.state.titulo}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            <div>

              <InputLabel htmlFor="idTipoSeccion">Tipo de sección</InputLabel>
              <Select
                value={this.state.idTipoSeccion}
                onChange={this.handleChangeC}
                className={classes.field}
                inputProps={{
                  name: 'idTipoSeccion',
                  id: 'idTipoSeccion',
                }}
              >
                <MenuItem value="Header">Header</MenuItem>
                <MenuItem value="Banner">Banner</MenuItem>
                <MenuItem value="Relevante">Relevante</MenuItem>
                <MenuItem value="Portada">Portadas</MenuItem>
                <MenuItem value="Notas">Notas</MenuItem>
                <MenuItem value="NotasColumnas">Columnas</MenuItem>
                <MenuItem value="NotasEditoriales">Editoriales</MenuItem>
                <MenuItem value="Card">Card</MenuItem>
                <MenuItem value="Radio">Radio</MenuItem>
                <MenuItem value="Evento">Evento</MenuItem>
                <MenuItem value="Footer">Footer</MenuItem>
              </Select>
            </div>

            <div>
              <InputLabel htmlFor="menu_description" className={classes.dblock}>Poner color de fondo</InputLabel>
              <div className={classes.colorsPicker}>
                <ColorPicker 
                  color={color}
                  colors={primary}
                  triangle={`top`}
                  width={`100%`}
                  onChange={this.onChange}
                  onChangeComplete={this.handleChangeComplete}
                />
              </div>
            </div>
            
            <div className={classes.wraperUploadFile}>
              <Dropzone
                className={classes.hiddenDropzone}
                accept={acceptedFiles.join(',')}
                acceptClassName="stripes"
                onDrop={onDrop}
                ref={(node) => { dropzoneRef = node; }}
              />
              <label htmlFor="raised-button-file" className={classes.spaceBetween}>
                  <Button variant="contained" color="primary" component="span" className={classes.button} onClick={() => {
                      dropzoneRef.open();
                    }}>
                    Subir Icono
                  </Button>
                  {(icon || this.state.hasicon!=="") ? <CheckCircle /> : null}
              </label>
              
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="raised" color="primary" type="submit" disabled={( ["Header","Banner","Relevante","Footer"].includes(this.state.idTipoSeccion)? false : (icon || this.state.hasicon!=="")? false:true)}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() =>{this._clearData(true)}}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddContentForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit:PropTypes.object,
  isOpen:PropTypes.bool
};
 

export default withStyles(styles)(AddContentForm);
