import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import brand from '../../../../utils/brand';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import styles from '../../../../components/Tables/tableParts/tableStyle-jss';
import ls from 'local-storage';
import { fetchCall } from '../../../../service/service_base';
import AddNewspaper from './AddNewspaper';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import { ThemeContext } from '../../../../utils/ThemeContext';
import Switch from '@material-ui/core/Switch';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#9F9F9F',
    padding: '4px 24px 4px 24px',
  },
  body: {
    padding: '4px 24px 4px 24px',
    fontSize: 14,
  },
}))(TableCell);


class Newspapers extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    anchorEl: null,
    dataNewspaper: [],
    openAddNewspaper: false,
    newspaperSelected: null,
    editNewspaper: false,
    openNotification: false,
    typeNotification: "info",
    messageNotification: "",
    canEdit: true,
    canShow: false,
    canPublish: true,
    canDelete: true,
    searchData: [],
    openConfirmDialog: false,
    idDelete: 0,
    isLoading: true,
    currentUser: ls.get("current_user")['user_id']
  }

  handleCloseNotification = () => {
    this.setState({ openNotification: false, messageNotification: '' });
  }

  componentDidMount() {
    this.context.toggleTheme(undefined);
    this._getUserPermissions();
  }

  _getUserPermissions = () => {
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
    if (permissionsCurrentUser[0] != null) {
      let add = permissionsCurrentUser.find(x => x.name === "Configuración/Catálogos/Periódicos");

      if (add !== undefined) {
        let currentPermissions = ls.get("permissions");
        if (currentPermissions != undefined || currentPermissions !== null) {

          let data = currentPermissions.find(x => x.menu_id == add.id);
          if (data != undefined) {
             let config = JSON.parse(data.grant_configuration);
            this.setState({
              canShow : config.chkViewNewspaper,
              canEdit : config.chkEditNewspaper,
            })
            this._getUsersByList()
          }

        }
      }
    }

  }

  _getUsersByList = () => {
    fetchCall("GET", "/newspaper/get").then(response => {
      this.setState({
        dataNewspaper: response.data,
        searchData: response.data,
        isLoading: false
      });
    }).catch(err => console.log(err));
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  _handleOpenAddNewspaper = () => {
    this.setState({
      openAddNewspaper: true,
      newspaperSelected: null,
      editKicker :false
    })
  }

  closeNewNewspaper = () => {
    this.setState({
      newspaperSelected: null,
      openAddNewspaper: false
    })
  }

  submitNewspaper = (event, data) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    data.active = 1;
    if (data.newspaper_name.toString().trim() === '') {
      this.setState({
        openNotification: true,
        messageNotification: 'Debes ingresar un nombre',
        typeNotification: 'warning',
        isLoading: false
      });
      return false;
    }
    if (!this.state.editNewspaper) {
      let exists = this.state.dataNewspaper.find(x => x.newspaper_name === data.newspaper_name.toString().trim().toLowerCase());
      if (exists != undefined) {
        this.setState({
          openNotification: true,
          messageNotification: 'Ya existe un periódico con este nombre',
          typeNotification: 'warning',
          isLoading: false
        });
        return false;
      }
      fetchCall("POST", "/newspaper/add", data).then(response => {
        if (response.status == 200) {
          this.setState({
            openAddNewspaper: false,
            editNewspaper: false,
            openNotification: true,
            messageNotification: "Se agregó el periódico",
            typeNotification: 'success',
            isLoading: false
          });
          this._getUsersByList();
        }
      }).catch(err => console.log(err));
    } else {
      let exists = this.state.dataNewspaper.find(x => x.newspaper_name === data.newspaper_name.toString().trim().toLowerCase() && x.newspaper_id !== this.state.newspaperSelected.newspaper_id);
      if (exists != undefined) {
        this.setState({
          openNotification: true,
          messageNotification: 'Ya existe un periódico con este nombre',
          typeNotification: 'warning',
          isLoading: false
        });
        return false;
      }
      data.newspaper_id = this.state.newspaperSelected.newspaper_id;
      data.newspaper_photo = this.state.newspaperSelected.newspaper_photo;
      fetchCall("POST", "/newspaper/update", data).then(response => {
        if (response.status == 200) {
          this.setState({
            openAddNewspaper: false,
            editNewspaper: false,
            openNotification: true,
            messageNotification: "Se modificó el periódico",
            typeNotification: 'success',
            isLoading: false,
            newspaperSelected: null
          });
          this._getUsersByList();
        }
      }).catch(err => console.log(err));
    }
  }

  _handleEditNewspaper = (newspaper) => {
    this.setState({
      newspaperSelected: newspaper,
      openAddNewspaper: true,
      editNewspaper: true
    })
  }

  _handleDeleteNewspaper = () => {
    this.setState({ isLoading: true });
    this.state.newspaperSelected.active = 2;
    fetchCall("POST", "/newspaper/update", this.state.newspaperSelected).then(response => {
      if (response.status == 200) {
        this.setState({
          openNotification: true,
          messageNotification: "Se eliminó " + this.state.newspaperSelected.newspaper_name,
          typeNotification: 'success',
          openConfirmDialog: false,
          idDelete: 0,
          isLoading: false
        })
        this._getUsersByList();
      }
    }).catch(err => console.log(err))
  }

  handleStatus = (item) => {
    this.setState({ isLoading: true });
    item.active = item.newspaper_status == 1 ? 0 : 1
    item.menu_id = 0
    fetchCall("POST", "/newspaper/update", item).then(response => {
      if (response.status == 200) {
        this.setState({
          openNotification: true,
          messageNotification: "Se cambió el estatus a " + item.newspaper_name,
          typeNotification: 'success',
          openConfirmDialog: false,
          idDelete: 0,
          isLoading: false
        })
        this._getUsersByList();
      } else {
        this.setState({
          openNotification: true,
          messageNotification: "Ocurrió un error al cambiar el estatus",
          typeNotification: "error",
          isLoading: false
        });
      }
    }).catch(err => console.log(err));
  }

  _search = (event) => {
    let searchWord = event.target.value.toString().toLowerCase();
    let result = this.state.dataNewspaper.slice(0);
    let arr = result.filter(x => {
      if (`${x.newspaper_name}`.toLowerCase().includes(searchWord)) {
        return x;
      }
    });
    this.setState({ searchData: arr });
  }

  _openConfirmDialog = (newspaper) => {
    this.setState({
      openConfirmDialog: true,
      newspaperSelected: newspaper
    });
  }

  _closeConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      newspaperSelected: null
    });
  }

  render() {
    const {
      classes
    } = this.props;
    const title = brand.name + ' - Contáctos';
    const description = brand.desc;
    return (
      <div>
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>

        {this.state.isLoading ? <LoaderFull /> : ''}

        <AddNewspaper
          openForm={this.state.openAddNewspaper}
          closeForm={this.closeNewNewspaper}
          classes={classes}
          submit={this.submitNewspaper}
          dataInit={this.state.newspaperSelected}
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>

        <ConfirmDialog
          title={"¿Está seguro que desea eliminar el periódico?"}
          message={"Al ser eliminado no se podrá recuperar"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._handleDeleteNewspaper}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />

        <PapperBlock title="Periódicos" fullTitle>
          <div>
            <Toolbar className={classes.toolbar}>
              <div className={classes.title}>
                {/* TODO: AGREGAR INPUT DE BUSQUEDA */}

                <div className={classes.flex}>
                  <div className={classes.searchWrapper}>
                    <div className={classes.search}>
                      <SearchIcon />
                    </div>
                    <input className={classes.input} onChange={(event) => this._search(event)} placeholder="Buscar" />
                  </div>
                </div>
              </div>

              {/* <div className={classes.spacer} /> */}
              <div className={classes.actions}>
                <Hidden xsDown>
                  <Button variant="raised" onClick={this._handleOpenAddNewspaper} color="secondary" className={classes.button}>
                    <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                    Nuevo
              </Button>
                </Hidden>
                <Hidden smUp>
                  <Tooltip title="Nuevo" placement="top">
                    <Button variant="fab" onClick={this._handleOpenAddNewspaper} mini color="secondary" aria-label="add">
                      <AddIcon className={classNames(classes.iconSmall)} />
                    </Button>
                  </Tooltip>
                </Hidden>

              </div>
            </Toolbar>
            <Paper className={classes.rootTable}>
              <div className={classes.maxHeightTable}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {/* <CustomTableCell align="right">
                      <Checkbox
                          checked={this.state.checkedA}
                          onChange={this.handleChange('checkedA')}
                          value="checkedA"
                        />
                      </CustomTableCell> */}
                      <CustomTableCell align="right">Nombre</CustomTableCell>
                      <CustomTableCell align="right">Tipo de síntesis</CustomTableCell>
                      <CustomTableCell align="right">Estatus</CustomTableCell>
                      <CustomTableCell align="right">Acciones</CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.searchData.map((row, i) => {
                      return (
                        <TableRow className={classes.row} key={row.newspaper_id}>
                          <CustomTableCell component="th" scope="row">
                            {row.newspaper_name}
                          </CustomTableCell>
                          <CustomTableCell align="right">{row.menu_name}</CustomTableCell>
                          <CustomTableCell align="right">
                            <Switch
                              disabled={!this.state.canEdit}
                              checked={row.newspaper_status === 1 ? true : false}
                              onChange={() => { this.handleStatus(row) }}
                              value="checkedB"
                              color="primary"
                            />
                          </CustomTableCell>
                          <CustomTableCell align="right">
                            <div key={i} className={classes.contentIcons}>
                              <Tooltip title="Editar">
                                <IconButton disabled={!this.state.canEdit} onClick={() => {
                                  this._handleEditNewspaper(row)
                                }} color="secondary" className={classes.button}>
                                  <Icon>edit</Icon>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <IconButton disabled={!this.state.canEdit} onClick={() => {
                                  this._openConfirmDialog(row)
                                }} color="secondary" className={classes.button}>
                                  <Icon>delete</Icon>
                                </IconButton>
                              </Tooltip>
                            </div>
                          </CustomTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              {
                this.state.searchData.length === 0 ?
                  <Typography className={classes.noMatch}>No se encontraron periódicos con ese criterio de búsqueda</Typography> : ''
              }
            </Paper>
          </div>
        </PapperBlock>
      </div>
    );
  }
}

Newspapers.propTypes = {
  classes: PropTypes.object.isRequired
};

Newspapers.contextType = ThemeContext;

export default withStyles(styles)(Newspapers);
