import React from 'react';
import {ChromeReaderMode, AccountBalance, Stars, LibraryBooks, Map, Settings} from '@material-ui/icons';

const SpecialIcons = ({ icon }) => {
  let svgContent = null;

  switch (icon) {
    case 'settings':
      svgContent = (
        <Settings/>
      );
      break;

    case 'chrome_reader_mode':
    case 'ChromeReaderMode':
      svgContent = (
        <ChromeReaderMode/>
      );
      break;

    case 'AccountBalance':
      svgContent = (
        <AccountBalance/>
      );
      break;
      
    case 'Stars':
      svgContent = (
        <Stars/>
      );
      break;
      
    case 'LibraryBooks':
      svgContent = (
        <LibraryBooks/>
      );
      break;
      
    case 'Map':
      svgContent = (
        <Map/>
      );
      break;

    case 'WbTwighlight':
      svgContent = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
            <path d="M22.6053 11.5467L25.432 8.72001L27.3186 10.6053L24.4786 13.432L22.6053 11.5467ZM23.8666 18.6667C23.2 14.8667 19.9333 12 16 12C12.0666 12 8.73329 14.8667 8.13329 18.6667H23.8666ZM2.66663 21.3333H29.3333V26.6667H2.66663V21.3333ZM14.6666 5.33334H17.3333V9.33334H14.6666V5.33334ZM4.71996 10.5667L6.60529 8.68001L9.43463 11.5093L7.54796 13.396L4.71996 10.5667Z" fill="currentColor"/>
        </svg>
      );
      break;

    case 'HomeRepairService':
      svgContent = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
            <path d="M24 21.3333H21.3333V20H10.6666V21.3333H7.99996V20H2.66663V26.6667H29.3333V20H24V21.3333Z" fill="currentColor"/>
            <path d="M26.6666 10.6667H22.6666V8.00001C22.6666 6.53334 21.4666 5.33334 20 5.33334H12C10.5333 5.33334 9.33329 6.53334 9.33329 8.00001V10.6667H5.33329C3.86663 10.6667 2.66663 11.8667 2.66663 13.3333V18.6667H7.99996V16H10.6666V18.6667H21.3333V16H24V18.6667H29.3333V13.3333C29.3333 11.8667 28.1333 10.6667 26.6666 10.6667ZM20 10.6667H12V8.00001H20V10.6667Z" fill="currentColor"/>
        </svg>
      );
      break;

    case 'SportsSoccer':
      svgContent = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
            <path d="M16 2.66666C8.63996 2.66666 2.66663 8.63999 2.66663 16C2.66663 23.36 8.63996 29.3333 16 29.3333C23.36 29.3333 29.3333 23.36 29.3333 16C29.3333 8.63999 23.36 2.66666 16 2.66666ZM17.3333 7.06666L19.1333 5.79999C21.56 6.54666 23.6266 8.14666 24.9733 10.2533L24.4533 12.04L22.6533 12.6533L17.3333 8.93332V7.06666ZM12.8666 5.79999L14.6666 7.06666V8.93332L9.34663 12.6533L7.54663 12.04L7.02663 10.2533C8.37329 8.15999 10.44 6.55999 12.8666 5.79999ZM9.43996 22.8133L7.91996 22.9467C6.30663 21.08 5.33329 18.6533 5.33329 16C5.33329 15.84 5.34663 15.6933 5.35996 15.5333L6.69329 14.56L8.53329 15.2L10.48 20.9867L9.43996 22.8133ZM19.3333 26.12C18.28 26.4667 17.16 26.6667 16 26.6667C14.84 26.6667 13.72 26.4667 12.6666 26.12L11.7466 24.1333L12.6 22.6667H19.4133L20.2666 24.1467L19.3333 26.12ZM19.0266 20H12.9733L11.1733 14.64L16 11.2533L20.84 14.64L19.0266 20ZM24.08 22.9467L22.56 22.8133L21.5066 20.9867L23.4533 15.2L25.3066 14.5733L26.64 15.5467C26.6533 15.6933 26.6666 15.84 26.6666 16C26.6666 18.6533 25.6933 21.08 24.08 22.9467Z" fill="currentColor"/>
        </svg>
      );
      break;

    default:
      svgContent = null;
      break;
  }

  return (<>
      {svgContent}
      </>
  );
}

export default SpecialIcons;