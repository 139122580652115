import React from 'react';
import PropTypes from 'prop-types';
import FloatingPanel from '../../../components/Panel/FloatingPanel';
import AddContentSectionForm from './AddContentSectionForm';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';
class AddContentSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      attachment:null,
      openNotification : false,
      messageNotification : 'Selecciona un archivo válido',
      typeNotification : "warning"
    };
    this.onClean = this.onClean.bind(this);
  }

  onClean(band){
    if(band){
      this.setState({
        attachment:null
      });
    }
  }

  componentWillReceiveProps(){
    if(!this.props.openForm){
      this.onClean(true);
    }

  }

  addAttachment = (files)=>{
    if(files[0]){
      this.setState({
        attachment : files[0]
      });
    }else{
      this.setState(
        {
          attachment : null,
          openNotification : true
        }
      )
    }
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification : false
      }
    )
  }

  sendValues = (values) => {
    setTimeout(() => {
      this.props.submit(values, this.state.img);
      this.setState({ img: '' });
    }, 500);
  }

  render() {
    const {
      openForm,
      closeForm,
      dataInit,
      submit,
      contentType,
      action,
      isLoading
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action} tarjeta`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddContentSectionForm
            handleSubmit={(event, data)=>submit(event, data, this.state.attachment)}
            dataInit={dataInit}
            contentType={contentType}
            onClean={this.onClean}
            isOpen={openForm}
            addAttachment={this.addAttachment}
            attachment={this.state.attachment}
            isLoading={isLoading}
          />
        </FloatingPanel>
        <Snackbar
         anchorOrigin={{
             vertical: 'top',
             horizontal: 'right',
         }}
         open={this.state.openNotification}
         autoHideDuration={6000}
         onClose={this.handleCloseNotification}>
       <CustomNotification
         onClose={this.handleCloseNotification}
         variant={this.state.typeNotification}
         message={this.state.messageNotification}/>
       </Snackbar>
      </div>
    );
  }
}

AddContentSection.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  addContact: PropTypes.func,
  openForm: PropTypes.bool,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object,
  contentType:PropTypes.string,
  action: PropTypes.string
};

export default (AddContentSection);
