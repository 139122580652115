import React from 'react';
import PropTypes from 'prop-types';
import AddKickerForm from './AddKickerForm';
import FloatingPanel from '../../../../components/Panel/FloatingPanel';

class AddKicker extends React.Component {

  cleanValues = () => { 
    setTimeout(() => {
      this.props.closeForm();
      this.setState({ img: '',urlPreview:'',files: [] });
    }, 500);
  }

  render() {
    const { 
      openForm,
      dataInit,
      submit,
      closeForm
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title="Kicker" openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddKickerForm
            onSubmit={()=>{}}
            handleSubmit={(event, data)=>(submit(event, data),closeForm)}
            dataInit={dataInit}
            isOpen={openForm}
            clean={closeForm}
          />
        </FloatingPanel>
      </div>
    );
  }
}

AddKicker.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  addKicker: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object
};

export default AddKicker;
