import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input , InputLabel} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import styles from './event-jss';
import { ValidationContent } from '../../../validator/Regex';
import { charDescriptionLength, charTitleLength } from '../../../utils/validation';

class AddNewEventForm extends React.Component {
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };
  
  state = {
    title:'',
    description:'',
    notes :0,
    contId:0,
    isUpdating :false,
    order:0
  };

  handleChange = (event, min) => {
    if(!ValidationContent(event.target.value) && event.target.value.toString().trim('').length <= min){
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  componentWillReceiveProps(newProps){
    if(newProps.dataInit != null){
      this.setState({
        title:this.state.title === '' ? newProps.dataInit.cont_name : this.state.title,
        description:this.state.description ==='' ? newProps.dataInit.cont_description :this.state.description,
        contId:newProps.dataInit.cont_id,
        isUpdating:true,
        order:newProps.dataInit.cont_order
      })
    }

    if(!newProps.isOpen){
      this._clearData(true);
      this.setState({
        isUpdating:false
      })
    }
  }
 
  _clearData = (band) =>{
    if(band){
      this.setState({
        title:'',
        description:''
      });
    }else{
      this.setState(
        {
          title : this.props.dataInit ? this.props.dataInit.cont_name : '',
          description : this.props.dataInit ? this.props.dataInit.cont_description : ''
        }
      )
    }
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
    } = this.props;
     
    return (
      <div>
        <form onSubmit={(event)=>handleSubmit(event, this.state)}>
          <section className={classes.bodyForm}>
            <div>            
             
            <div>
                    <InputLabel htmlFor="title">Título *</InputLabel>
                    <Input 
                        id="title"
                        name="title"
                        value={this.state.title}
                        type="text"
                        autoComplete="off"
                        required
                        className={classes.field}
                        startAdornment={
                        <InputAdornment position="start">
                            <PermContactCalendar />
                        </InputAdornment>
                        }
                        onChange={(event) => this.handleChange(event, charTitleLength)}
                    />
                </div>
                {this.state.title.length === charTitleLength && (<div>Has llegado al límite de caracteres ({charTitleLength})</div>)}
                <div>
                    <InputLabel htmlFor="description">Descripción</InputLabel>
                    <Input 
                        id="description"
                        name="description"
                        value={this.state.description}
                        type="text"
                        autoComplete="off"
                        required
                        className={classes.field}
                        startAdornment={
                        <InputAdornment position="start">
                            <PermContactCalendar />
                        </InputAdornment>
                        }
                        onChange={(event) => this.handleChange(event, charDescriptionLength)}
                    />
                </div> 
                {this.state.description.length > charDescriptionLength && (<div>Has llegado al límite de caracteres ({charDescriptionLength})</div>)}
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="raised" color="primary" type="submit" disabled={this.state.isUpdating ? false : (this.state.title === "") ? true : false}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={()=>{this._clearData(false)}}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddNewEventForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  isOpen:PropTypes.bool
};

export default withStyles(styles)(AddNewEventForm);
