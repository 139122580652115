import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Typography from "@material-ui/core/Typography";
import { Input, InputLabel } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import styles from "./card-jss";
import { ValidationContent } from "../../../validator/Regex";
import CheckCircle from '@material-ui/icons/CheckCircle';
import { charTitleLength } from "../../../utils/validation";

class AddCardForm extends React.Component {
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state = {
    title: "",
    description: "",
    notas: 1,
    newspaper: 0,
    contId: 0,
    isUpdating: false
  };

  handleChange = (event) => {
    if (!ValidationContent(event.target.value) &&event.target.value.toString().trim("").length <= charTitleLength) {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  handleChangeSelect = (event) => { 
      this.setState({ [event.target.name]: event.target.value ,title: this.props.dataNewspaper.find(e=>e.newspaper_id == event.target.value).newspaper_name });
  };
  componentWillReceiveProps(newProps) {
    if (newProps.dataInit != null) {
      this.setState({
        title:
          this.state.title === ""
            ? newProps.dataInit.cont_name
            : this.state.title,
        description:
          this.state.description === ""
            ? newProps.dataInit.cont_description
            : this.state.description,
        newspaper:
          this.state.newspaper !== 0
            ? this.state.newspaper
            : newProps.dataInit.columnist_id,
        contId: newProps.dataInit.cont_id,
        isUpdating: true
      });
    }

    if (!newProps.isOpen) {
      this._cleanData(true);
      this.setState({
        isUpdating: false,
        contId: 0
      });
    }
  }

  _cleanData = band => {
    if (band) {
      this.setState({
        title: "",
        description: "",
        notas: 1,
        newspaper: 0
      });
    } else {
      this.setState({
        title: this.props.dataInit ? this.props.dataInit.cont_name : "",
        description: this.props.dataInit
          ? this.props.dataInit.cont_description
          : "",
        newspaper: this.props.dataInit ?this.props.dataInit.cont_columnist_id:0
      });
    }
  };

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      onDrop,
      preview,
      errorMessage,
      addAttachment,
      attachment,
      isLoading,
      dataNewspaper
    } = this.props;
    let dropzoneRef;
    let dropzoneAttachment;
    const acceptedFiles = ["image/jpeg", "image/png", "image/bmp"];
    const acceptedAttachment = [
      "video/mp4",
      "application/x-mpegURL",
      "video/MP2T",
      "video/3gpp",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-ms-wmv",
      "image/ief",
      "image/jpeg",
      "image/bmp",
      "image/gif",
      "image/png",
      "image/svg+xml",
      "audio/basic",
      "auido/L24",
      "audio/mpeg",
      "audio/mp4",
      "audio/mp3",
      "audio/vnd.wav",
      "application/pdf"
    ];

    const fileSizeLimit = 93000000;

    return (
      <div>
        <form
          onSubmit={event => {
            var tempState = this.state;
            var titulo = tempState.title.split("'");
            if (titulo) {
              var tempTitulo = "";
              titulo.forEach(item =>
                item === ""
                  ? (tempTitulo = tempTitulo + item + "''")
                  : (tempTitulo = tempTitulo + item)
              );
              tempState.title = tempTitulo;
            }

            handleSubmit(event, tempState);
            this.props.onClean(true);
            this._cleanData(true);
          }}
        >
          <section className={classes.bodyForm}>
            <div>
              <div>
                <Typography variant="button" className={classes.textCenter}>
                  SUBIR IMAGEN PRINCIPAL
                </Typography>
                <Dropzone
                  className={classes.hiddenDropzone}
                  accept={acceptedFiles.join(",")}
                  acceptClassName="stripes"
                  onDrop={onDrop}
                  maxSize={fileSizeLimit}
                  ref={node => {
                    dropzoneRef = node;
                  }}
                />
                <div className={classes.avatarWrap}>
                  <Avatar
                    alt={preview}
                    className={classes.uploadAvatar}
                    src={preview}
                  />
                  <Tooltip id="tooltip-upload" title="Subir foto">
                    <IconButton
                      className={classes.buttonUpload}
                      component="button"
                      onClick={() => {
                        dropzoneRef.open();
                      }}
                    >
                      <PhotoCamera />
                    </IconButton>
                  </Tooltip>
                </div>
                <Typography variant="button" className={classes.textCenter}>
                  {errorMessage}
                </Typography>
              </div>
              <div>
                <InputLabel htmlFor="title">Título *</InputLabel>
                <Input
                  id="title"
                  name="title"
                  value={this.state.title}
                  type="text"
                  autoComplete="off"
                  required
                  className={classes.field}
                  startAdornment={
                    <InputAdornment position="start">
                      <PermContactCalendar />
                    </InputAdornment>
                  }
                  onChange={event => this.handleChange(event)}
                />
              </div>
              {this.state.title.length === charTitleLength && (<div>Has llegado al límite de caracteres ({charTitleLength})</div>)}
              <div >
                <InputLabel htmlFor="notes">Periódico *</InputLabel>
                {dataNewspaper ?
                  <Select
                    value={this.state.newspaper}
                    onChange={event => this.handleChangeSelect(event)}
                    className={classes.field}
                    name="newspaper"
                    required={true}
                  >
                    {dataNewspaper.map((item, index) => {
                      return (
                        <MenuItem
                          key={index.toString()}
                          value={item.newspaper_id}
                        >
                          {item.newspaper_name}
                        </MenuItem>
                      );
                    })}
                  </Select> : 'No hay periódicos disponibles'}
              </div>
              <div>
                <Dropzone
                  className={classes.hiddenDropzone}
                  accept={acceptedAttachment.join(",")}
                  acceptClassName="stripes"
                  onDrop={addAttachment}
                  maxSize={fileSizeLimit}
                  ref={node => {
                    dropzoneAttachment = node;
                  }}
                />
                <div className={classes.wraperUploadFile}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={() => {
                      dropzoneAttachment.open();
                    }}
                  >
                    Subir Archivos
                  </Button>
                  {attachment || (this.props.dataInit && this.props.dataInit.cont_attachment) ? <CheckCircle/> : null}
                  {attachment ? <small title={attachment.name}>{attachment.name}</small> : this.props.dataInit && <small title={this.props.dataInit.cont_attachment}>{this.props.dataInit.cont_attachment}</small>}

                </div>
              </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button
              variant="raised"
              color="primary"
              type="submit"
              disabled={
                this.state.isUpdating
                  ? false
                  : isLoading ||
                  (preview ? false : true) ||
                  (this.state.newspaper!==0?false:true)
              }
            >
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this.props.onClean(false);
                setTimeout(() => {
                  this._cleanData(false);
                }, 500);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddCardForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  preview: PropTypes.string,
  dataInit: PropTypes.object,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(AddCardForm);
