import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Toolbar, Tooltip, AppBar, IconButton, Hidden } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import AddIcon from '@material-ui/icons/Add';
import FileDownload from '@material-ui/icons/FileDownload';
import styles from './contact-jss';

class ContactHeader extends React.Component {
  render() {
    const {
      classes,
      total,
      hideDetail,
      showMobileDetail,
      addContact,
      download
    } = this.props;
    return (
      <AppBar
        position="absolute"
        className={classes.appBar}
      >
        <Toolbar>
          {showMobileDetail && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => hideDetail()}
              className={classes.navIconHide}
            >
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="subheading" className={classes.title} color="inherit" noWrap>
            <PermContactCalendar /> Usuarios ({total})
          </Typography>
          <Tooltip title="Descargar EXCEL/PDF" placement="top">
            <Button variant="fab" disabled={false} onClick={download} mini color="secondary" aria-label="add">
              <FileDownload />
            </Button>
          </Tooltip>
          <Hidden smUp>
            <Tooltip title="Usuario Externo" placement="top">
              <Button variant="fab" disabled={false} onClick={addContact} mini color="secondary" className={classes.btn} aria-label="add">
                <AddIcon />
              </Button>
            </Tooltip>
          </Hidden>
          <Hidden xsDown>
            <Button variant="raised" disabled={false} onClick={addContact} mini color="secondary" className={classes.btn} aria-label="add">
              <AddIcon /> USUARIO EXTERNO
            </Button>
          </Hidden>
        </Toolbar>

      </AppBar>
    );
  }
}

ContactHeader.propTypes = {
  classes: PropTypes.object,
  showMobileDetail: PropTypes.bool,
  addContact: PropTypes.func,
  hideDetail: PropTypes.func,
  total: PropTypes.number
};

export default withStyles(styles)(ContactHeader);
