import React from 'react';
import PropTypes from 'prop-types';
import FloatingPanel from '../../Panel/FloatingPanel';
import AddNewRadioForm from './AddNewRadioForm';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';
import { getPhotoCurrentUser } from '../../../service/s3Amplify';
class AddNewRadio extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: [],
      selectedPicture: null,
      errorMessage:'',
      attachment:null,
      urlPreview:'',
      openNotification : false,
      messageNotification : 'Selecciona un archivo válido',
      typeNotification : "warning",
      dataRadiostation:[]
    }; 
    this.onDrop = this.onDrop.bind(this);
    this.onClean = this.onClean.bind(this);
  }

  onDrop(files,url) {
    if(files.length===0){
      if(url){
          getPhotoCurrentUser(url).then(response =>{
            this.setState({
              urlPreview:response
            });
          }).catch(err =>{
            console.log("error url", err);
          });
      }else{
        this.setState({errorMessage:"archivo inválido"});
      }
    }else{
      let oldFiles = this.state.files;
      const filesLimit = 2;
      oldFiles = oldFiles.concat(files);
      if (oldFiles.length > filesLimit) {
        this.setState({
          errorMessage : 'No puedes subir más de un archivo'
        });
      } else {
        this.setState({ img: files[0].preview || '/pic' + files[0].path, selectedPicture: files[0] ,errorMessage:''});        
      }
    }    
  }

  addAttachment =(files)=>{
    if(files[0]){
      this.setState({
        attachment: files[0]
      });
    }else{
      this.setState({
        attachment: null,
        openNotification : true
      });
    }
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification : false
      }
    )
  }

  deleteSelectedPic=()=>{
    this.setState({ selectedPicture:null,urlPreview:''});
  }
  
  onClean(){
    this.setState(
      {
        selectedPicture:null,
        urlPreview:'',
        attachment:null
      }
    );
  }

  componentWillReceiveProps(newProps){
    if(newProps.dataInit !== null){
      getPhotoCurrentUser(newProps.dataInit.cont_imagePath).then(response =>{
        this.setState({
          urlPreview:response
        });
      }).catch(err =>{
        console.log("error url", err);
      });
    }else{
      this.setState({
        urlPreview:''
      });
    }
  }

  render() {
    const {
      openForm,
      closeForm,
      dataInit,
      submit,
      isLoading,
      action,
      secName,
      dataRadiostation
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action} Nota de Radio`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddNewRadioForm  
            handleSubmit={(event, data)=>submit(event, data, this.state.attachment)}
            onDrop={this.onDrop}
            preview={this.state.selectedPicture ? this.state.selectedPicture.preview : this.state.urlPreview}
            dataInit={dataInit}
            isOpen={openForm}
            onClean={this.onClean}
            errorMessage={this.state.errorMessage}
            addAttachment={this.addAttachment}
            attachment={this.state.attachment}
            isLoading={isLoading}
            deleteSelectedPic={this.deleteSelectedPic}
            secName={secName}
            dataRadiostation={dataRadiostation}
          />
        </FloatingPanel>
        <Snackbar
         anchorOrigin={{
             vertical: 'top',
             horizontal: 'right',
         }}
         open={this.state.openNotification}
         autoHideDuration={6000}
         onClose={this.handleCloseNotification}>
       <CustomNotification
         onClose={this.handleCloseNotification}
         variant={this.state.typeNotification}
         message={this.state.messageNotification}/>
       </Snackbar>
      </div>
    );
  }
}

AddNewRadio.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func, 
  openForm: PropTypes.bool, 
  closeForm: PropTypes.func,
  dataInit: PropTypes.object
};

export default (AddNewRadio);
