const blockStyle = {
    default: {
        card: {
            boxShadow: "0 2px 8px -1px rgb(0 0 0 / 14%), 0 0px 6px -1px rgb(0 0 0 / 10%)",
            marginTop: 20,
            marginBottom: 15,
        },
    },

    border: {
        '& span': {
            '& div': {
              border: "1px solid #e0e0e0"
            }
        }
    },
    
};

export default (blockStyle)