import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddContactForm from './AddContactForm';
import FloatingPanel from './../Panel/FloatingPanel';
import styles from './contact-jss';
import { getPhotoCurrentUser } from '../../service/s3Amplify';

class AddContact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      selectedPicture: null,
      urlPreview: ''
    };
    this.onDrop = this.onDrop.bind(this);
    this.onClean = this.onClean.bind(this);
  }

  onDrop(files) {
    if (files.length == 0) {
      alert('Archivo no válido. Sólo se aceptan archivos de tipo imagen y 3MB de tamaño')
    }
    if (files.length > 1) {
      alert('No puede subir más de una imagen');
    } else {
      this.setState({ selectedPicture: files[0] });
    }
  }

  sendValues = (event, values) => {
    event.preventDefault();

  }

  componentWillReceiveProps(newProps) {
    if (newProps.type=="edit"&&newProps.dataInit && newProps.dataInit.photo) {
      getPhotoCurrentUser(newProps.dataInit.photo).then(response => {
        this.setState({
          urlPreview: response
        });
      }).catch(err => {
        console.log("error url", err);
      });
    }else{
      this.onClean(true)
    }
  }

  onClean(band) {
    if (band) {
      this.setState({
        selectedPicture: null,
        urlPreview: ''
      });
    } else {
      this.setState({
        selectedPicture: null
      });
    }
  }

  render() {
    const {
      openForm,
      closeForm,
      dataInit,
      profiles,
      submit,
      dataContact,
      availableSynthesis
    } = this.props;
    return (
      <FloatingPanel title={this.props.type == "new" ? "Nuevo usuario" : "Editar usuario"} openForm={openForm} closeForm={closeForm}>
        <AddContactForm
          type={this.props.type}
          onSubmit={this.sendValues}
          onDrop={this.onDrop}
          handleSubmit={(event, data) => {
            submit(event, data, this.state.selectedPicture);
            this.setState({ selectedPicture: null })
          }}
          dataInit={dataInit}
          profiles={profiles}
          dataContact={dataContact}
          preview={this.state.selectedPicture ? this.state.selectedPicture.preview : this.state.urlPreview}
          isOpen={openForm}
          onClean={this.onClean}
          availableSynthesis={availableSynthesis}
          original={this.state.urlPreview}
        />
      </FloatingPanel>
    );
  }
}

AddContact.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  openForm: PropTypes.bool.isRequired,
  avatarInit: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  dataInit: PropTypes.object,
  profiles: PropTypes.array,
  dataContact: PropTypes.array,
  availableSynthesis: PropTypes.array
};

export default withStyles(styles)(AddContact);
