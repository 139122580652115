import App from '../components/App/App';
import Dashboard from '../components/App/Dashboard';
import Outer from '../components/App/Outer';

// Pages
import Login from './Pages/Users/Login';
import BlankPage from './Pages/BlankPage';
import LoginDedicated from './Pages/Standalone/LoginDedicated';
// Reportes
import SintesisPublicadas from './Pages/Reports/SintesisPublicadas';
import BitacoraMovimientos from './Pages/Reports/BitacoraMovimientos';
// Other
import NotFound from './NotFound/NotFound';
import NotFoundDedicated from './Pages/Standalone/NotFoundDedicated';
// Menú
import AdminUsuarios from './Pages/Administration/User';
import Contacts from './Pages/Administration/Contacts';
import Profile from './Pages/Administration/Profile';
import SendEmail from './Pages/Administration/SendEmail';
import Columnists from './Pages/Administration/Columnists';
import Editorials from './Pages/Administration/Editorials';
import Kickers from './Pages/Administration/Kickers';
import Newspapers from './Pages/Administration/Newspapers';
import Radiostations from './Pages/Administration/Radiostations';

//Synthesis
import Home from './Pages/Home';
import ViewMultimedia from '../components/App/ViewMultimedia';
import Multimedia from './Pages/Multimedia';



const routes = [{
  component: App,
  routes: [{
    component: App,
    routes: [
      {
        component: LoginDedicated,
        path: '/',
        exact: true,
      },
      {
        component: Outer,
        path: '/login',
        routes: [
          {
            path: '/login',
            component: Login,
            exact: true,
          },
          {
            path: '*',
            component: NotFound
          }
        ]
      },
      {
        component: Dashboard,
        path: '/synthesis/:param',
        routes: [
          {
            path: '/synthesis/:param',
            component:Home,
            exact: true
          }
        ]
      },
      {
        component: Dashboard,
        path: '/app',
        routes: [
          {
            path: '/app',
            component: BlankPage,
            exact: true,
          }
        ]
      },
      {
        component: Dashboard,
        path: '/admin',
        routes: [
          {
            path: '/admin/administracion-de-usuarios',
            component: AdminUsuarios,
            exact: true
          },
          {
            path: '/admin/administracion-de-perfiles',
            component: Profile,
            exact: true
          },
          {
            path: '/admin/envio-de-correos',
            component: SendEmail,
            exact: true
          },
          {
            path: '/admin/lista-contactos',
            component: Contacts,
            exact: true
          },
          {
            path: '/admin/columnas',
            component: Columnists,
            exact: true
          },
          {
            path: '/admin/editoriales',
            component: Editorials,
            exact: true
          },
          {
            path: '/admin/kickers',
            component: Kickers,
            exact: true
          },
          {
            path: '/admin/newspapers',
            component: Newspapers,
            exact: true
          },
          {
            path: '/admin/radiostations',
            component: Radiostations,
            exact: true
          }
        ]
      },
      {
        component: Dashboard,
        path: '/report',
        routes: [
          {
            path: '/report/bitacora-movimientos',
            component: BitacoraMovimientos,
            exact: true
          },
          {
            path: '/report/sintesis-publicadas',
            component: SintesisPublicadas,
            exact: true
          }
        ]
      },
      {
        component: ViewMultimedia,
        path: '/multimedia/:param',
        routes: [
          {
            path: '/multimedia/:param',
            component: Multimedia,
            //exact: true,
          }
        ]
      },
      {
        component: ViewMultimedia,
        path: '/multimedia/',
        routes: [
          {
            path: '/multimedia/',
            component: Multimedia,
            //exact: true,
          }
        ]
      },
      {
        path: '*',
        component: NotFoundDedicated
      }
    ]
  }
  ]
}];

export default routes;