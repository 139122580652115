import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import brand from '../../../../utils/brand';
import  PapperBlock  from '../../../../components/PapperBlock/PapperBlock'; 
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Select, MenuItem, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';  
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import styles from '../../../../components/Tables/tableParts/tableStyle-jss'; 
import ls from 'local-storage';
import {fetchCall} from '../../../../service/service_base'; 
import AddContact from './AddContact';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification'; 
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import { ThemeContext } from '../../../../utils/ThemeContext';
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#9F9F9F',
    padding: '4px 24px 4px 24px',
  },
  body: {
    padding: '4px 24px 4px 24px',
    fontSize: 14,
  },
}))(TableCell);

const validate = (email) => {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(email).toLowerCase())
}

class Contacts extends React.Component {

  constructor(props) {
    super(props);   
  }

  state ={
    anchorEl: null,
    dataContact: [],
    synthesisSelected: 0,
    listOfContacts: [],
    openAddContact: false,
    contactSelected:null,
    editContact : false,
    openNotification:false,
    typeNotification:"info",
    messageNotification:"",
    canEdit:true,
    canShow:false,
    canPublish:true,
    canDelete : true,
    searchData:[],
    openConfirmDialog:false,
    idDelete:0,
    isLoading:true,
    currentUser:ls.get("current_user")['user_id']
  }

  handleCloseNotification = () =>{
    this.setState({openNotification:false,messageNotification:''});
  }

  componentDidMount(){  
    this.context.toggleTheme(undefined);  
    this._getUserPermissions();
  }

  _getUserPermissions = () =>{    
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("["+currentUser.menuSynthesis+"]");    
    if(permissionsCurrentUser[0] != null){
      let add = permissionsCurrentUser.filter(x => x.name ==="Configuración/Contactos")[0];
    
    if(add != undefined || add != null){
      let currentPermissions = ls.get("permissions");
      if(currentPermissions != undefined || currentPermissions != null){
        
        let data = currentPermissions.filter(x => x.menu_id == add.id)[0];        
        if(data != undefined){
          let config = JSON.parse(data.grant_configuration);

          this.setState({
            canShow : config.chkViewContact,
            canEdit : !config.chkEditContact,
            canPublish: !config.chkPublishContact,
            canDelete : !config.chkDeleteContact
          });

          if(config.chkViewContact){
            fetchCall("GET", "/contact/getlistofconcat").then(response =>{
              this.setState({
                listOfContacts: response.data,              
                synthesisSelected: response.data[0].clist_id
              });
              this._getUsersByList(response.data[0].clist_id);
              
            }).catch(err => console.log(err));
          }else{
            this.setState({
              isLoading:false
            });
          }
        }

      }
    }
    }

  }

  _getUsersByList = (list) => {
    fetchCall("POST","/contact/getList", {
      clistId : list
    }).then(response =>{      
       this.setState({
         dataContact: response.data,
         searchData:response.data,
         isLoading:false
       });      
    }).catch(err => console.log(err));
  }

  handleChangeSelect = (event) =>{
    this.setState({[event.target.name]: event.target.value, synthesisSelected:event.target.value});
    this._getUsersByList(event.target.value);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  _handleOpenAddContact = () =>{
    this.setState({
      openAddContact : true,
      contactSelected : null
    })
  }

  closeNewContact = () =>{
    this.setState({
      contactSelected : null,
      openAddContact : false
    })
  }

  submitContact =(event, data) =>{
    event.preventDefault();
    this.setState({isLoading:true});
    data.clistId = this.state.synthesisSelected;

    if(data.distName.toString().trim() ===''){
      this.setState({
        openNotification: true,
        messageNotification: 'Debes ingresar un nombre',
        typeNotification:'warning',
        isLoading:false
      });      
      return false;
    }

    if(data.distLastname.toString().trim() ===''){
      this.setState({
        openNotification: true,
        messageNotification: 'Debes ingresar un apellido',
        typeNotification:'warning',
        isLoading:false
      });      
      return false;
    }

    if(data.distEmail.toString().trim() ===''){
      this.setState({
        openNotification: true,
        messageNotification: 'Debes ingresar un correo',
        typeNotification:'warning',
        isLoading:false
      });      
      return false;
    }
      
    if(!validate(data.distEmail)){
      this.setState({
        openNotification: true,
        messageNotification: 'Formato de correo inválido',
        typeNotification:'error',
        isLoading:false
      });      
      return false;
    }

    if(!this.state.editContact){
      let exists = this.state.dataContact.filter(x => x.correo === data.distEmail.toString().trim().toLowerCase() )[0];
      if(exists != undefined){
        this.setState({
          openNotification: true,
          messageNotification: 'Ya existe un contacto con este correo',
          typeNotification:'warning',
          isLoading:false
        });      
        return false;
      }
      fetchCall("POST", "/contact/add", data).then(response =>{
        if(response.status == 200){
          this.setState({
            openAddContact : false,
            editContact: false,
            openNotification:true,
            messageNotification:"Se agregó el contacto",
            typeNotification:'success',
            isLoading:false
          });
          this._getUsersByList(this.state.synthesisSelected);
          fetchCall("POST","/report/addlogmovements", {
            username:ls.get("username"),
            action:"Nuevo Contacto",
            description: `El usuario ${`${ls.get("username")}`.split("@")[0]} dio de alta al contacto ${`${data.distEmail}`.split("@")[0]}`,
            userId:this.state.currentUser
          })
        }
      }).catch(err => console.log(err));
    }else{

      let exists = this.state.dataContact.filter(x => x.correo === data.distEmail.toString().trim().toLowerCase() && x.distId !== this.state.contactSelected.distId )[0];
      if(exists != undefined){
        this.setState({
          openNotification: true,
          messageNotification: 'Ya existe un contacto con este correo',
          typeNotification:'warning',
          isLoading:false
        });      
        return false;
      }

      data.distId = this.state.contactSelected.distId;
      fetchCall("POST", "/contact/update", data).then(response =>{
        if(response.status == 200){
          this.setState({
            openAddContact : false,
            editContact:false,
            openNotification:true,
            messageNotification:"Se modificó el contacto",
            typeNotification:'success',
            isLoading:false
          });
          this._getUsersByList(this.state.synthesisSelected); 
        }
      }).catch(err => console.log(err));
    }
  }

  _handleEditContact = (contact)=>{
    this.setState({
      contactSelected : contact,
      openAddContact : true,
      editContact: true
    })
  }

  _handleDeleteContact= ()=>{ 
    this.setState({isLoading:true});
      fetchCall("POST","/contact/remove",{distId:this.state.idDelete}).then(response =>{
          if(response.status == 200){ 
            let email =this.state.dataContact.find(x => x.distId === this.state.idDelete).correo
            fetchCall("POST","/report/addlogmovements", {
              username:ls.get("username"),
              action:"Eliminación de Contacto",
              description: `El usuario ${`${ls.get("username")}`.split("@")[0]} eliminó el contacto ${`${email}`.split("@")[0]}`,
              userId:this.state.currentUser
            })
            this.setState({
              openNotification:true,
              messageNotification:"Se eliminó el contacto",
              typeNotification:'success',
              openConfirmDialog:false,
              idDelete:0,
              isLoading:false
            })
            this._getUsersByList(this.state.synthesisSelected);
            
          }
      }).catch(err => console.log(err))
  }

  _search = (event)=>{
    let searchWord = event.target.value.toString().toLowerCase();    
    let result = this.state.dataContact.slice(0);     
    var arr = new Array();
    result = result.filter(x => { 
      if(`${x.nombre}${x.apellido}`.toString().toLowerCase().includes(searchWord) || x.correo.toString().toLowerCase().includes(searchWord)){
        arr.push(x);
      }
      // if(x.nombre.toString().toLowerCase().includes(searchWord) ||
      //    x.apellido.toString().toLowerCase().includes(searchWord) || 
      //    x.correo.toString().toLowerCase().includes(searchWord)){
      //   arr.push(x); 
      // }

    });
    this.setState({searchData:arr});
  }

  _openConfirmDialog = (contact) =>{
    this.setState({
      openConfirmDialog : true,
      idDelete : contact.distId
    });
  }

  _closeConfirmDialog = () =>{
    this.setState({
      openConfirmDialog : false,
      idDelete : 0
    });
  }
   
  render() {
    const {
      classes,
      fetchData,
      dataTable,
      //closeNotif,
      //messageNotif,
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const title = brand.name + ' - Contáctos';
    const description = brand.desc;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        
        {this.state.isLoading ? <LoaderFull /> : ''}
        
         <AddContact 
          openForm={this.state.openAddContact}
          closeForm={this.closeNewContact} 
          classes={classes}
          submit={(event, data)=> this.submitContact(event, data)}
          dataInit={this.state.contactSelected}
        />

         <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>

        <ConfirmDialog 
          title={"¿Está seguro que desea eliminar el contacto?"}
          message={"El contacto al ser eliminado no se podrá recuperar"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._handleDeleteContact}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />
        
        <PapperBlock title="Contactos" fullTitle>
          <div>
            {/* <Button > 
              click
            </Button>*/}
            <form className={classes.root} autoComplete="off">
              <FormControl className={classes.formControl}>
              <InputLabel >Selecciona lista</InputLabel>
              <Select 
                value={this.state.synthesisSelected}
                onChange={this.handleChangeSelect}
                id="synthesisSelected"
                name="synthesisSelected"
              >
              {
                this.state.listOfContacts.map((item, index)=>{
                  return(
                    <MenuItem key={index.toString()} value={item.clist_id}>{item.clist_name}</MenuItem>                      
                  );
                })
              }
              </Select>
              </FormControl>
            </form>
            <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
              <div className={classes.flex}>
                <div className={classes.searchWrapper}>
                  <div className={classes.search}>
                    <SearchIcon />
                  </div>
                  <input className={classes.input} onChange={(event) => this._search(event)} placeholder="Buscar contacto" />
                </div>
              </div>
            </div>
          
          {/* <div className={classes.spacer} /> */}
          <div className={classes.actions}>
            <Hidden xsDown>
              <Button variant="raised" disabled={this.state.canPublish} onClick={this._handleOpenAddContact} color="secondary" className={classes.button}>
                <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                Nuevo
              </Button>
            </Hidden>
            <Hidden smUp>
              <Tooltip title="Nuevo" placement="top">
                <Button variant="fab" disabled={this.state.canPublish} onClick={this._handleOpenAddContact} mini color="secondary" aria-label="add">
                  <AddIcon className={classNames(classes.iconSmall)} />
                </Button>
              </Tooltip>
            </Hidden>
              
          </div>
        </Toolbar>
        <Paper className={classes.rootTable}>
          <div className={classes.maxHeightTable}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {/* <CustomTableCell align="right">
                  <Checkbox
                      checked={this.state.checkedA}
                      onChange={this.handleChange('checkedA')}
                      value="checkedA"
                    />
                  </CustomTableCell> */}
                  <CustomTableCell align="right">Id.</CustomTableCell>
                  <CustomTableCell align="right">Nombre</CustomTableCell>
                  <CustomTableCell align="right">Apellido</CustomTableCell>
                  <CustomTableCell align="right">Correo</CustomTableCell>
                  <CustomTableCell align="right">Acciones</CustomTableCell> 
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.searchData.map(row => {
                  return (
                    <TableRow className={classes.row} key={row.distId}>    
                      {/* <CustomTableCell align="right">
                        <Checkbox
                          checked={this.state.checkedA}
                          onChange={this.handleChange('checkedA')}
                          value="checkedA"
                        />
                      </CustomTableCell> */}

                      <CustomTableCell component="th" scope="row">
                        {row.distId}
                      </CustomTableCell>
                      <CustomTableCell component="th" scope="row">
                        {row.nombre}
                      </CustomTableCell>
                      <CustomTableCell align="right">{row.apellido}</CustomTableCell>
                      <CustomTableCell align="right">{row.correo}</CustomTableCell>
                      <CustomTableCell align="right">
                        <div key={row.profileId} className={classes.contentIcons}>
                        {/* <Icon hidden={this.state.canEdit} onClick={()=>{
                          this._handleEditContact(row)
                        }}>edit</Icon> */}
                        <Tooltip title="Editar">
                          <IconButton disabled={this.state.canEdit} onClick={()=>{
                            this._handleEditContact(row)
                          }} color="secondary" className={classes.button}>
                            <Icon>edit</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <IconButton disabled={this.state.canDelete} onClick={()=>{
                            this._openConfirmDialog(row)
                          }} color="secondary" className={classes.button}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </Tooltip>
                        {/* <Icon hidden={this.state.canDelete} onClick={()=>{
                          this._handleDeleteContact(row)
                        }}>delete</Icon> */}
                        </div>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
      {
        this.state.searchData.length === 0 ? 
        <Typography className={classes.noMatch}>No se encontraron contactos con ese criterio de búsqueda</Typography>:''
      }
        </Paper>
         </div>
        </PapperBlock>
      </div>
    );
  }
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired
};

Contacts.contextType = ThemeContext;

export default withStyles(styles)(Contacts);
