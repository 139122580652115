import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../new-jss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { fetchCall } from '../../../service/service_base';
import Portada from './Portada';
import Grid from '@material-ui/core/Grid/Grid';
import AddContentSection from '../../New/Portada/AddContentSection';
import { savePortrait } from '../../../service/s3Amplify';
import ls from 'local-storage';
import Loader from '../../Loader/Loader';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';

class PortadaNew extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      portadas: [],
      openAddContentSection: false,
      portraitSelected: null,
      isLoading: true,
      openConfirmDialog: false,
      openNotification: false,
      typeNotification: "info",
      messageNotification: "",
      idDeleted: 0,
      action: '',
      empty: []
    }
  }

  componentDidMount() {
    this._loadCard();
  }

  handleCloseNotification = () => {
    this.setState({
      openNotification: false
    })
  }

  _loadCard = () => {

    fetchCall("POST", "/synthesis/getcontent", {
      sectionId: this.props.section.sec_id,
      isPublish: this.props.isPublish,
      idPublish: this.props.idPublish
    }).then(response => {
      let empty = this.props.type === 'Portada' ? [1, 2] : [1];
      if (response.data.length > 0)
        empty.splice(0, response.data.length)
      this.setState({
        portadas: response.data,
        empty: empty,
        isLoading: false
      })
    }).catch(err => console.log(err));
  }

  _handleAddNewPortrait = (section) => {
    this.setState({
      openAddContentSection: true,
      contentType: section.sec_tipo,
      idSectionSelected: section.sec_id,
      portraitSelected: null,
      action: "Nueva"
    })
  }

  closeAddContentSection = () => {
    this.setState({
      openAddContentSection: false,
      portraitSelected: null
    });
  }

  saveContent = (event, data, file, attachment) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    if (data.title.toString().trim() === '') {
      this.setState({
        openNotification: true,
        messageNotification: 'Debes agregar un título',
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    }

    data.imagePath = '';
    data.sectionId = this.props.section.sec_id;
    data.userId = ls.get("current_user").user_id;
    const sec_name = this.props.section.sec_id;
    let keyS3 = `${this.props.synthesis}/${sec_name}/${file ? `${new Date().getTime()+"_" + file.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}`;
    let keyS3Attachment = attachment != null ? `${this.props.synthesis}/${sec_name}/attachment/${attachment ? `${new Date().getTime()+"_" + attachment.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}` : '';

    if (data.isUpdating) {
      Promise.all([(file?savePortrait(keyS3, file, file.type):null), (attachment? savePortrait(keyS3Attachment, attachment, attachment.type):null)])
        .then(response => {
          data.imagePath = response[0]!==null? response[0].key:this.state.portraitSelected.cont_imagePath;
          data.attachment = response[1]!==null? response[1].key:this.state.portraitSelected.cont_attachment;
          fetchCall("POST", "/synthesis/updatecontent", data).then(response => {
          if (response.status == 200) {
            this.setState({
              openNotification: true,
              messageNotification: 'Contenido actualizado',
              typeNotification: "success",
              isLoading: false,
              portraitSelected: null,
              openAddContentSection: false,
              empty:[]
            });
            this._loadCard();
          }
        }).catch(err => err);
        })
    } else {
      
      data.order = this.state.portadas.length + 1
      data.idPublish = this.props.idPublish
      Promise.all([savePortrait(keyS3, file, file.type), (attachment? savePortrait(keyS3Attachment, attachment, attachment.type):null)])
        .then(response => {
          data.imagePath = response[0].key;
          data.attachment = response[1]!==null? response[1].key:null;
          fetchCall("POST", "/synthesis/addcontent", data).then(response => {
            this.setState({
              openNotification: true,
              messageNotification: 'Contenido guardado',
              typeNotification: "success",
              portraitSelected: null,
              openAddContentSection:false,
              empty:[]
            });
            this._clearData();
            setTimeout(() => { this._loadCard(); }, 1500);
          }).catch(err => err);
        })
    }
  }
  
  _editPortrait = (item) => {
    this.setState({
      openAddContentSection: false,
      portraitSelected: null
    }, () => {
      this.setState(
        {
          openAddContentSection: true,
          portraitSelected: item,
          action: "Editar"
        }
      )
    })
  }

  _removeContent = () => {
    this.setState({ isLoading: true });
    fetchCall("POST", "/synthesis/removecontentsection", {
      contId: this.state.idDeleted,
      userId: ls.get("current_user").user_id
    }).then(response => {
      if (response.status == 200) {
        this.setState({
          openConfirmDialog: false,
          isLoading: false,
          openNotification: true,
          messageNotification: 'Contenido eliminado',
          typeNotification: "success"
        });
        this._loadCard();
      }
    }).catch(err => console.log(err));
  }

  openConfirmDelete = (item) => {
    this.setState({
      openConfirmDialog: true,
      idDeleted: item.cont_id
    })
  }

  _closeConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      idDeleted: 0
    })
  }

  _clearData = () => {
    return true;
  }

  moverDerecha = (order) => {
    let sections = this.state.portadas;
    let findElement = sections.findIndex(x => x.cont_order === order);
    var aux = sections[findElement];
    if (sections[findElement + 1] !== undefined) {
      sections[findElement] = sections[findElement + 1];
      sections[findElement].cont_orden = sections[findElement + 1].cont_orden;
      sections[findElement + 1] = aux;
      sections[findElement + 1].cont_orden = aux.cont_orden;

      this.setState({
        portadas: sections
      }, () => {
          fetchCall("POST", "/synthesis/updateorder", {
            order: sections.map((v,k)=>({id:v.cont_id,position:(k + 1)})),
            userId: ls.get("current_user").user_id,
            content: 1
          }).catch(err => console.log(err))
        // });

        this.setState({
          isLoading: false
        });
      });
    }
  }

  moverIzquierda = (order) => {
    let sections = this.state.portadas;
    let findElement = sections.findIndex(x => x.cont_order === order);
    var aux = sections[findElement];
    if (sections[findElement - 1] !== undefined) {
      sections[findElement] = sections[findElement - 1];
      sections[findElement].cont_orden = sections[findElement - 1].cont_orden;
      sections[findElement - 1] = aux;
      sections[findElement - 1].cont_orden = aux.cont_orden;

      this.setState({
        portadas: sections
      }, () => {
        fetchCall("POST", "/synthesis/updateorder", {
          order: sections.map((v,k)=>({id:v.cont_id,position:(k + 1)})),
          userId: ls.get("current_user").user_id,
          content: 1
        }).catch(err => console.log(err))

        this.setState({
          isLoading: false
        });
      });
    }
  }

  render() {
    const {
      classes,
      section,
      canEdit,
      canDelete
    } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>
        <Grid container >
          <Grid item xs={12}>
            <Grid
              container
              spacing={8}
              alignItems="center"
              direction="row"
              justify="center"
              className={classes.portadas}
            >
              {
                this.state.isLoading ? <Loader text={"Cargando..."} /> :

                  this.state.portadas.map((item, index) => {
                    item.cont_imagePath = item.cont_key
                    item.cont_attachment = item.cont_attachmentKey
                    const moreOne = this.state.portadas.length > 1
                    return (
                      <Grid key={index.toString()} item>
                        <Portada canMove={moreOne} canEdit={canEdit} canDelete={canDelete} attachment={item.cont_attachmentKey} image={item.cont_imagePath} edit={() => this._editPortrait(item)} remove={() => this.openConfirmDelete(item)} moverDerecha={() => this.moverDerecha(item.cont_order)} moverIzquierda={() => this.moverIzquierda(item.cont_order)} />
                      </Grid>
                    )
                  })
              }
              {this.state.empty.map(e => {
                return (
                  <Grid key={e} item>
                    <div className={classes.portadaNew}>
                      <div className={classes.contentBtnNew}>
                        <Tooltip title="Nuevo Contenido">
                          <Button variant="fab" onClick={() => { this._handleAddNewPortrait(section) }} mini aria-label="add" className={classes.btnNew} disabled={!canEdit}>
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                );
              })}

            </Grid>
          </Grid>
        </Grid>

        <AddContentSection
          openForm={this.state.openAddContentSection}
          closeForm={this.closeAddContentSection}
          classes={classes}
          submit={this.saveContent}
          dataInit={this.state.portraitSelected}
          contentType={section.cont_tipo}
          action={this.state.action}
          clearData={this._clearData}
          isLoading={this.state.isLoading}
        />

        <ConfirmDialog
          title={"¿Desea eliminar la portada?"}
          message={"La información de la portada no podrá recuperarse"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._removeContent}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />
      </div>

    );
  }
}

PortadaNew.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  submit: PropTypes.func,
  section: PropTypes.object,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool
};

export default withStyles(styles)(PortadaNew);
