
const styles = theme => ({
    portadaNew: {
      position: 'relative',
      //border: '1px solid #cacaca',
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      width: 320,
      height: 50,
    },
    slideNew: {
      position: 'relative',
      //border: '1px solid #cacaca',
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      marginTop: 4,
      borderRadius: 4,
      width: '100%',
      height: 332,
    },
    redesNew: {
      position: 'relative',
      //border: '1px solid #cacaca',
      borderRadius: 4,
      width: '100%',
      overflow: 'hidden',
      height: 380,
      boxShadow:' 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    contentRedes: {
      paddingTop: 0,
      width: '100%',
      height: '100%',
      textAlign: 'center',
      color: '#a7a7a7',
      borderRadius: 4,
      // Twitter
      '& div iframe':{
        height: '385px !important'
      },
      // Face
      '& div div span': {
        height: '380px !important',
        '& iframe': {
          height: '380px !important'
        }
      }
    },
    iconRedes: {
      margin: 'auto',
      display: 'block',
      marginBottom: -10,
      fontSize: 40,
    },
    notasNew: {
      position: 'relative',
      //border: '1px solid #cacaca',
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      height: 180,
      marginBottom: 16,
      width: '100%',
    },
    coverNew: {
      position: 'relative',
      //border: '1px solid #cacaca',
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      height: 180,
      marginBottom: 16,
      width: '100%',
    },
    contentBtnNew: {
      position: 'absolute',
      display: 'flex',
      backgroundColor: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      borderRadius: 4,
    },
    btnNew: {
      color: '#a7a7a7',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0 0 8px rgba(0,0,0,.15)',
        transform: 'scale(1.1)'
      },
    },
    adminActions: {
      padding: '5px 0',
      display: 'flex',
      justifyContent: 'space-between',
      '& button': {
        width: 30,
        height: 30,
        minHeight: 30,
        marginLeft: 5,
        //boxShadow: 'none'
        backgroundColor: '#fff',
        // border
      },
      '& svg': {
        fontSize: 18,
        color: theme.palette.primary.main,
      }
    },
    eventContent: {
      padding: theme.spacing.unit,
      paddingBottom: 0,
      '& $title': {
        fontSize: 14,
        marginBottom: 5,
      },
      '& hr':{
        margin: '13px 0 0 0'
      }
    },
    descriptionEvent: {
      fontSize: 12,
      maxHeight: 50,
      display: 'block',
      // display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      //whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    activo: {
      backgroundColor: theme.palette.primary.main,
      '&:after': {
        content: '" "',
        position: 'absolute',
        height: 70,
        width: 70,
        borderRadius: '50%',
        backgroundColor: 'rgba(255,255,255,0.2)',
      },
      '& p': {
        color: '#ffffff !important'
      }
    },
    eventActions: {
      padding: '10px 0 0 0',
      textAlign: 'center',
      '& button': {
        width: 30,
        height: 30,
        minHeight: 30,
        marginLeft: 5,
        //boxShadow: 'none'
        backgroundColor: '#fff',
        // border
      },
      '& svg': {
        fontSize: 18,
        color: theme.palette.primary.main,
      }
    },
    title: {
      fontSize: 18,
      marginBottom: 10,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%'
    },
    // TELEVISA
    item: {
      textAlign: 'center',
      '& img': {
        margin: '0 auto'
      }
    },
    titleTelevisa: {
      fontSize: 18,
      // height: 24,
      marginBottom: 10,
      color: '#ffffff',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%'
    },
    descriptionTelevisa: {
      color: '#ffffff',
      height: 60,
      display: 'block',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    diaSemana: {
      cursor: 'pointer',
      textAlign: 'center',
      width: 130,
    },
    contentDia: {
      //width: 130,
      height: 70,
      borderRadius: 4,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& p:first-child': {
        fontSize: "22px",
      },
      '& p': {
        fontSize: "26px",
        lineHeight: "22px",
        color: '#cacaca',
        fontWeight: 600
      }
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
  });
  
  export default styles;
  