import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import brand from '../../../../utils/brand';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import styles from './../reportes-jss';
import ls from 'local-storage';
import {fetchCall} from '../../../../service/service_base'; 
import PreviewSynthesis from '../../Administration/SendEmail/PreviewSynthesis';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification'; 
//Filtros
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid'; 
import LoaderFull from '../../../../components/Loader/LoaderFull';
import {ThemeContext} from '../../../../utils/ThemeContext';

const options = { 
  filter : false,
  responsive: 'scroll',
  print: false,
  download:false,
  rowsPerPage: 10,
  viewColumns: false,
  selectableRows: false,
  selectableRowsOnClick:false,
  textLabels: {
    body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV", 
        viewColumns: "Ver columnas",
        filterTable: "Filtrar registros",
      },
    filter: {
    all: "Todo",
    title: "FILTRO",
    reset: "LIMPIAR",
    }
  }
};
class SintesisPublicadas extends React.Component{
    
    state = {
        isLoading:true,
        //filters
        fechaInicio: new Date(),
        fechaFin: null,
        synthesis: 0, 
        //Table
        columns: [
          { name: 'Fecha Publicación',
              options: {
              filter:false,
              viewColumns: false,
              selectableRows: false,
              selectableRowsOnClick:false,
              customBodyRender: (value, tableMeta, updateValue) => { 
                const dt=value.toISOString().split('T');
                  return (
                    <label>{dt[0].split('-')[2]}/{dt[0].split('-')[1]}/{dt[0].split('-')[0]} {dt[1].split('.')[0] }</label>
                  );
              },
              } 
          },
          { name:'Tipo de Síntesis'},
          { name: 'Acción',
              options: {
              filter: false,
              viewColumns: false,
              selectableRows: false,
              selectableRowsOnClick:false,
              customBodyRender: (value, tableMeta, updateValue) => { 
                  return (
                    <Tooltip title="Previsualizar" placement="top">
                        <IconButton variant="fab" mini  onClick={()=>this.handleOpenModal(value)} aria-label="add">
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                  );
              },
              } 
          }
      ],
        data: [],
        //Autocomplete
        users: [],
        actions: [], 
        actionsTranslated: [],
        //preview modal
        openModal:false,
        preview:false,
        previewHTML:"",
        //Notification 
        openNotification:false,
        typeNotification:"info",
        messageNotification:"",
    }
    
    componentDidMount(){  
        this.context.toggleTheme(undefined);  
        this._getHistoryPublishSynthesis();  
    }
  
    _getHistoryPublishSynthesis = () => {
      this.setState({isLoading:true})
        fetchCall("POST","/report/gethistorypublishsynthesis", {
            synthesis:this.state.synthesis,
            startDate:this.state.fechaInicio==null?'':this.state.fechaInicio.getFullYear()+'-'+(this.state.fechaInicio.getMonth()+1)+'-'+this.state.fechaInicio.getDate(),
            endDate:this.state.fechaFin==null?'':this.state.fechaFin.getFullYear()+'-'+(this.state.fechaFin.getMonth()+1)+'-'+this.state.fechaFin.getDate()
        }).then(response =>{     
           this.setState({
            data: response.data, 
             isLoading:false
           });  
           //this._getUsersList();    
        }).catch(err =>{ 
            console.log(err); this.setState({isLoading:false})
        });
    }
     
    handleDateChange = date => {
      if(date)
        this.setState({ fechaInicio: new Date(date)});
      else
        this.setState({ fechaInicio: new Date()});
    };

    handleDateChangeFin = date => {
      if(date)
        this.setState({ fechaFin: new Date(date)});
      else
        this.setState({ fechaFin: null});
    };
 
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleStateChange = changes => {
        if (changes.hasOwnProperty('selectedItem')) {
        this.setState({ user: changes.selectedItem })
        } else if (changes.hasOwnProperty('inputValue')) {
        this.setState({ user: changes.inputValue })
        }
    }

    handleClearFilters=()=>{
        this.setState({
            fechaInicio: new Date(),
            fechaFin: null,
            synthesis: 0, 
        },()=>this._getHistoryPublishSynthesis());
    }

    render(){
        const { classes } = this.props;
        const title = brand.name + ' - Síntesis publicadas';
        const description = brand.desc;
        const { columns, data } = this.state;
        
        
        return(
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                {this.state.isLoading ? <LoaderFull /> : ''}
                <PreviewSynthesis
                  classes={classes}
                  openModalPermission={this.state.openModal}
                  preview={this.state.previewHTML}
                  handleCloseModal={this.handleCloseModal}
                  date={this.state.fechaInicio}
                />
                <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={()=>this.setState({openNotification:false,messageNotification:''})}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>
                <PapperBlock title="Histórico de síntesis" whiteBg fullTitle>
                    {/* Inicio Filtros */}
                    <form className={classes.container}>
                    <Grid
                        container
                        spacing={24}
                        direction="row"
                        justify="flex-start"
                        alignItems="center">
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                            helperText="Fecha inicial"
                            className={classes.formControl}
                            format="DD/MM/YYYY"
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            value={this.state.fechaInicio}
                            onChange={this.handleDateChange}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            disableFuture={true}
                            clearable={true}
                            cancelLabel="Cancelar"
                            clearLabel="Limpiar"
                            placeholder="DD/MM/AAAA"
                            />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                            helperText="Fecha Final"
                            className={classes.formControl}
                            format="DD/MM/YYYY"
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            value={this.state.fechaFin}
                            onChange={this.handleDateChangeFin}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            disableFuture={true}
                            clearable={true}
                            minDate={this.state.fechaInicio}
                            disabled={this.state.fechaInicio == null ? true : false}
                            cancelLabel="Cancelar"
                            clearLabel="Limpiar"
                            placeholder="DD/MM/AAAA"
                            />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl className={classes.formControl}>
                            <Select value={this.state.synthesis} onChange={this.handleChange} name="synthesis" displayEmpty className={classes.selectEmpty}>
                            <MenuItem key={0} value={0}>Todos</MenuItem>
                            {
                              ls.get("session_menu").map((item, index) =>{
                                return(
                                  <MenuItem key={index.toString()} value={item.menu_id}>{item.menu_name}</MenuItem>
                                );
                              })                    
                            }
                            </Select>
                             
                            <FormHelperText>Tipo de Síntesis</FormHelperText>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.centerOnlySmall}>
                        <Button onClick={this._getHistoryPublishSynthesis} variant="contained" color="secondary" className={classes.button}>
                            Buscar
                        </Button>
                        <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleClearFilters}>
                            Limpiar
                        </Button>
                        </Grid>
                    </Grid>
                    </form>
                    {/* Fin Filtros */}
                    <div> 
                        <MUIDataTable
                            title={`Publicaciones - (${data.length} registros encontrados)`}
                            data={
                              data ? data.map(item => {
                                return [
                                    new Date(item.date),
                                    item.menu_name,
                                    item
                                ]
                            }):[]
                            }
                            columns={ columns }
                            options={options}
                          />
                    </div>
                </PapperBlock>
            </div>
        )
    }

     //Funciones de previsualización
     handleOpenModal =(menu) =>{ 
        this._content = [];
        this.setState({isLoading:true, previewHTML : '', synthesiscom : [], sections: [], contentSection:[]}, () => {
            fetchCall("POST","/synthesis/getsection",{
              menuId: menu.menu_id
            }).then(response =>{
                this.setState(
                  {
                    sections: response.data
                  }, () => {
                    this._getPublishContent(this.state.sections, menu, menu.date, response.data.length, 0);
                  }
                );  
            }).catch(err => {
              console.log(err);
            });
        })
      }

      handleCloseModal = () =>{
        this.setState({
          openModal:false
        });
      }
    _getPublishContent = (section,menu, date, tam, index) =>{
        if(section.length === index){
          if(this.state.contentSection.length > 0){
            let menuSelected = ls.get("session_menu").filter(x => x.menu_id === menu.menu_id)[0];
            if(menuSelected!=undefined){
              let section = new Array();
              this.state.sections.map(item =>{
                let o = {
                  sec_icono: item.sec_icono,
                  sec_iconoUrl: item.sec_iconoUrl,
                  sec_id: item.sec_id,
                  sec_name: item.sec_name,
                  sec_orden:item.sec_orden,
                  sec_tipo: item.sec_tipo,
                  sec_color: item.sec_color,
                  content : [...new Set(this.state.contentSection.filter(x => x.sec_id === item.sec_id))]
                }
                section.push(o);
              });
              let d= new Date(date)
              d.setHours(9,0,0);
              let complete = {
                menu_id : menuSelected.menu_id,
                menu_name : menuSelected.menu_name,
                menu_description: menuSelected.menu_description,
                lastPublish: d,
                section : [...new Set(section)]
              }
      
              this.setState(
              {
                synthesiscom : complete
              } , () => {
                menuSelected.lastPublish = d;
                  fetchCall("POST","/distUserList/email", {
                    emails : null,
                    synthesis : this.state.synthesiscom ,
                    preview : true,
                    availableSynthesis : JSON.stringify(menuSelected),
                    date : this._getCurrentTime(d)
                  }).then(
                    response => {
                        this.setState({
                          previewHTML : response,
                          openModal : true,
                          isLoading: false,
                          synthesiscom: null
                        });
                        ls.set("synthesis_structure", this.state.synthesiscom);
                        ls.set("preview_html", response);
                    }
                  );
              });
            }else{
              this.setState({
                openNotification: true,
                messageNotification : "Esta síntesis fue eliminada del portal",
                typeNotification : 'warning',
                isLoading:false
              });
            }
          }else{
            this.setState({
              openNotification: true,
              messageNotification : "No se encontró una síntesis",
              typeNotification : 'warning',
              isLoading:false
            });
          }
        }else{
          fetchCall("POST", "/synthesis/getcontent",{
            sectionId: section[index].sec_id,
            isPublish: 1,
            idPublish: menu.ps_id
          }).then(response =>{
            if(response){
              if(response.status === 200){
                if(response.data != null){
                   response.data.map(item => {
                     this._content.push(item);
                   }); 
                   this.setState({
                     contentSection : this._content
                   },() => {
                     this._getPublishContent(section, menu, date, tam, index +1)
                   });
                }
               }
            }else{
              this._getPublishContent(section, menu, date, tam, index +1)
            }
          }).catch(err =>{
            console.log("error contenido", err)
          });
        }
      }
      _translateDay = (str) => {
        switch (str) {
          case "Mon": return "Lunes"; break;
          case "Tue": return "Martes"; break;
          case "Wed": return "Miércoles"; break;
          case "Thu": return "Jueves"; break;
          case "Fri": return "Viernes"; break;
          case "Sat": return "Sábado"; break;
          case "Sun": return "Domingo"; break;
        }
      }
    
      _translateYear = (str) => {
        switch (str) {
          case "Jan": return "enero"; break;
      case "Feb": return "febrero"; break;
      case "Mar": return "marzo"; break;
      case "Apr": return "abril"; break;
      case "May": return "mayo"; break;
      case "Jun": return "junio"; break;
      case "Jul": return "julio"; break;
      case "Aug": return "agosto"; break;
      case "Sep": return "septiembre"; break;
      case "Oct": return "octubre"; break;
      case "Nov": return "noviembre"; break;
      case "Dec": return "diciembre"; break;/*  */
        }
      }
    
      _getCurrentTime = (f) => {
        
        let date = new Date(f);
        let currentDay = this._translateDay(date.toString().split(" ")[0]);
        let currentDate = this._translateYear(date.toString().split(" ")[1]);
        return `${currentDay}, ${date.toString().split(" ")[2]} ${currentDate} ${date.toString().split(" ")[3]}`;
      } 
}
SintesisPublicadas.contextType = ThemeContext;
export default withStyles(styles)(SintesisPublicadas);