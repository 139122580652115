import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Input, InputLabel, Snackbar } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import styles from "./note-jss";
import { ValidationContent } from "../../../validator/Regex";
import CustomNotification from "../../Notification/CustomNotification";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Editor } from '@bit/primefaces.primereact.editor';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { charDescriptionLength, charTitleLength } from "../../../utils/validation";

class AddNewNoteForm extends React.Component {
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state = {
    title: "",
    description: "",
    notas: 0,
    contId: 0,
    isUpdating: false,
    openNotification: false,
    messageNotification: "",
    typeNotification: "",
    radiostation: 0,
    program:"",
    textLimited:0
  };

  handleChange = (event, min) => {
    if (
      !ValidationContent(event.target.value) &&
      event.target.value.toString().trim("").length <= min
    ) {
      this.setState({ [event.target.name]: event.target.value });
    }
    if (event.target.name === "radiostation") {
      let radiostationItem = this.props.dataRadiostation.filter(
        x => x.radiostation_id === event.target.value
      )[0];
      this.setState({
        imagePath: radiostationItem.radiostation_photo
          ? radiostationItem.radiostation_photo
          : ""
      });
      this.props.onDrop(
        [],
        radiostationItem.radiostation_photo ? radiostationItem.radiostation_photo : "null"
      );
    }
  };

  _cleanData = band => {
    if (band) {
      this.setState({
        title: "",
        description: "",
        program:"",
        notas: 0,
        radiostation: 0,
        imagePath: null
      });
    } else {
      this.setState({
        title: this.props.dataInit ? this.props.dataInit.cont_name : "",
        program: this.props.dataInit ? this.props.dataInit.cont_program : "",
        description: this.props.dataInit
          ? this.props.dataInit.cont_description
          : "",
        notas: this.props.dataInit ? this.props.dataInit.cont_notas : 0,
        radiostation: this.props.dataInit ? this.props.dataInit.columnist_id : 0,
        imagePath: this.props.dataInit
          ? this.props.dataInit.cont_imagePath
          : null
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.dataInit != null) {
      this.setState({
        isUpdating: true,
        contId: newProps.dataInit.cont_id,
        title:
          this.state.title === ""
            ? newProps.dataInit.cont_name
            : this.state.title,
        program:
          this.state.program === ""
            ? newProps.dataInit.cont_program
            : this.state.program,
        description:
            this.state.description === "" ||  this.state.description===null
              ? newProps.dataInit.cont_description
              :this.state.description,
        notas:
          this.state.notas === ""
            ? newProps.dataInit.cont_notas
            : this.state.notas,
        radiostation:
          this.state.radiostation !== 0
            ? this.state.radiostation
            : newProps.dataInit.columnist_id,
        imagePath:
          this.state.imagePath !== null
            ? this.state.imagePath
            : newProps.dataInit.cont_imagePath
      });
    }

    if (!newProps.isOpen) {
      this._cleanData(true);
      this.setState({
        isUpdating: false
      });
    }
  }

  _validateData = (event, data) => {
    event.preventDefault();
    if (data.title.toString().trim() === "") {
      this.setState({
        openNotification: true,
        messageNotification: "Debes agregar un título",
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    }
    if (data.program.toString().trim() === "") {
      this.setState({
        openNotification: true,
        messageNotification: "Debes indicar un programa o estación",
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    }
    if (data.description.toString().trim() === "") {
      this.setState({
        openNotification: true,
        messageNotification: "Debes agregar una descripción",
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    }

    if (data.notas.toString().trim() === "") {
      this.setState({
        openNotification: true,
        messageNotification: "Debes agregar un número de notas",
        typeNotification: "warning",
        isLoading: false
      });
      return false;
    }
    this.props.handleSubmit(event, data);
    this.props.onClean();
    this._cleanData(true);
  };

  addDefaultSrc(ev) {
    ev.target.src = "";
  }
  handleTextEditor = event => {
    if (event.textValue.toString().trim("").length <= charDescriptionLength){
      this.setState({ description: event.htmlValue&&event.htmlValue.replace("background",""),textLimited:false})
    }else{
        this.setState({
        textLimited: event.textValue.toString().trim("").length
      });
    }
  }
  renderHeader() {
    return (
      <span className='ql-formats'>
        <button className='ql-bold' aria-label='Bold' />
        <button className='ql-italic' aria-label='Italic' />
        <button className='ql-underline' aria-label='Underline' />
        <select className="ql-color"></select>
      </span>
    );
  }
  render() {
    
    const {
      classes,
      pristine,
      submitting,
      onClean,
      attachment,
      addAttachment,
      preview,
      errorMessage,
      dataRadiostation
    } = this.props;
    let dropzoneAttachment;
    const acceptedFiles = ["audio/mpeg",
    "audio/mp4",
    "audio/mp3",
    "audio/vnd.wav",];
    const header = this.renderHeader();
    return (
      <div>
        <form
          onSubmit={event => {
            var tempState = this.state;
            var titulo = tempState.title.split("'");
            var descripcion = tempState.description.split("'");
            if (titulo) {
              var tempTitulo = "";
              titulo.forEach(item =>
                item === ""
                  ? (tempTitulo = tempTitulo + item + "''")
                  : (tempTitulo = tempTitulo + item)
              );
              tempState.title = tempTitulo;
            }
            if (descripcion) {
              var tempDescription = "";
              descripcion.forEach(item =>
                item === ""
                  ? (tempDescription = tempDescription + item + "''")
                  : (tempDescription = tempDescription + item)
              );
              tempState.description = tempDescription.replace(/&nbsp;/g," ").replace(/&amp;nbsp;/g," ");
            }
            this._validateData(event, tempState);

            onClean();
            this._cleanData(true);
          }}
        >
          <section className={classes.bodyForm}>
            <div>
              <div>
                <div className={classes.avatarWrap}>
                  <Avatar
                    className={classes.uploadAvatar}
                    src={preview}
                    onError={event => this.addDefaultSrc(event)}
                  /> 
                </div>
              </div>
              <Typography variant="button" className={classes.textCenter}>
                {errorMessage}
              </Typography>
              <div>
                <InputLabel htmlFor="title">Título *</InputLabel>
                <Input
                  id="title"
                  name="title"
                  value={this.state.title}
                  type="text"
                  autoComplete="off"
                  required
                  className={classes.field}
                  startAdornment={
                    <InputAdornment position="start">
                      <PermContactCalendar />
                    </InputAdornment>
                  }
                  onChange={event => this.handleChange(event,charTitleLength)}
                />
              </div>
              {this.state.title.length === charTitleLength && (<div>Has llegado al límite de caracteres ({charTitleLength})</div>)}
              <div>
                <InputLabel htmlFor="program">Programa / Estación</InputLabel>
                <Input
                  name="program"
                  value={this.state.program}
                  type="text"
                  autoComplete="off"
                  required
                  className={classes.field}
                  onChange={event => this.handleChange(event, 41)}
                />
              </div>
              <div>
                <InputLabel htmlFor="radiostation">Radiodifusora *</InputLabel>
                {dataRadiostation?<Select
                  value={this.state.radiostation}
                  onChange={event => this.handleChange(event, 100)}
                  className={classes.field}
                  name="radiostation"
                  required={true}
                >
                  {dataRadiostation.map((item, index) => {
                    return (
                      <MenuItem
                        key={index.toString()}
                        value={item.radiostation_id}
                      >
                        {item.radiostation_name}
                      </MenuItem>
                    );
                  })}
                </Select>:'No hay radiodifusoras disponibles'}
              </div>
              <div>
              <InputLabel htmlFor="radiostation">Descripción *</InputLabel>
                <Editor
                  headerTemplate={header}
                  style={{ height: "100px", marginBottom: "15px" }}
                  value={this.state.description}
                  onTextChange={event => this.handleTextEditor(event)}
                />
                {this.state.textLimited > charDescriptionLength && (
                  <div>
                    Has llegado al límite de caracteres ({this.state.textLimited}
                    )
                  </div>
                )}
              </div>
              <div>
                <Dropzone
                  className={classes.hiddenDropzone}
                  accept={acceptedFiles.join(",")}
                  //acceptClassName="stripes"
                  onDrop={addAttachment}
                  // maxSize={fileSizeLimit}
                  ref={node => {
                    dropzoneAttachment = node;
                  }}
                />
                <div className={classes.wraperUploadFile}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={() => {
                      dropzoneAttachment.open();
                    }}
                  >
                    Subir Archivos
                  </Button>
                  {attachment || (this.props.dataInit && this.props.dataInit.cont_attachment) ? <CheckCircle/> : null}
                  {attachment ? <small title={attachment.name}>{attachment.name}</small> : this.props.dataInit && <small title={this.props.dataInit.cont_attachment}>{this.props.dataInit.cont_attachment}</small>}

                </div>
              </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button
              variant="raised"
              color="primary"
              type="submit"
              disabled={
                (this.state.title != "" && preview!=='' && (this.state.textLimited <= charDescriptionLength))
                  ? false
                  : true
              }
            >
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this._cleanData(false);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}
        >
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification}
          />
        </Snackbar>
      </div>
    );
  }
}

AddNewNoteForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  dataInit: PropTypes.object,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(AddNewNoteForm);
