import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input , InputLabel} from '@material-ui/core'; 
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './newspaper-jss'; 
import ls from 'local-storage';

class AddNewspaperForm extends React.Component {
 
  state ={
    newspaper_name:'',
    synthesis:0,
    openNotification:false,
    typeNotification:"info",
    messageNotification:""
  }

  resetValues = (band) =>{
    if(band){
      this.setState({
        newspaper_name:'',
        synthesis:0
      })
    }else{
      this.setState({
        newspaper_name : this.props.dataInit ? this.props.dataInit.newspaper_name :'',
        synthesis : this.props.dataInit ? this.props.dataInit.menu_id : 0
      })
    }
  }
  
  handleChange = event => {
    if(event.target.name==="newspaper_name" && event.target.value.length<26)
      this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeSelect = event => {
      this.setState({ [event.target.name]: event.target.value });
  };
  componentWillReceiveProps(newProps){
    
    if(!newProps.isOpen){
      this.resetValues(true);
    }
    if(newProps.dataInit != null){
      this.setState({
        newspaper_name : newProps.dataInit ? newProps.dataInit.newspaper_name :'',
        synthesis : newProps.dataInit ? parseInt(newProps.dataInit.menu_id) : 0,
        newspaper_color : newProps.dataInit? newProps.dataInit.newspaper_color:''
    });
    }
    
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit
    } = this.props;
 
      return(
        <div>
         
        <form onSubmit={(event) => handleSubmit(event, this.state)}>
          <section className={classes.bodyForm}>     
            <div>
              <InputLabel htmlFor="newspaper_name">Nombre</InputLabel>
              <Input 
                id="newspaper_name"
                name="newspaper_name"
                value={this.state.newspaper_name}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            <div>
              <InputLabel htmlFor="synthesis">Síntesis</InputLabel>
              <Select
                value={this.state.synthesis}
                onChange={this.handleChangeSelect}
                className={classes.field}
                name="synthesis"
              >
                {
                  ls.get("session_menu").map((item, index) =>{
                    return(
                      <MenuItem key={index.toString()} value={item.menu_id}>{item.menu_name}</MenuItem>
                    );
                  })                                
                }
              </Select>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="raised" color="primary" type="submit" disabled={this.state.synthesis!==0?false:true}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() =>{this.resetValues(false)}}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
      )
     
  }
}

AddNewspaperForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit:PropTypes.object,
  isOpen:PropTypes.bool
};
 

export default withStyles(styles)(AddNewspaperForm);
