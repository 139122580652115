
const styles = theme => ({
  root: {
    margin: '15px 0',
    padding: '1rem',
    borderRadius: 6,
    boxShadow: 'rgba(0, 0, 0, 0.14) 0px 2px 8px -1px, rgba(0, 0, 0, 0.1) 0px 0px 6px -1px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'space-between',
  },
  itemIcon: {
    padding: 5,
    borderRadius: 4,
    background: 'white',
    color: theme.palette.primary.main,
    maxWidth: 34,
    maxHeight: 34,
    boxShadow: theme.shadows[1],
    cursor: 'pointer',
    '& svg': {
      width: 24,
      height: 24
    },
    '&:hover':{
      transition: 'all 0.3s ease',
      boxShadow: theme.shadows[4],
    },
  },
  active: {
    background: theme.palette.primary.main,
    color: 'white',
  }
});

export default styles;
