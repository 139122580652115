import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { S3Image } from 'aws-amplify-react';
import styles from './notas-jss';

class NotasRadio extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      classes,
      title,program,
      description,
      attachment, cover,color
    } = this.props;

    return (
      <Card className={classes.cardMediaRadio}>
        <div className={classes.mediaRadio2} onClick={() => window.open(`/multimedia/${encodeURIComponent(attachment)}`)}>
          <S3Image imgKey={cover} />
        </div>
        <div className={classes.contenidoMediaIcon}>
          <CardContent className={classes.contenidoMedia}>
            <Typography className={classes.titleRadio} style={{color:color&&color.includes("#")&&color.toLowerCase()!="#ffffff"?"white":""}}>{title}</Typography>
            <Typography className={classes.programRadio}  style={{color:color&&color.includes("#")&&color.toLowerCase()!="#ffffff"?"white":""}}>{program}</Typography>
            <Typography color="textSecondary" className={classes.description}>
            <span style={{fontFamily:"sans-serif",color: color&&color.includes("#")&&color.toLowerCase()!="#ffffff"?"white":""}} dangerouslySetInnerHTML={{ __html: `${description}` }} />
            </Typography>
          </CardContent>
        </div>
      </Card>
    );
  }
}

NotasRadio.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(NotasRadio);
