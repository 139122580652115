import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Input , InputLabel} from '@material-ui/core'; 
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import styles from './contact-jss'; 
import { Alphanumeric, AlphanumericEmail} from '../../../../validator/Regex';

class AddContactForm extends React.Component {
    
  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  handleCloseNotification = () =>{
    this.setState({openNotification:false,messageNotification:''});
  }
 
  state ={
    distName:'',
    distLastname:'',
    distEmail:'',
    openNotification:false,
    typeNotification:"info",
    messageNotification:""
  }

  resetValues = (band) =>{
    if(band){
      this.setState({
        distName:'',
        distLastname:'',
        distEmail:''
      })
    }else{
      this.setState({
        distName : this.props.dataInit ? this.props.dataInit.nombre :'',
        distLastname : this.props.dataInit ? this.props.dataInit.apellido : '',
        distEmail : this.props.dataInit ? this.props.dataInit.correo : ''
      })
    }
  }

  handleChange = event => {
    if(!Alphanumeric(event.target.value) && (event.target.value.toString().trim('').length < 51)){
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChangeEmail = event => {
    if(!AlphanumericEmail(event.target.value) && (event.target.value.toString().trim('').length < 60)){
      this.setState({ [event.target.name]: event.target.value });
    }
  };
 
  componentWillReceiveProps(newProps){
    
    if(!newProps.isOpen){
      this.resetValues(true);
    }
    if(newProps.dataInit != null){
      this.setState({
        distName:newProps.dataInit.nombre,
        distLastname:newProps.dataInit.apellido,
        distEmail:newProps.dataInit.correo
    });
    }
    // else{
    //   this.resetValues();
    // }
    
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit
    } = this.props;
 
      return(
        <div>
         
        <form onSubmit={(event) => handleSubmit(event, this.state)}>
          <section className={classes.bodyForm}>           
          <div>
              <InputLabel htmlFor="distName">Nombre</InputLabel>
              <Input 
                id="distName"
                name="distName"
                value={this.state.distName}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            <div>
              <InputLabel htmlFor="distLastname">Apellido</InputLabel>
              <Input 
                id="distLastname"
                name="distLastname"
                value={this.state.distLastname}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChange}
              />
            </div>
            <div>
              <InputLabel htmlFor="distEmail">Correo electrónico</InputLabel>
              <Input 
                id="distEmail"
                name="distEmail"
                value={this.state.distEmail}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={this.handleChangeEmail}
              />
            </div>   
          </section>
          <div className={classes.buttonArea}>
            <Button onClick={() => {
              setTimeout(() => {
                // this.resetValues();
              }, 2000);
            }} variant="raised" color="primary" type="submit" disabled={submitting}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() =>{this.resetValues(false)}}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
      )
     
  }
}

AddContactForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit:PropTypes.object,
  isOpen:PropTypes.bool
};
 

export default withStyles(styles)(AddContactForm);
