import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Input, InputLabel } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import styles from "./note-jss";
import { ValidationContent, Numeric } from "../../../validator/Regex";
import { Editor } from "@bit/primefaces.primereact.editor";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import CheckCircle from '@material-ui/icons/CheckCircle';
import { charDescriptionLength, charTitleLength } from "../../../utils/validation";

class AddNewRelevanteForm extends React.Component {
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  _isClean = false;

  state = {
    title: "",
    description: "",
    notas: 1,
    contId: 0,
    columnist: 0,
    isUpdating: false,
    isColumnist: false,
    imagePath: null,
    textLimited: 0
  };

  handleChange = (event, min) => {
    if (min == 4) {
      if ((Numeric(event.target.value) || event.target.value == "") && event.target.value.toString().trim("").length <= min)
        this.setState({ [event.target.name]: event.target.value });
    } else {
      if (!ValidationContent(event.target.value) && event.target.value.toString().trim("").length <= min)
        this.setState({ [event.target.name]: event.target.value });
    }

    if (event.target.name == "columnist") {
      let columnistItem = this.props.dataColumnist.filter(
        x => x.columnist_id == event.target.value
      )[0];
      this.setState({
        imagePath: columnistItem.columnist_photo
          ? columnistItem.columnist_photo
          : ""
      });
      this.props.onDrop(
        [],
        columnistItem.columnist_photo ? columnistItem.columnist_photo : "null"
      );
    }
  };

  handleTextEditor = event => {
    if (event.textValue.toString().trim("").length <= charDescriptionLength) {
      this.setState({ description: event.htmlValue && event.htmlValue.replace("background", ""), textLimited: false });
    } else {
      this.setState({
        textLimited: event.textValue.toString().trim("").length
      });
    }
  };
  renderHeader() {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold" />
        <button className="ql-italic" aria-label="Italic" />
        <button className="ql-underline" aria-label="Underline" />
        <select className="ql-color"></select>
      </span>
    );
  }
  _cleanData = band => {
    if (band) {
      this.setState({
        title: this.props.dataInit ? this.props.dataInit.cont_name : "",
        description: this.props.dataInit
          ? this.props.dataInit.cont_description
          : "",
        notas: this.props.dataInit ? this.props.dataInit.cont_notas : 1,
        columnist: this.props.dataInit ? this.props.dataInit.columnist_id : 0,
        //isColumnist: this.props.dataInit? this.props.dataInit.isColumnist :false,
        imagePath: this.props.dataInit
          ? this.props.dataInit.cont_imagePath
          : null
      });
    } else {
      this.setState({
        title: "",
        description: "",
        notas: 1,
        contId: 0,
        columnist: 0,
        imagePath: null
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.dataInit != null) {
      this.setState({
        isUpdating: true,
        contId:
          this.state.contId !== 0
            ? this.state.contId
            : newProps.dataInit.cont_id,
        title:
          this.state.title !== ""
            ? this.state.title
            : newProps.dataInit.cont_name,
        description:
          this.state.description !== "" || this.state.description !== null
            ? this.state.description
            : newProps.dataInit.cont_description,
        notas:
          this.state.notas !== 1
            ? this.state.notas
            : newProps.dataInit.cont_notas,
        columnist:
          this.state.columnist !== 0
            ? this.state.columnist
            : newProps.dataInit.columnist_id,
        isColumnist:
          this.state.isColumnist !== false
            ? this.state.isColumnist
            : newProps.isColumnist,
        imagePath:
          this.state.imagePath !== null
            ? this.state.imagePath
            : newProps.dataInit.cont_imagePath
      });
    } else {
      if (
        newProps.isOpen &&
        newProps.isColumnist /*`${newProps.secName}`.toLowerCase().trim() =="editoriales y columnas relevantes"*/
      ) {
        this.setState({ notas: 1 });
      }
      if (newProps.isOpen) {
        this.setState({ isColumnist: newProps.isColumnist });
      }
    }

    if (!newProps.isOpen) {
      this._cleanData(false);
      this.setState({
        isUpdating: false
      });
    }
  }

  addDefaultSrc(ev) {
    ev.target.src = "";
  }

  handleChangeCheck() {
    this.state.isColumnist
      ? this.setState({ isColumnist: false })
      : this.setState({ isColumnist: true });
    this.props.onClean(true);
    setTimeout(() => {
      this._cleanData();
    }, 500);
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      errorMessage,
      addAttachment,
      attachment
    } = this.props;
    let dropzoneAttachment;
    const acceptedAttachment = [
      "video/mp4",
      "application/x-mpegURL",
      "video/MP2T",
      "video/3gpp",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-ms-wmv",
      "image/ief",
      "image/jpeg",
      "image/bmp",
      "image/gif",
      "image/png",
      "image/svg+xml",
      "audio/basic",
      "auido/L24",
      "audio/mpeg",
      "audio/mp4",
      "audio/mp3",
      "audio/vnd.wav",
      "application/pdf"
    ];
    const header = this.renderHeader();
    return (
      <div>
        <form
          onSubmit={event => {
            event.preventDefault();
            var tempState = this.state;
            var titulo = tempState.title.split("'");
            var descripcion = tempState.description ? tempState.description.split("'") : [``];
            if (titulo) {
              var tempTitulo = "";
              titulo.forEach(item =>
                item === ""
                  ? (tempTitulo = tempTitulo + item + "''")
                  : (tempTitulo = tempTitulo + item)
              );
              tempState.title = tempTitulo;
            }
            if (descripcion) {
              var tempDescription = "";
              descripcion.forEach(item =>
                item === `` ? (tempDescription = `${tempDescription}${item} `) : (tempDescription = `${tempDescription}${item}`)
              );
              tempState.description = tempDescription;
            }
            handleSubmit(event, tempState);
            this.props.onClean();
            setTimeout(() => {
              this._cleanData();
            }, 500);
          }}
        >
          <section className={classes.bodyForm}>
            <div>
              <Typography variant="button" className={classes.textCenter}>
                {errorMessage}
              </Typography>
              <div>
                <InputLabel htmlFor="title">Título *</InputLabel>
                <Input
                  id="title"
                  name="title"
                  value={this.state.title}
                  type="text"
                  autoComplete="off"
                  required
                  className={classes.field}
                  startAdornment={
                    <InputAdornment position="start">
                      <PermContactCalendar />
                    </InputAdornment>
                  }
                  onChange={event => this.handleChange(event, charTitleLength)}
                />
              </div>
              {this.state.title.length === charTitleLength && (<div>Has llegado al límite de caracteres ({charTitleLength})</div>)}
              <div>
                <InputLabel htmlFor="notas">Descripción</InputLabel>
                <Editor
                  headerTemplate={header}
                  style={{ height: "100px" }}
                  value={this.state.description}
                  onTextChange={event => this.handleTextEditor(event)}
                />
                {this.state.textLimited > charDescriptionLength && (
                  <div>
                    Has llegado al límite de caracteres ({this.state.textLimited}
                    )
                  </div>
                )}
              </div>
              <div>
                <InputLabel htmlFor="notas">Notas</InputLabel>
                <Input
                  id="notas"
                  name="notas"
                  value={this.state.notas}
                  type="text"
                  autoComplete="off"
                  className={classes.field}
                  startAdornment={
                    <InputAdornment position="start">
                      <InsertDriveFile />
                    </InputAdornment>
                  }
                  pattern="[0-9]*"
                  onChange={event => this.handleChange(event, 4)}
                />
              </div>
              <div>
                <Dropzone
                  className={classes.hiddenDropzone}
                  accept={acceptedAttachment.join(",")}
                  acceptClassName="stripes"
                  onDrop={addAttachment}
                  // maxSize={fileSizeLimit}
                  ref={node => {
                    dropzoneAttachment = node;
                  }}
                />
                <div className={classes.wraperUploadFile}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={() => {
                      dropzoneAttachment.open();
                    }}
                  >
                    Subir Archivos
                  </Button>
                  {attachment || (this.props.dataInit && this.props.dataInit.cont_attachment) ? <CheckCircle /> : null}
                  {attachment ? <small title={attachment.name}>{attachment.name}</small> : this.props.dataInit && <small title={this.props.dataInit.cont_attachment}>{this.props.dataInit.cont_attachment}</small>}

                </div>
              </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button
              variant="raised"
              color="primary"
              type="submit"
              disabled={
                (this.state.title != "" && (this.state.textLimited <= charDescriptionLength))
                  ? false
                  : true
              }
            >
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this.props.onClean();
                setTimeout(() => {
                  this._cleanData(true);
                }, 500);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddNewRelevanteForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  dataInit: PropTypes.object,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(AddNewRelevanteForm);
