import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './portada-jss';
import { S3Image } from 'aws-amplify-react';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete'
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
class Portada extends React.Component {
  render() {
    const {
      classes,
      image,
      edit,
      remove,
      canEdit, 
      canDelete,
      attachment,
      moverDerecha,
      moverIzquierda,
      canMove
    } = this.props;
    return (
            <Paper elevation={0}>
                <div className={classes.portadas}>
                  {/* <div className={classes.portadaHov}>
                    <IconButton aria-label="Favoritos" className={classes.btnContrast}>
                      <FavoriteBorder />
                    </IconButton>
                    <IconButton aria-label="Más tarde" className={classes.btnContrast}>
                      <Schedule />
                    </IconButton>
                  </div> */}
                  <div className={classes.portadaS3}>
                    <a target="_blank" href={`/multimedia/${encodeURIComponent(attachment)}`}>
                      <S3Image imgKey={image} /> 
                    </a>
                  </div>
                  {canMove && (
                    <div className={classes.adminActionsTop}>
                      <Tooltip title="Mover Izquierda">
                        <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>moverIzquierda()}>
                          <ChevronLeft />
                        </Button>   
                      </Tooltip>
                      <Tooltip title="Mover Derecha">
                        <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>moverDerecha()}>
                          <ChevronRight />
                        </Button>   
                      </Tooltip>
                  </div>
                  )}
                  
                  <div className={classes.adminActions}>
                    <Tooltip title="Editar">
                      <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>edit()}>
                        <Edit />
                      </Button>   
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <Button variant="fab" mini disabled={!canDelete} aria-label="add" className={classes.button} onClick={()=>remove()}>
                        <Delete />
                      </Button>
                    </Tooltip>
                    </div>
                </div>
            </Paper>
    );
  }
}

Portada.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  image: PropTypes.string,
  edit: PropTypes.func,
  remove: PropTypes.func,
  canEdit:PropTypes.bool,
  canDelete:PropTypes.bool
};

export default withStyles(styles)(Portada);
