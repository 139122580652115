import React from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import brand from "../../../../utils/brand";
import MUIDataTable from "mui-datatables";
import PapperBlock from "../../../../components/PapperBlock/PapperBlock";
import Paper from "@material-ui/core/Paper";
import styles from "./../reportes-jss";
import { fetchCall } from "../../../../service/service_base";
//Filtros
import { renderInput, getSuggestions, renderSuggestion } from "./../autocomplete";
import Downshift from "downshift";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { ThemeContext } from "../../../../utils/ThemeContext";
import LoaderFull from "../../../../components/Loader/LoaderFull";
import ls from 'local-storage';
import Modal from '@material-ui/core/Modal';
import XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";

class BitacoraMovimientos extends React.Component {

  state = {
    canExport: false,
    export: null,
    isLoading: true,
    //filters
    fechaInicio: new Date(),
    fechaFin: null,
    profiles: [{ name: "" }],
    accion: "0",
    user: "",
    employee: "0",
    //Table
    columns: [
      {
        name: "Fecha",
        options: {
          filter: false,
          viewColumns: false,
          selectableRows: false,
          selectableRowsOnClick: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const dt = value.toISOString().split('T');
            return (
              <label>{dt[0].split('-')[2]}/{dt[0].split('-')[1]}/{dt[0].split('-')[0]} {dt[1].split('.')[0]}</label>
            );
          }
        }
      },
      "Usuario",
      "Empleado",
      {
        name: "Acción",
        options: {
          filter: true,
          filterType: "multiselect",
          sort: false
        }
      },
      {
        name: "Descripción",
        options: { filter: false, viewColumns: false, selectableRows: false }
      },
      {
        name: "Estatus",
        options: { filter: false, viewColumns: false, selectableRows: false }
      },
      {
        name: "Perfil",
        oprtions: { filter: false, viewColumns: false, selectableRows: false }
      }
    ],
    data: [],
    // formatDescarga: "0",
    // DescargaFor: [
    //   { value: "0", name: "CSV" },
    //   { value: "1", name: "PDF" }
    // ],
    listOfActions: [
      { value: "0", name: "Todos" },
      {
        value: "Actualización de privilegios",
        name: "Actualización de privilegios"
      },
      { value: "Bloqueo de Usuarios", name: "Bloqueo de Usuarios" },
      { value: "Cambio de Perfil", name: "Cambio de Perfil" },
      { value: "Desbloqueo de Usuarios", name: "Desbloqueo de Usuarios" },
      { value: "Edición de Perfil", name: "Edición de Perfil" },
      { value: "Eliminación de Contacto", name: " Eliminación de Contacto" },
      { value: "Eliminación de Perfil", name: "Eliminación de Perfil" },
      {
        value: "Envío de síntesis por correo",
        name: "Envío de síntesis por correo"
      },
      { value: "Inicio de sesión", name: "Inicio de sesión" },
      { value: "Nuevo Contacto", name: "Nuevo Contacto" },
      { value: "Nuevo Perfil", name: "Nuevo Perfil" },
      { value: "Nuevo Usuario", name: "Nuevo Usuario" },
      { value: "Publicación de síntesis", name: "Publicación de síntesis" }
    ],
    //Autocomplete
    users: [],
    actions: [],
    actionsTranslated: []
  };

  //DATES
  handleDateChange = date => {
    if (date) this.setState({ fechaInicio: new Date(date) });
    else this.setState({ fechaInicio: new Date() });
  };

  handleDateChangeFin = date => {
    if (date) this.setState({ fechaFin: new Date(date) });
    else this.setState({ fechaFin: null });
  };

  //Innputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStateChange = changes => {
    if (changes.hasOwnProperty("selectedItem")) {
      this.setState({ user: changes.selectedItem });
    } else if (changes.hasOwnProperty("inputValue")) {
      this.setState({ user: changes.inputValue });
    }
  };

  async componentDidMount() {
    this.context.toggleTheme(undefined);
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
    if (permissionsCurrentUser[0] != null) {
      let add = permissionsCurrentUser.find(x => x.name === "Bitácora de movimientos");

      if (add !== undefined) {
        let currentPermissions = ls.get("permissions");
        let data = currentPermissions && currentPermissions.find(x => x.menu_id == add.id);
        if (data != undefined) {
          this.setState({
            canExport: JSON.parse(data.grant_configuration)["chkExportReport"]
          });
          this._getProfileList();
          await this._getLog();
        }
      }
    }

  }

  _getProfileList = () => {
    fetchCall("GET", "/profile/getList").then(response => {
      this.setState({
        profiles: response.data
      })
    }).catch(err => console.log(err));
  }

  _getLog = () => {
    this.setState({ isLoading: true });
    fetchCall("POST", "/report/getlogmovements", {
      startDate:
        this.state.fechaInicio == null
          ? ""
          : this.state.fechaInicio.getFullYear() +
          "-" +
          (this.state.fechaInicio.getMonth() + 1) +
          "-" +
          this.state.fechaInicio.getDate(),
      endDate:
        this.state.fechaFin == null
          ? ""
          : this.state.fechaFin.getFullYear() +
          "-" +
          (this.state.fechaFin.getMonth() + 1) +
          "-" +
          this.state.fechaFin.getDate(),
      action: this.state.accion == null ? "" : this.state.accion,
      user: this.state.user == null ? "" : this.state.user,
      employee : this.state.employee==null? "" : this.state.employee
    })
      .then(response => {
        this.setState({
          data: response.data,
          isLoading: false
        });
        this._getUsersList();
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  _getUsersList = () => {
    fetchCall("GET", "/user/getuserlist")
      .then(response => {
        this.setState({
          users: response.data
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  handleClearTexField = () => {
    this.setState(
      {
        fechaInicio: new Date(),
        fechaFin: null,
        accion: "0",
        user: ""
      },
      () => this._getLog()
    );
  };

  handleDownload = () => {
    const dataCsv = [["Fecha", "Usuario", "Empleado", "Acción", "Descripción", "Estatus", "Perfil"]];
    let obj = {};
    this.state.export.map(item => {
      const date = new Date(item.data[0]);
      obj = [
        date.toLocaleDateString() +
        " " +
        date
          .toISOString()
          .split("T")[1]
          .split(".")[0],
        item.data[1],
        item.data[2],
        item.data[3],
        item.data[4],
        item.data[5],
        item.data[6]
      ];
      dataCsv.push(obj);
    });

    const ws = XLSX.utils.aoa_to_sheet(dataCsv);
    const wb = XLSX.utils.book_new();
    let wscols = [
      { wch: 18 },
      { wch: 25 },
      { wch: 12 },
      { wch: 15 },
      { wch: 45 },
      { wch: 12 },
      { wch: 12 }
    ];
    let wsrows = [
      { hpt: 35 }
    ];
    ws['!rows'] = wsrows;
    ws['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, "Bitácora de movimientos");
    XLSX.writeFile(wb, `portalsintesisadmon_bitacoramovimientos.xlsx`);

    // const download = document.createElement("a");
    // document.body.appendChild(download); //AHDA 23/05/2019
    // const blob = new Blob(["\ufeff", dataCsv.join("\r\n")], {
    //   type:
    //     "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    // });
    // download.href = window.URL.createObjectURL(blob);
    // download.download = "síntesisejecutiva_bitácoramovimientos.csv";
    // download.click();
    this.setState({ export: null })
  };

  _statusName = (status) => {
    switch (status) {
      case 1:
        return 'Activado'
      case 2:
        return 'Bloqueado'
      default:
        break;
    }
  }

  _profileName = (currentProfile) => {

    const profile = this.state.profiles.find(x => x.profileId === currentProfile)

    return profile ? profile.name : "--";
  }

  exportarPDF = () => {
    let obj = {};
    const doc = new jsPDF({
      orientation: "landscape"
    });
    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: [23, 74, 124] }
    }, doc);
    const dataCsv = [];

    this.state.export.map(item => {
      const date = new Date(item.data[0]);
      obj = [
        date.toLocaleDateString() +
        " " +
        date
          .toISOString()
          .split("T")[1]
          .split(".")[0],
        item.data[1],
        item.data[2],
        item.data[3],
        item.data[4],
        item.data[5],
        item.data[6]
      ];
      dataCsv.push(obj);
    });
    doc.autoTable({
      head: [["Fecha", "Usuario", "Empleado", "Acción", "Descripción", "Estatus", "Perfil"]],
      body: dataCsv
    });
    doc.save("portalsintesisadmon_bitacoramovimientos.pdf");
    this.setState({ export: null })
  };

  modal = (data) => {
    this.setState({ export: data })
  }

  render() {
    const { classes } = this.props;
    const title = brand.name + " - Política y Negocios";
    const description = brand.desc;
    const { columns, data } = this.state;

    const options = {
      filterType: "dropdown",
      responsive: "scroll",
      rowsPerPage: 10,
      page: 0,
      print: false,
      download: this.state.canExport,
      downloadOptions: {
        filename: "síntesisejecutiva_bitácoramovimientos.csv",
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true
        }
      },
      onDownload: (buildHead, buildBody, columns, rows) => {
        this.modal(rows)
        //this.exportarPDF(rows);
        return false;
        // return (
        //   buildHead(columns) +
        //   buildBody(
        //     rows.map((row, index) => ({
        //       index,
        //       data: row.data.map(data =>
        //         typeof data === "object" ? data.alias : data
        //       )
        //     }))
        //   )
        // );
      },
      //   customToolbar: () => {
      //     return (
      //       <Tooltip title="Descargar" placement="bottom">
      //         <IconButton
      //           aria-label="download"
      //           onClick={() => this.handleDownload(this.state.data)}
      //         >
      //           <CloudDownload />
      //         </IconButton>
      //       </Tooltip>
      //     );
      //   },
      viewColumns: false,
      selectableRows: false,
      selectableRowsOnClick: false,
      textLabels: {
        body: {
          noMatch: "No se encontraron registros",
          toolTip: "Ordenar"
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Registros por página:",
          displayRows: "de"
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar EXCEL/PDF",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar registros"
        },
        filter: {
          all: "Todo",
          title: "FILTRO",
          reset: "LIMPIAR"
        }
      }
    };

    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        {this.state.isLoading ? <LoaderFull /> : ""}
        <PapperBlock title="Bitácora de movimientos" fullTitle whiteBg desc="">
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.export !== null}
            onClose={this.state.export == null}
          >
            <div style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              overflow: 'hidden',
              maxHeight: 200,
              backgroundColor: 'white',
              textAlign: 'right',
              position: 'absolute',
              width: '90%',
              maxWidth: 200,
              outline: 'none',
              borderRadius: 5,
              padding: '0 0 1rem'
            }}>
              <Button onClick={() => this.setState({ export: null })} color="primary">Cerrar</Button>
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: '1rem',fontSize: "larger" }}>Descargar archivo</p>
                <Button onClick={this.handleDownload} style={{ color: 'mediumseagreen' }}>
                  EXCEL
                </Button>
                <Button onClick={this.exportarPDF} style={{ color: 'red' }} >
                  PDF
                </Button>
              </div>
            </div>
          </Modal>
          {/* Inicio Filtros */}
          <form className={classes.container}>
            <Grid
              container
              spacing={24}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={6} sm={3} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    keyboard={false}
                    helperText="Fecha inicial"
                    className={classes.formControl}
                    format="DD/MM/YYYY"
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    value={this.state.fechaInicio}
                    onChange={this.handleDateChange}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    disableFuture={true}
                    clearable={true}
                    cancelLabel="Cancelar"
                    clearLabel="Limpiar"
                    placeholder="DD/MM/AAAA"
                    onE
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    keyboard={false}
                    helperText="Fecha Final"
                    className={classes.formControl}
                    format="DD/MM/YYYY"
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    value={this.state.fechaFin}
                    onChange={this.handleDateChangeFin}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    disableFuture={true}
                    clearable={true}
                    minDate={this.state.fechaInicio}
                    disabled={this.state.fechaInicio == null ? true : false}
                    cancelLabel="Cancelar"
                    clearLabel="Limpiar"
                    placeholder="DD/MM/AAAA"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <div className={classes.root}>
                  <Downshift
                    selectedItem={this.state.user}
                    onStateChange={this.handleStateChange}
                  >
                    {({
                      getInputProps,
                      getItemProps,
                      isOpen,
                      inputValue,
                      selectedItem,
                      highlightedIndex
                    }) => (
                        <div className={classes.formControl}>
                          {renderInput({
                            fullWidth: true,
                            classes,
                            helperText: "Usuario",
                            InputProps: getInputProps({
                              placeholder: "Usuario",
                              id: "user",
                              name: "user"
                            })
                          })}
                          {isOpen ? (
                            <Paper className={classes.paper} square>
                              {getSuggestions(inputValue, this.state.users).map(
                                (suggestion, index) =>
                                  renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({
                                      item: suggestion.email
                                    }),
                                    highlightedIndex,
                                    selectedItem
                                  })
                              )}
                            </Paper>
                          ) : null}
                        </div>
                      )}
                  </Downshift>
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    value={this.state.accion}
                    onChange={this.handleChange}
                    name="accion"
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    {this.state.listOfActions.map((item, index) => {
                      return (
                        <MenuItem key={index.toString()} value={item.value}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>Acciones</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    value={this.state.employee}
                    onChange={this.handleChange}
                    name="employee"
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    <MenuItem key={1} value="0">
                      Todos
                    </MenuItem>
                    <MenuItem key={2} value="Interno">
                      Interno
                    </MenuItem>
                    <MenuItem key={3} value="Externo">
                      Externo
                    </MenuItem>
                  </Select>
                  <FormHelperText>Empleado</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}
                className={classes.centerOnlyXs}
              >
                <Button
                  onClick={this._getLog}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                >
                  Buscar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={this.handleClearTexField}
                >
                  Limpiar
                </Button>
              </Grid>
            </Grid>
          </form>
          {/* Fin Filtros */}
          <div>
            <MUIDataTable
              title={`${data.length} registros encontrados`}
              data={data.map(item => {
                return [
                  new Date(item.date),
                  item.user,
                  item.employeeid,
                  item.action,
                  item.descr,
                  this._statusName(item.status),
                  this._profileName(item.profile) ? this._profileName(item.profile) : "",
                ];
              })}
              columns={columns}
              options={options}
            />
          </div>
        </PapperBlock>

      </div>
    );

  }

}

BitacoraMovimientos.contextType = ThemeContext;

export default withStyles(styles)(BitacoraMovimientos);
