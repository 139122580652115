const styles = theme => ({ 
    container: { 
        display: 'flex', 
        flexWrap: 'wrap', 
        margin: theme.spacing.unit * 2, 
    }, 
    formControl: { 
        width: '100%', 
        margin: theme.spacing.unit, 
        minWidth: 120, 
        '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */ 
            color: 'rgba(0,0,0,0.5) !important' 
        }, 
        '& ::-moz-placeholder': { /* Firefox 19+ */ 
            color: 'rgba(0,0,0,0.5) !important' 
        }, 
        '& :-ms-input-placeholder': { /* IE 10+ */ 
            color: 'rgba(0,0,0,0.5) !important' 
        }, 
        '& :-moz-placeholder': { /* Firefox 18- */ 
            color: 'rgba(0,0,0,0.5) !important' 
        }, 
        [theme.breakpoints.down('sm')]: { 
            minWidth: 90, 
            margin: 0, 
            '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */ 
                fontSize: 14 
            }, 
            '& ::-moz-placeholder': { /* Firefox 19+ */ 
                fontSize: 14 
            }, 
            '& :-ms-input-placeholder': { /* IE 10+ */ 
                fontSize: 14 
            }, 
            '& :-moz-placeholder': { /* Firefox 18- */ 
                fontSize: 14 
            } 
        }, 
    }, 
    textField: { 
        margin: theme.spacing.unit, 
        width: 180, 
    }, 
    button: { 
        margin: theme.spacing.unit, 
        // maxHeight: 30 
    }, 
    botonFlotante: { 
        position: 'absolute', 
        top: theme.spacing.unit * 8, 
        right: 0, 
        marginRight: theme.spacing.unit * 4, 
    }, 
    leftIcon: { 
        marginRight: theme.spacing.unit, 
    }, 
    rightIcon: { 
        marginLeft: theme.spacing.unit, 
    }, 
    iconSmall: { 
        fontSize: 20, 
    }, 
    appBar: { 
        position: 'relative', 
    }, 
    flex: { 
        flex: 1, 
    }, 
    paper: { 
        position: 'absolute', 
        zIndex: 200 
    }, 
    alignRight: { 
        textAlign: 'right', 
        marginBottom: '1rem', 
    }, 
    centerOnlyXs: { 
        [theme.breakpoints.down('xs')]: { 
            textAlign: 'center !important', 
        }, 
    }, 
    centerOnlySmall: { 
        [theme.breakpoints.down('sm')]: { 
            textAlign: 'center !important', 
        }, 
    }, 
    btnFullOnlySmall: { 
        [theme.breakpoints.down('sm')]: { 
            width: '100% !important', 
            margin: '0 !important' 
        }, 
    }, 
    nowrap: { 
        whiteSpace: 'nowrap' 
    }, 
    cursorPointer: { 
        cursor: 'pointer', 
    },
    // MODAL
  paperModal: {
    position: 'absolute',
    width: "50%",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing.unit * 4,
  },
  bgTitle: {
    backgroundColor: theme.palette.primary.dark,
    height: 50,
    padding: '2px 15px',
    color: '#fff',
    '& h6': {
      display: 'inline-block',
      paddingTop: 10
    }
  },
  flexHead: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  colorTitle: {
    color: "white",
    lineHeight: 'normal'
  },
  bgContenido: {
    padding: 15,
    maxHeight: 500,
    overflow: 'auto',
  },
  titleContenido: {
    color: '#4a4949',
    fontSize: 12,
    fontWeight: 'bold',
  },
  textContenido: {
    color: '#7d7d7d',
    fontSize: 12,
    textAlign: 'justify',
  },
  field: {
    width: '100%',
    marginBottom: 0,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    }
  },
  alignButtons: {
    textAlign: 'right',
    marginBottom: 15,
  },
  cajaBtn: {
    padding: 6
},
}); 
 
export default styles; 
