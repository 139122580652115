
const styles = theme => ({
  portadas: {
    width: 290,
    height: 70,
    maxHeight: 70,
    position: 'relative',
  },
  relative: {
    position: 'relative'
  },
  portadaS3: {
    width: 290,
    maxHeight: 36,
    borderRadius: 0,
    overflow: 'hidden',
    '& div': {
      width: '100%',
      height: '100%',
    },
    '& img': {
      width: '100%',
      height: 36,
      maxHeight: 36,
    }
  },
  portadaHov: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.5)',
    borderRadius: 4,
    zIndex: 1,
    opacity: 0,
    transition: 'ease-in .2s',
    '&:hover': {
      opacity: 1
    }
  },
  btnContrast:{
    color: "#cacaca"
  },
  btnActive: {
    color: '#ffffff'
  },
  adminActions: {
    position: 'absolute',
    width: 100,
    bottom: 0,
    textAlign: 'right',
    right: 5,
    zIndex: 10,
    '& button': {
      width: 30,
      height: 30,
      minHeight: 30,
      marginLeft: 5,
      backgroundColor: '#fff',
      // border
    },
    '& svg': {
      fontSize: 18,
      color: theme.palette.primary.main,
    }
  },
  adminActionsTop: {
    position: 'absolute',
    width: 100,
    textAlign: 'left',
    bottom: 0,
    // right: 5,
    zIndex: 10,
    '& button': {
      width: 30,
      height: 30,
      minHeight: 30,
      marginLeft: 5,
      backgroundColor: '#fff',
      // border
    },
    '& svg': {
      fontSize: 18,
      color: theme.palette.primary.main,
    }
  },
});

export default styles;
