import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//iconos
import Settings from '@material-ui/icons/Settings';
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode';
import Restore from '@material-ui/icons/Restore'; 
import ViewColumn from '@material-ui/icons/ViewColumn';
import Modal from '@material-ui/core/Modal';
//Taps
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ls from 'local-storage';
import LoaderFull from '../../../../components/Loader/LoaderFull';

function getModalStyle() {
    return {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }

const VerticalTabs = withStyles(theme => ({
    flexContainer: {
      flexDirection: "column",
      maxHeight: 350,
      overflow: 'auto',
      '& button':{
        textAlign: 'left',
        minHeight: 50,
        maxWidth: 350,
        '& span':{
          flexDirection: 'row',
          justifyContent: 'flex-start',
        },
      },
    },
    indicator: {
      display: "none"
    },
    noPB: {
      paddingBottom: '0 !important'
    }
  }))(Tabs);
  
  const MyTab = withStyles(theme => ({
    selected: {
      color: theme.palette.secondary.main,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
      padding: '0 5px',
      background: '#ededed',
    }
  }))(Tab);
  
  function TabContainer(props) {
    return (
      <Typography component="div" style={{ paddingLeft: 8 * 2 }}>
        {props.children}
      </Typography>
    );
  }
  // fin Taps

const JSON_BASE_SYNTHESIS = `{"chkViewSynthesis":false,"chkEditSynthesis":false,"chkPublishSynthesis":false,"chkDeleteSynthesis":false}`;
const JSON_BASE_CONTACT = `{"chkViewContact":false,"chkEditContact":false,"chkPublishContact":false,"chkDeleteContact":false}`;
const JSON_BASE_COLUMNIST = `{"chkViewColumnist":false,"chkEditColumnist":false}`;
const JSON_BASE_KICKER = `{"chkViewKicker":false, "chkEditKicker":false}`;
const JSON_BASE_NEWSPAPER = `{"chkViewNewspaper":false, "chkEditNewspaper":false}`;
const JSON_BASE_RADIOSTATION = `{"chkViewRadiostation":false, "chkEditRadiostation":false}`;
const JSON_BASE_CATALOG = `{"chkViewCatalog":false}`;
const JSON_BASE_EDITORIAL = `{"chkViewEditorial":false,"chkEditEditorial":false}`;
const JSON_BASE_HIST_NEW = `{"chkViewHistory":false}`;
const JSON_BASE_ADMIN_CONT = `{"chkViewAdminCont":false,"chkEditAdminCont":false,"chkPublishAdminCont":false,"chkDeleteAdminCont":false}`;
const JSON_BASE_ADMIN_USER = `{"chkViewAdminUser":false,"chkEditAdminUser":false,"chkBlockAdminUser":false,"chkUnblockAdminUser":false}`;
const JSON_BASE_ADMIN_PROFILE = `{"chkViewAdminProfile":false,"chkEditAdminProfile":false,"chkPublishAdminProfile":false,"chkDeleteAdminProfile":false,"chkViewPermissions":false,"chkEditPermissions":false}`;
const JSON_BASE_SEND_EMAIL = `{"chkViewSendEmail":false,"chkEditSendMail":false,"chkPublishSendMail":false,"chkDeleteSendEmail":false,"chkSendEmail":false}`;
const JSON_BASE_REPORT = `{"chkViewReport":false,"chkExportReport":false,"chkPrintReport":false}`;

const BASE_SYNTHESIS = `{"name": "Ver","attr":"chkViewSynthesis"},
                        {"name": "Editar","attr":"chkEditSynthesis"},
                        {"name": "Publicar","attr":"chkPublishSynthesis"},
                        {"name": "Eliminar","attr":"chkDeleteSynthesis"}`;
const BASE_CONTACT = `{"name": "Ver","attr":"chkViewContact"},
                      {"name": "Editar","attr":"chkEditContact"},
                      {"name": "Crear","attr":"chkPublishContact"},
                      {"name": "Eliminar","attr":"chkDeleteContact"}`;
const BASE_COLUMNIST = `{"name": "Ver","attr":"chkViewColumnist"},
                        {"name": "Editar","attr":"chkEditColumnist"}`;
const BASE_KICKER = `{"name": "Ver","attr":"chkViewKicker"},
                      {"name": "Editar","attr":"chkEditKicker"}`;
const BASE_NEWSPAPER = `{"name": "Ver","attr":"chkViewNewspaper"},
                        {"name": "Editar","attr":"chkEditNewspaper"}`;
const BASE_RADIOSTATION = `{"name": "Ver","attr":"chkViewRadiostation"},
                          {"name": "Editar","attr":"chkEditRadiostation"}`;
const BASE_CATALOG = `{"name": "Ver", "attr":"chkViewCatalog"}`;
const BASE_EDITORIAL = `{"name": "Ver", "attr":"chkViewEditorial"},
                        {"name": "Editar","attr":"chkEditEditorial"}`;
const BASE_HIST_NEW = `{"name": "Ver","attr":"chkViewHistory"}`;
const BASE_ADMIN_CONT = `{"name": "Crear","attr":"chkPublishAdminCont"}`;                         
const BASE_ADMIN_USER = `{"name": "Ver","attr":"chkViewAdminUser"},
                         {"name": "Editar","attr":"chkEditAdminUser"},
                         {"name": "Bloquear","attr":"chkBlockAdminUser"},
                         {"name": "Desbloquear","attr":"chkUnblockAdminUser"}`;
const BASE_ADMIN_PROFILE = `{"name": "Ver","attr":"chkViewAdminProfile"},
                            {"name": "Editar","attr":"chkEditAdminProfile"},
                            {"name": "Crear","attr":"chkPublishAdminProfile"},
                            {"name": "Eliminar","attr":"chkDeleteAdminProfile"},
                            {"name": "Ver Privilegios","attr":"chkViewPermissions"},
                            {"name": "Editar Privilegios","attr":"chkEditPermissions"}`;
const BASE_SEND_EMAIL = `{"name": "Ver","attr":"chkViewSendEmail"},
                         {"name": "Editar","attr":"chkEditSendMail"},
                         {"name": "Crear","attr":"chkPublishSendMail"},
                         {"name": "Eliminar","attr":"chkDeleteSendEmail"},
                         {"name": "Enviar correo","attr":"chkSendEmail"}`;
const BASE_REPORT = `{"name": "Ver","attr":"chkViewReport"},{"name": "Exportar","attr":"chkExportReport"}`;
class ModalPermissions extends React.Component {

  constructor(props) {
    super(props);
    this.handleShowPermissions = this.handleShowPermissions.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
  }

  state = {
    activeIndex: null,
    checkedAll: false,
    menuSynthesis : ls.get("session_menu"),
    optionsSelected :[],
    optionsMenu:[],
    menuSelected:0,
    newConfiguration:[],
    profileId :0,
    onlyView : false,
    isl:false,
    isLoading:false,
    isSynthesis:false,
    isHistNew:false,
    isAdminCont:false,
    isAdminUser:false,
    isAdminProfile:false,
    isSendEmail:false,
    isReport:false,
    isContact:false,
    chkBlockAdminUser: false,
    chkDeleteAdminCont: false,
    chkDeleteAdminProfile: false,
    chkDeleteContact: false,
    chkDeleteSendEmail: false,
    chkDeleteSynthesis: false,
    chkEditAdminCont: false,
    chkEditAdminProfile: false,
    chkEditAdminUser: false,
    chkEditContact: false,
    chkEditPermissions: false,
    chkEditSendMail: false,
    chkEditSynthesis: false,
    chkExportReport: false,
    chkPrintReport: false,
    chkPublishAdminCont: false,
    chkPublishAdminProfile: false,
    chkPublishContact: false,
    chkPublishSendMail: false,
    chkPublishSynthesis: false,
    chkSendEmail: false,
    chkUnblockAdminUser: false,
    chkViewAdminCont: false,
    chkViewAdminProfile: false,
    chkViewAdminUser: false,
    chkViewContact: false,
    chkViewHistory: false,
    chkViewPermissions: false,
    chkViewReport: false,
    chkViewSendEmail: false,
    chkViewSynthesis: false,
    chkViewColumnist:false,
    chkViewKicker:false,
    chkEditKicker:false,
    chkViewNewspaper:false,
    chkEditNewspaper:false,
    chkViewRadiostation:false,
    chkEditRadiostation:false,
    chkViewCatalog:false,
    chkViewEditorial:false,
    chkEditEditorial:false,
    chkEditColumnist:false,

    renderInput : []
  }

  _clearView = () =>{
    this.setState({
      isSynthesis : false,
      isHistNew : false,
      isAdminCont : false,
      isAdminUser:false,
      isAdminProfile:false,
      isSendEmail:false,
      isReport:false
    });
  }

  componentWillReceiveProps(newProps){
    if(newProps.openModalPermission && newProps.profileSelected){      
      this.setState({
        optionsMenu : newProps.optionsMenu,
        profileId:newProps.profileSelected.profileId,
        onlyView:newProps.onlyView,
        activeIndex :0
      }, () => {
        this.handleShowPermissions(3,"Creación de contenido");
      });
    }
  }

  handleChange = (_, activeIndex) => {
    this.setState({ activeIndex });
  };

  handleChangeChecked = name => event => {
     
    if(!this.state.onlyView) {
      let newConfiguration = new Array();
      let grantId = 0;

      this.state.optionsMenu.forEach(element => {
        if(parseInt(element.menu_id) !== parseInt(this.state.menuSelected)){
          grantId = element.grant_id;
          newConfiguration.push(element);
        }
      });
      
      let config={};

      let newMenu ={
        grant_id:grantId,
        menu_id : this.state.menuSelected,
        grant_configuration:""
      }
      if(name === "checkedAll"){
        
        switch (this.state.menuSelected) {
          case 1 || "Configuración/Administración de usuarios": 
            config = {
              chkViewAdminUser: event.target.checked,
              chkEditAdminUser:  event.target.checked,
              chkBlockAdminUser:event.target.checked,
              chkUnblockAdminUser: event.target.checked,
              [name]: event.target.checked
            }
          break;
          case 2 || "Configuración/Administración de perfiles" : 
              config = {
                chkViewAdminProfile:event.target.checked,
                chkEditAdminProfile:  event.target.checked,
                chkPublishAdminProfile:event.target.checked,
                chkDeleteAdminProfile: event.target.checked,
                chkViewPermissions:event.target.checked,
                chkEditPermissions: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 3 || "Creación de contenido": 
              config = {
                chkViewAdminCont: event.target.checked,
                chkEditAdminCont:  event.target.checked,
                chkPublishAdminCont:event.target.checked,
                chkDeleteAdminCont: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 4 || "Configuración/Contactos": 
              config = {
                chkViewContact: event.target.checked,
                chkEditContact: event.target.checked,
                chkPublishContact: event.target.checked,
                chkDeleteContact: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 5 || "Configuración/Lista de distribución": 
              config = {
                chkViewSendEmail: event.target.checked,
                chkEditSendMail: event.target.checked,
                chkPublishSendMail: event.target.checked,
                chkDeleteSendEmail: event.target.checked,
                chkSendEmail: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 6 || "Histórico de síntesis": 
              config = {
                chkViewHistory: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 7 ||  "Bitácora de movimientos": 
              config = {
                chkViewReport: event.target.checked,
                chkExportReport: event.target.checked,
                chkPrintReport: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 11: 
            config = {
              [name]: event.target.checked
            }
          break;
          case 12 || "Configuración/Catálogos/Columnistas": 
              config = {
                chkViewColumnist: event.target.checked,
                chkEditColumnist: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 13 || "Configuración/Catálogos/Kickers": 
              config = {
                chkViewKicker: event.target.checked,
                chkEditKicker: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case  14 || "Configuración/Catálogos/Periódicos": 
              config = {
                chkViewNewspaper: event.target.checked,
                chkEditNewspaper: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 15 || "Configuración/Catálogos/Radiodifusoras": 
              config = {
                chkViewRadiostation: event.target.checked,
                chkEditRadiostation: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 16 || "Configuración/Catálogos/Editoriales": 
              config = {
                chkViewEditorial: event.target.checked,
                chkEditEditorial: event.target.checked,
                [name]: event.target.checked
              }
          break;
          case 17 || "Configuración/Catalogos":
               config = {
                 chkViewCatalog: event.target.checked,
                 [name]: event.target.checked
               }
          break;
          default: 
            config = {
              chkViewSynthesis: event.target.checked,
              chkEditSynthesis: event.target.checked,
              chkPublishSynthesis: event.target.checked,
              chkDeleteSynthesis: event.target.checked,
              [name]: event.target.checked
            }
        }
        
        this.setState(config);
        console.log(config)
        newMenu.grant_configuration = JSON.stringify(config);       
        newConfiguration.push(newMenu); 
        this.setState({optionsMenu: newConfiguration});
        
      }else{      
        
        this.setState({ [name]: event.target.checked }, () =>{
          
          switch (this.state.menuSelected) {
            case 1 || "Configuración/Administración de usuarios": 
              config = {
                chkViewAdminUser: this.state.chkViewAdminUser ,
                chkEditAdminUser:  this.state.chkEditAdminUser ,
                chkBlockAdminUser: this.state.chkBlockAdminUser ,
                chkUnblockAdminUser: this.state.chkUnblockAdminUser
              }
            break;
            case 2 || "Configuración/Administración de perfiles": 
                config = {
                  chkViewAdminProfile: this.state.chkViewAdminProfile ,
                  chkEditAdminProfile:  this.state.chkEditAdminProfile ,
                  chkPublishAdminProfile: this.state.chkPublishAdminProfile ,
                  chkDeleteAdminProfile: this.state.chkDeleteAdminProfile ,
                  chkViewPermissions: this.state.chkViewPermissions ,
                  chkEditPermissions: this.state.chkEditPermissions
                }
            break;
            case 3 ||  "Creación de contenido": 
                config = {
                  chkViewAdminCont: this.state.chkViewAdminCont ,
                  chkEditAdminCont:  this.state.chkEditAdminCont ,
                  chkPublishAdminCont: this.state.chkPublishAdminCont ,
                  chkDeleteAdminCont: this.state.chkDeleteAdminCont 
                }
            break;
            case 4 ||  "Configuración/Contactos": 
                config = {
                  chkViewContact: this.state.chkViewContact ,
                  chkEditContact: this.state.chkEditContact ,
                  chkPublishContact: this.state.chkPublishContact ,
                  chkDeleteContact: this.state.chkDeleteContact
                }
            break;
            case 5 || "Configuración/Lista de distribución": 
                config = {
                  chkViewSendEmail: this.state.chkViewSendEmail ,
                  chkEditSendMail: this.state.chkEditSendMail ,
                  chkPublishSendMail: this.state.chkPublishSendMail ,
                  chkDeleteSendEmail: this.state.chkDeleteSendEmail ,
                  chkSendEmail: this.state.chkSendEmail 
                }
            break;
            case 6 || "Histórico de síntesis": 
                config = {
                  chkViewHistory: this.state.chkViewHistory 
                }
            break;
            case 7 ||  "Bitácora de movimientos": 
                config = {
                  chkViewReport: this.state.chkViewReport ,
                  chkExportReport: this.state.chkExportReport ,
                  chkPrintReport: this.state.chkPrintReport
                }
            break;
            case 11: 
              // config = {
              //   [name]: event.target.checked
              // }
            break;
            case 12 || "Configuración/Catálogos/Columnistas": 
              config = {
                chkViewColumnist: this.state.chkViewColumnist,
                chkEditColumnist: this.state.chkEditColumnist,
              }
            break;
            case 13 ||  "Configuración/Catálogos/Kickers": 
              config = {
                chkViewKicker: this.state.chkViewKicker,
                chkEditKicker: this.state.chkEditKicker,
              }
            break;
            case 14 ||  "Configuración/Catálogos/Periódicos": 
              config = {
                chkViewNewspaper: this.state.chkViewNewspaper,
                chkEditNewspaper: this.state.chkEditNewspaper,
              }
            break;
            case 15 || "Configuración/Catálogos/Radiodifusoras": 
              config = {
                chkViewRadiostation: this.state.chkViewRadiostation,
                chkEditRadiostation: this.state.chkEditRadiostation,
              }
            break;
            case 16 || "Configuración/Catálogos/Editoriales":
              config = {
                chkViewEditorial: this.state.chkViewEditorial,
                chkEditEditorial: this.state.chkEditEditorial,
              }
            break;
            case 17 || "Configuración/Catalogos":
               config = {
                 chkViewCatalog: this.state.chkViewCatalog,
               }
            break;
            default: 
              config = {
                chkViewSynthesis: this.state.chkViewSynthesis ,
                chkEditSynthesis: this.state.chkEditSynthesis ,
                chkPublishSynthesis: this.state.chkPublishSynthesis ,
                chkDeleteSynthesis: this.state.chkDeleteSynthesis
              }
          }
          newMenu.grant_configuration = JSON.stringify(config);       
          newConfiguration.push(newMenu); 
          this.setState({optionsMenu: newConfiguration});
        });
      }  
    }   
  };

  _getPermission = (menu) => {
    switch (menu) {
      case 1 || "Configuración/Administración de usuarios": return BASE_ADMIN_USER; 
      case 2 || "Configuración/Administración de perfiles": return BASE_ADMIN_PROFILE;
      case 3 || "Creación de contenido": return BASE_ADMIN_CONT; 
      case 4 || "Configuración/Contactos": return BASE_CONTACT;
      case 5 || "Configuración/Lista de distribución": return BASE_SEND_EMAIL;
      case 6 || "Histórico de síntesis": return BASE_HIST_NEW;
      case 7 || "Bitácora de movimientos": return BASE_REPORT;
      //case 11 || "Configuración/Catálogos/Columnistas": return BASE_BIT_MOV;
      case 12 || "Configuración/Catálogos/Columnistas": return BASE_COLUMNIST;
      case 13 || "Configuración/Catálogos/Kickers": return BASE_KICKER;
      case 14 || "Configuración/Catálogos/Periódicos": return BASE_NEWSPAPER;
      case 15 || "Configuración/Catálogos/Radiodifusoras": return BASE_RADIOSTATION;
      case 16 || "Configuración/Catálogos/Editoriales": return BASE_EDITORIAL;
      case 17 || "Configuración/Catalogos": return BASE_CATALOG;
      default: return BASE_SYNTHESIS;
    }
  }

  _getConfigMenu = (menu) => {
    switch (menu) {
      case 1 || "Configuración/Administración de usuarios": return JSON_BASE_ADMIN_USER;
      case 2 || "Configuración/Administración de perfiles": return JSON_BASE_ADMIN_PROFILE;
      case 3 || "Creación de contenido": return JSON_BASE_ADMIN_CONT;
      case 4 || "Configuración/Contactos": return JSON_BASE_CONTACT;
      case 5 || "Configuración/Lista de distribución": return JSON_BASE_SEND_EMAIL;
      case 6 || "Histórico de síntesis": return JSON_BASE_HIST_NEW;
      case 7 || "Bitácora de movimientos": return JSON_BASE_REPORT;
      //case 11 || "Configuración/Catálogos/Columnistas": return JSON_BASE_BIT_MOV;
      case 12 || "Configuración/Catálogos/Columnistas": return JSON_BASE_COLUMNIST;
      case 13 || "Configuración/Catálogos/Kickers": return JSON_BASE_KICKER;
      case 14 || "Configuración/Catálogos/Periódicos": return JSON_BASE_NEWSPAPER;
      case 15 || "Configuración/Catálogos/Radiodifusoras": return JSON_BASE_RADIOSTATION;
      case 16 || "Configuración/Catálogos/Editoriales": return JSON_BASE_EDITORIAL;
      case 17 || "Configuración/Catalogos": return JSON_BASE_CATALOG;
      default: return JSON_BASE_SYNTHESIS;
    }
  }

  handleShowPermissions = (menu_id,menuname) => {  
    this.setState(
      {
        checkedAll : false,
        menuSelected : menu_id,
        menuName:menuname,
        renderInput: this._getPermission(menu_id)
      }, () => {
        this.movePermissions(menu_id, this._getConfigMenu(menu_id))
      }
    );
    }
    
    
    movePermissions= (menu_id,jsonConfiguration) =>{
      console.log(jsonConfiguration)
       console.log(menu_id)
       console.log(this.state.menuSynthesis)
      let data = this.state.menuSynthesis.filter(x => x.menu_id === menu_id)[0];
      if(data){
        let modify = this.state.optionsMenu.filter(x => x.menu_id === data.menu_id)[0];
        if(modify){
          this.setState(JSON.parse(modify.grant_configuration));
        }else{
          let newMenu = {
            grant_configuration:jsonConfiguration,
            menu_id:menu_id,
            grant_id:0
          }
          this.setState(JSON.parse(newMenu.grant_configuration));
          let auxiliar = this.state.optionsMenu;
          auxiliar.push(newMenu);
          this.setState({optionsMenu :  auxiliar});
        }
      }else{
        let menu = this.state.optionsMenu.filter(m => m.menu_id === menu_id)[0];

        // menu.grant_configuration = jsonConfiguration
        console.log(menu)

        if(menu !== undefined){

          this.setState(JSON.parse(`${menu.grant_configuration}`));  
          let auxiliar = this.state.optionsMenu;
          console.log(auxiliar)
          this.setState({optionsMenu :  auxiliar});    
        }else{
          let newMenu = {
            grant_configuration:jsonConfiguration,
            menu_id:menu_id,
            grant_id:0
          }
          this.setState(JSON.parse(newMenu.grant_configuration));
          let auxiliar = this.state.optionsMenu;
          console.log(auxiliar)
          auxiliar.push(newMenu);
          this.setState({optionsMenu :  auxiliar});
        }
      }
    
  }

  saveChanges = () => {
    console.log(this.state.optionsMenu)
    this.props.handleSubmit(this.state.optionsMenu);
    this._clearView();
  }

  render() {    
    const {
        profileSelected,
        handleCloseModal,
        openModalPermission,
        classes,
    } = this.props;    
    
    
    if(profileSelected===null){
        return false;
    }
    
    return (
      <div>
        {this.state.isLoading ? <LoaderFull /> : ''}
       <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openModalPermission}
          onClose={handleCloseModal}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <div >
              <Typography className={classes.paperHeader}>
                Privilegios / {profileSelected.name}
              </Typography>
            </div>
            <div className={classes.paperBody}>
              <div
                style={{
                  display: "flex"
                }}  
              >              
              {/* CH 21/06/2019 Se cambia overflow de tabs */}
                <VerticalTabs className={classes.overflowVisible} value={this.state.activeIndex} onChange={this.handleChange}>
                  <MyTab indicatorColor="blue" key={3} label="Creación de contenido" onClick={() => this.handleShowPermissions(3,"Creación de contenido")} icon={<ChromeReaderMode />} /> 
                  <MyTab indicatorColor="blue"  key={1} label="Configuración/Administración de usuarios" onClick={() => this.handleShowPermissions(1,"Configuración/Administración de usuarios")} icon={<Settings />} /> 
                  <MyTab indicatorColor="blue"  key={2} label="Configuración/Administración de perfiles" onClick={() => this.handleShowPermissions(2,"Configuración/Administración de perfiles")} icon={<Settings />} /> 
                  <MyTab indicatorColor="blue"  key={4} label="Configuración/Contactos" onClick={() => this.handleShowPermissions(4,"Configuración/Contactos")} icon={<Settings />} /> 
                  <MyTab indicatorColor="blue"  key={5} label="Configuración/Lista de distribución" onClick={() => this.handleShowPermissions(5,"Configuración/Lista de distribución")} icon={<Settings />} /> 
                  <MyTab indicatorColor="blue"  key={6} onClick={() => this.handleShowPermissions(6,"Histórico de síntesis")} label="Histórico de síntesis" icon={<Restore />} />
                  <MyTab indicatorColor="blue"  key={7} onClick={() => this.handleShowPermissions(7,"Bitácora de movimientos")} label="Bitácora de movimientos" icon={<ViewColumn />} />
                  <MyTab indicatorColor="blue"  key={12} onClick={() => this.handleShowPermissions(12,"Configuración/Catálogos/Columnistas")} label="Configuración/Catálogos/Columnistas" icon={<Settings />} />
                  <MyTab indicatorColor="blue"  key={13} onClick={() => this.handleShowPermissions(13,"Configuración/Catálogos/Kickers")} label="Configuración/Catálogos/Kickers" icon={<Settings />} />
                  <MyTab indicatorColor="blue"  key={14} onClick={() => this.handleShowPermissions(14,"Configuración/Catálogos/Periódicos")} label="Configuración/Catálogos/Periódicos" icon={<Settings />} />
                  <MyTab indicatorColor="blue"  key={15} onClick={() => this.handleShowPermissions(15,"Configuración/Catálogos/Radiodifusoras")} label="Configuración/Catálogos/Radiodifusoras" icon={<Settings />} />
                  <MyTab indicatorColor="blue"  key={16} onClick={() => this.handleShowPermissions(16,"Configuración/Catálogos/Editoriales")} label="Configuración/Cátalogos/Editoriales" icon={<Settings/>} />
                  <MyTab indicatorColor="blue"  key={17} onClick={() => this.handleShowPermissions(17,"Configuración/Catalogos" )} label="Configuración/Catalogos" icon={<Settings/>} />       
                  {
                    this.state.menuSynthesis.map(item =>{
                      return(
                        <MyTab indicatorColor="blue"  key={item.menu_id} onClick={() => this.handleShowPermissions(item.menu_id)} label={item.menu_name} icon={<ChromeReaderMode />}/>
                      );
                    })
                  }
                </VerticalTabs>
               
                  <TabContainer>
                  {
                    this.state.renderInput.length === 0 ? '':
                    <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedAll}
                          onChange={this.handleChangeChecked('checkedAll')}
                          value="checkedAll"
                          color="primary"
                        />
                      }
                      label="Seleccionar Todo"
                    />
                  </div>
                  }
                  
                  <div>
                    <FormGroup>
                        {
                          this.state.renderInput.length === 0 ? '' :
                          JSON.parse(`[${this.state.renderInput}]`).map((item, index)=>{
                            console.log(item)
                            return (
                              <FormControlLabel
                                key={index.toString()}
                                control={
                                  <Switch
                                    checked={this.state[`${item.attr}`]}
                                    onChange={this.handleChangeChecked(item.attr)}
                                    value={item.attr}
                                    color="primary"
                                  /> 
                                }
                                label={item.name}
                              />
                            )
                          })
                        }
                    </FormGroup>
                  </div>
                </TabContainer>

             
              </div>
            </div>
            <div className={classes.paperFooter}>
              <Button onClick={()=>handleCloseModal(false)} variant="raised" color="primary" type="submit">
                Cerrar
              </Button>
              <Button variant="raised" color="primary" type="submit" disabled={this.state.onlyView} onClick={() => { this.saveChanges()}}>
                Guardar
              </Button>
            </div>
          </div>
        </Modal> 

      </div>
    );
  }
}

ModalPermissions.propTypes = {
  classes: PropTypes.object,
  openModalPermission:PropTypes.bool,
  profileSelected: PropTypes.object,
  handleCloseModal: PropTypes.func,
  optionsMenu:PropTypes.array,
  onlyView : PropTypes.bool,
  handleSubmit : PropTypes.func.isRequired
};

export default (ModalPermissions);
