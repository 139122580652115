import React from 'react';
import PropTypes from 'prop-types';
import AddNewspaperForm from './AddNewspaperForm';
import FloatingPanel from '../../../../components/Panel/FloatingPanel';

class AddNewspaper extends React.Component {

  render() {
    const { 
      openForm,
      dataInit,
      submit,
      closeForm
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title="Periódico" openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddNewspaperForm
            onSubmit={()=>{}}
            handleSubmit={(event, data)=>(submit(event, data),closeForm)}
            dataInit={dataInit}
            isOpen={openForm}
            clean={closeForm}
          />
        </FloatingPanel>
      </div>
    );
  }
}

AddNewspaper.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  addNewspaper: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object
};

export default AddNewspaper;
