const styles = theme => ({
  bannerNew: {
    position: 'relative',
    //border: '1px solid #cacaca',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    height: 180,
    margin: '0 auto 16px',
    maxWidth: '100%',
    width: 320,
  },
  contentBtnNew: {
    position: 'absolute',
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
  btnNew: {
    color: '#a7a7a7',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0 0 8px rgba(0,0,0,.15)',
      transform: 'scale(1.1)'
    },
  },
  btn: {
    display: "inline-block",
  },

  adminActions: {
    position: 'absolute',
    width: 100,
    textAlign: 'center',
    bottom: 10,
    textAlign: 'right',
    right: 5,
    zIndex: 10,
    '& button': {
      width: 30,
      height: 30,
      minHeight: 30,
      marginLeft: 5,
      backgroundColor: '#fff',
      // border
    },
    '& svg': {
      fontSize: 18,
      color: theme.palette.primary.main,
    }
  },
});

export default styles;