import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../new-jss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddNewRadio from '../../New/Radio/AddNewRadio';
import {fetchCall} from '../../../service/service_base';
import Grid from '@material-ui/core/Grid';
import NotasRadio from '../Notas/NotasRadio';
import {savePortrait} from '../../../service/s3Amplify';
import ls from 'local-storage';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Loader from '../../Loader/Loader';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';

class RadioNew extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    openAddNewNote : false,
    notes:[],
    noteSelected: null,
    isLoading: true,
    openConfirmDialog:false,
    idDeleted:0,
    openNotification:false,
    typeNotification:"info",
    messageNotification:"",
    action:'Nueva'
  }

  componentDidMount(){
    this._loadNotes();
  }

  handleCloseNotification = () =>{
    this.setState({
      openNotification: false
    })
  }

  _loadNotes = () =>{
    fetchCall("POST", "/synthesis/getcontent",{
      sectionId: this.props.section.sec_id,
      isPublish: this.props.isPublish,
      idPublish: this.props.idPublish
    }).then(response =>{
      this.setState({notes : response.data, isLoading: false});  
    }).catch(err => console.log(err));
  }

  _addNewNote = () => {
    this.setState({
      openAddNewNote : false,
      noteSelected : null,
      action : 'Nueva'
    }, () => {
      this.setState(
        {
          openAddNewNote : true
        }
      )
    })
  }

  _handleCloseAddNewNote = () => {
    this.setState({
      openAddNewNote : false,
      noteSelected:null
    })
  }
  
  _handleAddNewNote = (event, data, attachment) =>{
    event.preventDefault(); 
    this.setState(
      {
        isLoading : true
      }
    )
    
    let currentTime = new Date();    
    data.sectionId = this.props.section.sec_id;
    data.columnist = data.radiostation
    data.userId = ls.get("current_user").user_id;
    let keyS3Attachment = attachment != null ? `${this.props.synthesis}/${this.props.section.sec_id}/attachment/${attachment ? `${new Date().getTime()+"_" + attachment.name }`.replace(/[^0-9A-Z._]+/gi,"-") : ''}` :'';
    if(data.isUpdating){
      data.order=this.state.noteSelected.cont_order
      if(this.state.notes.filter(
        x => x.cont_name === data.title.toString().trim() && parseInt(x.cont_id) !== this.state.noteSelected.cont_id)[0]
      ){
        this.setState({
          openNotification:true,
          messageNotification:'Ya existe un contenido con el mismo título',
          typeNotification:"warning",
          isLoading:false
        });
        return false;
      }
      if(attachment === null){
        data.attachment = this.state.noteSelected.cont_attachment;          
          fetchCall("POST","/synthesis/updatecontent", data).then(response =>{
            if(response.status == 200){
              this.setState({
                openAddNewNote : false,
                openNotification:true,
                messageNotification:'Contenido actualizado',
                typeNotification:"success",
                isLoading:false    
              })
              this._loadNotes();
            }
          }).catch(err => err);  
      }else{
        savePortrait(keyS3Attachment, attachment, attachment.type).then(response =>{
          data.attachment = response.key;
        }).then(()=>{          
          fetchCall("POST","/synthesis/updatecontent", data).then(response =>{
            if(response.status == 200){
              this.setState({
                openAddNewNote : false,
                openNotification:true,
                messageNotification:'Contenido actualizado',
                typeNotification:"success",
                isLoading:false
              })
              this._loadNotes();
            }
          }).catch(err => err);
        
        }).catch(err => console.log("error save portrait",err));
      }
    }else{
      data.order = this.state.notes.length+1
      if(this.state.notes.filter(
        x => x.cont_name === data.title.toString().trim())[0]
      ){
        this.setState({
          openNotification:true,
          messageNotification:'Ya existe un contenido con el mismo título',
          typeNotification:"warning",
          isLoading:false
        });
        return false;
      }

      
        Promise.all([(attachment? savePortrait(keyS3Attachment, attachment, attachment.type):null)])
        .then(response => {
          data.attachment = response[0]!==null? response[0].key:null;
          data.idPublish=this.props.idPublish
          fetchCall("POST","/synthesis/addcontent", data).then(response =>{            
            if(response.status == 200){
              this.setState({
                //openAddNewNote : false,
                openNotification:true,
                messageNotification:'Contenido guardado',
                typeNotification:"success",
                isLoading:false    
              })
              this._loadNotes();
            }
          }).catch(err => err);
        }).catch(err => console.log("error save portrait",err)); 
      
    }
  }

  _editNotes = (item) =>{
    this.setState({
      openAddNewNote:true,
      noteSelected:item,
      action : 'Editar'
    })
  }

  _removeContent = () =>{
    fetchCall("POST", "/synthesis/removecontentsection",{
      contId:this.state.idDeleted,
      userId : ls.get("current_user").user_id
    }).then(response =>{  
      if(response.status == 200){
        this.setState({
          openConfirmDialog:false,
          idDeleted:0,
          openNotification:true,
          messageNotification:'Contenido eliminado',
          typeNotification:"success",
          isLoading:false
        });
        this._loadNotes(); 
      }
    }).catch(err => console.log(err));
  }

  _confirmRemoveContent = (item) =>{
    this.setState({
      openConfirmDialog:true,
      idDeleted:item.cont_id
    })
  }
  _closeConfirmDialog = () =>{
    this.setState({
      openConfirmDialog:false,
      idDeleted:0
    })
  }



  moverDerecha=(order)=>{
    let sections = new Array();
    sections = this.state.notes;
    let findElement = sections.findIndex(x => x.cont_order === order);
    var aux = sections[findElement];
    if(sections[findElement+1] !== undefined){
      sections[findElement] = sections[findElement+1];
      sections[findElement].cont_orden = sections[findElement+1].cont_orden;
      sections[findElement+1] = aux;
      sections[findElement+1].cont_orden = aux.cont_orden;

      this.setState({
        notes:sections
      }, () =>{
        fetchCall("POST", "/synthesis/updateorder", {
          order: sections.map((v,k)=>({id:v.cont_id,position:(k + 1)})),
          userId: ls.get("current_user").user_id,
          content: 1
        }).catch(err => console.log(err))

        this.setState({
          isLoading: false
        });
      });
    }
  }

  moverIzquierda=(order)=>{
    let sections = new Array();
    sections = this.state.notes;
    let findElement = sections.findIndex(x => x.cont_order === order);
    var aux = sections[findElement];
    if(sections[findElement-1] !== undefined){
      sections[findElement] = sections[findElement-1];
      sections[findElement].cont_orden = sections[findElement-1].cont_orden;
      sections[findElement-1] = aux;
      sections[findElement-1].cont_orden = aux.cont_orden;

      this.setState({
        notes:sections
      }, () =>{
        fetchCall("POST", "/synthesis/updateorder", {
          order: sections.map((v,k)=>({id:v.cont_id,position:(k + 1)})),
          userId: ls.get("current_user").user_id,
          content: 1
        }).catch(err => console.log(err))

        this.setState({
          isLoading: false
        });
      });
    }
  }


  render() {
    const {
      classes,
      canEdit,
      canDelete,dataRadiostation,section
    } = this.props;
    return (
      <div>

        <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>

        {
          this.state.isLoading ? <Loader text={"Cargando..."} /> :
          <Grid container spacing={24} >         
         {
           this.state.notes.map((item, index)=>{
            item.cont_imagePath = item.cont_key
            item.cont_attachment = item.cont_attachmentKey 
             return (
              <Grid item xs={12} md={6} lg={4} key={index.toString()}>
              
                <NotasRadio
                  title={item.cont_name}
                  program={item.cont_program}
                  description={item.cont_description}
                  attachment={item.cont_attachmentKey}
                  cover={item.cont_imagePath}
                  color={section.sec_color}
                />
                <div className={classes.adminActions}>
                  <div>
                      <Tooltip title="Mover Izquierda">
                        <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>this.moverIzquierda(item.cont_order)}>
                          <ChevronLeft />
                        </Button>   
                      </Tooltip>
                      <Tooltip title="Mover Derecha">
                        <Button variant="fab" mini disabled={!canEdit} aria-label="add" className={classes.button} onClick={()=>this.moverDerecha(item.cont_order)}>
                          <ChevronRight />
                        </Button>   
                      </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Editar">
                      <Button variant="fab" mini disabled={!canEdit} onClick={()=>{this._editNotes(item)}} aria-label="add" className={classes.button}>
                        <Edit />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <Button variant="fab" mini disabled={!canDelete} onClick={()=>{this._confirmRemoveContent(item)}} aria-label="add" className={classes.button}>
                        <Delete />
                      </Button>
                    </Tooltip>
                  </div>
                </div>   
              </Grid>
             );
           })
         }

        <Grid item xs={12} md={6} lg={4}>
          <div className={classes.notasNew}>
            <div className={classes.contentBtnNew}>
              <Tooltip title="Nuevo Contenido">
                <Button variant="fab" mini aria-label="add" onClick={()=>this._addNewNote()} className={classes.btnNew} disabled={!canEdit}>
                  <AddIcon />
                </Button>
              </Tooltip>
            </div>               
          </div>
        </Grid>
             
      </Grid>
        }
       
      <AddNewRadio
          classes={classes}
          submit={this._handleAddNewNote}
          openForm={this.state.openAddNewNote}
          closeForm={this._handleCloseAddNewNote}
          dataInit={this.state.openAddNewNote ? this.state.noteSelected: null}
          isLoading={this.state.isLoading}
          action={this.state.action}
          secName={this.props.section.sec_name}
          dataRadiostation={dataRadiostation}
        />

        <ConfirmDialog 
          title={"¿Desea eliminar el contenido?"}
          message={"El contenido al ser eliminado no podrá ser recuperado"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._removeContent}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />
 
      </div>
    );
  }
}

RadioNew.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  canEdit:PropTypes.bool,
  canDelete:PropTypes.bool
};

export default withStyles(styles)(RadioNew);
