import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';
import { Input, InputLabel, Snackbar } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import Bookmark from '@material-ui/icons/Bookmark';
import Https from '@material-ui/icons/Https';
import Email from '@material-ui/icons/Email';
import AccountCircle from '@material-ui/icons/AccountCircle';

import styles from './contact-jss';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alphanumeric } from '../../validator/Regex';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Validate, Password } from '../../validator/Regex';
import CustomNotification from '../Notification/CustomNotification';
// validation functions

class AddContactForm extends React.Component {
  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state = {
    username: '',
    lastname: '',
    email: '',
    confirmEmail: '',
    pass: "",
    confirmPass: "",
    idStatus: 1,
    employeeid: '',
    idProfile: 0,
    openNotification: false,
    messageNotification: '',
    typeNotification: "success",
    preview: ''
  }

  handleChange = (event, cont) => {
    if (event.target.value.toString().split('').length <= cont) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      if (!cont)
        this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleChangeCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  componentWillReceiveProps(newProps) {
    if (newProps.type == "edit" && newProps.dataInit && newProps.dataInit.userId) {

      if (newProps.dataInit.availableSynthesis) {
        let parse = JSON.parse(`[${newProps.dataInit.availableSynthesis}]`);
        parse.map(item => {
          let selected = this.props.availableSynthesis.filter(x => x.menu_id === parseInt(item.id))[0];
          if (selected) {
            this.setState({
              [`menu_id${selected.menu_id}`]: true
            });
          }
        });
      } else {
        let parse = this.props.availableSynthesis;
        if (parse !== undefined) {
          parse.map(item => {
            if (item) {
              this.setState({
                [`menu_id${item.menu_id}`]: false
              });
            }
          });
        }
      }
      this.setState({
        username: this.state.username === '' ? newProps.dataInit.username : this.state.username,
        lastname: this.state.lastname === '' ? newProps.dataInit.lastname : this.state.lastname,
        email: this.state.email === '' ? newProps.dataInit.email : this.state.email,
        employeeid: this.state.employeeid === '' ? newProps.dataInit.employeeid : this.state.employeeid,
        idProfile: this.state.idProfile === 0 ? newProps.dataInit.idProfile : this.state.idProfile,
        preview: newProps.preview,
        idStatus: this.state.idStatus === 1 ? newProps.dataInit.status : this.state.idStatus,
      });
    }

    if (newProps.dataInit && !newProps.isOpen) {
      this._clearData(true);
      if (newProps.dataInit.availableSynthesis) {
        let parse = JSON.parse(`[${newProps.dataInit.availableSynthesis}]`);
        parse.map(item => {
          let selected = this.props.availableSynthesis.filter(x => x.menu_id === parseInt(item.id))[0];
          if (selected) {
            this.setState({
              [`menu_id${selected.menu_id}`]: undefined
            });
          }
        });
      }
    }
  }

  _clearData = (band) => {

    if (band) {
      this.setState({
        username: '',
        lastname: '',
        email: '',
        employeeid: '',
        idProfile: 0,
        confirmEmail: '',
        pass: "",
        confirmPass: "",
        idStatus: 1
      });
    } else {
      this.setState({
        username: this.props.dataInit.username,
        lastname: this.props.dataInit.lastname,
        email: this.props.dataInit.email,
        employeeid: this.props.dataInit.employeeid,
        idProfile: this.props.dataInit.idProfile,
        preview: this.props.original,
        confirmEmail: ""
      });


      let parse = this.props.availableSynthesis;
      parse.map(item => {
        this.setState({
          [`menu_id${item.menu_id}`]: undefined
        });
      });

      if (this.props.dataInit.availableSynthesis) {
        let parse = JSON.parse(`[${this.props.dataInit.availableSynthesis}]`);
        parse.map(item => {
          let selected = this.props.availableSynthesis.filter(x => x.menu_id === parseInt(item.id))[0];
          if (selected) {
            this.setState({
              [`menu_id${selected.menu_id}`]: true
            });
          }
        });
      }
    }


    // if(this.props.dataInit.availableSynthesis){
    //   let parse = JSON.parse(`[${this.props.dataInit.availableSynthesis}]`);
    //   parse.map(item =>{
    //     let selected = this.props.availableSynthesis.filter(x => x.menu_id === parseInt(item.id))[0];
    //     if(selected){
    //       this.setState({
    //         [`menu_id${selected.menu_id}`] : undefined
    //       });
    //     }
    //   });
    // }
  }

  _validateSubmit = (event, data) => {
    event.preventDefault();
    let hasSynthesis = false;
    if (this.props.availableSynthesis) {
      let parse = JSON.parse(`[${this.props.dataInit.availableSynthesis}]`);
      this.props.availableSynthesis.map(item => {
        if(this.state[`menu_id${item.menu_id}`]){
          hasSynthesis=true;
        }
      });
    }
    if (this.props.type === "new" && (data.confirmEmail.toString().trim() !== data.email.toString().trim())) {
      this.setState({
        openNotification: true,
        messageNotification: 'La confirmación de correo electrónico no coincide',
        typeNotification: 'error',
        isLoading: false
      });
      return false;
    }
    if (data.pass.toString().trim() !== "" && (!Password(data.pass))) {
      this.setState({
        openNotification: true,
        messageNotification: 'Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales',
        typeNotification: 'error',
        isLoading: false
      });
      return false;
    }
    if (data.pass.toString().trim() != "" && (data.pass === `7313vI54*${new Date().getFullYear()}`)) {
      this.setState({
        openNotification: true,
        messageNotification: 'Elige otra combinación de contraseña',
        typeNotification: 'error',
        isLoading: false
      });
      return false;
    }
    if (data.pass.toString().trim() != "" && (data.confirmPass.toString().trim() !== data.pass.toString().trim())) {
      this.setState({
        openNotification: true,
        messageNotification: 'La confirmación de contraseña no coincide',
        typeNotification: 'error',
        isLoading: false
      });
      return false;
    }
    if (data.idProfile.toString().trim() == "0") {
      this.setState({
        openNotification: true,
        messageNotification: 'Selecciona un perfil',
        typeNotification: 'error',
        isLoading: false
      });
      return false;
    }
    if (!hasSynthesis) {
      this.setState({
        openNotification: true,
        messageNotification: 'Selecciona por lo menos una síntesis',
        typeNotification: 'error',
        isLoading: false
      });
      return false;
    }
    if (Validate(data.email)) {
      this.props.handleSubmit(event, data);
      if (this.props.dataInit.availableSynthesis) {
        let parse = JSON.parse(`[${this.props.dataInit.availableSynthesis}]`);
        parse.map(item => {
          let selected = this.props.availableSynthesis.filter(x => x.menu_id === parseInt(item.id))[0];
          if (selected) {
            this.setState({
              [`menu_id${selected.menu_id}`]: undefined
            });
          }
        });
      }
      this._clearData(true)
      this.props.onClean(true);
    } else {
      this.setState({
        openNotification: true,
        messageNotification: 'Formato de correo inválido',
        typeNotification: 'error'
      });
    }
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification: false
      }
    )
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      onDrop,
      dataInit,
      profiles,
      availableSynthesis, type
    } = this.props;
    let dropzoneRef;
    const acceptedFiles = ['image/jpeg', 'image/png', 'image/bmp'];
    const fileSizeLimit = 3000000;

    return (
      <div>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>

        <form onSubmit={(event) => {
          this._validateSubmit(event, this.state)
        }}>
          <section className={classes.bodyForm}>
            {(this.props.type == "edit" && this.state.employeeid != "Externo") && <div>
              <Typography variant="button" className={classes.textCenter}>SUBIR IMAGEN</Typography>
              <Dropzone
                className={classes.hiddenDropzone}
                accept={acceptedFiles.join(',')}
                acceptClassName="stripes"
                onDrop={onDrop}
                maxSize={fileSizeLimit}
                ref={(node) => { dropzoneRef = node; }}
              />
              <div className={classes.avatarWrap}>
                <Avatar
                  alt={dataInit && dataInit.name}
                  className={classes.uploadAvatar}
                  src={this.state.preview}
                />
                <Tooltip id="tooltip-upload" title="Subir foto">
                  <IconButton
                    className={classes.buttonUpload}
                    component="button"
                    onClick={() => {
                      dropzoneRef.open();
                    }}
                  >
                    <PhotoCamera />
                  </IconButton>
                </Tooltip>
              </div>
            </div>}
            <div>
              <InputLabel htmlFor="email">Nombre</InputLabel>
              <Input
                id="username"
                name="username"
                value={this.state.username}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={(event) => this.handleChange(event, 50)}
              />
            </div>
            <div>
              <InputLabel htmlFor="lastname">Apellido</InputLabel>
              <Input
                id="lastname"
                name="lastname"
                value={this.state.lastname}
                type="text"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <PermContactCalendar />
                  </InputAdornment>
                }
                onChange={(event) => this.handleChange(event, 100)}
              />
            </div>
            <div>
              <InputLabel htmlFor="user">Usuario</InputLabel>
              <Input
                id="user"
                name="user"
                value={this.state.email.split("@")[0]}
                type="text"
                autoComplete="off"
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
                disabled
              />
            </div>
            <div>
              <InputLabel htmlFor="email">Correo electrónico</InputLabel>
              <Input
                id="email"
                name="email"
                value={this.state.email}
                type="mail"
                autoComplete="off"
                required
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
                disabled={this.props.type == "edit" ? true : false}
                onChange={(event) => this.handleChange(event, 50)}
              />
            </div>
            {this.props.type == "new" && <div>
              <InputLabel htmlFor="confirmEmail">Confirmar correo electrónico</InputLabel>
              <Input
                id="confirmEmail"
                name="confirmEmail"
                value={this.state.confirmEmail}
                type="mail"
                autoComplete="off"
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
                onPaste={null}
                onChange={(event) => this.handleChange(event, 50)}
              /> </div>}

            {(this.props.type == "edit" && this.state.employeeid == "Externo") && <div><InputLabel htmlFor="pass">Contraseña</InputLabel>
              <Input
                id="pass"
                name="pass"
                value={this.state.password}
                type="password"
                autoComplete="off"
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <Https />
                  </InputAdornment>
                }
                onChange={(event) => this.handleChange(event)}
              />
              <InputLabel htmlFor="confirmPass">Confirmar contraseña</InputLabel>
              <Input
                id="confirmPass"
                name="confirmPass"
                value={this.state.confirmPass}
                type="password"
                autoComplete="off"
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <Https />
                  </InputAdornment>
                }
                onChange={(event) => this.handleChange(event)}
              />
              <InputLabel htmlFor="employeeid">Número de empleado</InputLabel>
              <Input
                id="employeeid"
                name="employeeid"
                value={this.state.employeeid}
                type="text"
                autoComplete="off"
                className={classes.field}
                startAdornment={
                  <InputAdornment position="start">
                    <Bookmark />
                  </InputAdornment>
                }
                disabled
              />
            </div>}
            <div>
              <InputLabel htmlFor="Perfil">Perfil</InputLabel>
              <Select
                required
                value={this.state.idProfile}
                // value={this.state.idProfile ? this.state.idProfile : data.idProfile}
                onChange={(event) => this.handleChange(event, 9999)}
                className={classes.field}
                inputProps={{
                  name: 'idProfile',
                  id: 'idProfile',
                }}
              >
                {/* <MenuItem value="0">
                <em>- Selecciona un perfil -</em>
              </MenuItem> */}
                {
                  profiles.map((item, index) => {
                    return (<MenuItem key={index.toString()} value={item.profileId}>{item.name}</MenuItem>);
                  })
                }
              </Select>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Síntesis*</FormLabel>
                <FormGroup>
                  {
                    availableSynthesis.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index.toString()}
                          control={
                            <Checkbox
                              id={`menu_id${item.menu_id}`}
                              name={`menu_id${item.menu_id}`}
                              checked={this.state[`menu_id${item.menu_id}`] ? true : false}
                              onChange={this.handleChangeCheck(`menu_id${item.menu_id}`)}
                              value={this.state[`menu_id${item.menu_id}`]}
                            />
                          }
                          label={item.menu_name}
                        />
                      )
                    })
                  }
                </FormGroup>
              </FormControl>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="raised" color="primary" type="submit" disabled={submitting}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this.props.onClean(false);
                setTimeout(() => {
                  this._clearData(false);
                }, 500);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dataInit: PropTypes.object,
  dataContact: PropTypes.array,
  isOpen: PropTypes.bool,
  preview: PropTypes.string,
  availableSynthesis: PropTypes.array
};

export default withStyles(styles)(AddContactForm);