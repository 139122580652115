import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './section-jss';

class AddHeaderForm extends React.Component {

  state = {
    contId: 0,
    isUpdating: false
  };


  componentWillReceiveProps(newProps) {
    if (newProps.dataInit !== null) {
      this.setState({
        contId: this.state.contId !== 0 ? this.state.contId : newProps.dataInit.cont_id,
        isUpdating: true
      });
    }

    if (!newProps.isOpen) {
      this.setState({
        isUpdating: false,
        contId: 0
      });
    }
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      onDrop,
      preview,
      previewmail,
      errorMessage,
      errorMessage2,
    } = this.props;

    let dropzoneRef;
    let dropzoneRefMail;
    const acceptedFiles = ['image/jpeg', 'image/png'];
    const fileSizeLimit = 93000000;

    return (
      <div>
        <form onSubmit={(event) => {
          handleSubmit(event, this.state);
        }}>
          <section className={classes.bodyForm}>
            <div>
              <div>
                <div className={classes.flexCenter}>
                  <div>
                    <Typography variant="button" className={classes.textCenter}>SUBIR LOGO WEB</Typography>
                    <Dropzone
                      className={classes.hiddenDropzone}
                      accept={acceptedFiles.join(',')}
                      acceptClassName="stripes"
                      onDrop={(e) => onDrop(e, "selectedPicture")}
                      maxSize={fileSizeLimit}
                      ref={(node) => { dropzoneRef = node; }}
                    />
                    <div className={classes.avatarWrap}>
                      <Avatar
                        alt=""
                        className={classes.uploadAvatar}
                        src={preview}
                      />
                      <Tooltip id="tooltip-upload" title="Subir foto">
                        <IconButton
                          className={classes.buttonUpload}
                          component="button"
                          onClick={() => {
                            dropzoneRef.open();
                          }}
                        >
                          <PhotoCamera />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <Typography variant="button" className={classes.textCenter}>{errorMessage}</Typography>
                  </div>
                  <div style={{ margin: "0 1.5rem" }}>
                    <Typography variant="button" className={classes.textCenter}>SUBIR IMAGEN EMAIL</Typography>
                    <Dropzone
                      className={classes.hiddenDropzone}
                      accept={acceptedFiles.join(',')}
                      acceptClassName="stripes"
                      onDrop={(e) => onDrop(e, "selectedPicturemail")}
                      maxSize={fileSizeLimit}
                      ref={(node) => { dropzoneRefMail = node; }}
                    />
                    <div className={classes.avatarWrap}>
                      <Avatar
                        alt=""
                        className={classes.uploadAvatar}
                        src={previewmail}
                      />
                      <Tooltip id="tooltip-upload" title="Subir foto">
                        <IconButton
                          className={classes.buttonUpload}
                          component="button"
                          onClick={() => {
                            dropzoneRefMail.open();
                          }}
                        >
                          <PhotoCamera />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <Typography variant="button" className={classes.textCenter}>{errorMessage2}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="raised" color="primary" type="submit" disabled={this.state.isUpdating || preview !== '' ? false : true}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this.props.onClean(!this.state.isUpdating);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddHeaderForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dataInit: PropTypes.object,
  preview: PropTypes.string,
  onClean: PropTypes.func,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(AddHeaderForm);
