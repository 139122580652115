import React from 'react';
import PropTypes from 'prop-types';
import FloatingPanel from '../Panel/FloatingPanel';
import AddMenuForm from './AddMenuForm';

class AddMenu extends React.Component {
  constructor(props) {
    super(props);
  }
  
  sendValues = (values) => {
    setTimeout(() => {
      this.props.submit(values);
    }, 500);
  }

  render() {
    const {
      openForm,
      closeForm,
      dataInit,
      submit,
      action,
      isLoading,
      isUpdating
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action} Síntesis`} openForm={openForm} branch={branch} closeForm={closeForm}>
        <AddMenuForm
            onSubmit={this.sendValues}
            handleSubmit={(event, data) => submit(event, data) }
            dataInit={dataInit}
            isOpen={openForm}
            isLoading={isLoading}
            isUpdating={isUpdating}
          />
        </FloatingPanel>
      </div>
    );
  }
}

AddMenu.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  addContact: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object
};

export default  AddMenu;
