import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import styles from './contact-jss';
import { S3Image } from 'aws-amplify-react';
import Loader from '../Loader/Loader';
import avatar from '../../../static/images/avatars/pp_boy4.svg';

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'all',
      txt: ''
    };
  }

  handleChange = (event, value) => {
    this.setState({ txt: event.target.value });
    this.props.search(event);
  };

  handleClean = () => {
    this.setState({
      txt: ''
    });
    this.props.clean();
  }

  render() {
    const {
      classes,
      dataContact,
      itemSelected,
      showDetail,
      clippedRight
    } = this.props;

    const getItem = dataArray => dataArray.map(data => {

      if (data.userId === -1) {
        return (
          <Loader />
        );
      }
      return (
        <ListItem
          button
          key={data.userId}
          className={data.userId === itemSelected.userId ? classes.selected : ''}
          onClick={() => showDetail(data)}
        >
          <Avatar alt={data.username} className={classes.avatar}>
            {data.photo.trim().length > 0 ? <S3Image className={classes.avatar} imgKey={data.photo} /> : <img src={avatar}/>}
          </Avatar>
          <ListItemText primary={`${data.username} ${data.lastname} ${data.employeeid=="Externo"?" (Externo)":""}`} secondary={data.profile} />
        </ListItem>
      );

    });

    return (
      <Fragment>
        <Drawer
          variant="permanent"
          anchor="left"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div>
            <div className={classNames(classes.toolbar, clippedRight && classes.clippedRight)}>
              <div className={classes.flex}>
                <div className={classes.searchWrapper}>
                  <div className={classes.search}>
                    <SearchIcon />
                  </div>
                  <input value={this.state.txt} className={classes.input} onChange={(event) => this.handleChange(event)} placeholder="Buscar usuario" />
                </div>
              </div>
            </div>
            <Divider />
            <List>
              {getItem(dataContact)}
              {
                dataContact.length === 0 ? <ListItem
                  button
                  key="1"
                >
                  <Avatar alt={""} className={classes.avatar} > :(
                  </Avatar>
                  <ListItemText primary={`Sin resultados`} secondary={""} />
                </ListItem>
                  : ''
              }
            </List>
          </div>
        </Drawer>
        <div className={classes.bottomIcon} onClick={() => {
          this.handleClean();

        }}>
          <PermContactCalendar className={classes.iconBlock} />
          <small>Todos</small>
        </div>
      </Fragment>
    );
  }
}

ContactList.propTypes = {
  classes: PropTypes.object,
  dataContact: PropTypes.array,
  keyword: PropTypes.string,
  itemSelected: PropTypes.object,
  showDetail: PropTypes.func,
  search: PropTypes.func,
  clean: PropTypes.func,
  clippedRight: PropTypes.bool,
};

ContactList.defaultProps = {
  clippedRight: false
};

export default withStyles(styles)(ContactList);
