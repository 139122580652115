import React from 'react';
import PropTypes from 'prop-types';
import FloatingPanel from '../../Panel/FloatingPanel';
import AddNewRelevanteForm from './AddNewRelevanteForm';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';
class AddNewRelevante extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      errorMessage: '',
      attachment: null,
      openNotification: false,
      messageNotification: 'Selecciona un archivo válido',
      typeNotification: "warning"
    };
    this.onClean = this.onClean.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
  }

  addAttachment(files) {
    if (files[0]) {
      this.setState({
        attachment: files[0]
      });
    } else {
      this.setState({
        attachment: null,
        openNotification: true
      });
    }
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification: false
      }
    )
  }

  onClean() {
    this.setState({
      errorMessage: '',
      attachment: null
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.dataInit === null) {
      this.setState({
        attachment: null
      });
    } 
  }

  render() {
    const {
      openForm,
      closeForm,
      dataInit,
      submit,
      action,
      isLoading,
      secName,
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action} Nota`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddNewRelevanteForm
            handleSubmit={(event, data) => submit(event, data, null, this.state.attachment, '')}
            dataInit={dataInit}
            isOpen={openForm}
            onClean={this.onClean}
            errorMessage={this.state.errorMessage}
            addAttachment={this.addAttachment}
            attachment={this.state.attachment}
            isLoading={isLoading}
            deleteSelectedPic={this.deleteSelectedPic}
            secName={secName}
          />
        </FloatingPanel>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>
      </div>
    );
  }
}

AddNewRelevante.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  openForm: PropTypes.bool,
  closeForm: PropTypes.func,
  dataInit: PropTypes.object
};

export default (AddNewRelevante);
