import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

const styles = theme => ({
  title: {
    '& h2':{
      fontSize: '1.1rem'
    }
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SelectDialog extends React.Component {
  state = {
    open: false,
    openSlide: false,
  };

  render() { 
    const { 
        classes,
        title,   
        funcAccept,
        funcCancel,
        open, 
        data
    } = this.props;
    return (
      <div>
        <Grid container spacing={16}>
          <Grid item md={6}>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={funcCancel}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
                {title}
              </DialogTitle>
              <DialogContent>
                {
                  data.map((item,index)=>{ 
                    return(
                      <DialogContentText id="alert-dialog-slide-description" key={index} style={{textAlign:'center'}}>
                        <input type='radio' name='ps' value={item.ps_id} onChange={()=>funcAccept(item.ps_id)}></input>
                         {item.ps_publishDate}
                      </DialogContentText>
                    )
                  })
                } 
              </DialogContent>
              <DialogActions>
                <Button onClick={funcCancel} color="primary">
                  Cancelar
                </Button>   
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SelectDialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    textAccept: PropTypes.string,
    textCancel: PropTypes.string,
    funcAccept: PropTypes.func,
    funcCancel: PropTypes.func,
    open: PropTypes.bool
};

export default withStyles(styles)(SelectDialog);