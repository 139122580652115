import React from 'react';
import { BlockPicker } from 'react-color';
import { withStyles } from '@material-ui/core/styles';

import blockStyle from './styles';

class ColorPicker extends React.Component {

    render() {
        const {
            color
            , colors
            , triangle
            , width
            , onChange
            , onChangeComplete
            , classes
        } = this.props;
        return (
            <BlockPicker
                color={color&&color!=""?color:"#FFFFFF"}
                colors={colors}
                triangle={triangle}
                width={width}
                onChange={(e) => onChange ? onChange(e) : null}
                onChangeComplete={(e) => onChangeComplete ? onChangeComplete(e) : null}
                styles={ blockStyle }
                className={classes.border}
            />
        )
    }
}

export default withStyles(blockStyle)(ColorPicker);
