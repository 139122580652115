import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import brand from '../../../../utils/brand';
import { withStyles } from '@material-ui/core/styles';
import { fetchCall } from '../../../../service/service_base';
import styles from '../../../../components/Tables/tableParts/tableStyle-jss';
import PapperBlock from "../../../../components/PapperBlock/PapperBlock";
import AddProfile from './AddProfile';
import ModalPermissions from './ModalPermissions';
// inicio Taps
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';
import ls from 'local-storage';
import ProfileTable from '../../../../components/Tables/ProfileTable';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import { ThemeContext } from '../../../../utils/ThemeContext';
class Profile extends Component {

  state = {
    dataProfile: [],
    anchorEl: null,
    openAddProfile: false,
    selectedProfile: null,
    openModal: false,
    activeIndex: 0,
    checkedAll: false,
    checkedConfig1: false,
    checkedConfig2: false,
    checkedConfig3: false,
    checkedConfig4: false,
    editProfile: false,
    openNotification: false,
    typeNotification: "info",
    messageNotification: "",
    optionsMenu: [],
    onlyView: false,
    canEdit: true,
    canShow: false,
    canPublish: true,
    canDelete: true,
    canShowPermissions: false,
    canEditPermission: false,
    isLoading: true,
    idDeleted: 0,
    openConfirmDialog: false,
    action: 'Nuevo',
    currentUser: ls.get("current_user")['user_id']
  }

  constructor(props) {
    super(props);
    this.handleStatus = this.handleStatus.bind(this);
  }

  handleClickOpt = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseOpt = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    //return to default theme
    this.context.toggleTheme(undefined);
    this._getUserPermissions();
  }

  _getUserPermissions = () => {
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
    if (permissionsCurrentUser[0] != null) {
      let add = permissionsCurrentUser.filter(x => x.name === "Configuración/Administración de perfiles")[0];

      if (add !== undefined || add !== null) {
        let currentPermissions = ls.get("permissions");
        if (currentPermissions !== undefined || currentPermissions !== null) {

          let data = currentPermissions.filter(x => x.menu_id == add.id)[0];
          if (data !== undefined) {
            let config = JSON.parse(data.grant_configuration);
            this.setState({
              canShow: config.chkViewAdminProfile,
              canEdit: !config.chkEditAdminProfile,
              canPublish: !config.chkPublishAdminProfile,
              canDelete: !config.chkDeleteAdminProfile,
              canShowPermissions: !config.chkViewPermissions,
              canEditPermission: !config.chkEditPermissions
            });
            if (config.chkViewAdminProfile) {
              this._getProfileList();
            } else {
              this.setState({
                isLoading: false
              })
            }
          }

        }
      }
    }
  }

  handleCloseNotification = () => {
    this.setState({ openNotification: false, messageNotification: '' });
  }

  _getProfileList = () => {
    fetchCall("GET", "/profile/getList").then(response => {

      this.setState({ dataProfile: response.data, isLoading: false });
    }).catch(err => console.log(err));
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
  }

  handleCloseModal = (loadData) => {
    this.setState({ openModal: false });
    if (loadData) {
      this._getProfileList();
    } else {
      this.setState(
        {
          profileSelected: null,
          selectedProfile: null
        }
      )
    }
  }

  handleClick = (event, profileId) => {
    this.setState({ [`${profileId}`]: true, anchorEl: event.currentTarget });
  };

  handleClose = (profileId) => {
    this.setState({ [`${profileId}`]: null });
  };

  openNewProfile = () => {
    this.setState({ openAddProfile: true, action: 'Nuevo' });
  }

  closeNewProfile = () => {
    this.setState({ openAddProfile: false, selectedProfile: null, editProfile: false });
  }

  handleEditProfile = (profile) => {
    this.setState({
      [`${profile.profileId}`]: null,
      selectedProfile: profile,
      openAddProfile: true,
      editProfile: true,
      action: 'Editar'
    });
  }

  handleEditPermissions = (profile) => {
    fetchCall("POST", "/synthesis/menuaction", {
      profileId: profile.profileId
    }).then(response => {
      this.setState({
        [`${profile.profileId}`]: null,
        selectedProfile: profile,
        openModal: true,
        optionsMenu: response.data,
        onlyView: false
      });
      // this.setState({optionsMenu :  response.data})
    }).catch(err => {

    });
  }

  handleViewPermissions = (profile) => {
    fetchCall("POST", "/synthesis/menuaction", {
      profileId: profile.profileId
    }).then(response => {
      this.setState({
        [`${profile.profileId}`]: null,
        selectedProfile: profile,
        openModal: true,
        optionsMenu: response.data,
        onlyView: true
      });
    }).catch(err => {

    });
  }

  submitProfile = (event, data) => {
    this.setState({ isLoading: true });
    event.preventDefault();

    if (data.proName.toString().trim() === '') {
      this.setState({
        openNotification: true,
        messageNotification: 'Debes ingresar un nombre para el perfil',
        typeNotification: "error",
        isLoading: false
      })
      return true;
    }

    if (data.proName.toString().trim().split('').length > 100) {
      this.setState({
        openNotification: true,
        messageNotification: 'Nombre del perfil no debe contener más de 100 caractéres',
        typeNotification: "error",
        isLoading: false
      })
      return true;
    }

    if (data.proDescripcion.toString().trim() === '') {
      this.setState({
        openNotification: true,
        messageNotification: 'Debes ingresar una descripción para el perfil',
        typeNotification: "error",
        isLoading: false
      })
      return true;
    }

    if (data.proDescripcion.toString().trim().split('').length > 200) {
      this.setState({
        openNotification: true,
        messageNotification: 'Descripción del perfil no debe contener más de 200 caractéres',
        typeNotification: "error",
        isLoading: false
      })
      return true;
    }

    if (this.state.editProfile) {
      data.profile_id = this.state.selectedProfile.profileId;
      let verifyName = true;

      this.state.dataProfile.map((item) => {
        if (item.name.toString().trim() === data.proName.toString().trim() && item.profileId !== this.state.selectedProfile.profileId) {
          verifyName = false;
        }
      });

      if (!verifyName) {
        this.setState({
          openNotification: true,
          messageNotification: "Ya existe un perfil con ese nombre",
          typeNotification: "error",
          isLoading: false,
          idDeleted: 0
        });
        return false;
      }
      fetchCall("POST", "/profile/update", data).then(response => {
        if (response.status === 200) {
          this.setState({
            openNotification: true,
            messageNotification: "Se modificó la información del perfil",
            typeNotification: "success",
            isLoading: false,
            idDeleted: 0
          });
          this.closeNewProfile();
          this._getProfileList();
          fetchCall("POST", "/report/addlogmovements", {
            username: ls.get("username"),
            action: "Edición de Perfil",
            description: `El usuario ${`${ls.get("username")}`.split("@")[0]} modificó el perfil ${data.proName}`,
            userId: this.state.currentUser
          })
        } else {
          this.setState({
            openNotification: true,
            messageNotification: "Ocurrió un error al editar el perfil",
            typeNotification: "error",
            isLoading: false,
            idDeleted: 0
          });
        }
      }).catch(err => console.log(err));

    } else {
      let verifyName = true;

      this.state.dataProfile.map((item) => {
        if (item.name.toString().trim() === data.proName.toString().trim()) {
          verifyName = false;
        }
      });

      if (!verifyName) {
        this.setState({
          openNotification: true,
          messageNotification: "Ya existe un perfil con ese nombre",
          typeNotification: "error",
          isLoading: false,
          idDeleted: 0
        });
        return false;
      }

      fetchCall("POST", "/profile/add", data).then(response => {

        if (response.status === 200) {
          this.setState({
            openNotification: true,
            messageNotification: "Se creó el nuevo perfil",
            typeNotification: "success",
            isLoading: false,
            idDeleted: 0
          });
          this.closeNewProfile();
          this._getProfileList();
          fetchCall("POST", "/report/addlogmovements", {
            username: ls.get("username"),
            action: "Nuevo Perfil",
            description: `El usuario ${`${ls.get("username")}`.split("@")[0]} creó el nuevo perfil ${data.proName}`,
            userId: this.state.currentUser
          })
        } else {
          this.setState({
            openNotification: true,
            messageNotification: "Ocurrió un error al crear el perfil",
            typeNotification: "error",
            isLoading: false,
            idDeleted: 0
          });
        }
      }).catch(err => console.log(err));

    }
  }

  handleStatus = (pro) => {
    this.setState({ isLoading: true });
    let obj = {
      profileId: pro.profileId,
      pro_sta: pro.status === "ON" ? 2 : 1
    }

    fetchCall("POST", "/profile/updatestatus", obj).then(response => {
      if (response.status === 200) {
        this.setState({
          openNotification: true,
          messageNotification: "Se cambió el estatus del perfil",
          typeNotification: "success",
          isLoading: false
        });
        this._getProfileList();
      } else {
        this.setState({
          openNotification: true,
          messageNotification: "Ocurrió un error al cambiar el estatus",
          typeNotification: "error",
          isLoading: false
        });
      }
    }).catch(err => console.log(err));
  }

  handleDefaultProfile = (pro) => {
    this.setState({ isLoading: true });
    let data = {
      profileId: pro.profileId
    }

    fetchCall("POST", "/profile/defaultprofile", data).then(response => {
      if (response.data[0].result === 1) {
        this.setState({
          openNotification: true,
          messageNotification: "Se cambió la configuración del rol " + pro.name,
          typeNotification: "success",
          isLoading: false
        });
        this._getProfileList();
      } else {
        this.setState({
          openNotification: true,
          messageNotification: "No se permite el Default Rol sin ningún perfil seleccionado",
          typeNotification: "error",
          isLoading: false
        });
      }
    }).catch(err => console.log(err))

  }

  handleDeletePermissions = () => {
    this.setState({ isLoading: true });
    var userExists = this.state.dataProfile.filter(x => x.profileId === this.state.idDeleted)[0];
    if (userExists.users > 0) {
      this.setState({
        openNotification: true,
        messageNotification: "El perfil no se puede eliminar porque tiene usuarios asignados",
        typeNotification: "error",
        isLoading: false,
        openConfirmDialog: false
      });
      return false;
    }

    fetchCall("POST", "/profile/remove", {
      profile_id: this.state.idDeleted
    }).then(response => {
      if (response.status === 200) {
        this.setState({
          openNotification: true,
          messageNotification: "Se eliminó el perfil",
          typeNotification: "success",
          isLoading: false,
          openConfirmDialog: false
        });
        fetchCall("POST", "/report/addlogmovements", {
          username: ls.get("username"),
          action: "Eliminación de Perfil",
          description: `El usuario ${`${ls.get("username")}`.split("@")[0]} eliminó el perfil ${this.state.dataProfile.find(x => x.profileId === this.state.idDeleted).name}`,
          userId: this.state.currentUser
        })
        this._getProfileList();
      }
    }).catch(err => console.log(err));
  }

  _openConfirmDialog = (profile) => {
    this.setState({
      openConfirmDialog: true,
      idDeleted: profile.profileId
    })
  }

  _closeConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      idDeleted: 0
    })
  }

  _savePermissions = (data) => {
    console.log(data)
    let success = [];

    this.setState({
      isLoading: true,
      openModal: false
    });
    fetchCall("POST", "/report/addlogmovements", {
      username: ls.get("username"),
      action: "Actualización de privilegios",
      description: `El usuario ${`${ls.get("username")}`.split("@")[0]} actualizó los privilegios del perfil ${this.state.dataProfile.find(x => x.profileId === this.state.selectedProfile.profileId).name}`,
      userId: this.state.currentUser
    })
    data.map((item, index) => {
      item.updatedAt = ls.get("current_user").user_id;
      item.profileId = this.state.selectedProfile.profileId;
      fetchCall("POST", "/synthesis/updatepermissions", item).then(response => {
        console.log(response)
        if (response.status === 200) {


          success.push(true);
          if (data.length - 1 === index) {
            this._reload();
          }
        } else {
          success.push(false);
          if (data.length - 1 === index) {
            this._reload();
          }
        }
      }).catch(err => {
        success.push(false);
        if (data.length - 1 === index) {
          this._reload();
        }
      });
    });
  }

  _reload = () => {
    this.setState({
      openNotification: true,
      messageNotification: "Cambios guardados",
      typeNotification: "success",
      selectedProfile: null,
      profileSelected: null
    }, () => {
      this.handleCloseModal(true);

    })

  }

  render() {

    const {
      classes
    } = this.props;
    const title = brand.name + ' - Perfiles';
    const description = brand.desc;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        {this.state.isLoading ? <LoaderFull /> : ''}
        <PapperBlock title="Perfiles" fullTitle>
          <AddProfile
            openForm={this.state.openAddProfile}
            closeForm={this.closeNewProfile}
            classes={classes}
            submit={(event, data) => this.submitProfile(event, data)}
            dataInit={this.state.selectedProfile}
            action={this.state.action}
          />

          <ModalPermissions
            classes={classes}
            openModalPermission={this.state.openModal}
            profileSelected={this.state.selectedProfile}
            handleCloseModal={this.handleCloseModal}
            optionsMenu={this.state.optionsMenu}
            onlyView={this.state.onlyView}
            handleSubmit={this._savePermissions}
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.openNotification}
            autoHideDuration={6000}
            onClose={this.handleCloseNotification}>
            <CustomNotification
              onClose={this.handleCloseNotification}
              variant={this.state.typeNotification}
              message={this.state.messageNotification} />
          </Snackbar>

          <ConfirmDialog
            title={"¿Está seguro que desea eliminar el perfil?"}
            message={"El perfil al ser eliminado no se podrá recuperar"}
            textAccept={"Aceptar"}
            textCancel={"Cancelar"}
            funcAccept={this.handleDeletePermissions}
            funcCancel={this._closeConfirmDialog}
            open={this.state.openConfirmDialog}
          />

          <ProfileTable
            classes={classes}
            dataProfile={this.state.dataProfile}
            openNewProfile={this.openNewProfile}
            handleStatus={this.handleStatus}
            handleDefaultProfile={this.handleDefaultProfile}
            handleEditProfile={this.handleEditProfile}
            handleViewPermissions={this.handleViewPermissions}
            handleEditPermissions={this.handleEditPermissions}
            handleDeletePermissions={this._openConfirmDialog}
            canPublish={this.state.canPublish}
            canEdit={this.state.canEdit}
            canDelete={this.state.canDelete}
            canShowPermissions={this.state.canShowPermissions}
            canEditPermissions={this.state.canEditPermission}
          />
        </PapperBlock>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

Profile.contextType = ThemeContext;

export default withStyles(styles)(Profile);