import React from 'react';
import PropTypes from 'prop-types';
import FloatingPanel from '../../Panel/FloatingPanel';
import AddHeaderForm from './AddHeaderForm';
import { getPhotoCurrentUser } from '../../../service/s3Amplify';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';
class AddHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      selectedPicture: null,
      selectedPicturemail: null,
      urlPreview:'',
      urlPreviewmail:'',
      errorMessage_selectedPicture:'',
      errorMessage_selectedPicturemail:'',
      openNotification : false,
      messageNotification : 'Selecciona un archivo válido',
      typeNotification : "warning"
    };
    this.onDrop = this.onDrop.bind(this);
    this.onClean = this.onClean.bind(this);
  }

  onClean(band){
    if(band){
      this.setState({
        selectedPicture:null,
        selectedPicturemail:null,
        urlPreview:'',
        urlPreviewmail :'',
        errorMessage_selectedPicture:'',
        errorMessage_selectedPicturemail:'',
      });
    }else{
      Promise.all([getPhotoCurrentUser(this.props.dataInit.cont_imagePath),getPhotoCurrentUser(this.props.dataInit.cont_description)])
      .then(response =>{
        this.setState({
          urlPreview :response[0],
          selectedPicture :null,
          urlPreviewmail :response[1],
          selectedPicturemail :null
        });
      }).catch(err =>{
        console.log("error url", err);
      });
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.dataInit !== null){
      Promise.all([getPhotoCurrentUser(newProps.dataInit.cont_imagePath),getPhotoCurrentUser(newProps.dataInit.cont_description)])
      .then(response =>{
        this.setState({
          urlPreview :response[0],
          urlPreviewmail :response[1],
        });
      }).catch(err =>{
        console.log("error url", err);
      });
    }else{
      this.setState({
        urlPreview: this.state.selectedPicture ? this.state.selectedPicture.preview : '',
        urlPreviewmail: this.state.selectedPicturemail ? this.state.selectedPicturemail.preview : ''
      });
    }

    if(!this.props.openForm){
      this.onClean(true);
    }

  }

  onDrop=(files, input)=> {
    if(files.length===0){
      this.setState({[`errorMessage_${input}`]:"archivo inválido"});
    }else{
      if (files.length > 1) {
        this.setState({
          [`errorMessage_${input}`] : 'No puedes subir más de un archivo'
        });
      } else {
        this.setState({ [input]: files[0] ,[`errorMessage_${input}`]:''});        
      }
    }    
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification : false
      }
    )
  }

  render() {
    const {
      openForm,
      closeForm,
      submit,
      action,
      isLoading,
      dataInit
    } = this.props;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action}`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddHeaderForm
            onDrop={this.onDrop}
            handleSubmit={(event, data)=>submit(event, data, this.state.selectedPicture,this.state.selectedPicturemail)}
            preview={this.state.selectedPicture ? this.state.selectedPicture.preview :this.state.urlPreview}
            previewmail={this.state.selectedPicturemail ? this.state.selectedPicturemail.preview :this.state.urlPreviewmail}
            errorMessage={this.state.errorMessage_selectedPicture}
            errorMessage2={this.state.errorMessage_selectedPicturemail}
            isLoading={isLoading}
            dataInit={dataInit}
            isOpen={openForm}
            onClean={this.onClean}
          />
        </FloatingPanel>
        <Snackbar
         anchorOrigin={{
             vertical: 'top',
             horizontal: 'right',
         }}
         open={this.state.openNotification}
         autoHideDuration={6000}
         onClose={this.handleCloseNotification}>
       <CustomNotification
         onClose={this.handleCloseNotification}
         variant={this.state.typeNotification}
         message={this.state.messageNotification}/>
       </Snackbar>
      </div>
    );
  }
}

AddHeader.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  addContact: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object,
  contentType:PropTypes.string,
  action: PropTypes.string
};

export default (AddHeader);
