import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
import  PapperBlock  from '../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import CircularProgress from '@material-ui/core/CircularProgress';
import ls from 'local-storage';
import menu from '../../../utils/menu.js';
class BlankPage extends React.Component {
 
  constructor(props) {
    super(props);  
  }

  state = {firstSynthesis: 8 }

  componentDidMount() {
    this.interval = setInterval(() => {
        if(ls.get("session_menu") !== null || ls.get("session_menu") !== undefined || ls.get("session_menu") !== ""){
          let filter = ls.get("session_menu").filter(x => x.menu_id === this.state.firstSynthesis)[0];
          let userPermissions = ls.get("permissions");
          if(filter){
            let havePermissions = userPermissions.filter(x => x.menu_id === filter.menu_id)[0];
            if(havePermissions){
              if(JSON.parse(havePermissions.grant_configuration).chkViewSynthesis){
                window.location.href = "/synthesis/"+filter.menu_name;
              }else{
                ls.get("session_menu").map(
                  item => {
                    let p = userPermissions.filter(x => x.menu_id === item.menu_id)[0];
                    if(p){
                      if(JSON.parse(p.grant_configuration).chkViewSynthesis){
                        window.location.href = "/synthesis/"+item.menu_name;
                      }     
                    }
                  }
                );

                setTimeout(() => {
                  menu[0].child.map(
                    item => {
                      let p = userPermissions.filter(x => x.menu_id === item.id)[0];
                      if(p){
                        switch (parseInt(item.id)) {
                          case 1:  
                          if(JSON.parse(p.grant_configuration).chkViewAdminUser){
                            window.location.href = item.link;
                          }
                           break;
                          case 2:  
                          if(JSON.parse(p.grant_configuration).chkViewAdminProfile){
                            window.location.href = item.link;
                          }
                           break;
                          case 3:  
                          if(JSON.parse(p.grant_configuration).chkViewAdminCont){
                            window.location.href = item.link;
                          }
                           break;
                          case 4:  
                          if(JSON.parse(p.grant_configuration).chkViewContact){
                            window.location.href = item.link;
                          }
                           break;
                          case 5:  
                          if(JSON.parse(p.grant_configuration).chkViewSendEmail){
                            window.location.href = item.link;
                          }
                           break;
                          case 6: 
                          if(JSON.parse(p.grant_configuration).chkViewHistory){
                            window.location.href = item.link;
                          }
                          break;
                          case 7: 
                            if(JSON.parse(p.grant_configuration).chkViewReport){
                              window.location.href = item.link;
                            } 
                          break;
                          case 11: return ""; break;
                          default: 
                            if(JSON.parse(p.grant_configuration).chkViewSynthesis){
                              window.location.href = item.link;
                            } 
                        }
                      }
                    }
                  )
                }, 1500);
                
              }
            }else{
              ls.get("session_menu").map(
                item => {
                  let p = userPermissions.filter(x => x.menu_id === item.menu_id)[0];
                  if(p){
                    if(JSON.parse(p.grant_configuration).chkViewSynthesis){
                      window.location.href = "/synthesis/"+item.menu_name;
                    }     
                  }
                }
              );

              setTimeout(() => {
                menu[0].child.map(
                  item => {
                    let p = userPermissions.filter(x => x.menu_id === item.id)[0];
                    if(p){
                      
                      switch (parseInt(item.id)) {
                        case 1:  
                        if(JSON.parse(p.grant_configuration).chkViewAdminUser){
                          window.location.href = item.link;
                        }
                         break;
                        case 2:  
                        if(JSON.parse(p.grant_configuration).chkViewAdminProfile){
                          window.location.href = item.link;
                        }
                         break;
                        case 3:  
                        if(JSON.parse(p.grant_configuration).chkViewAdminCont){
                          window.location.href = item.link;
                        }
                         break;
                        case 4:  
                        if(JSON.parse(p.grant_configuration).chkViewContact){
                          window.location.href = item.link;
                        }
                         break;
                        case 5:  
                        if(JSON.parse(p.grant_configuration).chkViewSendEmail){
                          window.location.href = item.link;
                        }
                         break;
                        case 6: 
                        if(JSON.parse(p.grant_configuration).chkViewHistory){
                          window.location.href = item.link;
                        }
                        break;
                        case 7: 
                          if(JSON.parse(p.grant_configuration).chkViewReport){
                            window.location.href = item.link;
                          } 
                        break;
                        case 11: return ""; break;
                        default: 
                          if(JSON.parse(p.grant_configuration).chkViewSynthesis){
                            window.location.href = item.link;
                          } 
                      }
                    }
                  }
                )
              }, 1500);
            }
          }else{
            ls.get("session_menu").map(
              item => {
                let p = userPermissions.filter(x => x.menu_id === item.menu_id)[0];
                if(p){
                  if(JSON.parse(p.grant_configuration).chkViewSynthesis){
                    window.location.href = "/synthesis/"+item.menu_name;
                  }     
                }
              }
            );

            setTimeout(() => {
              menu[0].child.map(
                item => {
                  let p = userPermissions.filter(x => x.menu_id === item.id)[0];
                  if(p){
                    switch (parseInt(item.id)) {
                      case 1:  
                      if(JSON.parse(p.grant_configuration).chkViewAdminUser){
                        window.location.href = item.link;
                      }
                       break;
                      case 2:  
                      if(JSON.parse(p.grant_configuration).chkViewAdminProfile){
                        window.location.href = item.link;
                      }
                       break;
                      case 3:  
                      if(JSON.parse(p.grant_configuration).chkViewAdminCont){
                        window.location.href = item.link;
                      }
                       break;
                      case 4:  
                      if(JSON.parse(p.grant_configuration).chkViewContact){
                        window.location.href = item.link;
                      }
                       break;
                      case 5:  
                      if(JSON.parse(p.grant_configuration).chkViewSendEmail){
                        window.location.href = item.link;
                      }
                       break;
                      case 6: 
                      if(JSON.parse(p.grant_configuration).chkViewHistory){
                        window.location.href = item.link;
                      }
                      break;
                      case 7: 
                        if(JSON.parse(p.grant_configuration).chkViewReport){
                          window.location.href = item.link;
                        } 
                      break;
                      case 11: return ""; break;
                      default: 
                        if(JSON.parse(p.grant_configuration).chkViewSynthesis){
                          window.location.href = item.link;
                        } 
                    }
                  }
                }
              )
            }, 1500);
          }
        }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
 
  render() {
    const title = brand.name + ' - Blank Page';
    const description = brand.desc;
    const { classes } = this.props;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <PapperBlock title="Inicio" desc="Cargando síntesis" className={classes.centerProgress}>
            <div className={classes.centerProgress}>
            <CircularProgress
                size={50} 
              /> 
            </div>
          </PapperBlock>
      </div>
    );
  }
}

export default withStyles(styles)(BlankPage);
