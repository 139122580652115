import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from './papperStyle-jss';
import Button from '@material-ui/core/Button/Button';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import iconPreview from '../../../static/images/preview_mail.svg'
import { S3Image } from 'aws-amplify-react';
import { Hidden } from '@material-ui/core';
 

function getImg(img){
  if(img === "" || img === null ||img === undefined){
    return (
      ""
    );
  }
  else
  {
    return(
      <S3Image imgKey={img} /> 
    );
  }
}

// function getButtons(deleted){
//   if(deleted !== undefined){
//     return(
//       <Button variant="fab" mini color="primary" aria-label="add" onClick={()=>deleted()}>
//         <Delete />
//       </Button>
//     )
//   }
// }

function PaperSheet(props) {

  const {
    classes,
    title,
    children,
    whiteBg,
    noMargin,
    colorMode,
    overflowX,
    optionsSinthesis,
    editSynthesis,
    canEditSynthesis, 
    canDeleteSynthesis,
    deleteSynthesis,
    previewSynthesis,
    previewWeb,
    handleDateChange, 
    date,
    isEditing,
    color,
    fullTitle,
    img
  } = props;  
  return (
    <div>
      <Paper className={classNames(classes.root, noMargin && classes.noMargin, colorMode && classes.colorMode)} elevation={4}   style={{backgroundColor:color}}>

      { optionsSinthesis ? "" :
        <Typography variant="title" component="h2" className={classNames(classes.title, fullTitle && classes.fullTitle)} style={{color:(color&&color.includes("#")&&color.toLowerCase()!="#ffffff")?"white":""}}>
          {img && <div className={classes.iconTitle}>
              {getImg(img)}
          </div>}
          {title}
        </Typography>
      }
        { optionsSinthesis && 
        <div className={classes.contentActions}>
          <Hidden smUp>
            <div className={classes.btnsMobile}>
              <Tooltip title="Previsualizar">
                <Button variant="fab" onClick={()=>isEditing==1? previewSynthesis(true):previewSynthesis()} mini color="primary" aria-label="add" className={classes.button}>
                <img src={iconPreview} alt="preview" style={{width: "24px"}} />
                </Button>
              </Tooltip>
              <Tooltip title="Previsualizador web">
                <Button variant="fab" onClick={previewWeb} mini color="primary" aria-label="add" className={classes.button}>
                  <Visibility />
                </Button>
              </Tooltip>
              <Tooltip title="Editar">
                <Button variant="fab" disabled={!canEditSynthesis} onClick={() => editSynthesis()} mini color="primary" aria-label="add" className={classes.button}>
                  <Edit />
                </Button>
              </Tooltip>
              <Tooltip title="Eliminar">
                <Button variant="fab" disabled={!canDeleteSynthesis} onClick={() => deleteSynthesis() } mini color="primary" aria-label="add" className={classes.button}>
                  <Delete />
                </Button>
              </Tooltip>
            </div> 
          </Hidden>
          <div style={{flex:'1', marginTop: 15}}>
            <Typography variant="title" component="h2" className={classes.title}>
              {title}
              <Hidden xsDown>
                <div className={classes.btns}>
                  <Tooltip title="Previsualizar">
                    <Button variant="fab" onClick={()=>isEditing==1? previewSynthesis(true):previewSynthesis()} mini color="primary" aria-label="add" className={classes.button}>
                    <img src={iconPreview} alt="preview" style={{width: "24px"}} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Previsualizador web">
                    <Button variant="fab" onClick={previewWeb} mini color="primary" aria-label="add" className={classes.button}>
                      <Visibility />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Editar">
                    <Button variant="fab" disabled={!canEditSynthesis} onClick={() => editSynthesis()} mini color="primary" aria-label="add" className={classes.button}>
                      <Edit />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <Button variant="fab" disabled={!canDeleteSynthesis} onClick={() => deleteSynthesis() } mini color="primary" aria-label="add" className={classes.button}>
                      <Delete />
                    </Button>
                  </Tooltip>
                </div> 
              </Hidden>
            </Typography>
            
          </div>
          <div className={classes.picker}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  keyboard={false}
                  label="Fecha de publicación"
                  format="DD/MM/YYYY"
                  placeholder="dd/mm/yyyy"
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  value={date}
                  onChange={handleDateChange}
                  animateYearScrolling={false}
                  cancelLabel={"Cancelar"}
                  okLabel="Ver"
                  disableFuture={true}
                />
              </MuiPickersUtilsProvider>
          </div>
        </div>
        }

        <section className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>
          {children}
        </section>
      </Paper>
    </div>
  );
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  whiteBg: PropTypes.bool,
  colorMode: PropTypes.bool,
  noMargin: PropTypes.bool,
  overflowX: PropTypes.bool,
  optionsSinthesis: PropTypes.bool,
  fullTitle: PropTypes.bool,
  // ,
  // deleted : PropTypes.func
};

PaperSheet.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  optionsSinthesis: false
};

export default withStyles(styles)(PaperSheet);
