import React, { Component } from 'react';
import { Router } from "react-router-dom";
import createHistory from 'history/createBrowserHistory';
import { renderRoutes } from 'react-router-config';
import routes from './app/routes/index';

import './app/styles/normalize.scss';
import './app/styles/layout/_base.scss';
import { ThemeContext } from './app/utils/ThemeContext';
import ThemePallete from './app/utils/themePalette';
import { createMuiTheme } from '@material-ui/core/styles';

const history = createHistory();
//Fase 2
class App extends Component {

  constructor(props) {
    super(props);

    this.toggleTheme = (item) => {

      if (item && item.menu_description != '') {
        const colors = item.menu_description.split(",");
        this.setState({
          theme:
            createMuiTheme({
              palette: {
                primary: {
                  main: colors[0],
                  contrastText: '#fff',
                },
                secondary: {
                  main: colors[1] || colors[0],
                  contrastText: '#fff',
                },
              }
            })
        });
      } else {
        this.setState({
          theme: createMuiTheme(ThemePallete.sintesisTheme)
        });
      }

    };

    this.state = {
      theme: createMuiTheme(ThemePallete.sintesisTheme),
      toggleTheme: this.toggleTheme,
    };
  }

  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        <span>
          <Router history={history}>
            {renderRoutes(routes)}
          </Router>
        </span>
      </ThemeContext.Provider>
    );
  }
}

export default App;
