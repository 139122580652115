import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { NavLink, Route } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ls from 'local-storage';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
// import KeyboardArrowDown from '@material-ui/icons/North';
// import KeyboardArrowUp from '@material-ui/icons/North';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import SpecialIcons from '../SpecialIcons';

import styles from './sidebar-jss';
import { fetchCall } from '../../service/service_base';
import CustomNotification from '../Notification/CustomNotification';

class MainMenu extends React.Component {

  state = {
    dense: true,
    open: false,
    openSubMenu: false,
    openAddMenu: false,
    synthesis: [],
    isLoading: false,
    keyS3: '',
    openNotification: false,
    messageNotification: '',
    typeNotification: "success"
  }

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.setState({synthesis:this.props.synthesis})
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ synthesis: [] })
  }

  handleClick() {
    this.props.toggleDrawerOpen();
    this.props.loadTransition(false);
  }

  openNewMenu() {
    this.setState({ openAddMenu: true });
  }

  closeNewMenu = () => {
    this.setState({ openAddMenu: false });
  }

  handleClickExpand = () => {
    this.setState({ open: !this.state.open });
  }

  handleClickExpandSubMenu = () => {
    this.setState({ openSubMenu: !this.state.openSubMenu });
  }


  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  }

  _handleEditMenu = (menu_id,visible) => {
    let menu = ls.get("session_menu").find(item => item.menu_id === menu_id);
    if(menu){
      menu.menuId = menu_id
      menu.menu_visible=visible===1?0:1;
      menu.userId=ls.get("current_user").user_id;
      fetchCall("POST", "/synthesis/update", menu)
      .then(()=>{
        const menuupdate = this.state.synthesis.map(item=>{
          if(menu_id===item.id){
            item.visible=visible===1?0:1;
          }
          return item;
          
        })
        this.setState({synthesis:menuupdate})
      })
      .catch(err => console.log(err));
    }
    
  }

  _menuPosition = (pos,direction) => {
    const menu = this.state.synthesis;
      let findElement = menu.findIndex(x => x.menu_orden === pos);
      var aux = menu[findElement];
      if(aux&&menu.key!=="configuración"){
        switch (direction) {
          case "up":
            if (menu[findElement - 1] !== undefined) {
              menu[findElement] = menu[findElement - 1];
              menu[findElement].menu_orden = menu[findElement - 1].menu_orden;
              menu[findElement - 1] = aux;
              menu[findElement - 1].menu_orden = aux.menu_orden;
            }
            break;

          case "down":
            if (menu[findElement + 1] !== undefined && menu[findElement + 1].key!=="configuración") {
              menu[findElement] = menu[findElement + 1];
              menu[findElement].menu_orden = menu[findElement + 1].menu_orden;
              menu[findElement + 1] = aux;
              menu[findElement + 1].menu_orden = aux.menu_orden;
            }
            break;
          
          default:
            break;
        }
        this.setState({
          synthesis: menu
        }, () => {
          fetchCall("POST", "/synthesis/updateorder", {
            order: menu.map((v,k)=>({id:v.id,position:(k + 1)})),
            userId: ls.get("current_user").user_id,
            isMenu: 1
          }).catch(err => console.log(err))
        });
      }
  }

  render() {
    const {
      classes,
      addMenu,
      openNewMenu
    } = this.props;
    const {synthesis} = this.state;
    const renderSubmenuChild = child => child.map((item, index) => (
      <ListItem
        button
        key={`${item.name + index.toString()}`}
        className={classNames(classes.nested, classes.pUnset, classes.submenuItem)}
        activeClassName={classes.active}
        component={NavLink}
        to={`${item.link}`}
        onClick={() => this.handleClick()}
        dense={true}
      >
        <ListItemText className={classNames(classes.primary, classes.paddingtext)} inset primary={item.name} />
      </ListItem>
    ))

    const renderSubmenu = child => child.map((item, index) => (
      item.child !== undefined ?
        <React.Fragment key={`${item.name + index.toString()}`}>
          <ListItem
            button
            
            className={classNames(classes.nested, classes.pUnset, classes.submenu)}
            activeClassName={classes.active}
            component={item.child === undefined ? NavLink : "div"}
            to={`${item.link}`}
            onClick={item.child === undefined ? () => this.handleClick() : this.handleClickExpandSubMenu}
            dense={true}
          >
            <ListItemText classes={{ primary: classes.primary }} className={classes.minCollapse} inset primary={item.name} />
            {item.child !== undefined ? this.state.openSubMenu ? <ExpandLess /> : <ExpandMore /> : ''}
          </ListItem>
            {
              <Collapse in={this.state.openSubMenu} timeout="auto" unmountOnExit>
                {renderSubmenuChild(item.child)}
              </Collapse>
            }
         
        </React.Fragment>
        :
        <ListItem
          button
          key={`${item.name + index.toString()}`}
          className={classNames(classes.nested, classes.pUnset)}
          activeClassName={classes.active}
          component={NavLink}
          to={`${item.link}`}
          onClick={() => this.handleClick()}
          dense={true}
        >
          {item.icon &&
            <ListItemIcon>
              <Icon className={classes.icon}>{item.icon}</Icon>
            </ListItemIcon>
          }
          <ListItemText className={classNames(classes.primary, classes.paddingtext)} inset primary={item.name} />
              <Icon className={classes.icon}>{item.icon}</Icon>
        </ListItem>
    ))

    return (
      <div>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>

        <Route
          path='/synthesis/:id'
          render={(props) => { return ''; }}
        />
        {
          addMenu ?
            <ListItem
              button
              className={classNames(classes.nested, "itemText")}
              onClick={() => openNewMenu()}
            >
              <ListItemIcon>
                <Icon className={[classes.icon,classes.colorDos]}>add circle</Icon>
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} inset primary="Nueva Síntesis" />
            </ListItem> : null
        }
        {
        synthesis.map((item, index) =>
          (
            <React.Fragment key={index.toString()}>
              <ListItem
                button
                className={classNames(classes.nested, "itemText")}
                activeClassName={classes.active}
                component={item.child === undefined ? NavLink : "div"}
                to={addMenu?false:`${item.link}`}
                onClick={addMenu?null:(item.child === undefined ? () => this.handleClick() : this.handleClickExpand)}
              >
                {item.icon &&
                  <ListItemIcon>
                    <span className={classNames(classes.icon,classes.colorDos)}>
                      <SpecialIcons icon={item.icon}/>
                    </span>
                  </ListItemIcon>
                }
                <ListItemText classes={{ primary: classes.primary }} className={classes.minCollapse} inset primary={item.name} />
                {item.child !== undefined ? (this.state.open ? <ExpandLess /> : <ExpandMore />) :""} 
                {
                  item.child === undefined&&<Fragment>
                  {addMenu&&<div className={classes.arrowMenu}>
                        <Tooltip title="Subir">
                          {/* <Button variant="fab" mini aria-label="add" className={classes.button} onClick={()=>addMenu?this._menuPosition(item.menu_orden,"up"):null}>
                            <KeyboardBackspaceIcon />
                          </Button>   */}
                          <Icon
                            className={classNames(classes.iconArrow)} 
                            onClick={()=>addMenu?this._menuPosition(item.menu_orden,"up"):null}
                            style={{color:'dimgrey'}}>north
                          </Icon> 
                        </Tooltip>
                        <Tooltip title="Bajar">
                          {/* <Button variant="fab" mini aria-label="add" className={classes.button} onClick={()=>addMenu?this._menuPosition(item.menu_orden,"down"):null}>
                            <KeyboardBackspaceIcon />
                          </Button>   */}
                          <Icon
                            className={classNames(classes.iconArrow)} 
                            onClick={()=>addMenu?this._menuPosition(item.menu_orden,"down"):null}
                            style={{color:'dimgrey'}}>south
                          </Icon>  
                        </Tooltip>
                  </div>}
                  <Tooltip title={`${item.visible===1?'Ocultar':'Hacer visible'} esta síntesis en el menú público de todas las plataformas`}>
                    <Icon
                      className={classNames(classes.iconVisibility)} 
                      onClick={()=>addMenu?this._handleEditMenu(item.id,item.visible):null} 
                      style={{color:addMenu?'dimgrey':'lightgrey'}}>{item.visible===1?'visibility':'visibility_off'}
                    </Icon>
                  </Tooltip>
                  </Fragment>
                }
                
              </ListItem>
              {
                item.child !== undefined ? 
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  {renderSubmenu(item.child)}
                </Collapse> : ''
              }
            </React.Fragment>
          )
        )
        }
      </div>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  synthesis: PropTypes.array,
  openNewMenu: PropTypes.func
};

export default withStyles(styles)(MainMenu);
