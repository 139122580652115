import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CheckCircle from '@material-ui/icons/CheckCircle';
import Delete from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import { Input, InputLabel } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import styles from "./note-jss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ValidationContent, Numeric } from "../../../validator/Regex";
import { Editor } from "@bit/primefaces.primereact.editor";
import { charDescriptionLength, charTitleLength } from "../../../utils/validation";

class AddNewNoteForm extends React.Component {
  _isClean = false;

  state = {
    title: "",
    description: "",
    notas: 0,
    contId: 0,
    columnist: 0,
    isUpdating: false,
    imagePath: null,
    program: "",
    textLimited: 0
  };

  handleChange = (event, min) => {
    if (min == 4) {
      if (
        (Numeric(event.target.value) || event.target.value == "") &&
        event.target.value.toString().trim("").length <= min
      )
        this.setState({ [event.target.name]: event.target.value });
    } else {
      if (
        !ValidationContent(event.target.value) &&
        event.target.value.toString().trim("").length <= min
      )
        this.setState({ [event.target.name]: event.target.value });
    }

    if (event.target.name == "columnist") {
      let selectItem = this.props.dataSelect.find(
        x =>
          (this.props.type === "Kickers" ? x.kicker_id : x.columnist_id) ==
          event.target.value
      );
      this.setState({
        program:
          this.props.type === "Kickers"
            ? (selectItem ? selectItem.kicker_name + "," + selectItem.kicker_color : ",")
            : selectItem.columnist_name,
        title:
          this.props.type === "Editoriales"
            ? selectItem.columnist_name
            : this.state.title
      });
    }

    // if (event.target.name == "columnist" && this.props.type === "Editoriales") {
    //   let selectItem = this.props.dataSelect.find(x => (this.props.type === "Kickers" ? x.kicker_id : x.columnist_id) == event.target.value)
    //   this.setState({ title: });
    // }

    if (
      event.target.name == "columnist" &&
      (this.props.type === "Columnas" || this.props.type === "Editoriales")
    ) {
      let selectItem = this.props.dataSelect.find(
        x => x.columnist_id == event.target.value
      );
      console.log(selectItem);
      this.setState({
        imagePath: selectItem.columnist_photo ? selectItem.columnist_photo : ""
      });
      this.props.onDrop(
        [],
        selectItem.columnist_photo ? selectItem.columnist_photo : "null"
      );
    }
  };

  _cleanData = band => {
    if (band) {
      this.setState({
        title: this.props.dataInit ? this.props.dataInit.cont_name : "",
        description: this.props.dataInit
          ? this.props.dataInit.cont_description
          : "",
        notas: this.props.dataInit ? this.props.dataInit.cont_notas : 0,
        columnist: this.props.dataInit ? this.props.dataInit.columnist_id : 0,
        imagePath: this.props.dataInit
          ? this.props.dataInit.cont_imagePath
          : null
      });
    } else {
      this.setState({
        title: "",
        description: "",
        notas: 0,
        contId: 0,
        columnist: 0,
        imagePath: null,
        program: "",
        textLimited: 0
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.dataInit != null) {
      this.setState({
        isUpdating: true,
        contId:
          this.state.contId !== 0
            ? this.state.contId
            : newProps.dataInit.cont_id,
        title:
          this.state.title !== ""
            ? this.state.title
            : newProps.dataInit.cont_name,
        description:
          this.state.description == "" || this.state.description == null
            ? newProps.dataInit.cont_description
            : this.state.description,
        notas:
          this.state.notas !== 0
            ? this.state.notas
            : newProps.dataInit.cont_notas,
        columnist:
          this.state.columnist !== 0
            ? this.state.columnist
            : newProps.dataInit.columnist_id,
        program:
          this.state.program === ""
            ? newProps.dataInit.cont_program
            : this.state.program,
        imagePath:
          this.state.imagePath !== null
            ? this.state.imagePath
            : newProps.dataInit.cont_key
      });
    } else {
      if (newProps.isOpen && this.state.notas == 0) {
        this.setState({ notas: 1 });
      }
    }

    if (!newProps.isOpen) {
      this._cleanData(false);
      this.setState({
        isUpdating: false
      });
    }
  }

  addDefaultSrc(ev) {
    ev.target.src = "";
  }

  handleTextEditor = event => {
    if (event.textValue.toString().trim("").length <= charDescriptionLength) {
      this.setState({ description: event.htmlValue && event.htmlValue.replace("background", ""), textLimited: false });
    } else {
      this.setState({
        textLimited: event.textValue.toString().trim("").length
      });
    }
  };
  renderHeader() {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold" />
        <button className="ql-italic" aria-label="Italic" />
        <button className="ql-underline" aria-label="Underline" />
        <select className="ql-color"></select>
      </span>
    );
  }
  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit,
      onDrop,
      preview,
      errorMessage,
      addAttachment,
      attachment,
      dataSelect,
      deleteSelectedPic,
      type
    } = this.props;
    let dropzoneRef;
    let dropzoneAttachment;
    const acceptedAttachment = [
      "video/mp4",
      "application/x-mpegURL",
      "video/MP2T",
      "video/3gpp",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-ms-wmv",
      "image/ief",
      "image/jpeg",
      "image/bmp",
      "image/gif",
      "image/png",
      "image/svg+xml",
      "audio/basic",
      "auido/L24",
      "audio/mpeg",
      "audio/mp4",
      "audio/vnd.wav",
      "application/pdf",
      "audio/mp3"
    ];
    const header = this.renderHeader();
    const acceptedFiles = ["image/jpeg", "image/png"];
    const fileSizeLimit = 939999999;
    return (
      <div>
        <form
          onSubmit={event => {
            event.preventDefault();
            var tempState = this.state;
            var titulo = tempState.title.split("'");
            var descripcion = tempState.description ? tempState.description.split("'") : [``];
            if (titulo) {
              var tempTitulo = "";
              titulo.forEach(item =>
                item === ""
                  ? (tempTitulo = tempTitulo + item + "''")
                  : (tempTitulo = tempTitulo + item)
              );
              tempState.title = tempTitulo;
            }
            if (descripcion) {
              var tempDescription = "";
              descripcion.forEach(item =>
                item === `` ? (tempDescription = `${tempDescription}${item} `) : (tempDescription = `${tempDescription}${item}`)
              );
              tempState.description = tempDescription;
            }
            handleSubmit(event, tempState);
            this.props.onClean();
            setTimeout(() => {
              this._cleanData();
            }, 500);
          }}
        >
          <section className={classes.bodyForm}>
            <div>
              <div>
                <Typography
                  variant="button"
                  className={classes.textCenter}
                  style={{
                    display:
                      type == "Editoriales" || type == "Columnas"
                        ? "none"
                        : "block"
                  }}
                >
                  SUBIR IMAGEN PRINCIPAL
                </Typography>

                <Dropzone
                  className={classes.hiddenDropzone}
                  accept={acceptedFiles.join(",")}
                  acceptClassName="stripes"
                  onDrop={event => onDrop(event, null)}
                  maxSize={fileSizeLimit}
                  ref={node => {
                    dropzoneRef = node;
                  }}
                  style={{
                    display:
                      type == "Editoriales" || type == "Columnas"
                        ? "none"
                        : "block"
                  }}
                />
                <div className={classes.avatarWrap}>
                  <Avatar
                    className={classes.uploadAvatar}
                    src={preview}
                    onError={event => this.addDefaultSrc(event)}
                  />
                  <Tooltip id="tooltip-upload" title="Subir foto">
                    <IconButton
                      className={classes.buttonUpload}
                      component="button"
                      onClick={() => {
                        dropzoneRef.open();
                      }}
                      style={{
                        display:
                          type == "Editoriales" || type == "Columnas"
                            ? "none"
                            : "block"
                      }}
                    >
                      <PhotoCamera />
                    </IconButton>
                  </Tooltip>
                  <div
                    className={classes.deleteImage}
                    style={{
                      display:
                        type == "Editoriales" || type == "Columnas"
                          ? "none"
                          : "block"
                    }}
                  >
                    <Tooltip title="Eliminar">
                      <Button
                        variant="fab"
                        mini
                        className={classes.button}
                        onClick={deleteSelectedPic}
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <Typography variant="button" className={classes.textCenter}>
                {errorMessage}
              </Typography>
              <div
                style={{ display: type === "Editoriales" ? "none" : "block" }}
              >
                <InputLabel htmlFor="title">Título *</InputLabel>
                <Input
                  id="title"
                  name="title"
                  value={this.state.title}
                  type="text"
                  autoComplete="off"
                  required={type === "Editoriales" ? false : true}
                  className={classes.field}
                  startAdornment={
                    <InputAdornment position="start">
                      <PermContactCalendar />
                    </InputAdornment>
                  }
                  onChange={event => this.handleChange(event, charTitleLength)}
                />
              </div>
              {this.state.title.length === charTitleLength && (<div>Has llegado al límite de caracteres ({charTitleLength})</div>)}
              <div>
                <InputLabel htmlFor="notes">{type === "Columnas" ? "Columnista *" : type === "Editoriales" ? "Editorial" : "Kicker *"}</InputLabel>
                {dataSelect.length > 0 ? (
                  <Select
                    value={this.state.columnist}
                    onChange={event => this.handleChange(event, 100)}
                    className={classes.field}
                    name="columnist"
                    required={true}
                  >
                    {type === "Kickers" && <MenuItem value={0}>Ninguno</MenuItem>}
                    {dataSelect.map((item, index) => {
                      return (
                        <MenuItem
                          key={index.toString()}
                          value={
                            type === "Columnas"
                              ? item.columnist_id
                              : type === "Editoriales"
                                ? item.columnist_id
                                : item.kicker_id
                          }
                        >
                          {type === "Columnas"
                            ? item.columnist_name
                            : type === "Editoriales"
                              ? item.columnist_name
                              : item.kicker_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <div>No hay {type} disponibles</div>
                )}
              </div>
              <div>
                <InputLabel htmlFor="notas">Descripción</InputLabel>
                <Editor
                  headerTemplate={header}
                  style={{ height: "100px", marginBottom: "15px" }}
                  value={this.state.description}
                  onTextChange={event => this.handleTextEditor(event)}
                />
                {this.state.textLimited > charDescriptionLength && (
                  <div>
                    Has llegado al límite de caracteres ({this.state.textLimited}
                    )
                  </div>
                )}
              </div>
              <div
                style={{
                  display:
                    type == "Editoriales" || type == "Columnas"
                      ? "none"
                      : "block"
                }}
              >
                <InputLabel htmlFor="notes">Notas</InputLabel>
                <Input
                  id="notas"
                  name="notas"
                  value={this.state.notas}
                  type="text"
                  autoComplete="off"
                  className={classes.field}
                  startAdornment={
                    <InputAdornment position="start">
                      <InsertDriveFile />
                    </InputAdornment>
                  }
                  pattern="[0-9]*"
                  onChange={event => this.handleChange(event, 4)}
                />
              </div>
              <div>
                <Dropzone
                  className={classes.hiddenDropzone}
                  accept={acceptedAttachment.join(",")}
                  acceptClassName="stripes"
                  onDrop={addAttachment}
                  // maxSize={fileSizeLimit}
                  ref={node => {
                    dropzoneAttachment = node;
                  }}
                />
                <div className={classes.wraperUploadFile}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={() => {
                      dropzoneAttachment.open();
                    }}
                  >
                    Subir Archivos
                  </Button>
                  {attachment || (this.props.dataInit && this.props.dataInit.cont_attachment) ? <CheckCircle /> : null}
                  {attachment ? <small title={attachment.name}>{attachment.name}</small> : this.props.dataInit && <small title={this.props.dataInit.cont_attachment}>{this.props.dataInit.cont_attachment}</small>}

                </div>
              </div>
            </div>
          </section>
          <div className={classes.buttonArea}>
            <Button
              variant="raised"
              color="primary"
              type="submit"
              disabled={
                (this.state.title != "" && this.state.columnist!==0 && (this.state.textLimited <= charDescriptionLength)) 
                  ? false : true
              }
            >
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => {
                this.props.onClean();
                setTimeout(() => {
                  this._cleanData(true);
                }, 500);
              }}
            >
              Deshacer
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

AddNewNoteForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  dataInit: PropTypes.object,
  isOpen: PropTypes.bool
};

export default withStyles(styles)(AddNewNoteForm);
