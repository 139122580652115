const drawerWidth = 240; 
const styles = theme => ({
  usuario: {
    display: 'block',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    '& h4': {
      margin: 0,
      fontSize: '1em !important',
    },
    '& span': {
      fontSize: '.8rem !important',
    },
    [theme.breakpoints.up('lg')]:{
      color: theme.palette.primary.contrastText
    },
  },
  user: {
    justifyContent: 'center'
  },
  drawerPaper: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    border: 'none',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  swipeDrawerPaper: {
    width: drawerWidth,
  },
  opened: {
    background: theme.palette.grey[200],
    '& $primary, & $icon': {
      color: theme.palette.secondary.dark,
    },
  },
  drawerPaperClose: {
    width: 66, 
    // antes absolute 
    position: 'relative', 
    // overflowX: 'hidden', 
    transition: theme.transitions.create('width', { 
      easing: theme.transitions.easing.sharp, 
      duration: theme.transitions.duration.leavingScreen, 
    }),
    '& $user': {
      justifyContent: 'center',
      alignItems: 'flex-end',
      //marginTop: 40,
    },
    '& $bigAvatar': {
      display: 'none'
      // width: 40,
      // height: 40,
    },
    '& $usuario': {
      display: 'none'
    },
    '& div div div div a':{
      display: 'none'
    },
    '& li ul': {
      display: 'none'
    },
    '&:hover': {
      width: '240px !important',
      //backgroundColor: '#ffffff',
      //boxShadow: theme.shadows[6],
      '& $bigAvatar': {
        margin: '0 auto',
        display: 'block'
      },
      '& li ul': {
        display: 'block'
      },
      '& $usuario': {
        display: 'block'
      },
      '& div div div div a':{
        display: 'block'
      },
      // '& $bigAvatar': {
      //   display: 'block'
      // },
    },
    '& $menuContainer': {
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 1.5,
    }
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
    height: '100%'
  },
  drawerHeader: {
    background: 'rgba(255, 255, 255, 0)',
    color: theme.palette.primary.contrastText,
    height: 184,
    padding: '0',
    ...theme.mixins.toolbar,
    '& h3': {
      color: theme.palette.primary.contrastText,
    }
  },
  avatar: {
    margin: 10,
    border: '2px solid #FF5A1A',
    '& div':{
      width: '100%',
      height: '100%',
      '& img': {
        height: '100%',
        objectFit: 'cover'
      }
    }
  },
  bigAvatar: {
    width: 80,
    height: 80,
  },
  brandBar: {
    transition: theme.transitions.create(['width', 'margin', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:after': {
      transition: theme.transitions.create(['box-shadow'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
  },
  darker: {
    background: theme.palette.primary.dark,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 1.5,
  },
  child: {
    '& a': {
      paddingLeft: theme.spacing.unit * 6,
    }
  },
  dense: {
    marginLeft: -15
  },
  active: {
    background: theme.palette.grey[200],
   //backgroundColor: theme.palette.secondary.light,
    '& $primary, & $icon': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: 'darkgrey',
    }
  },
  nolist: {
    listStyle: 'none',
  },
  primary: {},
  icon: {
    marginRight: 0,
    color: theme.palette.secondary.main,
    maxWidth: 24,
    maxHeight: 24,
  },
  iconArrow:{
    color: 'dimgrey',
    fontSize: '1rem',
    '&:hover': {
    }
  },
  iconVisibility:{
    color: 'dimgrey',
    // fontSize: '.9rem',
    '&:hover': {
    }
  },
  head: {
    paddingLeft: 16
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 10px 5px',
    height: 64,
    position: 'relative',
    '& img': {
      width: 20
    },
    '& h3': {
      fontSize: 16,
      margin: 0,
      paddingLeft: 10,
      fontWeight: 500
    }
  },
  profile: {
    height: 120,
    display: 'flex',
    fontSize: 14,
    padding: 10,
    alignItems: 'center',
    '& h4': {
      fontSize: 18,
      marginBottom: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 110
    },
    '& span': {
      fontSize: 12,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 110,
      display: 'block',
      overflow: 'hidden'
    }
  },
  submenu: {
    '& >div':{
      paddingLeft: '20px !important',
    }
  },
  submenuItem: {
    textAlign: 'right',
  },
  menuContainer: {
    padding: '8px 5px', 
    [theme.breakpoints.up('lg')]: { 
      padding: theme.spacing.unit * 1.5 + 'px ' + '0', 
    }, 
    paddingRight: theme.spacing.unit, 
    overflow: 'auto', 
    height: 'calc(100% - 185px)', 
    position: 'relative', 
    display: 'block' 
  },
  divider: {
    marginTop: theme.spacing.unit
  },
  adminActions: {
    position: 'absolute',
    top: 165,
    right: 4,
    zIndex: 1
  },
  colorUno: {
    color: '#174A7C',
    '& $checked': {
      color: '#174A7C',
    },
  },
  colorDos: {
    color: '#008085',
    '& $checked': {
      color: '#008085',
    },
  },
  colorTres: {
    color: '#66CDCD',
    '& $checked': {
      color: '#66CDCD',
    },
  },
  colorCuatro: {
    color: '#FF5E3E',
    '& $checked': {
      color: '#FF5E3E',
    },
  },
  paddingtext: {
    paddingLeft: '20px !important',
  },
  pUnset: {
    padding: '5px 0px 5px 16px !important',
  },
  colorEdit: {
    backgroundColor: '#008085'
  },
  version: {
    color:'lightgrey',
    fontSize: '10px'
  },
  accionDivider2: {
    color: '#cacaca',
    // backgroundColor: '#fff',
    // top: -20,
    // right: 0,
    cursor: 'pointer',
    // padding: '10px 25px',
    position: 'absolute',
    // textDecoration: 'none'
  },
  minCollapse: {
    paddingLeft: '10px !important'
  },
  arrowMenu: {
    padding: '5px 8px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      width: 20,
      height: 20,
      minHeight: 20,
      marginLeft: 5,
      //boxShadow: 'none'
      backgroundColor: '#fff',
      // border
    },
    '& svg, span': {
      fontSize: 18,
      width: 20,
      paddingLeft: '0 5px',
      //color: theme.palette.primary.main,
    }
  }
});

export default styles;
