import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import FloatingPanel from '../../../components/Panel/FloatingPanel';
import AddNewEventForm from './AddNewEventForm';

class AddNewEvent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: [],
      selectedPicture: null
    }; 
  }
 
  render() {
    const {
      openForm,
      closeForm,
      dataInit,
      submit,
      selectedDate,
      action
    } = this.props;
    const { img } = this.state;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${action} Evento ${selectedDate ? selectedDate: ''}`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddNewEventForm  
            handleSubmit={(event, data)=>submit(event, data)}
            isOpen={openForm}
            dataInit={dataInit}
          />
        </FloatingPanel>
      </div>
    );
  }
}

AddNewEvent.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func, 
  openForm: PropTypes.bool, 
  closeForm: PropTypes.func,
  selectedDate:PropTypes.string,
  dataInit: PropTypes.object,
};

export default (AddNewEvent);
