import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import brand from '../../../../utils/brand';
import ContactHeader from '../../../../components/Contact/ContactHeader';
import ContactList from '../../../../components/Contact/ContactList';
import ContactDetail from '../../../../components/Contact/ContactDetail';
import AddContact from '../../../../components/Contact/AddContact';
import styles from '../../../../components/Contact/contact-jss';
import { fetchCall } from '../../../../service/service_base';
import { saveUserPhoto } from '../../../../service/s3Amplify';
import ls from 'local-storage';
import Snackbar from '@material-ui/core/Snackbar';
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import CustomNotification from '../../../../components/Notification/CustomNotification';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import XLSX from 'xlsx';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { ThemeContext } from '../../../../utils/ThemeContext';
import { cognitoSignUp } from '../../../../service/cognitoService';

class Contact extends React.Component {
  state = {
    export: null,
    darBaja: false,
    type: "",
    open: false,
    close: false,
    searchData: [],
    data: [],
    selectedUser: null,
    profiles: [],
    img: '',
    selectedPicture: '',
    openNotification: false,
    typeNotification: "info",
    messageNotification: "",
    canEdit: true,
    canShow: false,
    canPublish: true,
    canDelete: true,
    canBlock: true,
    canUnBlock: true,
    isLoading: true,
    isMobile: true,
    availableSynthesis: ls.get('session_menu'),
    currentUser: ls.get("current_user")['user_id'],
    headers: ["NOMBRE", "APELLIDO", "USUARIO", "CORREO ELECTRÓNICO", "NÚMERO DE EMPLEADO", "PERFIL", "SÍNTESIS", "ESTATUS"]
  }

  handleCloseNotification = () => {
    this.setState({
      openNotification: false
    })
  }

  componentDidMount() {
    this.context.toggleTheme(undefined);
    this._getUserPermissions();
  }

  _getUserPermissions = () => {
    let currentUser = ls.get("current_user");
    let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
    if (permissionsCurrentUser[0] != null) {
      let add = permissionsCurrentUser.filter(x => x.name === "Configuración/Administración de usuarios")[0];

      if (add != undefined || add != null) {
        let currentPermissions = ls.get("permissions");
        if (currentPermissions != undefined || currentPermissions != null) {

          let data = currentPermissions.filter(x => x.menu_id == add.id)[0];

          if (data != undefined) {
            let config = JSON.parse(data.grant_configuration);

            if (config.chkViewAdminUser) {

              this._getUsers();
              fetchCall("GET", "/profile/getList").then(response => {
                this.setState({ profiles: response.data.filter(x => x.status === "ON") });
              }).catch(err => console.log(err));
            }

            this.setState({
              canShow: config.chkViewAdminUser,
              canEdit: !config.chkEditAdminUser,
              canPublish: !config.chkPublishAdminUser,
              canDelete: !config.chkDeleteAdminUser,
              canBlock: !config.chkBlockAdminUser,
              canUnBlock: !config.chkUnblockAdminUser
            });

          }
        }
      }
    }
  }

  _getUsers = (idSelected) => {
    fetchCall("GET", "/user/getuserlist").then(response => {
      this.setState({
        searchData: response.data,
        data: response.data,
        selectedUser: idSelected ? response.data.find(e => e.userId == idSelected.userId) : response.data[0],
        isLoading: false
      });
    }).catch(err => console.log(err));
  }

  onDrop(files) {
    this.setState({ img: files[0].preview || '/pic' + files[0].path, selectedPicture: files });
  }

  submitContact = (event, data, file) => {
    event.preventDefault();

    this.setState({ isLoading: true });
    //new permissions for sintesis
    let viewSynthesis = new Array();
    this.state.availableSynthesis.map(
      item => {
        if (data[`menu_id${item.menu_id}`]) {
          viewSynthesis.push({
            id: item.menu_id,
            status: 1
          });
        } else {
          viewSynthesis.push({
            id: item.menu_id,
            status: 0
          });
        }
      });

    data.availableSynthesis = viewSynthesis;
    if (this.state.type == "edit") {
      const oldProfile = this.state.data.find(x => x.userId === this.state.selectedUser.userId).idProfile;
      Promise.all([(file ? saveUserPhoto(this.state.selectedUser.userId + "_" +  `${new Date().getTime()+"_" + file.name }`.replace(/[^0-9A-Z._]+/gi,"-") , file, file.type) : null)])
        .then((response) => {
          data.photo = response[0] ? response[0].key : (this.state.selectedUser.photo !== null ? this.state.selectedUser.photo : ' ');
          data.userId = this.state.selectedUser.userId;
          data.updatedBy = this.state.currentUser;
          data.pass = btoa(data.pass)
          fetchCall("POST", "/user/update", data).then(response => {
            if (response.status == 200) {
              if (data.idStatus != this.state.selectedUser.status)
                this.blockUser(data.idStatus)
              if (oldProfile !== data.idProfile) {
                fetchCall("POST", "/report/addlogmovements", {
                  username: ls.get("username"),
                  action: "Cambio de Perfil",
                  description: `El usuario ${`${ls.get("username")}`.split("@")[0]} realizó el cambio de perfil ${this.state.profiles.find(x => x.profileId === oldProfile).name} a ${this.state.profiles.find(x => x.profileId === data.idProfile).name} al usuario ${data.email}`,
                  userId: this.state.currentUser
                })
              }
              this.setState({
                open: false,
                openNotification: true,
                messageNotification: 'Usuario actualizado',
                typeNotification: "success",
                isLoading: false,
              });
              this._getUsers(data);
            }
          }).catch(err => console.log("ERROR UPDATING USER", err));
        });
    } else {
      const userdata = {
        username: `${data.email}`.split("@")[0],
        cred_username: data.email,
        cred_password: data.email,
        email: data.email,
        profileActive: data.idProfile,
        name: data.username,
        lastname: data.lastname,
        id_empleado: 'Externo',
        updatedBy: this.state.currentUser,
        availableSynthesis: data.availableSynthesis
      }
      const email = `${data.email}`.split("@");
      cognitoSignUp({
        username: email[0] + "_" + email[1], password: `7313vI54*${new Date().getFullYear()}`,
        attributes: { email: data.email, profile: 'Externo', name: data.username, family_name: data.lastname }
      }).then(user => {
        fetchCall("POST", "/user/addexternal", userdata).then(response => {
          if (response.status === 200) {
            fetchCall("POST", "/user/resetPassword", {
              type: "passwordUpdatedAt",
              username: email[0] + "_" + email[1]
            });
            fetchCall("POST", "/report/addlogmovements", {
              username: ls.get("username"),
              action: "Nuevo Usuario",
              description: `El usuario ${`${ls.get("username")}`.split("@")[0]} dio de alta el usuario ${data.email}`,
              userId: this.state.currentUser
            });
            this.setState({
              open: false,
              openNotification: true,
              messageNotification: 'Usuario agregado',
              typeNotification: "success",
              isLoading: false,
            });
            this._getUsers();
          }
        }).catch(err => console.log("ADD USER ERROR", err));
      }).catch(e => {
        this.setState({
          open: false,
          openNotification: true,
          messageNotification: e.code == "UsernameExistsException" ? 'Ya existe en el portal un usuario con este correo, favor de validar' : 'Ocurrió un error al realizar la solicitud',
          typeNotification: "error",
          isLoading: false,
        });
        console.log("signUP", e);
      });
    }
  }

  downloadUsers = (type) => {
    let data = type == 'xlsx' ? [this.state.headers] : [];
    this.state.searchData.map(item => {
      let names = "";
      if (item.availableSynthesis) {
        let parse = JSON.parse(`[${item.availableSynthesis}]`);
        if (parse.length < this.state.availableSynthesis.length)
          parse.map(item => {
            const synthesis = this.state.availableSynthesis.find(x => x.menu_id === parseInt(item.id));
            if (synthesis)
              names += `${synthesis.menu_name}, `
          })
      }
      let ob = [
        item.username,
        item.lastname,
        item.email && item.email.split('@')[0],
        item.email,
        item.employeeid,
        item.profile,
        names != "" ? names : this.state.availableSynthesis.map(e => e.menu_name).join(', '),
        item.status == 0 ? "Inactivo" : (item.status == 2 ? "Bloqueado" : "Activo")
      ]
      data.push(ob)
    });
    this.setState({ export: null })
    if (type == 'xlsx') {
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      let wscols = [
        { wch: 18 },
        { wch: 22 },
        { wch: 15 },
        { wch: 28 },
        { wch: 20 },
        { wch: 26 },
        { wch: 40 },
        { wch: 15 }
      ];
      let wsrows = [
        { hpt: 35 }
      ];
      ws['!rows'] = wsrows;
      ws['!cols'] = wscols;
      XLSX.utils.book_append_sheet(wb, ws, "Listado de usuarios");
      XLSX.writeFile(wb, `portalsintesisadmon_usuarios.xlsx`);
    } else {
      const doc = new jsPDF({
        orientation: "landscape",
        format: ' legal'
      })
      doc.setFontSize(8)
      jsPDF.autoTableSetDefaults({
        headStyles: { fillColor: [23, 74, 124] }
      }, doc);
      doc.autoTable({
        head: [this.state.headers],
        body: data,
        startY: 10,
        margin: 10,
        styles: { overflow: "linebreak", columnWidth: "auto" },
        columnStyles: { 2: { cellWidth: 30 }, 3: { cellWidth: 60 }, 7: { cellWidth: 20 } },
      })
      doc.save('portalsintesisadmon_usuarios.pdf')
    }
  }

  _openAddUser = () => {
    this.setState({ open: true, type: "new" });
  }

  _closeAddUser = () => {
    this.setState({ open: false });
  }

  showDetail = (data) => {
    this.setState({ selectedUser: data, open: false, isMobile: true })
  }

  edit = () => {
    this.setState({ open: true, type: "edit" });
  }

  blockUser = (status) => {
    this.setState({ darBaja: false, isLoading: true });
    fetchCall("POST", "/user/blockUser", {
      userId: this.state.selectedUser.userId,
      userStatus: status
    }).then(response => {
      if (response.status == 200) {
        fetchCall("POST", "/report/addlogmovements", {
          username: ls.get("username"),
          action: this.state.selectedUser.status == 1 ? "Bloqueo de Usuarios" : "Desbloqueo de Usuarios",
          description: `El usuario ${`${ls.get("username")}`.split("@")[0]}` + (this.state.selectedUser.status == 1 ? ' bloqueó al usuario ' : " desbloqueó al usuario ") + `${this.state.selectedUser.email}`.split("@")[0],
          userId: this.state.currentUser
        })
        this.setState({
          openNotification: true,
          messageNotification: 'Usuario actualizado',
          typeNotification: 'success',
          isLoading: false
        });

        this._getUsers(this.state.selectedUser);
      }
    }).catch(err => console.log(err));
  }

  search = (event) => {
    let searchWord = event.target.value.toString().toLowerCase();
    let result = this.state.data.slice(0);
    var arr = new Array();
    result = result.filter(
      x => {
        if (`${x.username} ${x.lastname}`.toString().toLocaleLowerCase().includes(searchWord) 
        || x.profile.toString().toLowerCase().includes(searchWord) 
        || x.employeeid.toString().toLowerCase().includes(searchWord)
        || x.email.toString().toLowerCase().includes(searchWord)) {
          arr.push(x);
        }
      }
    )
    this.setState({ searchData: arr });
  }

  _cleanInput = () => {
    this.setState({ searchData: this.state.data });
  }

  changeMobile = () => {
    if (this.state.isMobile) {
      this.setState({ isMobile: false })
    } else {
      this.setState({ isMobile: true })
    }
  }

  render() {
    const title = brand.name + ' - Usuarios';
    const description = brand.desc;
    const {
      classes,
      favorite,
      keyword,
    } = this.props;
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        {/* <Notification close={() => closeNotif()} message={messageNotif} /> */}
        {this.state.isLoading ? <LoaderFull /> : ''}
        <div className={classes.root}>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.export !== null}
            onClose={() => this.setState({ export: null })}
          >
            <div style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              overflow: 'hidden',
              maxHeight: 200,
              backgroundColor: 'white',
              textAlign: 'right',
              position: 'absolute',
              width: '90%',
              maxWidth: 200,
              outline: 'none',
              borderRadius: 5,
              padding: '0 0 1rem'
            }}>
              <Button onClick={() => this.setState({ export: null })} color="primary">Cerrar</Button>
              <div style={{ textAlign: 'center' }}>
                <p style={{ margin: '1rem', fontSize: "larger" }}>Descargar archivo</p>
                <Button onClick={() => this.downloadUsers('xlsx')} style={{ color: 'mediumseagreen' }}>
                  EXCEL
                </Button>
                <Button onClick={() => this.downloadUsers('pdf')} style={{ color: 'red' }} >
                  PDF
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.darBaja}
            onClose={() => this.setState({ darBaja: false })}
          >
            <div style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'right',
              position: 'absolute',
              borderRadius: 5,
              padding: '0 0 1rem'
            }}>
              <div style={{ textAlign: 'center', margin: '1rem' }}>
                <p style={{ fontSize: "larger" }}>¿Está seguro de dar de baja al usuario {this.state.selectedUser && this.state.selectedUser.email}?</p>
                <p style={{ fontSize: "small" }}>Estatus Inactivo: el usuario ya no colabora más con televisa y/o ya no debe tener acceso al sistema por ninguna plataforma.</p>
                <Button onClick={() => this.blockUser(0)}>
                  Continuar
                </Button>
                <Button onClick={() => this.setState({ darBaja: false })} style={{ color: 'grey' }} >
                  Cancelar
                </Button>
              </div>
            </div>
          </Modal>
          <ContactHeader
            hideDetail={this.changeMobile}
            addContact={this._openAddUser}
            total={this.state.searchData.length}
            showMobileDetail={this.state.isMobile}
            download={() => this.setState({ export: true })}
          />
          <ContactList
            clippedRight
            itemSelected={this.state.selectedUser}
            dataContact={this.state.searchData}
            showDetail={this.showDetail}
            search={this.search}
            keyword={keyword}
            clean={this._cleanInput}
          />
          <ContactDetail
            showMobileDetail={this.state.isMobile}
            dataContact={this.state.data}
            itemSelected={this.state.selectedUser}
            edit={this.edit}
            block={this.blockUser}
            favorite={favorite}
            permissionsEdit={this.state.canEdit}
            permissionsDelete={this.state.canDelete}
            permissionBlock={this.state.canBlock}
            permissionUnblock={this.state.canUnBlock}
            availableSynthesis={this.state.availableSynthesis}
            darBaja={() => this.setState({ darBaja: true })}
          />
        </div>
        <AddContact
          type={this.state.type}
          openForm={this.state.open}
          closeForm={this._closeAddUser}
          submit={this.submitContact}
          avatarInit="https://www.drupal.org/files/issues/default-avatar.png"
          dataContact={this.state.data}
          dataInit={this.state.selectedUser}
          profiles={this.state.profiles}
          onDrop={this.onDrop}
          availableSynthesis={this.state.availableSynthesis}
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object,
  avatarInit: PropTypes.string,
  fetchData: PropTypes.func,
  showDetail: PropTypes.func,
  hideDetail: PropTypes.func,
  keyword: PropTypes.string,
  open: PropTypes.bool,
  showMobileDetail: PropTypes.bool,
  add: PropTypes.func,
  close: PropTypes.func,
  submit: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  favorite: PropTypes.func,
  search: PropTypes.func,
  dataContact: PropTypes.object,
  itemSelected: PropTypes.number,
  closeNotif: PropTypes.func,
  messageNotif: PropTypes.string,
};

Contact.contextType = ThemeContext;

export default withStyles(styles)(Contact);
