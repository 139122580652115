import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

//import LoginForm from '../../../components/Forms/LoginForm';
import LoginForm from '../../../components/Forms/LoginForm';
import styles from './../../../components/Forms/user-jss';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification'; 
// import logo_televisa from '../../../../static/images/logo-televisa.svg';
import './Login.css' 

class Login extends React.Component {
  
  constructor(props) {
    super(props);      
  }
 
  state = {
    valueForm: [],
    openNotification: false,
    requireChangePassword : false,
    messageNotification :'',
    typeNotification:'default',
    userData : null,
    isLoading: false,
    completedLoading: 0,
    authState:'loading'
  }
 
  handleCloseNotification = () =>{
    this.setState({openNotification:false})
  }
  
  render() {
    const title = brand.name + ' - Login';
    const description = brand.desc;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <Grid container spacing={24} alignItems="center" direction="row" justify="center">
            <Grid item container justify="center" spacing={0} className={classes.loginWrap}>
              {/* <Hidden smDown> */}
                <Grid item md={6} sm={8} xs={11} className={classes.welcomeWrap}>
                  {/* Welcome Login */}
                  <div className={classes.welcome}>
                    {/* <div className={classes.welcomeContent}>
                      <div className={classes.brand}> */}
                        {/* <img src={logo_televisa} alt={brand.name} className="centrarLogo" /> */}
                        <LoginForm />
                      {/* </div>
                    </div>
                    <ArrowForward className={classes.decoBottom} /> */}
                  </div>
                </Grid>
              {/* </Hidden> */}
              <Hidden xsUp={true}>
              <Grid item md={6} sm={8} xs={11}>  

              {/* <div className="App">
                {authState === 'loading' && (<div>loading...</div>)}
                {authState === 'signIn' && <OAuthButton/>}
                {authState === 'signedIn' && <button onClick={this.signOut}>Sign out</button>}
              </div> 

                 {
                  this.state.isLoading ?
                  <PapperBlock className={classes.centerProgress}>
                    <div className={classes.centerProgress}>
                    <CircularProgress
                        size={50} 
                      /> 
                    </div>
                  </PapperBlock>
                  : this.state.requireChangePassword ?
                  <NewPassword user={this.state.userData} pwd={this.state.valueForm.password} /> :
                  <LoginForm />
                }  */}              
              </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </div>

      {/*for error notifications*/}
      <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>

      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login); 
