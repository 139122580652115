import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import SpecialIcons from '../SpecialIcons';
import styles from './style-jss';

const buttons = ['ChromeReaderMode', 'WbTwighlight', 'HomeRepairService', 'SportsSoccer', 'AccountBalance', 'Stars', 'LibraryBooks', 'Map'];

function SelectorIcon({
  classes,
  onChange,
  value
}) {

  return (
    <div className={classes.root}>
      {buttons.map((button) => (
        <span
          key={button}
          onClick={() => onChange(button)}
          className={classNames(classes.itemIcon, { [classes.active]: value === button })}
        >
          <SpecialIcons icon={button} />
        </span>
      ))}
    </div>
  );
}

SelectorIcon.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(SelectorIcon);