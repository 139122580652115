import React from 'react';
import { object, PropTypes } from 'prop-types';
import { renderRoutes } from 'react-router-config';
import Loading from 'react-loading-bar';
import {
  withTheme, withStyles,
  createMuiTheme, MuiThemeProvider
} from '@material-ui/core/styles';
import ThemePallete from '../../utils/themePalette';
import '../../styles/components/vendors/react-loading-bar/index.css'; 
import styles from './appStyles-jss';
import {ThemeContext} from '../../utils/ThemeContext';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: true,
      theme: createMuiTheme(ThemePallete['sintesisTheme']),
    };
  }

  componentWillMount = () => {
    this.onProgressShow();
  }

  componentDidMount = () => {
    this.playProgress();
    this.unlisten = this.props.history.listen(() => {
      this.playProgress();
    });
  }

  componentWillUnmount() {
    this.unlisten();
    this.onProgressShow();
  }

  onProgressShow = () => {
    this.setState({ pageLoaded: true });
  }

  onProgressHide = () => {
    this.setState({ pageLoaded: false });
  }

  playProgress = () => {
    this.onProgressShow();
    setTimeout(() => {
      this.onProgressHide();
    }, 500);
  }

  render() {
    const {
      classes,
      route,
    } = this.props;
    const {
      pageLoaded,
    } = this.state;
    return (
      <ThemeContext.Consumer>
        {({theme}) => (
          <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
              <Loading
                show={pageLoaded}
                color="rgba(255,255,255,.9)"
                showSpinner={false}
              />
              {renderRoutes(route.routes)}
            </div>
          </MuiThemeProvider>
        )}
      </ThemeContext.Consumer>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired, 
  route: object,
};

App.defaultProps = {
  route: object,
};
 
export default withTheme()(withStyles(styles)(App));
