import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './notas-jss';
import { S3Image } from 'aws-amplify-react';

function getActions(cover, classes, title, description, autor, notes, attachment,color) {
  return (
    <Card className={classes.cardEditorial}>
      <div id="nose" className={classes.details} style={{backgroundColor:color}}>
      {cover!==""?<div  className={classNames(cover === 'ProfilePictures/1531234656_086401_1531239887_portadilla_normal.jpg' ? classes.coverS3 : classes.smallCoverS3)}>
        <S3Image imgKey={cover} className={classes.cover} />
      </div>:null}

      <CardContent className={classes.contenidoMedia}>
        <Typography className={classNames(classes.title, description === '<p></p>' && classes.autoHeight)} style={{color:color&&color.includes("#")&&color.toLowerCase()!="#ffffff"?"white":""}}>{title}</Typography>
        {cover === 'ProfilePictures/1531234656_086401_1531239887_portadilla_normal.jpg' && <Typography component="p" className={classes.autor}>{autor}</Typography>}
        <Typography color="textSecondary" className={classes.description}>
          <div style={{fontFamily:"sans-serif",color: color&&color.includes("#")&&color.toLowerCase()!="#ffffff"?"white":""}} 
          dangerouslySetInnerHTML={{ __html: `${description}` }} />
        </Typography>

        <Button variant="raised" color="primary" size="small" className={classNames(classes.mt, classes.button)} onClick={() => window.open(`/multimedia/${encodeURIComponent(attachment)}`)}>
          Leer
            </Button>
      </CardContent>
      </div>
    </Card>
  );
}

class Notas extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      classes,
      title,
      description,
      autor,
      cover,
      notes,
      attachment,color
    } = this.props;
    return (
      <>
        {getActions(cover, classes, title, description, autor, notes, attachment,color)}
      </>
    );
  }
}

Notas.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object,
  title: PropTypes.string,
  artist: PropTypes.string,
  cover: PropTypes.string,
  notes: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Notas);
