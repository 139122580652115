import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../new-jss';
import { fetchCall } from '../../../service/service_base';
import Grid from '@material-ui/core/Grid';
import ls from 'local-storage';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddNewEvent from '../../New/Evento/AddNewEvent';
import Loader from '../../Loader/Loader';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../Notification/CustomNotification';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

class EventoNew extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      noteSelected: null,
      openAddEvent: false,
      selectedEvent: null,
      isLoading: true,
      selEvent: null,
      openConfirmDialog: false,
      openNotification: false,
      typeNotification: "info",
      messageNotification: "",
      idDeleted: 0,
      action: 'Nuevo',
      selectedDate: null,
      dataEvents:[]
    }
  }

  handleCloseNotification = () => {
    this.setState({
      openNotification: false
    })
  }

  _translate = (str) => {
    switch (str) {
      case "Mon": return "Lu";
      case "Tue": return "Ma";
      case "Wed": return "Mi";
      case "Thu": return "Ju";
      case "Fri": return "Vi";
      case "Sat": return "Sa";
      case "Sun": return "Do";
    }
  }

  componentDidMount() {

    var curr = new Date; // get current date
    var currname = new Date();
    var last = new Date();
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    last.setDate(curr.getDate() - curr.getDay() + 6);

    var arraydays = new Array();

    for (var cont = 0; cont < 6; cont++) {
      curr = new Date; currname = new Date;
      var firstday = new Date(curr.setDate(first)).toISOString().split('T')[0];
      var name = new Date(currname.setDate(first)).toUTCString().split(',')[0];

      arraydays.push({
        name: this._translate(name),
        date: firstday,
        day: firstday.split("-")[2],
        month: firstday.split("-")[1],
        year: firstday.split("-")[0],
      });
      first++;
    }

    var lastday = last.toISOString().split('T')[0];
    name = last.toUTCString().split(',')[0]
    arraydays.push({
      name: this._translate(name),
      date: lastday,
      day: lastday.split("-")[2],
      month: lastday.split("-")[1],
      year: lastday.split("-")[0],
      eventos: [],
      ev: false
    });
    this.setState({
      events: arraydays
    });

    this._loadEvents();
    // console.log("lastday", new Date(curr.setDate(last)).toISOString().split('T')[0]);
    // console.log("firstday", firstday);

  }

  _loadEvents = () => {
    fetchCall("POST", "/synthesis/getcontent", {
      sectionId: this.props.section.sec_id,
      isPublish: this.props.isPublish,
      idPublish: this.props.idPublish
    }).then(response => {
      let aux = this.state.events.slice(0);
      aux.map((item, index) => {
        let searchEvents = response.data.filter(x => x.cont_order === Number.parseInt(item.day));
        if (searchEvents.length > 0) {
          aux[index].eventos = searchEvents;
          aux[index].ev = true;
        } else {
          aux[index].eventos = searchEvents;
          aux[index].ev = false;
        }
      });
      this.setState({ events: aux, isLoading: false, dataEvents: response.data});
    }).catch(err => console.log(err));
  }

  _handleCloseAddNewNote = () => {
    this.setState({
      openAddEvent: false,
      noteSelected: null,
      selEvent: null
    })
  }

  _handleAddNewEvent = (event, data) => {
    event.preventDefault();
    this.setState(
      {
        isLoading: true
      }, () => {
        if (data.title.toString().trim() === '') {
          this.setState({
            openNotification: true,
            messageNotification: 'Debes agregar un título',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }

        if (data.description.toString().trim() === '') {
          this.setState({
            openNotification: true,
            messageNotification: 'Debes agregar una descripción',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }

        if (data.notes.toString().trim() === '') {
          this.setState({
            openNotification: true,
            messageNotification: 'Debes agregar un número de notas',
            typeNotification: "warning",
            isLoading: false
          });
          return false;
        }


        data.sectionId = this.props.section.sec_id;
        data.userId = ls.get("current_user").user_id;

        if (data.isUpdating) {
          let exi = false;
          for (let i = 0; i < this.state.events.length; i++) {
            let item = this.state.events[i];
            let d = item.eventos.filter(x => x.cont_name === data.title.toString().trim() && x.cont_id !== this.state.selEvent.cont_id && x.cont_imagePath === this.state.selectedDate)[0]
            if (d) {
              exi = true;
            }
          }

          if (exi) {
            this.setState({
              openNotification: true,
              messageNotification: 'Ya existe un contenido con el mismo título',
              typeNotification: "warning",
              isLoading: false
            });
            return false;
          }

          data.imagePath = this.state.selectedDate;
          fetchCall("POST", "/synthesis/updatecontent", data).then(response => {
            if (response.status == 200) {
              this.setState({
                openNotification: true,
                messageNotification: 'Contenido actualizado',
                typeNotification: "success",
                isLoading: false,
                openAddEvent: false,
                selectedEvent: null,
                selectedDate: null
              });
              this._loadEvents();
            }
          }).catch(err => {
            this.setState({
              isLoading: false
            })
          });
        } else {

          let exi = false;
          for (let i = 0; i < this.state.events.length; i++) {
            let item = this.state.events[i];
            let d = item.eventos.filter(x => x.cont_name === data.title.toString().trim())[0]
            if (d) {
              if (this.state.selectedDate == d.cont_imagePath) {
                exi = true;
              }
            }
          }

          if (exi) {
            this.setState({
              openNotification: true,
              messageNotification: 'Ya existe un contenido con el mismo título',
              typeNotification: "warning",
              isLoading: false
            });
            return false;
          }
          data.order = this.state.events.length + 1
          data.imagePath = this.state.selectedDate;
          data.order = this.state.selectedEvent.day;
          data.idPublish = this.props.idPublish
          fetchCall("POST", "/synthesis/addcontent", data).then(response => {

            if (response.status == 200) {
              this.setState({
                openAddEvent: false,
                selectedEvent: null,
                selectedDate: null,
                openNotification: true,
                messageNotification: 'Contenido guardado',
                typeNotification: "success",
                isLoading: false
              });
              this._loadEvents();
            }
          }).catch(err => {
            this.setState({
              isLoading: false
            })
          });
        }
      }
    )

  }

  _removeContent = () => {
    this.setState(
      {
        isLoading: true
      }, () => {
        fetchCall("POST", "/synthesis/removecontentsection", {
          contId: this.state.idDeleted,
          userId: ls.get("current_user").user_id
        }).then(response => {
          if (response.status == 200) {
            this.setState({
              openConfirmDialog: false,
              idDeleted: 0,
              openNotification: true,
              messageNotification: 'Contenido eliminado',
              typeNotification: "success",
              isLoading: false
            });
            this._loadEvents();
          }
        }).catch(err => console.log(err));
      }
    )
  }

  _confirmRemoveContent = (item) => {
    this.setState({
      openConfirmDialog: true,
      idDeleted: item.cont_id
    });
  }

  _addEvent = (item) => {
    if (this.props.canEdit) {
      this.setState({
        openAddEvent: false,
        selectedDate: item.date,
        selectedEvent: item,
        selEvent: null
      }, () => {
        this.setState(
          {
            openAddEvent: true,
            action: 'Nuevo'
          }
        )
      })
    }
  }

  _editEvent = (evento, date) => {
    this.setState({
      openAddEvent: true,
      selEvent: evento,
      selectedDate: date,
      action: "Editar"
    });
  }

  _closeConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      idDeleted: 0
    });
  }

  handleDateChange = date => {
    var curr =  new Date(date) 
    var currname =  new Date(date) 
    var last =  new Date(date) 
    var first = curr.getDate() - curr.getDay(); 
    last.setDate(curr.getDate() - curr.getDay() + 6);
    var arraydays = new Array();

    for (var cont = 0; cont < 6; cont++) {
      curr = new Date(date); currname = new Date(date);
      var firstday = new Date(curr.setDate(first)).toISOString().split('T')[0];
      var name = new Date(currname.setDate(first)).toUTCString().split(',')[0];
      arraydays.push({
        name: this._translate(name),
        date: firstday,
        day: firstday.split("-")[2],
        month: firstday.split("-")[1],
        year: firstday.split("-")[0],
      });
      first++;
    }

    var lastday = last.toISOString().split('T')[0];
    name = last.toUTCString().split(',')[0]
    arraydays.push({
      name: this._translate(name),
      date: lastday,
      day: lastday.split("-")[2],
      month: lastday.split("-")[1],
      year: lastday.split("-")[0],
      eventos: [],
      ev: false
    });

    
    arraydays.forEach((item, index) => {
        let searchEvents = this.state.dataEvents.filter(x => x.cont_order === Number.parseInt(item.day));
        if (searchEvents.length > 0) {
          arraydays[index].eventos = searchEvents;
          arraydays[index].ev = true;
        } else {
          arraydays[index].eventos = searchEvents;
          arraydays[index].ev = false;
        }
      });
      this.setState({ events: arraydays,selectedDate: new Date(date) })
   
  };

  render() {
    const {
      classes,
    } = this.props;

    const getEvents = (classes, itemDate) => {
      if (itemDate.ev) {
        if (itemDate.eventos) {
          return itemDate.eventos.map((item, index) => {
            item.cont_imagePath = item.cont_key
            item.cont_attachment = item.cont_attachmentKey
            return <div key={index.toString()} className={classes.eventContent}>
              <Typography className={classes.title}>
                {item.cont_name}
              </Typography>
              <Typography component="p" color="textSecondary" className={classes.descriptionEvent}>
                {item.cont_description}
              </Typography>
              <div className={classes.eventActions}>
                <Tooltip title="Editar">
                  <Button variant="fab" mini disabled={!this.props.canEdit} onClick={() => { this._editEvent(item, itemDate.date) }} aria-label="add" className={classes.button}>
                    <Edit />
                  </Button>
                </Tooltip>

                <Tooltip title="Eliminar">
                  <Button variant="fab" mini disabled={!this.props.canDelete} onClick={() => { this._confirmRemoveContent(item) }} aria-label="add" className={classes.button}>
                    <Delete />
                  </Button>
                </Tooltip>

              </div>
              <hr></hr>
            </div>
          })
        }
      }
    }

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <div className={classes.picker}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  //keyboard={true}
                  label="Selecionar fecha"
                  format="DD/MM/YYYY"
                  placeholder="10/10/2018"
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  value={this.state.selectedDate}
                  onChange={this.handleDateChange}
                  animateYearScrolling={false}
                  cancelLabel={"Cancelar"}
                  okLabel="Ver"
                //disableFuture={true}
                //helperText=""
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={8}
              alignItems="flex-start"
              direction="row"
              justify="center"
              className={classes.portadas}
            >
              {
                this.state.isLoading ? <Loader text={"Cargando.."} /> :

                  this.state.events.map((item, index) => {

                    return (
                      <Grid key={index.toString()} item>
                        <Paper className={classes.diaSemana}>
                          <div className={classNames(item.ev ? classes.activo : '', classes.contentDia)} onClick={() => { this._addEvent(item) }}>
                            <Typography component="p" color="textSecondary">
                              {item.name}
                            </Typography>
                            <Typography component="p" color="textSecondary">
                              {item.day}
                            </Typography>
                          </div>
                          <div className={classes.contentPendientes}>
                            {getEvents(classes, item)}
                          </div>
                        </Paper>
                      </Grid>
                    );
                  })

              }
            </Grid>
          </Grid>
        </Grid>

        <AddNewEvent
          classes={classes}
          submit={this._handleAddNewEvent}
          openForm={this.state.openAddEvent}
          closeForm={this._handleCloseAddNewNote}
          selectedDate={this.state.selectedDate}
          dataInit={this.state.selEvent}
          action={this.state.action}
        />

        <ConfirmDialog
          title={"¿Desea eliminar el contenido?"}
          message={"El contenido será eliminado y no podrá recuperarse"}
          textAccept={"Aceptar"}
          textCancel={"Cancelar"}
          funcAccept={this._removeContent}
          funcCancel={this._closeConfirmDialog}
          open={this.state.openConfirmDialog}
        />


      </div>
    );
  }
}

EventoNew.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool
};

export default withStyles(styles)(EventoNew);
