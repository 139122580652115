
const styles = theme => ({
  CardButton: {
    width: 400,
    height: 50,
    maxHeight: 50,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  relative: {
    position: 'relative'
  },
  btnContrast:{
    color: "#cacaca"
  },
  btnActive: {
    color: '#ffffff'
  },
  btnCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '10px 20px',
    lineHeight: 1.1,
    borderRadius: 20,
    height: 40,
    textDecoration: 'none',
    '& svg': {
      fontSize: 18,
      marginRight: 5
    }
  },
  adminActions: {
    width: 50,
    textAlign: 'center',
    '& button': {
      width: 30,
      height: 30,
      minHeight: 30,
      marginLeft: 5,
      backgroundColor: '#fff',
      // border
    },
    '& svg': {
      fontSize: 18,
      color: theme.palette.primary.main,
    }
  },
});

export default styles;
