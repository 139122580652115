import pink from '@material-ui/core/colors/pink';
import lightGreen from '@material-ui/core/colors/lightGreen';

const styles = theme => ({
  card: {
    minWidth: 275,
  },
  cardEditorial:{
    position: 'relative',
    flexGrow: 1,
  },
  liked: {
    color: pink[500]
  },
  shared: {
    color: lightGreen[500]
  },
  cardPlayer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardRelevante: {
    backgroundColor: 'transparent !important',
    borderRadius: "5px 5px 26px 5px !important",
    boxShadow: 'none !important',
    border: '3px solid #e0e0e0',
    textAlign: 'center !important',
  },
  cardMediaRadio: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 275,
    padding: 0,
    borderRadius: '0 !important',
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important'
  },
  mediaRadio:{
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //padding: '0 70px',
    cursor: 'pointer',
    position: "relative",
    width: '100%',
    // '@media (max-width: 360px)': {
    //   padding: '0 40px',
    // },
    '& div':{
      width: '100%',
      height: '100%',
    },
    '& img':{
      width: '100%',
      height: '185px',
      objectFit: "cover"
    }
  },

  mediaRadio2:{
    backgroundColor: theme.palette.primary.main,
    '& div':{
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    '& img':{
      height: '100%',
      width: '300px',
      objectFit: 'none',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        height: '200px',
      },
    },
    cursor:"pointer"
  },
  contenidoMediaIcon:{
    width: '100%',
    backgroundColor: '#e6e6e6'
  },
  contenidoMedia: {
    padding: '.7rem !important',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  content: {
    flex: '1 0 auto',
    paddingBottom: '0 !important'
    //padding: theme.spacing.unit,
  },
  mediaEditoriales: {
    position: 'relative',
    backgroundColor: 'aliceblue',
    margin: '0 24px',
    height: 175,
    overflow: 'hidden',
    '& div':{
      height: '100%',
    },
    '& div':{
      height: '100%',
    },
    '& img':{
      position: 'absolute',
      //top: '-25%',
      width: '100%'
    }
  },
  coverS3: {
    // height: 186,
    // width: 180,
    height: 175,
    width: '100%',
    minWidth: 180,
    overflow: 'hidden',
    '& div': {
      width: '100%',
      height: '100%',
      backgroundColor: '#f2f2f2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& img ': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
    '& img ~ div': {
      display: 'none'
    }
  },
  smallCoverS3: {
    minHeight: 200,
    height: 200,
    width: '100%',
    minWidth: 180,
    overflow: 'hidden',
    '& div': {
      width: '100%',
      height: '100%',
      backgroundColor: '#f2f2f2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img':{
        height: '100%',
        width: '100%',
        objectFit: 'cover'
      },
    },
    '& img ~ div': {
      display: 'none'
    }
  },
  actionsS3: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, .6)',
    width: 180,
    display: 'flex',
    bottom: 0,
    right: 0,
    zIndex: 1,
    padding: 0
  },
  cover: {
    minWidth: 180,
    height: 175,
    display: 'flex',
    alignItems: 'flex-end',
    '& div:first-child':{
      width: '100%',
      backgroundColor: 'rgba(255,255,255,0.4)'
    }
  },
  rightAction: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& small': {
        fontSize: 12
    }
  },
  title: {
    fontSize: 18,
    minHeight: 80,
    color: theme.palette.primary.main
  },
  autoHeight: {
    height: 'auto',
  },
  titleRadio: {
    fontSize: '18px',
  },
  programRadio: {
    color: theme.palette.primary.main,
    opacity: 0.7,
    fontFamily: "Roboto"
  },
  autor:{
    fontWeight: 300,
    fontSize: '1.1rem',
    marginBottom: 10
  },
  description: {
    flexGrow: 1,
  },
  descriptionImg: {
    height: 120,
    marginBottom: 20,
    display: 'block',
    display: '-webkit-box',
    WebkitLineClamp: 6,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    //whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  actions: {
    display: 'flex',
    padding: 0,
    // se elimina margen
    margin: '0 -24px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 -16px',
    },
  },
  button: {
    marginRight: theme.spacing.unit,
    lineHeight: '1em',
    minHeight: 'initial',
    marginTop: 5,
    padding: '5px 14px',
    fontWeight:400
  },
  mb: {
    marginBottom: '1rem'
  },
  mt: {
    marginTop: '1rem'
  }
});

export default styles;
